import { alpha, Theme } from "@mui/material/styles";
import classNames from 'classnames';
import { FormHelperTextProps } from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useMemo, useState } from "react";
import { updateCase, UpdateCaseFailReason, updateCaseOptions } from "../../actions/GatherCase.action";
import { isValidCaseName, joinNameParts } from "../../services";
import { canUpdateCase } from "../../shared/authority/can";
import { CaseOptionsUpdateRequest, GatherCaseUpdateRequest, GatherCaseUX } from "../../shared/types";
import { useGDispatch, useGSelector } from "../../types";
import GButton from "../common/GButton";
import GTextField from "../common/GTextField";
import GDialog from "../common/GDialog";
import UserAvatar from "../common/UserAvatar";
import ChangeDecedentNameForm from "./ChangeDecedentNameForm";

const useStyles = makeStyles((theme: Theme) => ({
    dialogPaper: {
        minWidth: '100% !important',
        maxWidth: '100% !important',
        minHeight: '100% !important',
        maxHeight: '100% !important',
        [theme.breakpoints.up('md')]: {
            minWidth: '760px !important',
            maxWidth: '760px !important',
            height: 'unset !important',
            minHeight: 'unset !important',
        },
    },
    dialogContent: {
        padding: '20px 16px'
    },
    fontWeight500: {
        fontWeight: 500
    },
    borderedContainer: {
        border: `1px solid ${theme.palette.primary.main}`,
        padding: '10px 8px 16px !important',
        background: alpha(theme.palette.primary.main, 0.08),
        borderRadius: '6px'
    },
    caseAvatar: {
        height: 92,
        width: 92,
        fontSize: 36,
        fontWeight: 300,
        margin: 'auto'
    },
    displayFlex: {
        display: 'flex',
        flexDirection: 'column',
    },
}), { name: 'ChangeDecedentNameDialog' });
interface CaseNameFields extends Pick<GatherCaseUX,
    | 'fname'
    | 'lname'
    | 'mname'
    | 'suffix'
    | 'display_full_name'
    | 'display_fname'
    | 'name'
    | 'uuid'
    | 'funeral_home_id'
> {
}

interface Props {
    isOpen: boolean;
    gatherCase: CaseNameFields;
    zIndex: number;
    onClose: () => void;
}

const ChangeDecedentNameDialog = (props: Props) => {
    const {
        isOpen,
        zIndex,
        gatherCase,
        onClose,
    } = props;

    const classes = useStyles();

    const dispatch = useGDispatch();

    const user = useGSelector(({ userSession }) => userSession.userData);
    const canUserUpdateCase = canUpdateCase(user, gatherCase);

    const [fname, setFname] = useState<string>('');
    const [mname, setMname] = useState<string | null>(null);
    const [lname, setLname] = useState<string>('');
    const [suffix, setSuffix] = useState<string | null>(null);
    const [displayFullName, setDisplayFullName] = useState<string>('');
    const [displayFirstName, setDisplayFirstName] = useState<string>('');
    const [caseName, setCaseName] = useState<string>('');
    const [isCaseNameAlreadyTaken, setIsCaseNameAlreadyTaken] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [saveAttempted, setSaveAttempted] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setFname(gatherCase.fname);
            setMname(gatherCase.mname);
            setLname(gatherCase.lname);
            setSuffix(gatherCase.suffix);
            setDisplayFullName(gatherCase.display_full_name);
            setDisplayFirstName(gatherCase.display_fname);
            setCaseName(gatherCase.name);
            setIsCaseNameAlreadyTaken(false);
        }
    }, [
        gatherCase.fname,
        gatherCase.mname,
        gatherCase.lname,
        gatherCase.suffix,
        gatherCase.display_full_name,
        gatherCase.display_fname,
        gatherCase.name,
        isOpen,
    ]);

    useEffect(() => {
        setIsCaseNameAlreadyTaken(false);
    }, [caseName]);

    const isFNameInvalid = !fname.trim();
    const isLNameInvalid = !lname.trim();
    const isDisplayFullNameInvalid = !displayFullName.trim();
    const isDisplayFNameInvalid = !displayFirstName.trim();
    const isCaseNameInvalid = !isValidCaseName(caseName) || isCaseNameAlreadyTaken;
    const onSave = async () => {
        if (isFNameInvalid
            || isLNameInvalid
            || isDisplayFNameInvalid
            || isDisplayFullNameInvalid
            || isCaseNameInvalid
        ) {
            setSaveAttempted(true);
            return;
        }

        setIsSaving(true);

        let result: GatherCaseUX | UpdateCaseFailReason | null;

        if (canUserUpdateCase) {
            const changes: GatherCaseUpdateRequest = {
                name: caseName,
                fname,
                mname,
                lname,
                suffix,
                display_full_name: displayFullName,
                display_fname: displayFirstName,
            };
            result = await dispatch(updateCase(gatherCase.uuid, changes));
            if (result && result !== UpdateCaseFailReason.caseNameUsed) {
                const url = new URL(window.location.href);
                if (url.pathname.includes(`/${gatherCase.name}`) && gatherCase.name !== result.name) {
                    // if we are on a case URL and the case.name changes then update the URL 
                    url.pathname = url.pathname.replace(`/${gatherCase.name}`, `/${result.name}`);
                    window.history.replaceState({}, '', url);
                }
            }
        } else {
            const changes: CaseOptionsUpdateRequest = {
                fname,
                mname,
                lname,
                suffix,
                display_full_name: displayFullName,
                display_fname: displayFirstName,
            };
            result = await dispatch(updateCaseOptions(gatherCase.uuid, changes));
        }
        if (result && result !== UpdateCaseFailReason.caseNameUsed) {
            onClose();
        } else if (result === UpdateCaseFailReason.caseNameUsed) {
            setIsCaseNameAlreadyTaken(true);
        }
        setSaveAttempted(true);
        setIsSaving(false);
    };

    const caseFullName = joinNameParts(gatherCase);

    const formHelperTextProps: FormHelperTextProps = useMemo(() => ({
        sx: {
            lineHeight: 1.25,
            textAlign: 'center',
            marginTop: '12px',
            color: theme => `${theme.palette.secondary.main} !important`
        }
    }), []);

    const dialogTitleElement = useMemo(() => (
        <Grid container component="span" direction="column">
            <UserAvatar user={gatherCase} size={92} className={classes.caseAvatar} />

            <Typography component="span" lineHeight="1" color="#fff" align="center" mt="16px" fontSize="22px">
                Change {gatherCase.fname}'s Name
            </Typography>
        </Grid>
    ), [classes.caseAvatar, gatherCase]);

    return (
        <GDialog
            zIndex={zIndex}
            isOpen={isOpen}
            disableTypography
            title={dialogTitleElement}
            paperClass={classes.dialogPaper}
            contentClass={classes.dialogContent}
            fullScreenBreakpoint="md"
            onClose={onClose}
        >
            <Typography color="secondary" mt={1} mb={10}>
                The settings below for {caseFullName} will be applied everywhere
            </Typography>

            <Grid container spacing="16px">
                <ChangeDecedentNameForm
                    fname={fname}
                    mname={mname}
                    lname={lname}
                    suffix={suffix}
                    saveAttempted={saveAttempted}
                    onChangeFname={setFname}
                    onChangeMname={setMname}
                    onChangeLname={setLname}
                    onChangeSuffix={setSuffix}
                    isFNameInvalid={isFNameInvalid}
                    isLNameInvalid={isLNameInvalid}
                />

                <Grid item xs={12} md={6}>
                    <Grid item xs={12} maxWidth={{ xs: '400px', md: 'unset' }} m="auto">
                        <Grid item xs={12} className={classes.borderedContainer}>
                            <GTextField
                                value={displayFullName}
                                label="Display Name"
                                required
                                error={isDisplayFullNameInvalid && saveAttempted}
                                onValueChange={setDisplayFullName}
                                formControlProps={{ sx: { mt: 0 } }}
                                FormHelperTextProps={formHelperTextProps}
                                helperText="The display name is the name that will show on the Remember pages."
                            />
                        </Grid>

                        <Grid item xs={12} mt="20px" className={classes.borderedContainer}>
                            <GTextField
                                value={displayFirstName}
                                label="Display First Name"
                                required
                                error={isDisplayFNameInvalid && saveAttempted}
                                formControlProps={{ sx: { mt: 0 } }}
                                onValueChange={setDisplayFirstName}
                                FormHelperTextProps={formHelperTextProps}
                                helperText={<>
                                    The display first name will be used anywhere the site uses your loved one's first
                                    name to describe them. (Example: How
                                    did <span className={classes.fontWeight500}>Jason</span> change the world?)
                                </>}
                            />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            mt="20px"
                            className={classNames(classes.borderedContainer, classes.displayFlex)}
                        >
                            <Typography
                                component="span"
                                align="left"
                                fontSize="12px"
                                color={isCaseNameInvalid && saveAttempted ? 'error' : 'primary'}
                            >
                                Website URL *
                            </Typography>

                            <Typography
                                component="span"
                                color="secondary"
                                sx={formHelperTextProps.sx}
                                m="4px 0 8px !important"
                                fontSize="12px"
                            >
                                This is the URL that will show at the top of the page for both the website and
                                the Remember Page. This URL has to be unique and used by no other decedents.
                            </Typography>

                            {canUserUpdateCase &&
                                <GTextField
                                    value={caseName}
                                    label={`${location.origin}/remember/`}
                                    placeholder="Enter Unique Ending"
                                    formControlProps={{ sx: { mt: 0 } }}
                                    onValueChange={setCaseName}
                                    error={isCaseNameInvalid && saveAttempted}
                                    InputLabelProps={{ shrink: true }}
                                    helperText="Can only contain numbers, letters and dashes (-). No spaces"
                                    helperTextOnError={isCaseNameAlreadyTaken
                                        ? 'This URL has already been used. Please choose something different'
                                        : !caseName
                                            ? 'URL is required'
                                            : undefined
                                    }
                                />
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container justifyContent="space-between" mt={4}>
                <GButton
                    text="Cancel"
                    variant="text"
                    size="large"
                    color="inherit"
                    onClick={onClose}
                />
                <GButton
                    size="large"
                    text="Save"
                    variant="contained"
                    isSpinning={isSaving}
                    onClick={onSave}
                />
            </Grid>
        </GDialog>
    );
};

export default ChangeDecedentNameDialog;
