import momentTz from 'moment-timezone';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import IconButton from '@mui/material/IconButton';
import TreeIcon from '@mui/icons-material/Park';
import PlantedIcon from '@mui/icons-material/VolunteerActivism';
import VerifiedIcon from '@mui/icons-material/Verified';

import { getFullNameFromCase, getIntercomTargetProp } from '../../../services';
import UserAvatar from '../../common/UserAvatar';
import HorizontalDivider from '../../family/goodsAndServices/widgets/HorizontalDivider';
import { GatherCasePublic } from '../../../shared/types';
import GLink from '../../common/GLink';
import { RouteBuilder } from "../../../services";
import makeGStyles from '../../../styles/makeGStyles';

export interface HeaderStyles {
    barType: 'store' | 'login';
    propIdx?: number;
    overrideShadow?: boolean;
}

const useStyles = makeGStyles(theme => ({
    root: {
    },
    memoriamSection: {
        marginTop: 48,
        maxWidth: 350,
        margin: 'auto',
        // padding: '0 12px',
        '@media (min-width: 440px)': {
            maxWidth: 428,
        }
    },
    headerSection: {
        marginTop: 1,
        maxWidth: 350,
        margin: 'auto',
        // padding: '0 12px',
        '@media (min-width: 440px)': {
            maxWidth: 428,
        }

    },
    avatarWithDividers: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    avatarSides: {
        display: 'flex',
        flexDirection: 'column',
        '& p': {
            fontSize: 14,
            lineHeight: 'normal',
            '@media (min-width: 960px)': {
                fontSize: 16,
            }
        }
    },
    marginRight12: {
        marginRight: 12
    },
    customLink: {
        color: theme.palette.primary.main,
        backgroundColor: 'transparent',
        textDecoration: 'none',
        '& button': {
            width: 60,
            height: 60,
        }
    },
    learnMore: {
        color: theme.palette.primary.main,
        fontSize: 14,
        textAlign: 'center',
        width: "100%",
        fontWeight: theme.typography.fontWeightMedium,
    },
    headerAvatar: {
        width: 60,
        height: 60,
    },
    marginLeft12: {
        marginLeft: 12
    },
    caseName: {
        fontFamily: '\'Great Vibes\', cursive !important',
        lineHeight: '1.25em',
        color: theme.palette.primary.main,
        width: 'fit-content',
        margin: '4px auto',
        fontSize: 28,
        textAlign: 'center',
        '@media (min-width: 960px)': {
            fontSize: 38,
        }
    },
    features: (props: HeaderStyles) => {
        const isLogin = props.barType === 'login';
        return {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 6,
            position: isLogin ? 'absolute' : undefined,
            top: isLogin ? 25 : undefined,
            '& .MuiSvgIcon-root': {
                marginBottom: `${isLogin ? 0 : 4} !important`,
            }
        };
    },
    buttonSection: {
        maxWidth: 74
    },
    iconButton: {
        textTransform: 'none',
        maxWidth: 74,
        padding: 0,
        '& svg': {
            fontSize: 22,
            '@media (min-width: 960px)': {
                fontSize: 24,
            }
        },
        '&:hover': {
            cursor: 'auto',
            // backgroundColor: 'inherit'
            backgroundColor: 'transparent',
        }
    },
    buttonLabel: (props: HeaderStyles) => {
        return {
            display: 'flex',
            flexDirection: 'column',
            color: props.barType === 'store'
                ? theme.palette.primary.main : theme.palette.common.white,
        };
    },
    buttonText: (props: HeaderStyles) => {
        const overrideShadow = props.overrideShadow;
        return {
            fontSize: 10,
            lineHeight: '12px',
            fontWeight: 500,
            '@media (min-width: 960px)': {
                fontSize: 12
            },
            textShadow: overrideShadow ? undefined : '0px 2px 9px rgba(0, 0, 0, 0.95)',
        };
    },
    marginTop0: {
        marginTop: 0
    },
    wordNoWrap: {
        whiteSpace: 'nowrap'
    },
    paddingLR12: {
        padding: '0 12px'
    },
    pullUpNextSection: {
        marginBottom: -40
    },
}), { name: 'FlowerSalesHeader' });

interface Props {
    gatherCase: GatherCasePublic;
    isOrderPage?: boolean;
    isFlowerListPage?: boolean;
}

export const TreeIconBar = (props: HeaderStyles) => {
    const classes = useStyles(props);
    const idx = `${props.propIdx || ''}`;
    return (
        <Grid item xs={12} className={classes.features}>
            <Grid
                item
                xs={12}
                className={classes.buttonSection}
                {...getIntercomTargetProp(`TreesPlantedByArborDayFoundation${idx}`)}
            >
                <Button
                    color="primary"
                    className={classes.iconButton}
                    classes={{
                        text: classes.buttonLabel
                    }}
                    disableRipple
                >
                    <TreeIcon />
                    <span className={classes.buttonText}>
                        Arbor Day Foundation
                    </span>
                </Button>
            </Grid>

            <Grid
                item
                xs={12}
                className={classes.buttonSection}
                {...getIntercomTargetProp(`TreesPlantedByHand${idx}`)}
            >                <Button
                color="primary"
                className={classes.iconButton}
                classes={{
                    text: classes.buttonLabel
                }}
                disableRipple
            >
                    <PlantedIcon />
                    <span className={classes.buttonText}>
                        Planted by Hand
                    </span>
                </Button>
            </Grid>

            <Grid
                item
                xs={12}
                className={classes.buttonSection}
                {...getIntercomTargetProp(`TreesVerifiedByArborDayFoundation${idx}`)}
            >                <Button
                color="primary"
                className={classes.iconButton}
                classes={{
                    text: classes.buttonLabel
                }}
                disableRipple
            >
                    <VerifiedIcon />
                    <span className={classes.buttonText}>
                        Verified Certificate
                    </span>
                </Button>
            </Grid>

            <Grid
                item
                xs={12}
                className={classes.buttonSection}
                {...getIntercomTargetProp(`TreesPublicOrAnonymous${idx}`)}
            >                <Button
                color="primary"
                className={classes.iconButton}
                classes={{
                    text: classes.buttonLabel
                }}
                disableRipple
            >
                    <LoyaltyIcon />
                    <span className={classes.buttonText}>
                        Public or Anonymous
                    </span>
                </Button>
            </Grid>

        </Grid>
    );
};

export const FlowerIconBar = (props: HeaderStyles) => {
    const classes = useStyles(props);
    const idx = `${props.propIdx || ''}`;
    return (
        <Grid item xs={12} className={classes.features}>
            <Grid
                item
                xs={12}
                className={classes.buttonSection}
                {...getIntercomTargetProp(`FlowersPreparedByLocalFlorist${idx}`)}
            >
                <Button
                    color="primary"
                    className={classes.iconButton}
                    classes={{
                        text: classes.buttonLabel
                    }}
                    disableRipple
                >
                    <LocalFloristIcon />
                    <span className={classes.buttonText}>
                        Prepared By Local Florist
                    </span>
                </Button>
            </Grid>

            <Grid
                item
                xs={12}
                className={classes.buttonSection}
                {...getIntercomTargetProp(`FlowersSameDayArrival${idx}`)}
            >                <Button
                color="primary"
                className={classes.iconButton}
                classes={{
                    text: classes.buttonLabel
                }}
                disableRipple
            >
                    <LocalShippingIcon />
                    <span className={classes.buttonText}>
                        Same-Day Arrival
                    </span>
                </Button>
            </Grid>

            <Grid
                item
                xs={12}
                className={classes.buttonSection}
                {...getIntercomTargetProp(`FlowersHandDelivered${idx}`)}
            >                <Button
                color="primary"
                className={classes.iconButton}
                classes={{
                    text: classes.buttonLabel
                }}
                disableRipple
            >
                    <EmojiPeopleIcon />
                    <span className={classes.buttonText}>
                        Guaranteed Hand Delivery
                    </span>
                </Button>
            </Grid>

            <Grid
                item
                xs={12}
                className={classes.buttonSection}
                {...getIntercomTargetProp(`FlowersPublicOrAnonymous${idx}`)}
            >                <Button
                color="primary"
                className={classes.iconButton}
                classes={{
                    text: classes.buttonLabel
                }}
                disableRipple
            >
                    <LoyaltyIcon />
                    <span className={classes.buttonText}>
                        Public or Anonymous
                    </span>
                </Button>
            </Grid>
        </Grid>
    );
};

export const FlowerSalesHeader = (props: Props) => {
    const { isOrderPage, isFlowerListPage } = props;

    const classes = useStyles({ barType: 'store' });
    return (
        <Grid
            container
            className={classNames(
                classes.headerSection,
                isOrderPage && classes.marginTop0,
                isFlowerListPage && classes.paddingLR12
            )}>

            <HorizontalDivider color="primary" inset="none" />
            <FlowerIconBar barType='store' overrideShadow={true}/>
        </Grid>
    );
};

export const InMemoriamHeader = (props: Props) => {
    const { gatherCase, isOrderPage, isFlowerListPage } = props;

    const classes = useStyles({ barType: 'store' });

    const deathYear = momentTz(gatherCase.dod_start_date && gatherCase.dod_start_date || '').year();
    const birthYear = momentTz(gatherCase.dob_date && gatherCase.dob_date || '').year();
    const rememberPageLink = RouteBuilder.RememberPage(gatherCase.name);

    return (
        <Grid
            container
            className={classNames(
                classes.memoriamSection,
                isOrderPage && classes.marginTop0,
                isFlowerListPage && classes.paddingLR12
            )}>
            <Grid item xs={12} className={classes.avatarWithDividers}>
                <Grid item xs={12} className={classes.avatarSides}>
                    <HorizontalDivider color="primary" inset="none" />
                    <Typography
                        color="primary"
                        align="right"
                        className={classNames(classes.marginRight12, classes.wordNoWrap)}
                    >
                        In Memory of
                    </Typography>
                </Grid>
                <GLink to={rememberPageLink} linkClass={classes.customLink}>
                    <IconButton size="large">
                        <UserAvatar
                            id="avtarIconButton"
                            user={gatherCase}
                            className={classes.headerAvatar}
                            size={60}
                        />
                    </IconButton>
                </GLink>
                <Grid item={true} xs={12} className={classes.avatarSides}>
                    <HorizontalDivider color="primary" inset="none" />
                    <Typography
                        color="primary"
                        align="left"
                        className={classes.marginLeft12}
                        style={{
                            height: 20
                        }}
                    >
                        {birthYear && deathYear && (`${birthYear}-${deathYear}`) || ''}
                    </Typography>
                </Grid>
            </Grid>

            <Grid item={true} xs={12} className={classes.caseName}>
                <GLink
                    to={rememberPageLink}
                    linkClass={classes.customLink}
                >
                    {getFullNameFromCase(gatherCase)}
                </GLink>
            </Grid>
        </Grid>
    );
};
