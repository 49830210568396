import classNames from 'classnames';

import Grid from '@mui/material/Grid';

import { DeathCertificateWorkHistoryType, OccupationListOfOptions } from '../../../../shared/types';
import YearNumberMask from '../../../common/YearMask';
import { FormParent, ParentProps } from './FormParent';
import {
    workHistoryValidators,
    workHistoryMasterValidators
} from '../../../../shared/death_certificate/validators/workHistory';
import FormTitle from '../common/FormTitle';
import ConfigurableToggleButtonsWrapper from '../common/fields/toggleButtons/ConfigurableToggleButtons';
import { ConfigurableWorkHistoryFieldKey } from '../../../../shared/death_certificate/validators/config';
import { values } from 'lodash';
import
ConfigurableTextFieldWithCheckbox
    from '../common/fields/textFieldWithCheckbox/ConfigurableTextFieldWithCheckbox';
import ConfigurableTextField from '../common/fields/textField/ConfigurableTextField';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles, withStyles } from '@mui/styles';
import { GStyles } from '../../../../styles/GStyles';

const ConfigurableToggleButtons = ConfigurableToggleButtonsWrapper<OccupationListOfOptions>();

interface Props {
    caseFname: string;
    handleOpenSnackbar: () => void;
}

interface State {
    localData: DeathCertificateWorkHistoryType;
    employmentStatusValidator: boolean | null;
    normalOccupationValidator: boolean | null;
    normalOccupationIndustryValidator: boolean | null;
    currentEmployerValidator: boolean | null;
    yearsInOccupationValidator: boolean | null;
    retiredYearValidator: boolean | null;
    retiredLastEmployerValidator: boolean | null;
}

const styles: StyleRulesCallback<Theme, Props & ParentProps<DeathCertificateWorkHistoryType>> = (theme) => ({
    root: {
        justifyContent: 'center',
        flexGrow: 1,
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
    },
    inviteHelperForm: {
        margin: 'auto',
        '@media (max-width: 599px)': {
            padding: '0 24px',
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: 500,
        },
    },
    checkbox: {
        width: 'auto',
        marginRight: 10
    },
    toggleContainer: {
        height: 56,
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        '@media (max-width: 500px)': {
            marginTop: 10,
        },
    },
    toggleButton: {
        fontSize: '13px !important',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    toggleButtonGroupLabel: {
        position: 'relative',
        transform: 'none',
        textAlign: 'center',
    },
    inputTextWidth: {
        width: '100%',
        '@media (min-width: 421px)': {
            width: 360,
        },
    },
    margin_0_16: {
        margin: '16px 0'
    },
    employmentButton: {
        '@supports (-webkit-touch-callout: none)': {
            '& button:nth-of-type(4)': {
                marginTop: -2
            }
        }
    }
});

type StyledProps = Props & WithStyles<'root' | 'inviteHelperForm' | 'buttonSuccess' | 'checkbox'
    | 'toggleButton' | 'toggleButtonGroupLabel' | 'toggleContainer' | 'inputTextWidth' | 'margin_0_16'
    | 'employmentButton'>;

class WorkHistoryForm extends FormParent<DeathCertificateWorkHistoryType, StyledProps, State> {

    state = {
        localData: this.props.data,
        employmentStatusValidator: null,
        normalOccupationValidator: null,
        normalOccupationIndustryValidator: null,
        currentEmployerValidator: null,
        yearsInOccupationValidator: null,
        retiredYearValidator: null,
        retiredLastEmployerValidator: null
    };

    handleRetiredInYearChange = (value: string) => {

        this.updateData({ retiredInYear: value }, this.validateRetiredInYear);
    };

    handleRetiredLastEmployerChange = (value: string) => {

        this.updateData({ retiredLastEmployer: value }, this.validateRetiredLastEmployer);
    };

    handleCurrentEmployerChange = (value: string) => {

        this.updateData({ currentEmployer: value }, this.validateCurrentEmployer);
    };

    handleYearsInOccupationChange = (value: string) => {

        this.updateData({ yearsInOccupation: value }, this.validateYearsInOccupation);
    };

    handleNormalOccupationIndustryChange = (value: string) => {

        this.updateData({ normalOccupationIndustry: value }, this.validateNormalOccupationIndustry);
    };

    handleNormalOccupationChange = (value: string) => {

        this.updateData({ normalOccupation: value }, this.validateNormalOccupation);
    };

    handleKindBusinessUnknown = (isChecked: boolean) => {

        const changes: DeathCertificateWorkHistoryType = {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            normalOccupationIndustry_unknown: isChecked,
        };
        if (isChecked) {
            changes.normalOccupationIndustry = '';
        }
        this.updateData(changes, this.validateNormalOccupationIndustry);
    };

    handleNormalOccupationUnknown = (isChecked: boolean) => {

        const changes: DeathCertificateWorkHistoryType = {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            normalOccupation_unknown: isChecked,
        };
        if (isChecked) {
            changes.normalOccupation = '';
        }
        this.updateData(changes, this.validateNormalOccupation);
    };

    handleEmploymentChange = (occupationListOfOptions: OccupationListOfOptions) => {

        this.updateData(
            (prevData) => {
                const changes: Partial<DeathCertificateWorkHistoryType> = {
                    occupationListOfOptions,
                };
                const prevEmployment = prevData.occupationListOfOptions;

                // if previously was retired, just clear retired date
                if (prevEmployment === OccupationListOfOptions.Retired) {
                    changes.retiredInYear = undefined;
                }

                // if previously was employed, just clear name of current employer
                if (prevEmployment === OccupationListOfOptions.Employed) {
                    changes.currentEmployer = '';
                }

                // if new employment option is Never Employed, just clear all fields
                if (occupationListOfOptions === OccupationListOfOptions['Never Employed']) {
                    changes.normalOccupation = '';
                    changes.normalOccupation_unknown = false;
                    changes.normalOccupationIndustry = '';
                    changes.normalOccupationIndustry_unknown = false;
                    changes.retiredInYear = undefined;
                    changes.currentEmployer = '';
                    changes.retiredLastEmployer = '';
                    changes.yearsInOccupation = undefined;
                }
                return changes;
            },
            this.validateEmploymentStatus,
        );
    };

    getContentIfRetired = () => {
        const {
            caseFname,
            isDeathCertificateLocked,
            handleOpenSnackbar,
            isEditMode
        } = this.props;
        const {
            localData,
            retiredYearValidator,
            retiredLastEmployerValidator
        } = this.state;

        return (
            <Grid container alignItems="flex-end" justifyContent="center" >
                <Grid item className={classNames(GStyles.width100, GStyles.textCenter)}>
                    <ConfigurableTextField
                        id={ConfigurableWorkHistoryFieldKey.retiredInYear}
                        label={`In what year did ${caseFname} retire?`}
                        value={localData.retiredInYear || ''}
                        onChange={this.handleRetiredInYearChange}
                        inputType="tel"
                        inputComponent={YearNumberMask}
                        disabled={isDeathCertificateLocked}
                        handleOpenSnackbar={handleOpenSnackbar}
                        error={retiredYearValidator === false}
                        onBlur={this.validateRetiredInYear}
                        isEditMode={isEditMode}
                    />

                    <ConfigurableTextField
                        id={ConfigurableWorkHistoryFieldKey.retiredLastEmployer}
                        label="Last Employer (Name, City, State)"
                        value={localData.retiredLastEmployer || ''}
                        onChange={this.handleRetiredLastEmployerChange}
                        disabled={isDeathCertificateLocked}
                        handleOpenSnackbar={handleOpenSnackbar}
                        error={retiredLastEmployerValidator === false}
                        isEditMode={isEditMode}
                        onBlur={this.validateRetiredLastEmployer}
                    />
                </Grid>
            </Grid>
        );
    };

    matchesEmploymentOption(options: OccupationListOfOptions[]) {
        const { localData } = this.state;

        const result = options.find(item => item === localData.occupationListOfOptions);
        return result ? true : false;
    }

    // VALIDATION

    validateEmploymentStatus = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        this.setState({
            employmentStatusValidator: workHistoryValidators.occupation(localData, dcConfig, user),
        });
    };

    validateNormalOccupation = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            normalOccupationValidator: workHistoryValidators.normalOccupation(localData, dcConfig, user),
        });
    };

    validateNormalOccupationIndustry = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            normalOccupationIndustryValidator: workHistoryValidators.normalOccupationIndustry(
                localData,
                dcConfig,
                user
            ),
        });
    };

    validateCurrentEmployer = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            currentEmployerValidator: workHistoryValidators.currentEmployer(localData, dcConfig, user),
        });
    };

    validateYearsInOccupation = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            yearsInOccupationValidator: workHistoryValidators.yearsInOccupation(localData, dcConfig, user),
        });
    };

    validateRetiredInYear = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            retiredYearValidator: workHistoryValidators.retiredYear(localData, dcConfig, user),
        });
    };

    validateRetiredLastEmployer = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            retiredLastEmployerValidator: workHistoryValidators.retiredLastEmployer(localData, dcConfig, user),
        });
    };

    checkValid() {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        return workHistoryMasterValidators.valid(localData, dcConfig, user);
    }

    checkIfTouched() {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        return workHistoryMasterValidators.touched(localData, dcConfig, user);
    }

    validateAll() {
        this.validateEmploymentStatus();
        this.validateNormalOccupation();
        this.validateNormalOccupationIndustry();
        this.validateCurrentEmployer();
        this.validateYearsInOccupation();
        this.validateRetiredInYear();
        this.validateRetiredLastEmployer();
    }

    render() {
        const {
            classes,
            caseFname,
            isDeathCertificateLocked,
            handleOpenSnackbar,
            isEditMode,
        } = this.props;
        const {
            localData,
            employmentStatusValidator,
            normalOccupationValidator,
            normalOccupationIndustryValidator,
            currentEmployerValidator,
            yearsInOccupationValidator
        } = this.state;

        return (
            <div ref={this.props.formRef}>
                <Grid
                    item
                    xs={12}
                >
                    <FormTitle title="Work History" complete={this.checkIfTouched() ? this.checkValid() : null} />
                    <form
                        className={classNames(GStyles.overflowHidden, classes.inviteHelperForm)}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container alignItems="flex-end" justifyContent="center" >
                            <Grid item className={GStyles.width100}>
                                <ConfigurableToggleButtons
                                    id={ConfigurableWorkHistoryFieldKey.occupationListOfOptions}
                                    options={values(OccupationListOfOptions)}
                                    selected={localData.occupationListOfOptions || null}
                                    label={`${caseFname}'s employment status?`}
                                    disabled={isDeathCertificateLocked}
                                    error={employmentStatusValidator === false}
                                    isEditMode={isEditMode}
                                    onSelect={this.handleEmploymentChange}
                                    handleOpenSnackbar={handleOpenSnackbar}
                                    rootClass={GStyles.marginTop40}
                                    buttonContainerClass={classes.employmentButton}
                                />
                            </Grid>
                        </Grid>
                        {this.matchesEmploymentOption([
                            OccupationListOfOptions.Employed,
                            OccupationListOfOptions['Not Employed'],
                            OccupationListOfOptions.Retired,
                        ]) && (
                                <Grid container alignItems="flex-end" justifyContent="center" >
                                    <Grid item className={classNames(GStyles.width100, GStyles.textCenter)}>
                                        <ConfigurableTextFieldWithCheckbox
                                            id={ConfigurableWorkHistoryFieldKey.normalOccupation}
                                            label={`${caseFname}'s Normal Occupation`}
                                            value={localData.normalOccupation || ''}
                                            onChange={this.handleNormalOccupationChange}
                                            onBlur={this.validateNormalOccupation}
                                            disabled={isDeathCertificateLocked}
                                            handleOpenSnackbar={handleOpenSnackbar}
                                            checkboxLabel="Occupation unknown"
                                            onCheckboxChange={this.handleNormalOccupationUnknown}
                                            isChecked={localData.normalOccupation_unknown || false}
                                            isEditMode={isEditMode}
                                            error={normalOccupationValidator === false}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        {this.matchesEmploymentOption([
                            OccupationListOfOptions.Employed,
                            OccupationListOfOptions['Not Employed'],
                            OccupationListOfOptions.Retired,
                        ]) && (
                                <Grid container alignItems="flex-end" justifyContent="center" >
                                    <Grid item className={classNames(GStyles.width100, GStyles.textCenter)}>
                                        <ConfigurableTextFieldWithCheckbox
                                            id={ConfigurableWorkHistoryFieldKey.kindOfBusiness}
                                            error={normalOccupationIndustryValidator === false}
                                            disabled={isDeathCertificateLocked}
                                            label="Kind of Business"
                                            value={localData.normalOccupationIndustry || ''}
                                            onChange={this.handleNormalOccupationIndustryChange}
                                            handleOpenSnackbar={handleOpenSnackbar}
                                            checkboxLabel="Kind of business unknown"
                                            onCheckboxChange={this.handleKindBusinessUnknown}
                                            isChecked={localData.normalOccupationIndustry_unknown || false}
                                            isEditMode={isEditMode}
                                            onBlur={this.validateNormalOccupationIndustry}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        {this.matchesEmploymentOption([OccupationListOfOptions.Retired])
                            ? this.getContentIfRetired()
                            : null
                        }
                        {this.matchesEmploymentOption([OccupationListOfOptions.Employed]) && (
                            <Grid container alignItems="flex-end" justifyContent="center" >
                                <Grid item className={classNames(GStyles.width100, GStyles.textCenter)}>
                                    <ConfigurableTextField
                                        id={ConfigurableWorkHistoryFieldKey.currentEmployer}
                                        label="Name of Company"
                                        value={localData.currentEmployer || ''}
                                        onChange={this.handleCurrentEmployerChange}
                                        disabled={isDeathCertificateLocked}
                                        handleOpenSnackbar={handleOpenSnackbar}
                                        isEditMode={isEditMode}
                                        error={currentEmployerValidator === false}
                                        onBlur={this.validateCurrentEmployer}
                                    />

                                    <ConfigurableTextField
                                        id={ConfigurableWorkHistoryFieldKey.yearsInOccupation}
                                        label="Years in Occupation"
                                        value={localData.yearsInOccupation || ''}
                                        onChange={this.handleYearsInOccupationChange}
                                        inputType="tel"
                                        inputComponent={YearNumberMask}
                                        disabled={isDeathCertificateLocked}
                                        handleOpenSnackbar={handleOpenSnackbar}
                                        isEditMode={isEditMode}
                                        error={yearsInOccupationValidator === false}
                                        onBlur={this.validateYearsInOccupation}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </form>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(WorkHistoryForm);
