import * as t from 'io-ts';
import { PdfFieldType, HelloSignSignerRequest, HelloSignSignerStatusEnum } from '.';
// ---> HelloSign <---
export type HelloSignFieldType = PdfFieldType;
export type HelloSignReqiredType = 'req' | 'noreq';

export type HelloSignEventType =
    'signature_request_viewed' |
    'signature_request_signed' |
    'signature_request_downloadable' |
    'signature_request_sent' |
    'signature_request_declined' |
    'signature_request_reassigned' |
    'signature_request_remind' |
    'signature_request_all_signed' |
    'signature_request_email_bounce' |
    'signature_request_invalid' |
    'signature_request_canceled' |
    'signature_request_prepared' |
    'file_error' |
    'unknown_error' |
    'sign_url_invalid' |
    'account_confirmed' |
    'template_created' |
    'template_error';

export enum HelloSignEventEnum {
    'signature_request_viewed' = 'signature_request_viewed',
    'signature_request_signed' = 'signature_request_signed',
    'signature_request_downloadable' = 'signature_request_downloadable',
    'signature_request_sent' = 'signature_request_sent',
    'signature_request_declined' = 'signature_request_declined',
    'signature_request_reassigned' = 'signature_request_reassigned',
    'signature_request_remind' = 'signature_request_remind',
    'signature_request_all_signed' = 'signature_request_all_signed',
    'signature_request_email_bounce' = 'signature_request_email_bounce',
    'signature_request_invalid' = 'signature_request_invalid',
    'signature_request_canceled' = 'signature_request_canceled',
    'signature_request_prepared' = 'signature_request_prepared',
    'file_error' = 'file_error',
    'unknown_error' = 'unknown_error',
    'sign_url_invalid' = 'sign_url_invalid',
    'account_confirmed' = 'account_confirmed',
    'template_created' = 'template_created',
    'template_error' = 'template_error',
}

const HelloSignEventEnumV = t.keyof({
    [HelloSignEventEnum.signature_request_viewed]: null,
    [HelloSignEventEnum.signature_request_signed]: null,
    [HelloSignEventEnum.signature_request_downloadable]: null,
    [HelloSignEventEnum.signature_request_sent]: null,
    [HelloSignEventEnum.signature_request_declined]: null,
    [HelloSignEventEnum.signature_request_reassigned]: null,
    [HelloSignEventEnum.signature_request_remind]: null,
    [HelloSignEventEnum.signature_request_all_signed]: null,
    [HelloSignEventEnum.signature_request_email_bounce]: null,
    [HelloSignEventEnum.signature_request_invalid]: null,
    [HelloSignEventEnum.signature_request_canceled]: null,
    [HelloSignEventEnum.signature_request_prepared]: null,
    [HelloSignEventEnum.file_error]: null,
    [HelloSignEventEnum.unknown_error]: null,
    [HelloSignEventEnum.sign_url_invalid]: null,
    [HelloSignEventEnum.account_confirmed]: null,
    [HelloSignEventEnum.template_created]: null,
    [HelloSignEventEnum.template_error]: null,
});

const helloSignEventMetadataDefinition = {
    related_signature_id: t.union([t.string, t.null]),
    reported_for_account_id: t.string,
    reported_for_app_id: t.string
};
const HelloSignEventMetadataType = t.type(helloSignEventMetadataDefinition);
export interface HelloSignEventMetadata extends t.TypeOf<typeof HelloSignEventMetadataType> {
}

const helloSignEventDefinition = {
    event_time: t.number,
    event_type: t.union([HelloSignEventEnumV, t.string]),
    event_hash: t.string,
    event_metadata: HelloSignEventMetadataType,
};

const HelloSignEventType = t.type(helloSignEventDefinition);
export interface HelloSignEvent extends t.TypeOf<typeof HelloSignEventType> {
    event_type: HelloSignEventEnum | string;
    event_metadata: HelloSignEventMetadata;
}

const HelloSignSignerStatusEnumV = t.keyof({
    [HelloSignSignerStatusEnum.awaiting_signature]: null,
    [HelloSignSignerStatusEnum.viewed]: null, // this won't come from HelloSign but is used internally in Gather
    [HelloSignSignerStatusEnum.signed]: null,
    [HelloSignSignerStatusEnum.declined]: null,
});

const signaturesDefinition = {
    signature_id: t.string,
    signer_email_address: t.string,
    signer_name: t.string,
    signer_role: t.union([t.string, t.null]),
    order: t.union([t.number, t.null]),
    has_pin: t.boolean,
    status_code: HelloSignSignerStatusEnumV,
    signed_at: t.union([t.number, t.null]),
    last_viewed_at: t.union([t.number, t.null]),
    last_reminded_at: t.union([t.number, t.null]),
    error: t.union([t.string, t.null]),
    decline_reason: t.union([t.string, t.null]),
};

const SignaturesType = t.type(signaturesDefinition);
export interface Signatures extends t.TypeOf<typeof SignaturesType> {
    status_code: HelloSignSignerStatusEnum;
}

const signatureRequestDefinition = {
    test_mode: t.boolean,
    title: t.string,
    original_title: t.string,
    subject: t.string,
    message: t.string,
    signing_redirect_url: t.union([t.string, t.null]),
    cc_email_addresses: t.array(t.string),
    signatures: t.array(SignaturesType),
    signature_request_id: t.string,
    requester_email_address: t.string,
    is_complete: t.boolean,
    is_declined: t.boolean,
    has_error: t.boolean,
    files_url: t.string,
    signing_url: t.string,
    details_url: t.string,
};

const signatureRequestOptional = {
    metadata: t.dictionary(t.string, t.string),
};

const SignatureRequestType = t.intersection([
    t.type(signatureRequestDefinition),
    t.partial(signatureRequestOptional),
]);
export interface SignatureRequest extends t.TypeOf<typeof SignatureRequestType> {
    signatures: Signatures[];
}

const helloSignCallbackDefinition = {
    account_guid: t.union([t.string, t.null]),
    client_id: t.union([t.string, t.null]),
    event: HelloSignEventType,
    signature_request: SignatureRequestType,
};

const HelloSignCallbackType = t.type(helloSignCallbackDefinition);
export interface HelloSignCallback extends t.TypeOf<typeof HelloSignCallbackType> {
    event: HelloSignEvent;
    signature_request: SignatureRequest;
}

// ***********************************************************************************************
export interface SignatureResponse {
    signature_request: SignatureRequest;
    resHeaders: { [key: string]: string };
    statusCode: number;
    statusMessage: string;
}

interface SigningOptions {
    draw: boolean;
    type: boolean;
    upload: boolean;
    phone: boolean;
    default: 'draw' | 'type' | 'upload' | 'phone';
}

export interface SendSignatureOptions {
    signers: HelloSignSignerRequest[];
    test_mode?: 0 | 1;
    files?: string[];
    file_url?: string[];
    title?: string;
    subject?: string;
    message?: string;
    signing_redirect_url?: string;
    cc_email_addresses?: string[];
    use_text_tags?: 0 | 1;
    hide_text_tags?: 0 | 1;
    metadata?: { [key: string]: string };
    client_id?: string;
    allow_decline?: 0 | 1;
    signing_options?: SigningOptions;
    // attachments // see HelloSign docs on how to do attachments
    // custom_fields?: // only used when using pre-set values
    // allow_reassign
    // form_fields_per_document  // see HelloSign docs
    // field_options
}

export interface HelloSignResponseError {
    error_type: string;
    hellosign_error_message: string;
}
export class HelloSignResponseError {
    public static isHelloSignErrorResponse(
        obj: HelloSignResponseError | Object | null | undefined
    ): obj is HelloSignResponseError {
        return Boolean(obj && obj.hasOwnProperty('hellosign_error_message'));
        
    }
}