import styled from '@mui/material/styles/styled';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import TextField, {
    StandardTextFieldProps,
    FilledTextFieldProps,
    OutlinedTextFieldProps,
} from '@mui/material/TextField';
import { memo } from 'react';
import FormHelperText from '@mui/material/FormHelperText';

export interface GTextFieldProps extends Omit<StandardTextFieldProps, 'variant'> {
    value: string;
    label: string;
    onValueChange: (newVal: string) => void;
    variant?: StandardTextFieldProps['variant'] | FilledTextFieldProps['variant'] | OutlinedTextFieldProps['variant'];
    formControlProps?: FormControlProps;
    helperTextOnError?: string;
}

const GTextFieldFormControl = styled(FormControl)({
    width: '100%',
    textAlign: 'left',
    marginTop: 20,
});

const GTextField = (props: GTextFieldProps) => {
    const {
        value,
        label,
        onValueChange,
        formControlProps,
        helperTextOnError,
        helperText,
        FormHelperTextProps,
        ...restProps
    } = props;

    return (
        <GTextFieldFormControl error={props.error} disabled={props.disabled}  {...formControlProps}>
            <TextField
                fullWidth
                autoComplete="new-password"
                type="text"
                label={label}
                value={value}
                onChange={(e) => onValueChange(e.currentTarget.value)}
                {...restProps}
            />
            {(helperText || (helperTextOnError && props.error)) && (
                <FormHelperText color="secondary" {...FormHelperTextProps} error={props.error}>
                    {props.error ? helperTextOnError ?? helperText : helperText}
                </FormHelperText>
            )}
        </GTextFieldFormControl>
    );
};

export default memo(GTextField);
