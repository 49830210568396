import * as React from 'react';
import classNames from 'classnames';
import Dinero from 'dinero.js';

import { TransitionGroup } from 'react-transition-group';
import Transition from 'react-transition-group/Transition';

import Grid from '@mui/material/Grid';
import ImageListItem from '@mui/material/ImageListItem';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import BankIcon from '@mui/icons-material/AccountBalance';
import GestureIcon from '@mui/icons-material/Gesture';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import TerminalSVG from './svg/TerminalSVG';

import { StyledProps, styleWrapper } from './styles';

import {
    GatherCaseUX,
    PaymentRequest,
    FeeSchedule,
    PaymentMethod,
    Transaction,
    UserRoles,
    PaymentGrouping,
    PaymentMode,
    PaymentType,
    EntityType,
    PayerEntity,
    ProductTaxRateUX,
    UserProfileSummary,
    LongAddress,
    FeatureKey,
    GuestPaymentCaseTotals
} from '../../../../shared/types';

import EmptyPaymentCard from './widgets/EmptyPaymentCard';
import PayerCard from './widgets/PayerCard';
import Details from './widgets/Details';
import SelectPayersCount from './widgets/SelectPayersCount';
import SplitBill from './widgets/SplitBill';

import {
    getTransactionsForCase,
    proposePayment,
    proposeEmptyPayments,
    adjustInvoice,
    patchPayment,
    initializeStripeTerminal,
    resetStripePaymentState,
    resetStripeChargeState,
    removePayer,
    deletePayment,
    getStripeChargeDetails,
    refundPayment,
    patchPaymentMode,
    resetPlaidChargeState,
    PatchPaymentParameters,
} from '../../../../actions/Finance.action';
import CashPaymentDialog from './CashPayment.dialog';
import PaymentReceiptDialog from './PaymentReceipt.dialog';
import RecordPolicyPaymentDialog from './RecordPolicyPayment.dialog';
import RecordInsurancePolicyDialog from './RecordInsurancePolicy.dialog';
import CardReaderDialog from './CardReader.dialog';
import { StoreState } from '../../../../types';
import RefundScreenDialog from './RefundScreen.dialog';
import AddPayerCard from './widgets/AddPayerCard';
import RestrictedPaymentFeaturePopper, { RestrictedPaymentFeature } from './widgets/RestrictedPaymentFeaturePopper';
import ConfirmationDialog from '../../../common/ConfirmationDialog';

import PlaidPaymentDialog from './PlaidPayment.dialog';
import PriceAdjustmentDialog from '../dialogs/PriceAdjustment.dialog';
import { splitFullName } from '../../../../shared/utils';
import { getDinero, applyTaxRate } from '../../../../shared/goods_and_services/pricing';
import { setOneOffProductSelectionDialogOpen } from '../../../../actions/GatherCase.action';
import { openMySettingsDialog } from '../../../../actions/Dialog.action';
import AssignPaymentPopper from '../../../assignmentPoppers/AssignPayment.popper';
import withGStyles from '../../../../styles/WithGStyles';
import { AppDispatch } from '../../../../store';
import withState from '../../../common/utilHOC/WithState';
import GLink from '../../../common/GLink';
import { FamilyRoutePage, getPaymentMethodLabel, RouteBuilder } from '../../../../services';
import { log } from '../../../../logger';
import DisabledPermissionTooltip from '../../../common/DisabledPermissionTooltip';
import { Permission } from '../../../../shared/types/permissions';
import CaseOnlinePaymentDialog from './CaseOnlinePayment.dialog';
import moment from 'moment-timezone';
import PreArrangedGoodAndServices from '../overview/PreArrangedGoodAndServices';
import { WhenEnabled } from '../../../common/AppFeature';

export interface Payer {
    payerEntity: PayerEntity;
    user?: UserProfileSummary;
    home_address?: LongAddress | null;
}

export interface ActivePaymentOrPayer {
    name: string | null;
    id: number | null;
    onConfirm: () => void;
}

export function calculateProcessingFeesInCents(
    amountInCents: number,
    feeSchedule: FeeSchedule | null,
    method: PaymentMethod
) {
    if (feeSchedule && feeSchedule[method]) {
        const fees = feeSchedule[method].merchant;
        if (fees && (fees.base > 0 || fees.rate > 0)) {
            return Math.round(
                Math.min(
                    amountInCents * fees.rate,
                    fees.max ? fees.max : amountInCents * fees.rate
                ) + fees.base);
        } else {
            return 0.0;
        }
    } else {
        return 0.0;
    }
}

export function calculateAmountInCents(
    totalInCents: number,
    feeSchedule: FeeSchedule | null,
    method: PaymentMethod
) {
    if (feeSchedule && feeSchedule[method]) {
        const fees = feeSchedule[method].merchant;
        if (fees && (fees.base > 0 || fees.rate > 0)) {
            return Math.round((totalInCents - fees.base) / (fees.rate + 1));
        } else {
            return 0.0;
        }
    } else {
        return 0.0;
    }
}

export interface RenderPaymentMethodIconProps {
    paymentMethod: PaymentMethod | null;
    iconStyle?: React.CSSProperties;
}

export const PaymentMethodIcon = (props: RenderPaymentMethodIconProps & { classFillPrimary: string }) => {
    const { iconStyle, paymentMethod, classFillPrimary } = props;

    if (!paymentMethod) {
        return <AttachMoneyIcon color="primary" />;
    }

    switch (paymentMethod) {
        case PaymentMethod.card:
            return <TerminalSVG themeFillClass={classFillPrimary} iconStyle={iconStyle} />;
        case PaymentMethod.cash:
            return <LocalAtmIcon color="primary" style={{ ...iconStyle }} />;
        case PaymentMethod.check:
            return <GestureIcon color="primary" style={{ ...iconStyle }} />;
        case PaymentMethod.online:
            return <KeyboardIcon color="primary" style={{ ...iconStyle }} />;
        case PaymentMethod.insurance:
            return <InsertDriveFileIcon color="primary" style={{ ...iconStyle }} />;
        case PaymentMethod.plaid:
            return <BankIcon color="primary" style={{ ...iconStyle }} />;
        case PaymentMethod.other:
        case PaymentMethod.unknown:
        default:
            return <AttachMoneyIcon color="primary" style={{ ...iconStyle }} />;
    }
};

const ZoomAnimationStyle = {
    transition: 'transform 800ms linear 0s, ' +
        'margin 600ms linear 0s, ' +
        'width 600ms linear 0s, ' +
        'opacity 800ms linear 0s',
    transform: 'scale(0)',
    width: 0,
    opacity: 0
};

const TransitionStyles = {
    entering: {
        transform: 'scale(1)',
        width: 302,
        opacity: 1
    },
    entered: {
        transform: 'scale(1)',
        width: 302,
        opacity: 1,
    },
    exiting: {
        transition: 'transform 800ms linear 0s, ' +
            'margin 600ms linear 200ms, ' +
            'width 600ms linear 200ms, ' +
            'opacity 800ms linear 0s',
        transform: 'scale(0)',
        width: 0,
        opacity: 0
    }
};

export enum PaymentFieldToolTip {
    paymentSubtotal = "The amount the customer owes prior to any Convenience Fees.",
    paymentFee = "The fee charged to your customer. This is added onto the Payment \
        Subtotal to arrive at the full Payment Total that the customer owes.The percentage \
        for this Convenience Fee can be programmatically set in Gather, allowing you to offset \
        the Processing Expense charged by Gather.This allows you to ensure that your \
        Payout Total is equal to or even greater than the Payment Subtotal.\
        This fee can be adjusted dynamically per case, as needed.",
    paymentTotal = "The total amount the customer actually pays, including any Convenience Fees.\
        Payment Subtotal + Convenience Fee = Payment Total.",
    paymentDate = "This is an adjustable date to reflect when the actual payment was made by the \
        customer. For example, a customer may have paid prior to when the payment was entered into Gather, \
        this field allows the funeral home to enter the correct date for the actual receipt of the payment \
        even if it was in the past.",
    paymentCreatedBy = "This represents the person that entered the payment into Gather and is different from \
        the Payer Name field. For Web payments, the funeral home employee assigned to the case at the time of \
        the payment will be listed in this field. For Remote Payments, the funeral home employee that sent the \
        remote request will be listed in this field.",
    paymentCreatedDate = "This is the date that the payment was created in Gather. This date and time cannot be \
        changed. To adjust when the payment was actually made by the customer, use the Payment Date."
}

function mapStateToProps({ financeState, funeralHomeState, userSession, casesState, caseInsuranceState }: StoreState) {
    return {
        financeState,
        funeralHome: funeralHomeState.activeFuneralHome,
        userSession,
        isOneOffProductSelectionDialogOpen: casesState.isOneOffProductSelectionDialogOpen,
        helpers: casesState.helpers,
    };
}

interface Props extends ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
    isContractFrozen: boolean;
    selectedCase: GatherCaseUX;
    taxRates: ProductTaxRateUX[];
    onAddNonUserContactInfoClick: (nonUserId: number) => void;
    onAddItem: () => void;
    handleClickEventOnAvatar: (
        event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>,
        entityId: number,
    ) => void;
    zIndex: number;
    setItemizedStatement: (isItemized: boolean, callback?: () => void) => void;
    setDownloadInvoice: (downloadInvoice: boolean, payerId: number, callback?: () => void) => void;
}

interface State {
    printContract: boolean;
    assignPayerAnchorEl: HTMLElement | null;
    isOpenCashPaymentDialog: boolean;
    isRestricted: boolean;
    activePaymentInfo: PaymentRequest;
    paymentDialogKey: number;
    activeReceipt: number | null; // the id of the payment who's receipt is displayed
    activeInvoice: Transaction | null;
    payer: Payer;
    isRecordPolicyPaymentDialogOpen: boolean;
    isRecordInsurancePolicyDialogOpen: boolean;
    payerCount: number;
    isOpenCardReaderDialog: boolean;
    isOpenOnlinePaymentDialog: boolean;
    isOpenPlaidPaymentDialog: boolean;
    paymentToRefund: Transaction | null;
    activeRestrictedPaymentFeature: RestrictedPaymentFeature | null;
    isConfirmationDialogOpen: boolean;
    activePaymentOrPayer: ActivePaymentOrPayer | null;
    isLoading: boolean;
    activeDetailMenu: { anchorEle: HTMLElement; transaction: Transaction } | null;
    isAdjustPaymentModeOn: boolean;
    isPriceAdjustmentDialogOpen: boolean;
    sentNotification: boolean;
    isDeletingEmptyCard: boolean;
}

class PaymentTab extends React.Component<StyledProps & Props, State> {
    protected navigateToContractLinkTagEle: HTMLAnchorElement | null = null;

    constructor(props: StyledProps & Props) {
        super(props);
        const { userSession } = props;
        this.state = {
            printContract: false,
            assignPayerAnchorEl: null,
            isOpenCashPaymentDialog: false,
            isRestricted: !!(UserRoles.isFamilyOnCase(userSession.userData, this.props.selectedCase.id)),
            activePaymentInfo: this.getDefaultPaymentInfo(),
            paymentDialogKey: 0,
            activeReceipt: null,
            activeInvoice: null,
            payer: this.getDefaultPayer(),
            isRecordPolicyPaymentDialogOpen: false,
            isRecordInsurancePolicyDialogOpen: false,
            payerCount: 0,
            isOpenCardReaderDialog: false,
            isOpenOnlinePaymentDialog: false,
            isOpenPlaidPaymentDialog: false,
            activeRestrictedPaymentFeature: null,
            paymentToRefund: null,
            isConfirmationDialogOpen: false,
            activePaymentOrPayer: null,
            isLoading: false,
            activeDetailMenu: null,
            isAdjustPaymentModeOn: false,
            isPriceAdjustmentDialogOpen: false,
            sentNotification: false,
            isDeletingEmptyCard: false
        };
    }

    componentDidMount() {
        const { isOneOffProductSelectionDialogOpen, selectedCase, onAddItem, dispatch } = this.props;

        dispatch(getTransactionsForCase(selectedCase.uuid));

        if (isOneOffProductSelectionDialogOpen) {
            onAddItem();
            dispatch(setOneOffProductSelectionDialogOpen(false));
        }

        const goodsAndServicesMainContainer = document.getElementById('goods-and-services-main-container');
        if (goodsAndServicesMainContainer) {
            goodsAndServicesMainContainer.style.marginBottom = '0px';
        }
    }

    componentWillUnmount() {
        const goodsAndServicesMainContainer = document.getElementById('goods-and-services-main-container');

        if (goodsAndServicesMainContainer) {
            goodsAndServicesMainContainer.style.marginBottom = '75px';
        }
    }

    getDefaultPaymentInfo(): PaymentRequest {
        const { selectedCase } = this.props;
        return {
            method: PaymentMethod.unknown,
            mode: PaymentMode.IN_PERSON,
            type: PaymentType.CASE,
            amount: Dinero({ amount: 0, currency: 'USD' }),
            merch_fee: Dinero({ amount: 0, currency: 'USD' }),
            memo: '',
            funeralHomeCaseId: selectedCase.funeral_home_case_id,
            is_anon: false
        };
    }

    getDefaultPayer(): Payer {
        return {
            payerEntity: {
                id: NaN,
                type: EntityType.person,
                fname: '',
                lname: '',
                email: '',
                phone: '',
                user_profile_id: null,
            }
        };
    }

    renderConfirmationDialog = () => {
        const { isConfirmationDialogOpen, isLoading, activePaymentOrPayer } = this.state;
        const { zIndex } = this.props;

        if (!activePaymentOrPayer) {
            return;
        }

        const header = `Are you sure you want to remove ${activePaymentOrPayer.name || ''}?`;

        return (
            <ConfirmationDialog
                header={header}
                confirmationButtonText={'Remove'}
                onClose={() => this.toggleConfirmationDialog(false, null)}
                open={isConfirmationDialogOpen}
                onConfirm={() => activePaymentOrPayer.onConfirm()}
                isLoading={isLoading}
                zIndex={zIndex + 5}
            />
        );
    };

    renderDetailMenu = () => {
        const { activeDetailMenu } = this.state;
        const { selectedCase, zIndex } = this.props;

        if (!activeDetailMenu) {
            return;
        }
        const { anchorEle, transaction: txn } = activeDetailMenu;

        const menuItems: JSX.Element[] = [];

        if (txn.contract_id) {
            menuItems.push(
                <MenuItem
                    key={1}
                    onClick={() => {
                        if (this.navigateToContractLinkTagEle) {
                            this.navigateToContractLinkTagEle.click();
                            this.hideDetailMenu();
                        }
                    }}
                >
                    {selectedCase ? `View ${selectedCase.fname}'s Statement` : 'View or Edit Statement'}
                </MenuItem>
            );
            menuItems.push(
                <MenuItem
                    key={9}
                    onClick={() => {
                        this.setState(
                            { printContract: true },
                            () => {
                                if (this.navigateToContractLinkTagEle) {
                                    this.navigateToContractLinkTagEle.click();
                                    this.hideDetailMenu();
                                }
                            }
                        );
                    }}
                >
                    {selectedCase ? `Print ${selectedCase.fname}'s Statement` : 'Print Statement'}
                </MenuItem>
            );
        }

        if (txn.type === 'PAYMENT' && txn.payment_id) {
            menuItems.push(
                <MenuItem
                    key={2}
                    onClick={(e) => {
                        this.showReceipt(txn.payment_id);
                        this.hideDetailMenu();
                    }}
                >
                    View Receipt
                </MenuItem>
            );
        }

        if (txn.type === 'PAYMENT' && (
            txn.method === PaymentMethod.card
            || txn.method === PaymentMethod.online
            || txn.method === PaymentMethod.plaid
        )) {
            menuItems.push(
                <DisabledPermissionTooltip key={3} permission={Permission.EDIT_PAYMENTS}>
                    {disabled =>
                        <MenuItem
                            disabled={disabled}
                            onClick={(e) => {
                                this.showRefundDialog(txn);
                                this.hideDetailMenu();
                            }}
                        >
                            Offer Refund
                        </MenuItem>
                    }
                </DisabledPermissionTooltip>
            );
        }

        if (txn.type === 'INVOICE' && !txn.contract_id) {
            menuItems.push(
                <DisabledPermissionTooltip key={4} permission={Permission.MODIFY_PRICES}>
                    {disabled =>
                        <MenuItem
                            disabled={disabled}
                            key={4}
                            onClick={() => {
                                this.openPriceAdjustmentDialog(txn);
                                this.hideDetailMenu();
                            }}
                        >
                            Adjust Charge
                        </MenuItem>
                    }
                </DisabledPermissionTooltip>
            );
            menuItems.push(
                <DisabledPermissionTooltip key={5} permission={Permission.REMOVE_ITEMS}>
                    {disabled =>
                        <MenuItem
                            disabled={disabled}
                            key={5}
                            onClick={() => {
                                this.toggleConfirmationDialog(
                                    true,
                                    {
                                        name: 'this charge',
                                        id: txn && txn.invoice_id || null,
                                        onConfirm: () => this.voidInvoice(txn)
                                    }
                                );
                                this.hideDetailMenu();
                            }}
                        >
                            Remove Charge
                        </MenuItem>
                    }
                </DisabledPermissionTooltip>
            );
        }

        if (txn.type === 'PAYMENT' && txn.method === PaymentMethod.insurance && txn.status !== 'succeeded') {
            menuItems.push(
                <DisabledPermissionTooltip key={6} permission={Permission.REMOVE_PAYMENTS}>
                    {disabled =>
                        <MenuItem
                            disabled={disabled}
                            onClick={() => {
                                this.toggleConfirmationDialog(
                                    true,
                                    {
                                        name: txn.payer_name,
                                        id: txn.payer_id,
                                        onConfirm: () => this.removePayer()
                                    }
                                );

                                this.hideDetailMenu();
                            }}
                        >
                            Remove this Insurer
                        </MenuItem>
                    }
                </DisabledPermissionTooltip>
            );
        }

        if (txn.type === 'PAYMENT' && txn.payment_id
            && txn.method !== PaymentMethod.card
            && txn.method !== PaymentMethod.online
            && txn.method !== PaymentMethod.plaid) {
            if (txn.method !== PaymentMethod.unknown) {
                menuItems.push(
                    <DisabledPermissionTooltip key={7} permission={Permission.EDIT_PAYMENTS}>
                        {disabled =>
                            <MenuItem
                                disabled={disabled}
                                onClick={() => {
                                    this.adjustPaymentOrPolicy(txn);
                                    this.hideDetailMenu();
                                }}
                            >
                                Adjust Payment
                            </MenuItem>
                        }
                    </DisabledPermissionTooltip>
                );
            }
            menuItems.push(
                <DisabledPermissionTooltip key={8} permission={Permission.REMOVE_PAYMENTS}>
                    {disabled =>
                        <MenuItem
                            disabled={disabled}
                            onClick={() => {
                                this.toggleConfirmationDialog(
                                    true,
                                    {
                                        name: 'this payment',
                                        id: txn.id,
                                        onConfirm: () => txn.payment_id && this.deletePayment(txn.payment_id)
                                    }
                                );

                                this.hideDetailMenu();
                            }}
                        >
                            Remove Payment
                        </MenuItem>
                    }
                </DisabledPermissionTooltip>
            );
        }

        if (txn.is_proposed && txn.type === 'PAYMENT' && txn.method === PaymentMethod.insurance) {
            menuItems.splice(0, menuItems.length);
            menuItems.push(
                <DisabledPermissionTooltip key={10} permission={Permission.EDIT_PAYMENTS}>
                    {disabled =>
                        <MenuItem
                            disabled={disabled}
                            onClick={() => {
                                this.adjustPaymentOrPolicy(txn, true);
                                this.hideDetailMenu();
                            }}
                        >
                            Adjust policy information
                        </MenuItem>
                    }
                </DisabledPermissionTooltip>

            );
            menuItems.push(
                <DisabledPermissionTooltip key={11} permission={Permission.ADD_PAYMENTS}>
                    {disabled =>
                        <MenuItem
                            disabled={disabled}
                            onClick={() => {
                                this.adjustPaymentOrPolicy(txn);
                                this.hideDetailMenu();
                            }}
                        >
                            Record payment on policy
                        </MenuItem>
                    }
                </DisabledPermissionTooltip>
            );
            menuItems.push(
                <DisabledPermissionTooltip key={12} permission={Permission.REMOVE_PAYMENTS}>
                    {disabled =>
                        <MenuItem
                            disabled={disabled}
                            onClick={() => {
                                this.toggleConfirmationDialog(
                                    true,
                                    {
                                        name: 'this policy',
                                        id: txn.payer_id,
                                        onConfirm: () => this.removePayer()
                                    }
                                );

                                this.hideDetailMenu();
                            }}
                        >
                            Remove policy
                        </MenuItem>
                    }
                </DisabledPermissionTooltip>
            );
        }

        return (
            <Menu
                id="payment-item-menu"
                anchorEl={anchorEle}
                open={Boolean(activeDetailMenu)}
                onClose={() => this.hideDetailMenu()}
                style={{ zIndex }}
            >
                {menuItems}
            </Menu>
        );
    };

    render() {
        const {
            classes,
            helpers,
            financeState,
            selectedCase,
            isContractFrozen,
            funeralHome,
            userSession,
            zIndex,
            handleClickEventOnAvatar,
            setDownloadInvoice,
            onAddNonUserContactInfoClick,
            onAddItem,
            dispatch,
            setItemizedStatement,
        } = this.props;
        const { transactions } = financeState;
        const { isPriceAdjustmentDialogOpen, isDeletingEmptyCard } = this.state;
        const { totals } = transactions;
        const stripeLocation = funeralHome ? funeralHome.stripe_location : null;

        if (transactions.isLoading || !selectedCase) {
            return null;
        }

        const {
            assignPayerAnchorEl,
            activePaymentInfo,
            paymentDialogKey,
            isOpenCashPaymentDialog,
            isOpenCardReaderDialog,
            isOpenOnlinePaymentDialog,
            isOpenPlaidPaymentDialog,
            isRestricted,
            paymentToRefund,
            isRecordPolicyPaymentDialogOpen,
            isRecordInsurancePolicyDialogOpen,
            payerCount,
            activeRestrictedPaymentFeature,
            activeInvoice,
            activeReceipt,
            isAdjustPaymentModeOn,
            printContract,
            sentNotification,
        } = this.state;

        const loggedInUserId = userSession.userData && userSession.userData.id;

        // filter my transactions
        const payments: PaymentGrouping[] = isRestricted ?
            transactions.payments
                .map(p => {
                    return { ...p, payer_user_id: p.transactions[0].payer_user_id };
                })
                .filter(p => loggedInUserId !== null && loggedInUserId === p.payer_user_id)
            : transactions.payments;
        const feeSchedule = financeState.transactions.feeSchedule;

        const caseTotals: GuestPaymentCaseTotals = {
            case_uuid: selectedCase.uuid,
            guest_payment_display_option: selectedCase.funeral_home.guest_payment_display_option,
            expense_total: selectedCase.expense_total,
            collected_total: selectedCase.collected_total,
            outstanding_total: selectedCase.outstanding_total,
            payment_progress: selectedCase.payment_progress,
        };

        return (
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                className={classes.root}
            >
                <GLink
                    to={RouteBuilder.FamilyPage({
                        caseName: selectedCase.name,
                        funeralHomeKey: selectedCase.funeral_home.key,
                        page: FamilyRoutePage.CONTRACT,
                        query: {
                            print: JSON.stringify(printContract),
                        },
                    })}
                    linkClass={classes.linkStyle}
                    ref={ref => this.navigateToContractLinkTagEle = ref}
                />
                <Details
                    onRecordExpenseClick={onAddItem}
                    isContractFrozen={isContractFrozen}
                    selectedCase={selectedCase}
                    openDetailMenu={this.showDetailMenu}
                    navigateToContract={() => {
                        if (this.navigateToContractLinkTagEle) {
                            this.navigateToContractLinkTagEle.click();
                        }
                    }}
                    showReceipt={(pid) => this.showReceipt(pid)}
                    editInvoice={(txn) => this.openPriceAdjustmentDialog(txn)}
                    isFamilyUser={isRestricted}
                    loggedInUserId={loggedInUserId}
                    funeralHome={funeralHome}
                    setItemizedStatement={setItemizedStatement}
                    zIndex={zIndex + 1}
                    caseTotals={caseTotals}
                />
                <div className={classes.rightPane} id="payment-right-pane">
                    <SelectPayersCount
                        key={`spc:${payments.length}`}
                        payerCount={payerCount}
                        onClick={(c) => this.updatePayerCount(c)}
                        isHidden={isRestricted || payments.length > 0}
                        zIndex={zIndex}
                    />

                    <SplitBill
                        key={`sb:${payments.length}`}
                        payerCount={payerCount}
                        price={Dinero({ amount: totals.expense_total })}
                        onClick={(count, split) => this.addPayers(count, split)}
                        isHidden={isRestricted || payments.length > 0}
                    />

                    {payments.length > 0 &&
                        <div className={classNames(classes.ulGrid, payments.length > 0 && classes.show)}>
                            <ul className={classNames(classes.ul, classes.list)}>
                                <TransitionGroup className={classes.transitionGroup}>
                                    {payments.map((group, i) =>
                                        <Transition
                                            in
                                            exit={isDeletingEmptyCard || group.payer_id > 0}
                                            timeout={800}
                                            key={group.payer_id}
                                        >
                                            {(state) => (
                                                // TODO(Jonathan): Consider refactoring this to use the
                                                // List/ListItem components
                                                <ImageListItem
                                                    style={{
                                                        ...ZoomAnimationStyle,
                                                        ...TransitionStyles[state],
                                                        marginLeft: i === 0 && 24 || undefined
                                                    }}
                                                    className={classes.li}
                                                    classes={{ img: classes.listTile }}
                                                >
                                                    {group.payer_id > 0 && <PayerCard
                                                        paymentGroup={group}
                                                        helpers={helpers}
                                                        selectedCase={selectedCase}
                                                        onAddNonUserContactInfoClick={onAddNonUserContactInfoClick}
                                                        showReceipt={(pid) => this.showReceipt(pid)}
                                                        feeSchedule={feeSchedule}
                                                        isRestricted={isRestricted}
                                                        showPaymentDialog={(info, payer, restricted) =>
                                                            this.showPaymentDialog(info, payer, restricted)}
                                                        patchPayment={this.handlePatchPayment}
                                                        patchPaymentMode={(paymentId, mode) => {
                                                            dispatch(patchPaymentMode(paymentId, mode));
                                                        }}
                                                        deletePayment={(paymentId, payerName) => {
                                                            if (payerName) {
                                                                this.toggleConfirmationDialog(
                                                                    true,
                                                                    {
                                                                        id: paymentId,
                                                                        name: payerName,
                                                                        onConfirm: () =>
                                                                            this.deletePayment(paymentId)
                                                                    }
                                                                );
                                                                return;
                                                            }
                                                            this.deletePayment(paymentId);
                                                        }}
                                                        removePayer={(payerId, payerName) => {
                                                            this.toggleConfirmationDialog(
                                                                true,
                                                                {
                                                                    name: payerName,
                                                                    id: payerId,
                                                                    onConfirm: () => this.removePayer()
                                                                }
                                                            );
                                                        }}
                                                        proposePayment={(p) => dispatch(proposePayment(p))}
                                                        totalDebits={transactions.totals.expense_total}
                                                        openRestrictedPaymentFeaturePopper={
                                                            this.toggleRestrictedPaymentFeaturePopper}
                                                        isFamilyUser={isRestricted}
                                                        showRefundDialog={this.showRefundDialog}
                                                        showAdjustPaymentDialog={(info, payer) => {
                                                            this.setState(
                                                                { isAdjustPaymentModeOn: true },
                                                                () => this.showPaymentDialog(info, payer)
                                                            );
                                                        }}
                                                        handleClickEventOnAvatar={handleClickEventOnAvatar}
                                                        setDownloadInvoice={setDownloadInvoice}
                                                        zIndex={zIndex + 1}
                                                    />}
                                                    {group.payer_id < 0 && <EmptyPaymentCard
                                                        showAssignPayerDialog={(ref, info) =>
                                                            this.showAssignPayerDialog(ref, info)}
                                                        group={group}
                                                        funeralHomeCaseId={selectedCase.funeral_home_case_id}
                                                        deletePayment={(paymentId) => {
                                                            this.toggleConfirmationDialog(
                                                                true,
                                                                {
                                                                    name: 'empty payer card',
                                                                    id: paymentId,
                                                                    onConfirm: () => this.deletePayment(paymentId)
                                                                }
                                                            );
                                                        }}
                                                        showRecordInsurancePolicyDialog={(info, payer) =>
                                                            this.showRecordInsurancePolicyDialog(info, payer)}
                                                    />}
                                                </ImageListItem>
                                            )}
                                        </Transition>
                                    )}

                                    {/* we need to sync animation */}
                                    {!isRestricted &&
                                        <Transition
                                            in
                                            timeout={800}
                                            key="add-another-payer"
                                        >
                                            {(state) => (
                                                <ImageListItem
                                                    style={{
                                                        ...ZoomAnimationStyle,
                                                        ...TransitionStyles[state]
                                                    }}
                                                    className={classes.li}
                                                    classes={{ img: classes.listTile }}
                                                >
                                                    <DisabledPermissionTooltip permission={Permission.ADD_PAYMENTS}>
                                                        {disabled =>
                                                            <div>
                                                                <AddPayerCard
                                                                    label="Add another payer"
                                                                    onClick={disabled ? undefined : this.addPayer}
                                                                />
                                                            </div>
                                                        }
                                                    </DisabledPermissionTooltip>
                                                </ImageListItem>
                                            )}
                                        </Transition>
                                    }
                                </TransitionGroup>
                            </ul>
                        </div>}
                    {isRestricted && payments.length === 0 && <Typography
                        color="secondary"
                        align="center"
                        className={classes.fontSize18}
                    >
                        Payment details will display here once entered by {selectedCase.assignee.fname}.
                    </Typography>}

                    {!!selectedCase.insurance_policies.length &&
                        <Grid marginTop={'60px'}>
                            <WhenEnabled feature={FeatureKey.INSURANCE_INTEGRATION}>
                                <PreArrangedGoodAndServices
                                    activeCase={selectedCase}
                                    funeralHome={this.props.funeralHome}
                                    title={'Insurance information detected for this case!'}
                                    subtitle={`Refer to ${(selectedCase.fname || '')}'s
                            insurance Information to add the relevant payers.`}
                                    zIndex={zIndex + 1}
                                    btntext={'Review Policy Information'}
                                />
                            </WhenEnabled>
                        </Grid>
                    }
                </div>

                <AssignPaymentPopper
                    zIndex={zIndex + 1}
                    closePopper={() => this.closeAssignPayerDialog()}
                    anchorEl={assignPayerAnchorEl}
                    selectedCase={selectedCase}
                    patchPayment={this.handlePatchPayment}
                    paymentInfo={activePaymentInfo}
                    helpers={helpers}
                />

                <CashPaymentDialog
                    key={`cpd:${paymentDialogKey}`}
                    zIndex={zIndex + 1}
                    isEditMode={isAdjustPaymentModeOn}
                    caseUuid={selectedCase.uuid}
                    patchPayment={this.handlePatchPayment}
                    isDialogOpen={isOpenCashPaymentDialog}
                    closeDialog={this.closeCashPaymentDialog}
                    paymentInfo={activePaymentInfo}
                    payer={this.state.payer}
                    updatePayment={(info) => this.updatePayment(info)}
                    openMySettingsDialog={this.openMySettingsDialog}
                    onAddNonUserContactInfoClick={onAddNonUserContactInfoClick}
                    removePayment={id => this.toggleConfirmationDialog(
                        true,
                        {
                            name: 'this payment',
                            id,
                            onConfirm: () => {
                                if (this.state.activePaymentOrPayer && this.state.activePaymentOrPayer.id) {
                                    this.deletePayment(this.state.activePaymentOrPayer.id);
                                }
                            }
                        }
                    )}
                />

                <PaymentReceiptDialog
                    isDialogOpen={Boolean(activeReceipt)}
                    funeralHome={this.props.funeralHome}
                    activeCase={selectedCase}
                    transactions={transactions}
                    paymentId={activeReceipt}
                    closeDialog={() => this.showReceipt(null)}
                    paymentMethodName={() => getPaymentMethodLabel(activePaymentInfo.method, activePaymentInfo.mode)}
                    zIndex={zIndex + 1}
                    sentNotification={sentNotification}
                    isRestricted={isRestricted}
                />

                <RecordPolicyPaymentDialog
                    key={`rpd:${paymentDialogKey}`}
                    zIndex={zIndex + 1}
                    isEditMode={isAdjustPaymentModeOn}
                    patchPayment={this.handlePatchPayment}
                    caseUuid={selectedCase.uuid}
                    isDialogOpen={isRecordPolicyPaymentDialogOpen}
                    closeDialog={(info) => this.closePolicyPaymentDialog(info)}
                    paymentInfo={activePaymentInfo}
                    payer={this.state.payer}
                    updatePayment={(info) => this.updatePayment(info)}
                    openMySettingsDialog={this.openMySettingsDialog}
                    onAddNonUserContactInfoClick={onAddNonUserContactInfoClick}
                    removePayment={() => {
                        this.toggleConfirmationDialog(
                            true,
                            {
                                name: 'this payment',
                                id: null,
                                onConfirm: () => activePaymentInfo.payment_id &&
                                    this.deletePayment(activePaymentInfo.payment_id)
                            }
                        );
                    }}
                />

                <RecordInsurancePolicyDialog
                    key={`rip:${paymentDialogKey}`}
                    isEditMode={isAdjustPaymentModeOn}
                    paymentInfo={activePaymentInfo}
                    caseUuid={selectedCase.uuid}
                    feeSchedule={financeState.transactions.feeSchedule}
                    patchPayment={this.handlePatchPayment}
                    updatePayment={(info) => this.updatePayment(info)}
                    isDialogOpen={isRecordInsurancePolicyDialogOpen}
                    closeDialog={() => this.setState({
                        isRecordInsurancePolicyDialogOpen: false,
                        isAdjustPaymentModeOn: false
                    })}
                    removePolicy={(payerId, payerName) => {
                        this.toggleConfirmationDialog(
                            true,
                            {
                                name: payerName,
                                id: payerId,
                                onConfirm: () => this.removePayer()
                            }
                        );
                    }}
                    zIndex={zIndex + 1}
                />

                <CardReaderDialog
                    key={`crd:${paymentDialogKey}`}
                    isDialogOpen={isOpenCardReaderDialog}
                    closeDialog={this.closeCardReaderDialog}
                    stripeLocation={stripeLocation}
                    paymentInfo={activePaymentInfo}
                    payer={this.state.payer}
                    updatePayment={(info) => this.updatePayment(info)}
                    activeCase={selectedCase}
                    openMySettingsDialog={this.openMySettingsDialog}
                    onAddNonUserContactInfoClick={onAddNonUserContactInfoClick}
                    zIndex={zIndex + 1}
                />

                <CaseOnlinePaymentDialog
                    key={`opd:${paymentDialogKey}`}
                    isDialogOpen={isOpenOnlinePaymentDialog}
                    isRestricted={isRestricted}
                    caseUuid={selectedCase.uuid}
                    closeDialog={this.closeOnlinePaymentDialog}
                    paymentInfo={activePaymentInfo}
                    updatePayment={(info) => this.updatePayment(info)}
                    payer={this.state.payer}
                    openMySettingsDialog={this.openMySettingsDialog}
                    onAddNonUserContactInfoClick={onAddNonUserContactInfoClick}
                    zIndex={zIndex + 1}
                    updateSentNotification={(didSend: boolean) => this.updateSentNotification(didSend)}
                />

                <PlaidPaymentDialog
                    key={`ppd:${paymentDialogKey}`}
                    isDialogOpen={isOpenPlaidPaymentDialog}
                    isRestricted={isRestricted}
                    closeDialog={this.closePlaidPaymentDialog}
                    paymentInfo={activePaymentInfo}
                    updatePayment={(info) => this.updatePayment(info)}
                    payer={this.state.payer}
                    openMySettingsDialog={this.openMySettingsDialog}
                    onAddNonUserContactInfoClick={onAddNonUserContactInfoClick}
                    zIndex={zIndex + 1}
                />

                <PriceAdjustmentDialog
                    key={`payment_${activeInvoice?.id ?? -1}`}
                    closeDialog={this.closePriceAdjustmentDialog}
                    isDialogOpen={isPriceAdjustmentDialogOpen}
                    onPriceAdjustmentChange={this.handlePriceAdjustment}
                    listPrice={getDinero(activeInvoice ? activeInvoice.debit : 0, 'USD')}
                    existingAdjustment={getDinero(0, 'USD')}
                    target={activeInvoice && activeInvoice.description
                        ? activeInvoice.description
                        : 'Invoice'
                    }
                    assetType={'USD'}
                    zIndex={zIndex + 3}
                />

                <RefundScreenDialog
                    key={`rsd:${paymentDialogKey}`}
                    chargeDetails={financeState.chargeDetails}
                    payment={paymentToRefund}
                    processRefund={(paymentId, amount, reason, memo) =>
                        dispatch(refundPayment(paymentId, amount, reason, memo))}
                    closeDialog={() => this.hideRefundDialog()}
                    zIndex={zIndex + 2}
                />

                <RestrictedPaymentFeaturePopper
                    restrictedPaymentFeature={activeRestrictedPaymentFeature}
                    closePopper={() => this.toggleRestrictedPaymentFeaturePopper(null)}
                    zIndex={zIndex + 1}
                />

                {!isRestricted && this.renderDetailMenu()}
                {this.renderConfirmationDialog()}
            </Grid>
        );
    }

    handlePatchPayment = async ({ caseUuid, sendNotification, paymentInfo, collect }: PatchPaymentParameters[0]) => {
        const { dispatch } = this.props;
        this.updateSentNotification(sendNotification);
        await dispatch(patchPayment({
            caseUuid,
            paymentInfo,
            collect,
            sendNotification,
        }));
    };

    updatePayment(activePaymentInfo: PaymentRequest) {
        this.setState({ activePaymentInfo });
    }

    updateSentNotification(sentNotification: boolean): void {
        this.setState({ sentNotification });
    }

    async removePayer() {
        const { dispatch } = this.props;
        const { activePaymentOrPayer } = this.state;
        if (this.props.selectedCase && this.props.selectedCase.id
            && activePaymentOrPayer && activePaymentOrPayer.id) {
            dispatch(removePayer(this.props.selectedCase.uuid, activePaymentOrPayer.id));
        }
        this.toggleConfirmationDialog(false, null);
    }

    voidInvoice(txn: Transaction) {
        const { dispatch, selectedCase } = this.props;
        if (txn.invoice_id === null) {
            log.warn('Invoice ID is null for transaction', { txn });
            return;
        }
        dispatch(adjustInvoice({
            amount: Dinero({ amount: 0, currency: 'USD' }),
            taxAmount: Dinero({ amount: 0, currency: 'USD' }),
            description: txn.description || '',
            caseUuid: selectedCase.uuid,
            invoiceId: txn.invoice_id,
        }));
        this.toggleConfirmationDialog(false, null);
    }

    async deletePayment(paymentId: number) {
        const { dispatch, selectedCase } = this.props;
        this.toggleConfirmationDialog(false, null);

        if (selectedCase && selectedCase.id) {
            this.setState({ isDeletingEmptyCard: true });
            await dispatch(deletePayment({ caseUuid: selectedCase.uuid, paymentId }));
            this.setState({ isDeletingEmptyCard: false });
        }
    }

    showReceipt(activeReceipt: number | null) {
        this.setState({ activeReceipt });
    }

    showRecordInsurancePolicyDialog(activePaymentInfo: PaymentRequest, payer: Payer) {
        let feeAmount: number;
        // If the payment already exists, don't try to calculate it again
        if (activePaymentInfo.payment_id) {
            feeAmount = activePaymentInfo.merch_fee.getAmount();
        } else {
            feeAmount = calculateProcessingFeesInCents(
                activePaymentInfo.amount.getAmount(),
                this.props.financeState.transactions.feeSchedule,
                activePaymentInfo.method || PaymentMethod.unknown);
        }
        const processingFee = Dinero({ amount: feeAmount, currency: 'USD' });
        activePaymentInfo.merch_fee = processingFee;
        this.setState({
            activePaymentInfo,
            payer,
            isRecordInsurancePolicyDialogOpen: true,
        });
    }

    showPaymentDialog(activePaymentInfo: PaymentRequest, payer: Payer, restricted: boolean = false) {
        // Calculate the default convenience fee based on the payment method
        // Only used for some payment methods

        const { dispatch } = this.props;
        const processingFee = Dinero({
            amount: calculateProcessingFeesInCents(
                activePaymentInfo.amount.getAmount(),
                this.props.financeState.transactions.feeSchedule,
                activePaymentInfo.method || PaymentMethod.unknown),
            currency: 'USD'
        });
        const newKey = this.state.paymentDialogKey + 1;
        switch (activePaymentInfo.method) {
            case PaymentMethod.cash:
            case PaymentMethod.check:
            case PaymentMethod.other:
                this.setState({
                    activePaymentInfo,
                    paymentDialogKey: newKey,
                    payer,
                    isOpenCashPaymentDialog: true,
                    isRestricted: restricted,
                });
                break;
            case PaymentMethod.card:
                activePaymentInfo.merch_fee = processingFee;
                const { terminalState } = this.props.financeState;
                dispatch(resetStripePaymentState());
                if (terminalState === 'NOT_INITIALIZED'
                    || terminalState === 'INITIALIZATION_ERROR'
                    || terminalState === 'CONNECTION_ERROR') {
                    // Try to initialize the terminal connection
                    if (this.props.funeralHome) {
                        dispatch(initializeStripeTerminal(this.props.funeralHome.stripe_location));
                    }
                }
                this.setState({
                    activePaymentInfo,
                    paymentDialogKey: newKey,
                    payer,
                    isOpenCardReaderDialog: true,
                    isRestricted: restricted,
                });
                break;
            case PaymentMethod.online:
                activePaymentInfo.merch_fee = processingFee;
                dispatch(resetStripeChargeState());
                this.setState({
                    activePaymentInfo,
                    paymentDialogKey: newKey,
                    payer,
                    isOpenOnlinePaymentDialog: true,
                    isRestricted: restricted,
                });
                break;
            case PaymentMethod.plaid:
                activePaymentInfo.merch_fee = processingFee;
                dispatch(resetPlaidChargeState());
                this.setState({
                    activePaymentInfo,
                    paymentDialogKey: newKey,
                    payer,
                    isOpenPlaidPaymentDialog: true,
                    isRestricted: restricted,
                });
                break;
            case PaymentMethod.insurance:
                this.setState({
                    activePaymentInfo,
                    paymentDialogKey: newKey,
                    payer,
                    isRecordPolicyPaymentDialogOpen: true,
                    isRestricted: restricted,
                });
                break;
            default:
                alert(`I don't know how to enter ${activePaymentInfo.method} payments`);
        }
    }

    addPayers(payerCount: number, split: number) {
        const { financeState, selectedCase, dispatch } = this.props;
        const { transactions } = financeState;
        const { totals } = transactions;
        const balance = totals.expense_total - totals.collected_total - totals.proposed_total;
        if (selectedCase && selectedCase.id) {
            const splits = Dinero({ amount: split ? balance : 0, currency: 'USD' })
                .allocate(new Array<number>(payerCount).fill(split || 1));
            const payments: PaymentRequest[] = splits.map((amount) => {
                const payment: PaymentRequest = {
                    method: PaymentMethod.unknown,
                    mode: PaymentMode.IN_PERSON,
                    amount,
                    merch_fee: Dinero({ amount: 0, currency: 'USD' }),
                    memo: '',
                    funeralHomeCaseId: selectedCase.funeral_home_case_id,
                    is_anon: false,
                    payer_id: NaN,
                    payer_name: '',
                    type: PaymentType.CASE,
                };
                return payment;
            });
            dispatch(proposeEmptyPayments(payments));
        }
    }

    addPayer = () => {
        const { financeState, selectedCase, dispatch } = this.props;
        const { transactions } = financeState;
        const { totals } = transactions;
        const balance = totals.expense_total - totals.collected_total - totals.proposed_total;
        if (selectedCase && selectedCase.id) {
            dispatch(proposePayment({
                method: PaymentMethod.unknown,
                mode: PaymentMode.IN_PERSON,
                type: PaymentType.CASE,
                amount: Dinero({ amount: balance, currency: 'USD' }),
                merch_fee: Dinero({ amount: 0, currency: 'USD' }),
                memo: '',
                funeralHomeCaseId: selectedCase.funeral_home_case_id,
                is_anon: false,
                payer_id: NaN,
                payer_name: '',
            }));
        }
    };

    showAssignPayerDialog(event: React.MouseEvent<HTMLElement>, activePaymentInfo: PaymentRequest) {
        this.setState({ assignPayerAnchorEl: event.currentTarget, activePaymentInfo });
    }

    closeAssignPayerDialog() {
        this.setState({ assignPayerAnchorEl: null });
    }

    closeCashPaymentDialog = (activeReceipt: number | null) => {
        this.setState((prevState) => ({
            isOpenCashPaymentDialog: false,
            activeReceipt,
            isAdjustPaymentModeOn: false
        }));
    };

    closePolicyPaymentDialog = (activeReceipt: number | null) => {
        this.setState({
            isRecordPolicyPaymentDialogOpen: false,
            activeReceipt,
            isAdjustPaymentModeOn: false
        });
    };

    updatePayerCount = (payerCount: number) => {
        this.setState({
            payerCount,
        });
        if (payerCount === 1) {
            this.addPayers(1, 1);
        }
    };

    closeCardReaderDialog = (activeReceipt: number | null) => {
        this.setState(() => ({
            isOpenCardReaderDialog: false,
            activeReceipt
        }));
    };

    closeOnlinePaymentDialog = (activeReceipt: number | null) => {
        this.setState(() => ({
            isOpenOnlinePaymentDialog: false,
            activeReceipt
        }));
    };

    closePlaidPaymentDialog = (activeReceipt: number | null) => {
        this.setState(() => ({
            isOpenPlaidPaymentDialog: false,
            activeReceipt
        }));
    };

    showRefundDialog = (payment: Transaction) => {
        const { dispatch } = this.props;
        if (payment.payment_id) {
            dispatch(getStripeChargeDetails(payment.payment_id));
            this.setState({ paymentToRefund: payment });
        }
    };

    hideRefundDialog = () => {
        this.setState({ paymentToRefund: null });
    };

    openPriceAdjustmentDialog = (activeInvoice: Transaction) => {
        this.setState({
            isPriceAdjustmentDialogOpen: true,
            activeInvoice,
        });
    };

    closePriceAdjustmentDialog = () => {
        this.setState({
            isPriceAdjustmentDialogOpen: false,
            activeInvoice: null,
        });
    };

    handlePriceAdjustment = (priceAdjustment: number) => {
        const { dispatch, taxRates, selectedCase } = this.props;
        const { activeInvoice } = this.state;

        if (!activeInvoice) {
            console.warn('No active invoice on price adjustment');
            return;
        }

        const newAmount = activeInvoice.debit + priceAdjustment;
        let newTaxDinero = Dinero();
        if (activeInvoice.invoice_tax_rate_id) {
            const taxRate = taxRates.find((tr) => tr.id === activeInvoice.invoice_tax_rate_id);
            if (taxRate) {
                newTaxDinero = getDinero(applyTaxRate(newAmount, taxRate), 'USD');
            } else {
                console.warn('handlePriceAdjustment: Tax rate not found in list of tax rates');
            }
        }

        if (activeInvoice) {
            if (activeInvoice.invoice_id === null) {
                log.warn('Invoice ID is null for activeInvoice', { activeInvoice });
                return;
            }
            dispatch(adjustInvoice({
                amount: Dinero({ amount: newAmount, currency: 'USD' }),
                taxAmount: newTaxDinero,
                description: activeInvoice.description || '',
                caseUuid: selectedCase.uuid,
                invoiceId: activeInvoice.invoice_id,
            }));
        }
    };

    toggleConfirmationDialog = (
        isConfirmationDialogOpen: boolean,
        activePaymentOrPayer: ActivePaymentOrPayer | null
    ) => {
        this.setState({ isConfirmationDialogOpen, activePaymentOrPayer });
    };

    openMySettingsDialog = () => {
        const { dispatch } = this.props;
        const { payer } = this.state;

        if (payer.user) {
            dispatch(openMySettingsDialog(1330, payer.payerEntity.id));
        }
    };

    showDetailMenu = (anchorEle: HTMLElement, transaction: Transaction) => {
        this.setState({
            activeDetailMenu: { transaction, anchorEle }
        });
    };

    hideDetailMenu = () => {
        this.setState({ activeDetailMenu: null });
    };

    adjustPaymentOrPolicy = (transaction?: Transaction | null, isPolicy?: boolean) => {
        const { selectedCase } = this.props;
        if (!transaction || !transaction.payment_id) {
            return;
        }
        const amount = transaction.merch_fee && transaction.method === PaymentMethod.other ?
            transaction.credit - transaction.merch_fee : transaction.credit;

        const activePaymentInfo: PaymentRequest = {
            payment_id: transaction.payment_id,
            payer_id: transaction.payer_id,
            payer_name: transaction.payer_name || '',
            method: transaction.method ?? PaymentMethod.unknown,
            mode: transaction.mode || PaymentMode.IN_PERSON,
            type: PaymentType.CASE,
            amount: Dinero({ amount, currency: 'USD' }),
            merch_fee: Dinero({ amount: transaction.merch_fee || 0, currency: 'USD' }),
            memo: transaction.description || '',
            funeralHomeCaseId: selectedCase.funeral_home_case_id,
            is_anon: transaction.is_anon,
            external_id: transaction.external_id || undefined,
            payment_date: moment(transaction.payment_date),
        };

        const { fname, mname, lname } = splitFullName(transaction.payer_name);
        const payer: Payer = {
            payerEntity: {
                id: transaction.payer_id || 0,
                fname,
                mname,
                lname,
                email: transaction.payer_email || '',
                phone: transaction.payer_phone || '',
                type: EntityType.person,
                user_profile_id: transaction.payer_user_id || null,
            },
            user: undefined
        };

        if (isPolicy) {
            this.showRecordInsurancePolicyDialog(activePaymentInfo, payer);
        } else {
            this.showPaymentDialog(activePaymentInfo, payer);
        }

        this.setState({ isAdjustPaymentModeOn: true });
    };

    toggleRestrictedPaymentFeaturePopper = (activeRestrictedPaymentFeature: RestrictedPaymentFeature | null) => {
        this.setState({ activeRestrictedPaymentFeature });
    };
}

export default withState(mapStateToProps)(withGStyles(styleWrapper<Props>())(PaymentTab));
