import classNames from 'classnames';

import ImageListItem from '@mui/material/ImageListItem';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Zoom from '@mui/material/Zoom';

import UserAvatarWithZoom from '../common/UserAvatarWithZoom';
import { useGDispatch, useGSelector } from '../../types';
import {
    EntityCaseRole,
    GatherCaseUX,
    getDisplayRelationshipForPerson,
    getCaseRole,
    EntitySummary,
    getCaseEntity,
    UserRoles,
    getDisplayTitleForFHUser,
} from '../../shared/types';
import { GRAY_COLOR_3, SKYBLUE_COLOR_2 } from '../../constants/colorVariables';
import HelperPopper from '../family/helperPopper/HelperPopper';
import { canViewCaseAllHelpersReport } from '../../shared/authority/can';
import CaseHelperReport from '../family/caseHelperReport';
import { closeHelperInvitationDialog } from '../../actions/Dialog.action';
import { Theme } from '@mui/material/styles';
import { getHelpersTasks } from '../../shared/utils/task';
import { useState } from 'react';
import makeStyles from "@mui/styles/makeStyles";
import { GStyles } from '../../styles/GStyles';

interface Props {
    activeTab: EntityCaseRole;
    emptyAvatarList?: number[];
    selectedCase: GatherCaseUX;
    closeParentDialog: () => void;
    zIndex: number;
}
const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    avatarContainer: {
        display: 'inline-flex',
        width: 'auto !important',
        height: 'auto !important',
    },
    avatar: {
        height: 60,
        width: 60,
        '&$avatarButton': {
            margin: '8px auto 7px',
            padding: 0
        },
        '&$profilePictureAvatar': {
            boxShadow: theme.shadows[5],
        },
        '&$emptyAvatar': {
            color: theme.palette.primary.main,
            boxSizing: 'border-box',
            margin: '8px auto 5px',
            border: '2px dashed',
            background: 'transparent'
        },
        '&$nameInitialsAvatar': {
            fontSize: 30,
            textTransform: 'uppercase',
            boxShadow: theme.shadows[5],
            fontWeight: 200,
            '-webkit-font-smoothing': 'antialiased',
        },
    },
    gridList: {
        maxWidth: '100%',
        width: 'fit-content',
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
        margin: '18px auto !important',
        display: 'flex',
        padding: 0,
        overflowY: 'auto',
        listStyle: 'none',
        scrollBehavior: 'smooth',
        '-webkit-overflow-scrolling': 'touch',
        '& li': {
            height: 'auto !important',
            width: '98px !important',
        }
    },
    avatarCaption: {
        fontSize: 12,
        fontWeight: 500,
        width: 98,
        lineHeight: '1.275em',
        marginLeft: 'auto',
        marginRight: 'auto',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',
    },
    gridListTTitle: {
        textAlign: 'center',
        boxSizing: 'border-box',
        flexShrink: 0,
        display: 'flex',
        padding: 0,
        flexWrap: 'wrap',
        overflowY: 'auto',
        listStyle: 'none',
        '-webkit-overflow-scrolling': 'touch',
    },
    gridTile: {
        height: '100%',
        display: 'block',
        position: 'relative',
        overflow: 'hidden',
        margin: '0 auto',
        '& $overlapDot': {
            top: 4,
            left: 4,
            fontSize: 12,
            background: SKYBLUE_COLOR_2,
            height: '1em',
            width: '1em',
            borderRadius: '50%',
            zIndex: 1,
            position: 'absolute',
        },
    },
    loadingText: {
        color: theme.palette.secondary.main,
        margin: '-6px 8px 11px',
        textTransform: 'uppercase',
        fontSize: 12,
        fontWeight: 500,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 90,
    },
    caseHelperReportGrid: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        '& button': {
            margin: '8px auto 7px',
            minWidth: 60,
            maxWidth: 60,
            minHeight: 60,
            maxHeight: 60
        },
        '& svg': {
            fontSize: 44,
            color: GRAY_COLOR_3
        }
    },
    progress: {
        marginLeft: 9,
    },
    avatarButton: {},
    profilePictureAvatar: {},
    emptyAvatar: {},
    nameInitialsAvatar: {},
    overlapDot: {},
}), { name: 'InviteeList' });

const InviteeList = (props: Props) => {

    const {
        activeTab,
        closeParentDialog,
        selectedCase,
        zIndex,
        emptyAvatarList,
    } = props;

    const classes = useStyles();
    const dispatch = useGDispatch();
    const { helpers, isHelpersLoading } = useGSelector((state) => state.casesState);
    const tasks = useGSelector((state) => state.tasksState.checklistTasks);
    const { userData, isUserPhotoSaving } = useGSelector((state) => state.userSession);

    const [helperPopperAnchorEle, setHelperPopperAnchorEle] = useState<HTMLElement | null>(null);
    const [activeEntityId, setActiveEntityId] = useState<number | null>(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const shouldRenderHelperReportFab = userData && canViewCaseAllHelpersReport(userData, selectedCase.id);

    const openCaseHelperDialog = () => {
        dispatch(closeHelperInvitationDialog());
        if (shouldRenderHelperReportFab) {
            setIsDialogOpen(true);
        }
    };

    const closeDialog = () => {
        setIsDialogOpen(false);
    };


    const renderCaseHelperReportTab = () => {

        return (
            // TODO(Jonathan): consider refactoring this to use a List/ListItem component
            <ImageListItem
                className={classNames(
                    classes.gridListTTitle,
                )}
            >
                <div className={classNames(classes.gridTile, classes.caseHelperReportGrid)}>
                    <Zoom
                        in
                        timeout={1200}
                    >
                        <IconButton onClick={e => openCaseHelperDialog()} size="large">
                            <FormatListBulletedIcon color="primary" />
                        </IconButton>
                    </Zoom>

                    <Zoom
                        in
                        timeout={1200}
                    >
                        <Typography
                            variant="caption"
                            gutterBottom
                            align="center"
                            color="secondary"
                            className={classNames(classes.avatarCaption, GStyles.textUppercase)}
                        >
                            {'view all'}
                        </Typography>
                    </Zoom>
                </div>
            </ImageListItem >
        );
    };

    const renderEmptyAvatars = () => {
        const showOverlapDot = activeTab === EntityCaseRole.admin;

        return (
            <div className={classes.avatarContainer} id="invitee-list-inner-2">
                {(emptyAvatarList || []).map((emptyAvatar: number) => (
                    <ImageListItem
                        key={emptyAvatar}
                        className={classes.gridListTTitle}
                    >
                        <div className={classes.gridTile}>
                            <Zoom in timeout={1200} key={activeTab}>
                                <div className={GStyles.positionRelative}>
                                    {showOverlapDot && <span className={classes.overlapDot} />}
                                    <Avatar className={classNames(classes.avatar, classes.emptyAvatar)} />
                                </div>
                            </Zoom>
                        </div>
                    </ImageListItem>
                ))}
            </div >
        );
    };

    const closeHelperPopper = () => {
        setHelperPopperAnchorEle(null);
        setActiveEntityId(null);
    };

    const openHelperPopper = (
        event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>,
        _activeEntityId: number,
    ) => {
        setActiveEntityId(_activeEntityId);
        setHelperPopperAnchorEle(event.currentTarget);
    };

    const renderHelperPopper = () => {

        return (
            <HelperPopper
                key={activeEntityId}
                zIndex={zIndex}
                clickAwayListener={closeHelperPopper}
                selectedCase={selectedCase}
                activeEntityId={activeEntityId}
                popperAnchorEle={helperPopperAnchorEle}
                closeHelperPopper={closeHelperPopper}
                closeParentDialog={closeParentDialog}
            />
        );
    };

    const renderProfilePictureAvatars = () => {

        const { assignee } = selectedCase;
        const displayHelpers = helpers.filter((helper) => getCaseRole(helper, selectedCase.id) === activeTab);
        const displayEntities = activeTab === EntityCaseRole.admin
            ? [assignee, ...displayHelpers]
            : displayHelpers;

        return (
            <div className={classes.avatarContainer} id="invitee-list-inner-1">
                {displayEntities.map((helper: EntitySummary) => {
                    const caseEntity = getCaseEntity(helper, selectedCase.id);
                    const isAssignee = assignee.entity_id === helper.entity_id;
                    if (!caseEntity && !isAssignee) {
                        return null;
                    }
                    const helpersTasks = getHelpersTasks({ tasks, helper, onlyIncompleteTasks: true });
                    const helperTaskCount = helpersTasks.length;
                    return (
                        <ImageListItem
                            key={helper.entity_id}
                            className={classes.gridListTTitle}
                        >
                            <div className={classes.gridTile}>
                                <IconButton
                                    className={classNames(classes.avatarButton, classes.avatar)}
                                    onClick={(e) => openHelperPopper(e, helper.entity_id)}
                                    size="large">
                                    <UserAvatarWithZoom
                                        user={helper}
                                        photoClassName={classNames(classes.avatar, classes.profilePictureAvatar)}
                                        initialsClassName={classNames(classes.avatar, classes.nameInitialsAvatar)}
                                        zoomDuration={1200}
                                        size={70}
                                        isLoading={isUserPhotoSaving && userData && helper.user_id
                                            && userData.id === helper.user_id || undefined}
                                        showOverlapDot={UserRoles.isFamilyAdmin(helper, selectedCase.id)
                                            || UserRoles.isFHorGOMUser(helper) || isAssignee}
                                        overlapDotClass={classes.overlapDot}
                                        isInviteeList
                                        badgeCount={helperTaskCount}
                                        isMe={Boolean(userData && helper.user_id && userData.id === helper.user_id)}
                                    />
                                </IconButton>
                                <Zoom
                                    in
                                    timeout={1200}
                                >
                                    <Typography
                                        variant="caption"
                                        align="center"
                                        color="secondary"
                                        className={classNames(classes.avatarCaption, GStyles.textUppercase)}
                                    >
                                        {helper.fname}
                                    </Typography>
                                </Zoom>
                                <Zoom
                                    in
                                    timeout={1200}
                                >
                                    <Typography
                                        variant="caption"
                                        gutterBottom
                                        align="center"
                                        color="secondary"
                                        className={classNames(classes.avatarCaption, GStyles.fontWeight400)}
                                    >
                                        {isAssignee
                                            ? getDisplayTitleForFHUser(assignee, selectedCase.funeral_home_id)
                                            : caseEntity
                                                ? getDisplayRelationshipForPerson(caseEntity) : ''
                                        }
                                    </Typography>
                                </Zoom>
                            </div>
                        </ImageListItem>
                    );
                })}
            </div>
        );
    };

    const renderLoadingHelpers = () => {
        return (
            <ImageListItem>
                <CircularProgress
                    color="primary"
                    className={classes.progress}
                    size={76}
                    thickness={3}
                />
                <Typography
                    align="center"
                    noWrap
                    className={classes.loadingText}
                >
                    Loading...
                </Typography>
            </ImageListItem>
        );
    };

    return (
        <ul className={classes.gridList}>
            {isHelpersLoading ? renderLoadingHelpers() :
                <>
                    {renderProfilePictureAvatars()}
                    {renderEmptyAvatars()}
                    {renderHelperPopper()}
                    {shouldRenderHelperReportFab && renderCaseHelperReportTab()}
                    {shouldRenderHelperReportFab &&
                        <CaseHelperReport
                            zIndex={zIndex}
                            selectedCase={selectedCase}
                            isOpen={isDialogOpen}
                            closeDialog={closeDialog}
                        />
                    }
                </>
            }
        </ul>
    );
};

export default InviteeList;
