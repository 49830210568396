import CheckIcon from '@mui/icons-material/Check';
import { FormControl, Grid, TextField, Typography } from "@mui/material";
import {
    CaseHelperCreateRequest,
    EntityCaseRole,
    EntityRelationship,
    EntityRelationshipType,
    EntitySummary,
    GatherCaseUX,
    getCaseEntity,
    getCaseEntityById,
    UserInviteStatusEnum,
    UserProfile,
    UserRole,
} from "../../../../shared/types";
import classNames from "classnames";
import { GStyles } from "../../../../styles/GStyles";
import DCRelatedEntities from "../DCRelatedEntities";
import { useGDispatch, useGSelector } from "../../../../types";
import { DCEntityEnum } from "../../../assignmentPoppers/SelectHelper";
import GGroupButtons from "../../../common/GGroupButtons";
import { AliveType } from "../../../invitation/InvitationForm";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PhoneNumberMask from "../../../common/PhoneNumberMask";
import GButton from "../../../common/GButton";
import { styled } from '@mui/material';
import { isValidEmail, isValidPhoneNumber, splitFullName } from '../../../../services';
import { useCallback, useEffect, useMemo, useState } from 'react';
import makeStyles from "@mui/styles/makeStyles";
import { inviteCaseHelper } from '../../../../actions/CaseHelper.action';
import { setAppSnackbar } from '../../../../actions/AppSnackbar.action';
import AdminGuestToggleButton from '../../../dialogs/mySettings/AdminGuestToggleButton';
import SimilarUsersDialog from '../../../invitation/SimilarUsersDialog';
import { log } from '../../../../logger';

const useStyles = makeStyles({
    minWidth124: {
        minWidth: '124px',
    },
    adminGuestToggleButton: {
        marginTop: '16px',
    },
}, { name: 'DCInvitationForm' });

const Container = styled(Grid)(({ theme }) => ({
    width: '100%',
    maxWidth: '100%',
    '@media (min-width: 421px)': {
        width: 360,
        maxWidth: 360,
    },
    '@media (min-width: 600px)': {
        width: 280,
    },
    '@media (min-width: 800px)': {
        width: 360,
        maxWidth: 360,
    },
}));

const Heading = styled(Typography)(({ theme }) => ({
    fontSize: '18px',
    fontWeight: '300',
    padding: '12px 0px 0px',
    color: theme.palette.secondary.main,
}));

const HelperNote = styled(Typography)({
    padding: '12px 0px 4px',
    fontSize: '13px',
});

const RecordManually = styled(Typography)(({ theme }) => ({
    fontSize: '13px',
    padding: '6px 0px 12px',
    color: theme.palette.secondary.main,
}));

const InviteAsHelperButton = styled(GButton)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    fontSize: 14,
    textTransform: 'uppercase',
    paddingTop: '12px',
    margin: '8px auto -12px',
    cursor: 'pointer',
    boxShadow: 'none',
    '&:hover': {
        background: `transparent`,
        boxShadow: 'none',
    },
    '&:disabled': {
        backgroundColor: theme.palette.common.white,
    },
}));

const TextFieldsContainer = styled(Grid)({
    maxWidth: '380px',
    display: 'flex',
    rowGap: '8px',
    columnGap: '8px',
    margin: '12px auto 0px',
});

interface DCInvitationFormProps {
    DCEntity: 'Father' | 'Mother' | 'Spouse' | 'Informant';
    activeCase: GatherCaseUX;
    selectedCaseEntityId: number | null;
    selectedEntityId: number | null;
    onEntityChange: (entity: EntitySummary | null) => void;
    relationToDeceased: DCEntityEnum;
    isDeathCertificateLocked: boolean;
    updateState: (newlyAddedHelper: EntitySummary) => void;
    zIndex: number;
}

const DCInvitationForm = (props: DCInvitationFormProps) => {

    const {
        activeCase,
        selectedCaseEntityId,
        onEntityChange,
        relationToDeceased,
        isDeathCertificateLocked,
        DCEntity,
        updateState,
        zIndex,
    } = props;

    const classes = useStyles();
    const dispatch = useGDispatch();
    const { helpers } = useGSelector(({ casesState }) => casesState);
    const { userData } = useGSelector(({ userSession }) => userSession);
    const { similarUsers } = useGSelector(({ invitationState }) => invitationState);

    const getSelectedEntity = useMemo(() => (entityId: number | null): EntitySummary | null => {
        return entityId && helpers.find((helper) => Boolean(getCaseEntityById(helper, entityId))) || null;
    }, [helpers]);

    const selectedEntity = getSelectedEntity(selectedCaseEntityId);
    const isAlreadyInvited = selectedEntity?.user;

    const [isInviting, setIsInviting] = useState(false);
    const [fullName, setFullName] = useState('');
    const [aliveType, setAliveType] = useState<AliveType>(AliveType.null);
    const [personEmail, setPersonEmail] = useState('');
    const [personPhone, setPersonPhone] = useState('');
    const [entityCaseRole, setEntityCaseRole] = useState(EntityCaseRole.guest);
    const [isSimilarUsersDialogOpen, setIsSimilarUsersDialogOpen] = useState(false);

    const updateAliveType = useCallback((personId: number | null) => {
        const isDeceased = getSelectedEntity(personId)?.is_deceased;

        const _aliveType = isDeceased === true
            ? AliveType.deceased
            : isDeceased === false
                ? AliveType.living
                : AliveType.null;

        setAliveType(_aliveType);
    }, [getSelectedEntity]);

    useEffect(() => {
        updateAliveType(selectedCaseEntityId);
    }, [selectedCaseEntityId, updateAliveType]);

    const isEmailValid = useMemo(() => personEmail.trim() !== '' && isValidEmail(personEmail), [personEmail]);
    const isPhoneValid = useMemo(() => personPhone.trim() !== '' && isValidPhoneNumber(personPhone), [personPhone]);

    const caseEntity = getCaseEntity(selectedEntity, activeCase.id);

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFullName(event.target.value);

        if (!event.target.value.trim()) {
            setAliveType(AliveType.null);
        }
    };

    const handleAddHelper = async (_aliveType: AliveType) => {

        const relationship_type = (DCEntity === 'Father' || DCEntity === 'Mother')
            ? EntityRelationshipType.family
            : null;

        const relationship = DCEntity === 'Father' ? EntityRelationship.father
            : DCEntity === 'Mother'
                ? EntityRelationship.mother
                : null;

        const isDeceased = _aliveType === AliveType.deceased;
        const { fname, mname, lname } = splitFullName(fullName);

        const request: CaseHelperCreateRequest = {
            fname,
            mname,
            lname,
            email: null,
            phone: null,
            home_phone: null,
            work_phone: null,
            relationship_type: relationship_type,
            relationship: relationship,
            relationship_alias: '',
            case_role: EntityCaseRole.guest,
            entity_id: null,
            is_deceased: isDeceased,
            home_address: null,
            send_invite: false,
            use_address_description: false,
            state_license_number: null,
            case_entity_subscription_updates: [],
            case_entity_subscription_creates: [],
        };

        handleHelperResponse(request);
    };

    const inviteHelper = async () => {
        setIsInviting(true);

        if (entityCaseRole === EntityCaseRole.visitor) {
            log.warn('Cannot invite helper with visitor role', { entityCaseRole });
            return;
        }

        if (!selectedEntity) {
            return;
        };

        const {
            fname,
            mname,
            lname,
            is_deceased,
            work_phone,
            home_phone,
            home_address,
            use_address_description,
            state_license_number,
            email,
            phone,
            entity_id,
        } = selectedEntity;

        const { relationship_type, relationship, relationship_alias } = caseEntity ?? {};
        const request: CaseHelperCreateRequest = {
            fname,
            mname,
            lname,
            email: personEmail || email,
            phone: personPhone || phone,
            home_phone,
            work_phone,
            relationship_type: relationship_type ?? null,
            relationship: relationship ?? null,
            relationship_alias: relationship_alias ?? null,
            case_role: entityCaseRole,
            entity_id: entity_id,
            is_deceased,
            home_address,
            send_invite: true,
            use_address_description: use_address_description ?? false,
            state_license_number,
            case_entity_subscription_updates: [],
            case_entity_subscription_creates: [],
        };

        handleHelperResponse(request);
    };

    const handleHelperResponse = async (
        request: CaseHelperCreateRequest,
    ) => {
        if (!activeCase) {
            return;
        }

        const response = await dispatch(inviteCaseHelper(activeCase.uuid, request));

        if (!response) {
            return;
        }

        if (response.status === UserInviteStatusEnum.success && response.helpers && response.addedEntityId) {
            const newlyAddedHelper = response.helpers.find((h) => h.entity_id === response.addedEntityId);
            if (newlyAddedHelper) {
                updateState(newlyAddedHelper);
                dispatch(setAppSnackbar(`Success`, 'success'));
                setIsInviting(false);
            }
        } else if (response.similarUsers &&
            (response.status === UserInviteStatusEnum.multiple_users_found
                || UserInviteStatusEnum.different_name_found)) {
            setIsSimilarUsersDialogOpen(true);
        } else {
            dispatch(setAppSnackbar(`Error`, 'error'));
            setIsInviting(false);
            return;
        }
    };

    const handleSelectSimilarUser = async (user: UserProfile) => {
        setIsInviting(true);

        if (entityCaseRole === EntityCaseRole.visitor) {
            log.warn('Cannot invite helper with visitor role', { entityCaseRole });
            return;
        }

        const { relationship_type, relationship, relationship_alias } = caseEntity ?? {};
        const { fname, mname, lname, email, phone, entity_id, use_address_description } = user;

        const request: CaseHelperCreateRequest = {
            fname: fname,
            mname: mname || null,
            lname: lname || '',
            email: email || null,
            phone: phone || null,
            work_phone: null,
            home_phone: null,
            entity_id: entity_id,
            relationship_type: relationship_type || null,
            relationship: relationship ?? null,
            relationship_alias: relationship_alias || null,
            case_role: entityCaseRole,
            is_deceased: false,
            home_address: null,
            send_invite: true,
            use_address_description: use_address_description ?? false,
            state_license_number: null,
            case_entity_subscription_updates: [],
            case_entity_subscription_creates: [],
        };

        handleHelperResponse(request);
    };

    let fieldsToDisplay: ("relationship" | "full_name" | keyof EntitySummary)[] = [];
    let requiredFields: ("relationship" | "full_name" | keyof EntitySummary)[] = [];

    if (DCEntity === 'Father' || DCEntity === 'Mother' || DCEntity === 'Spouse') {
        fieldsToDisplay = ['fname', 'mname', 'lname', 'relationship'];
        requiredFields = ['fname', 'lname'];
    }
    if (DCEntity === 'Informant') {
        fieldsToDisplay = ['full_name', 'relationship', 'home_address', 'phone'];
        requiredFields = ['full_name', 'relationship', 'home_address'];
    }

    return (
        <>
            <Heading>
                Who is {activeCase.fname}'s {DCEntity}?
            </Heading>

            <Container className={GStyles.marginAuto}>
                <DCRelatedEntities
                    activeCase={activeCase}
                    helpers={helpers}
                    userData={userData}
                    selectedCaseEntityId={selectedCaseEntityId}
                    onEntityChange={onEntityChange}
                    zIndex={1320}
                    relationToDeceased={relationToDeceased}
                    fieldsToDisplay={fieldsToDisplay}
                    requiredFields={requiredFields}
                    isDeathCertificateLocked={isDeathCertificateLocked}
                />
            </Container>

            {!selectedCaseEntityId && <>
                <RecordManually>Or Record {DCEntity} Manually</RecordManually>

                <Container item className={classNames(GStyles.textLeft, GStyles.marginBottom10, GStyles.marginAuto)}>
                    <FormControl className={classNames(GStyles.width100, GStyles.textLeft)} required>
                        <TextField
                            required
                            label={`${DCEntity}'s Full Name`}
                            id={`${DCEntity}fullName`}
                            name="fullName"
                            value={fullName}
                            className={GStyles.width100}
                            onChange={handleChangeName}
                        />
                    </FormControl>
                </Container>
            </>}

            <Grid sx={{ marginTop: '16px' }}>
                <GGroupButtons
                    buttons={[
                        { label: AliveType.living, value: AliveType.living },
                        { label: AliveType.deceased, value: AliveType.deceased }
                    ]}
                    activeButton={aliveType}
                    disabled={!!selectedCaseEntityId || !fullName.trim()}
                    onClick={(_aliveType) => {
                        setAliveType(_aliveType);
                        setTimeout(() => handleAddHelper(_aliveType), 100);
                    }}
                    controlClasses={classes.minWidth124}
                />

                {selectedEntity &&
                    <HelperNote color="secondary">
                        {selectedEntity?.fname} has been preserved as a&nbsp;
                        {activeCase.display_fname}'s {DCEntity} in {activeCase.display_fname}'s family list.
                    </HelperNote>
                }
            </Grid>

            {aliveType === AliveType.living && selectedEntity &&
                <>
                    <TextFieldsContainer>
                        <TextField
                            placeholder={"Add Email"}
                            name="email"
                            type="email"
                            value={selectedEntity?.email || personEmail}
                            className={GStyles.width100}
                            onChange={(e) => setPersonEmail(e.target.value)}
                            size="small"
                            disabled={!!isAlreadyInvited}
                        />

                        <TextField
                            placeholder={'Add Phone'}
                            type="tel"
                            name="phone"
                            value={selectedEntity?.phone || personPhone}
                            className={GStyles.width100}
                            onChange={(e) => setPersonPhone(e.target.value)}
                            InputProps={{ inputComponent: PhoneNumberMask }}
                            size="small"
                            disabled={!!isAlreadyInvited}
                        />
                    </TextFieldsContainer>

                    <AdminGuestToggleButton
                        entityCaseRole={isAlreadyInvited ? caseEntity?.case_role || entityCaseRole : entityCaseRole}
                        setSettingsUserButton={(role) => setEntityCaseRole(role)}
                        disabled={isAlreadyInvited ? true : !(isEmailValid || isPhoneValid)}
                        rootClasses={classes.adminGuestToggleButton}
                    />

                    <InviteAsHelperButton
                        disabled={isAlreadyInvited ? true : !(isEmailValid || isPhoneValid)}
                        onClick={() => inviteHelper()}
                        isSpinning={isInviting}
                    >
                        {isAlreadyInvited
                            ? <><CheckIcon />&nbsp; Invited</>
                            : <><PersonAddIcon />&nbsp; Invite {selectedEntity?.fname} as a family member</>
                        }
                    </InviteAsHelperButton>
                </>
            }

            <SimilarUsersDialog
                invitedRole={UserRole.User}
                users={similarUsers}
                isDialogOpen={isSimilarUsersDialogOpen}
                closeDialog={() => {
                    setIsSimilarUsersDialogOpen(false);
                    setIsInviting(false);
                }}
                onSelectUser={handleSelectSimilarUser}
                zIndex={zIndex + 1}
            />
        </>
    );
};

export default DCInvitationForm;
