import classNames from 'classnames';
import Grid from '@mui/material/Grid';
import Zoom from '@mui/material/Zoom';

import SmartphoneIcon from '@mui/icons-material/Smartphone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import DevicesIcon from '@mui/icons-material/Devices';

import GGroupButtons from '../common/GGroupButtons';
import { InviteMethod } from './InvitationForm';
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from '@mui/material';
import { GStyles } from '../../styles/GStyles';
import { InvitationFormStyles } from './InvitationFormStyles';

interface Props {
    sendInvitation: boolean;
    inviteMethod: InviteMethod;
    isDeceased: boolean;
    handleGGroupButtonsOnClick: (value: InviteMethod) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    gGroupButtonCont: {
        margin: '20px auto 4px',
        display: 'flex',
        justifyContent: 'center',
        '& $gGroupButton': {
            padding: 0,
            minWidth: 93,
            fontWeight: 200,
            height: 36,
            '& svg': {
                fontSize: 28,
            },
        },
    },
    gButtonlabel: {
        textTransform: 'none',
        fontSize: 12,
        display: 'grid',
        lineHeight: '13px',
        '& span': {
            textTransform: 'uppercase',
            fontSize: '16px !important',
            fontWeight: 200,
            lineHeight: '14px',
        }
    },
    gGroupButton: {},
    ...InvitationFormStyles(theme),
}), { name: 'InvitationGroupButtons' });

const InvitationGroupButtons = (props: Props) => {

    const { inviteMethod, sendInvitation, isDeceased, handleGGroupButtonsOnClick } = props;

    const classes = useStyles();

    const renderGroupButtonLabel = (_inviteMethod: InviteMethod) => {
        return (
            <span className={classes.gButtonlabel}>
                {sendInvitation && 'Invite via' || 'Include'}
                <span>{_inviteMethod}</span>
            </span>
        );
    };

    return (
        <Zoom in={!isDeceased} timeout={600}>
            <Grid
                container
                alignItems="flex-end"
                justifyContent="center"
                className={classNames(classes.formControlContainer, 'notranslate')}
            >
                <Grid className={classes.gGroupButtonCont}>
                    <GGroupButtons
                        buttons={[
                            {
                                label: renderGroupButtonLabel(InviteMethod.phone),
                                value: InviteMethod.phone,
                                icon: <SmartphoneIcon className={classNames(
                                    inviteMethod === 'phone' ? GStyles.colorWhite : GStyles.colorPrimary
                                )} />
                            },
                            {
                                label: renderGroupButtonLabel(InviteMethod.email),
                                value: InviteMethod.email,
                                icon: <AlternateEmailIcon className={classNames(
                                    inviteMethod === 'email' ? GStyles.colorWhite : GStyles.colorPrimary
                                )} />
                            },
                            {
                                label: renderGroupButtonLabel(InviteMethod.both),
                                value: InviteMethod.both,
                                icon: <DevicesIcon className={classNames(
                                    inviteMethod === 'both' ? GStyles.colorWhite : GStyles.colorPrimary
                                )} />
                            }
                        ]}
                        activeButton={inviteMethod}
                        onClick={(value) => {
                            if (value === 'phone' || value === 'email' || value === 'both') {
                                handleGGroupButtonsOnClick(value);
                            }
                        }}
                        labelClass={GStyles.displayFlex}
                        controlClasses={classes.gGroupButton}
                    />
                </Grid>
            </Grid>
        </Zoom >
    );
};

export default InvitationGroupButtons;