import { Grid, Typography } from "@mui/material";
import { CaseOptionsUpdateRequest, GatherCaseUpdateRequest, GatherCaseUX } from "../../shared/types";
import { styled } from '@mui/material/styles';
import { useState } from "react";
import GButton from "../common/GButton";
import ChangeDecedentNameForm from "./ChangeDecedentNameForm";
import { updateCase, updateCaseOptions } from "../../actions/GatherCase.action";
import { useGDispatch, useGSelector } from "../../types";
import { canUpdateCase } from "../../shared/authority/can";

const Root = styled(Grid)(({ theme }) => ({
    maxWidth: '248px',
    margin: '48px auto',
    textAlign: 'center',
    '@media (min-width: 480px)': {
        maxWidth: '380px',
    },
}));

const Heading = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: 20,
}));

const FormContainer = styled(Grid)(({ theme }) => ({
    marginTop: '18px',
}));

const SubmitButton = styled(GButton)(({ theme }) => ({
    margin: '20px auto',
}));

interface Props {
    selectedCase: GatherCaseUX;
};

const OneOffTransactionCaseDetails = (props: Props) => {

    const { selectedCase } = props;

    const dispatch = useGDispatch();

    const user = useGSelector(({ userSession }) => userSession.userData);
    const canUserUpdateCase = canUpdateCase(user, selectedCase);

    const [fname, setFname] = useState<string>(selectedCase.fname);
    const [mname, setMname] = useState<string | null>(selectedCase.mname);
    const [lname, setLname] = useState<string>(selectedCase.lname);
    const [suffix, setSuffix] = useState<string | null>(selectedCase.suffix);

    const [saveAttempted, setSaveAttempted] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const isFNameInvalid = !fname.trim();
    const isLNameInvalid = !lname.trim();

    const onSave = async () => {
        if (isFNameInvalid || isLNameInvalid) {
            setSaveAttempted(true);
            return;
        }

        setIsSaving(true);

        if (canUserUpdateCase) {
            const changes: GatherCaseUpdateRequest = {
                fname,
                mname,
                lname,
                suffix,
            };
            await dispatch(updateCase(selectedCase.uuid, changes));
        } else {
            const changes: CaseOptionsUpdateRequest = {
                fname,
                mname,
                lname,
                suffix,
            };
            await dispatch(updateCaseOptions(selectedCase.uuid, changes));
        }
        setSaveAttempted(true);
        setIsSaving(false);
    };

    return (
        <Root>
            <Heading>
                Edit Decedent Details
            </Heading>

            <FormContainer>
                <ChangeDecedentNameForm
                    fname={fname}
                    mname={mname}
                    lname={lname}
                    suffix={suffix}
                    saveAttempted={saveAttempted}
                    onChangeFname={setFname}
                    onChangeMname={setMname}
                    onChangeLname={setLname}
                    onChangeSuffix={setSuffix}
                    isFNameInvalid={isFNameInvalid}
                    isLNameInvalid={isLNameInvalid}
                />

                <SubmitButton
                    text="Save Details"
                    onClick={onSave}
                    isSpinning={isSaving}
                />

            </FormContainer>
        </Root>
    );
};

export default OneOffTransactionCaseDetails;
