import { stringify } from 'query-string';
import {
    AssignKeepTrackSearchParam,
    buildQueryParamForCaseDialog,
    buildQueryParamForGlobalDialog,
    buildQueryParamForRememberDialog,
    buildQueryParamForRememberStoreCTA,
    CaseDialog,
    encodeReportURLSearchParams,
    FlowerSalesProductCategory,
    GlobalDialog,
    isGlobalDialog,
    KeeptrackCardType,
    ProductCategoryEnum,
    RememberDialog,
    RememberStoreCTA,
    ReportState,
    TrackingTabsEnum,
} from './types';

export const isAbsoluteURL = (to: string | undefined): boolean => {
    return Boolean(to && /^https?:\/\//.test(to));
};

export enum RememberRoutePage {
    DEFAULT = '',
    THEMES = '/themes',
}

export type AnchorTag<T extends string = string> = `#${T}`;

type URLQueryPart = Record<string, string | number | undefined>;
export interface URLParts {
    prefix?: string;
    path: string;
    query?: URLQueryPart;
    subPath?: string;
    anchor?: AnchorTag;
}

export const buildURLString = (parts: URLParts): string => {
    const queryString = stringify(parts.query ?? {});
    return `${parts.prefix ?? ''}${parts.path}${parts.subPath ?? ''
        }${queryString ? '?' + queryString : ''
        }${parts.anchor ?? ''}`;
};

export const buildRelativePathURLString = (route: AppRoute): string => {

    const parts = buildRelativePathURLParts(route);
    return buildURLString(parts);
};

export const DESTINATION_QUERY_PARAM = 'dest';
export const ALLOW_FH_REDIRECT_QUERY_PARAM = 'r';

export enum AppRouteType {
    REMEMBER,
    REMEMBERSTORE,
    GUESTPAYMENT,
    FLOWERPRODUCT,
    FLOWERCATEGORY,
    TREEPROJECT,
    FAMILY,
    TASK,
    TRACKING,
    DOCPACKET,
    DISABLED,
    FUNERALHOME,
    WORKFLOW,
    REPORT,
    KEEPTRACK,
    KEEPTRACKECOMM,
    ROOT,
    LOGIN,
    ADMIN,
    SERVICETEMPLATES,
    SHOWROOM,
    PUBLICGPL,
    SUBSCRIPTION
}

interface BaseRoute {
    type: AppRouteType;
}

interface RootRoute extends BaseRoute {
    type: AppRouteType.ROOT;
}

interface LoginRoute extends BaseRoute {
    type: AppRouteType.LOGIN;
    page?: LoginRoutePage;
    destination?: AppRoute | string;
}

export enum LoginRoutePage {
    FORGOTPASSWORD = '/forgot-password',
    RESETPASSWORD = '/reset-password',
    CREATEPASSWORD = '/create-password',
    USER = '/user',
}

export enum AdminRoutePage {
    ROOT = '',
    ALLFUNERALHOMES = '/all-funeral-homes',
    ALLCASES = '/all-cases',
    LIVESTREAMS = '/live-stream/watch-list',
    LIVESTREAMSBILLING = '/live-stream/stream-billing',
    GOODSANDSERVICES = '/goods-and-services',
    MODERATION = '/moderation',
    SERVICEDETAILITEMS = '/service-detail-items',
    CASEKETBEARERITEMS = '/casket-bearer-items',
    MYTEAM = '/my-team',
    WEBSITES = '/websites',
    TREEPROJECTS = '/tree-projects',
    CURSEDREPORTS = '/cursed-reports',
    INSURANCE = '/insurance-carriers',
    GATHERANALYTICS = '/gather-analytics',
    THEMES = '/themes',
}

interface AdminRoute extends BaseRoute {
    type: AppRouteType.ADMIN;
    page: AdminRoutePage;
}

interface ServiceTemplatesRoute extends BaseRoute {
    type: AppRouteType.SERVICETEMPLATES;
    key?: string;
}

interface DisabledRoute extends BaseRoute {
    type: AppRouteType.DISABLED;
}

interface RememberRoute extends BaseRoute {
    caseName: string;
    type: AppRouteType.REMEMBER;
    page: RememberRoutePage;
    dialog?: RememberDialog | GlobalDialog;
    query?: URLQueryPart;
}

export enum RememberStoreType {
    flowers = 'flowers',
    trees = 'trees',
}

export enum GuestPaymentRouteType {
    GuestPaymeontribute = 'GuestPayment',
}

export interface RememberStoreRoute extends BaseRoute {
    caseName: string;
    type: AppRouteType.REMEMBERSTORE;
    storeType: RememberStoreType;
    cta: RememberStoreCTA | null;
    projectCode?: string;
}

interface GuestPaymentRoute extends BaseRoute {
    caseName: string;
    type: AppRouteType.GUESTPAYMENT;
    storeType: GuestPaymentRouteType;
}

interface FlowerProductRoute extends BaseRoute {
    caseName: string;
    type: AppRouteType.FLOWERPRODUCT;
    product: string;
    cta?: RememberStoreCTA;
}

interface TreeProjectRoute extends BaseRoute {
    caseName: string;
    type: AppRouteType.TREEPROJECT;
    projectCode: string;
    cta?: RememberStoreCTA;
}

interface FlowerCategoryRoute extends BaseRoute {
    caseName: string;
    type: AppRouteType.FLOWERCATEGORY;
    category: FlowerSalesProductCategory;
}

interface KeepTrackRoute extends BaseRoute {
    type: AppRouteType.KEEPTRACK;
    funeralHomeKey?: string;
    caseName?: string;
    trackerId?: string;
    keepsakeId?: string;
    cardType: KeeptrackCardType;
}

export enum KeepTrackECommPage {
    THANKS = '/thanks'
}

interface KeepTrackECommRoute extends BaseRoute {
    type: AppRouteType.KEEPTRACKECOMM;
    page?: KeepTrackECommPage;
}

interface ShowroomRoute extends BaseRoute {
    type: AppRouteType.SHOWROOM;
    caseName: string;
    funeralHomeKey: string;
    category?: ProductCategoryEnum;
}

interface PublicGPLRoute extends BaseRoute {
    type: AppRouteType.PUBLICGPL;
    funeralHomeKey: string;
    category?: ProductCategoryEnum;
}

export enum FamilyRoutePage {
    DEFAULT = '',
    QUESTIONS = '/questions',
    OBITUARY = '/obituary',
    CASKETBEARERS = '/casket-bearers',
    SERVICEDETAILS = '/service-details',
    CONTRACT = '/contract',
    PAYMENTS = '/contract/payment',
    DETAILS = '/contract/details',
}

interface FamilyRoute extends BaseRoute {
    caseName: string;
    funeralHomeKey: string;
    type: AppRouteType.FAMILY;
    page: FamilyRoutePage;
    dialog?: CaseDialog;
    query?: URLQueryPart;
}

export enum TaskRouteType {
    PHOTO_SLIDESHOW = 'photo-slideshow',
}

interface TaskRoute extends BaseRoute {
    caseName: string;
    funeralHomeKey: string;
    taskId: number;
    taskType: TaskRouteType;
    type: AppRouteType.TASK;
}

interface TrackingRoute extends BaseRoute {
    caseName: string;
    funeralHomeKey: string;
    tab?: TrackingTabsEnum;
    allowFHRedirect?: boolean;
    type: AppRouteType.TRACKING;
}

interface DocPacketRoute extends BaseRoute {
    type: AppRouteType.DOCPACKET;
    funeralHomeKey: string;
    caseName: string;
    docPacketId?: number;
}

interface SubscriptionRoute extends BaseRoute {
    type: AppRouteType.SUBSCRIPTION;
    funeralHomeKey: string;
}

export enum FuneralHomeRoutePage {
    ROOT = '',
    MYFUNERALHOME = '/my-funeral-home',
    MYCASES = '/my-cases',
    MYTEAM = '/my-team',
    WHITEBOARD = '/whiteboard',
    DOCLIBRARY = '/document-library',
    DEATHCERTIFICATE = '/death-certificate',
    GOODSANDSERVICES = '/goods-and-services',
    CREATECASE = '/create-case',
    SUBSCRIPTION = '/subscription',
}

export enum LegacyReportPage {
    CASESREPORT = 'cases-report',
    HELPERSREPORT = 'helpers-report',
    ROLODEXREPORT = 'rolodex-report',
    BATCHREPORT = 'batch-report',
    REVENUEREPORT = 'revenue-report',
    PAYMENTSREPORT = 'payments-report',
    CUSTOMREPORT = 'custom-reports',
    KEEPTRACKREPORT = 'keeptrack-report',
    STANDARDREPORTS = 'standard-reports',
    DISPATCHREPORT = 'dispatch-report',
    PREPREPORT = 'prep-report',
    INSURANCEPOLICIES = 'insurance-policies',
}

export const GPLLinkLookup: Record<ProductCategoryEnum, string> = {
    [ProductCategoryEnum.equipment_facilities_staff]: 'professional-services',
    [ProductCategoryEnum.transportation]: 'professional-services',
    [ProductCategoryEnum.care_of_loved_one]: 'professional-services',
    [ProductCategoryEnum.casket]: 'casket',
    [ProductCategoryEnum.urn]: 'urn',
    [ProductCategoryEnum.vault]: 'vault',
    [ProductCategoryEnum.cemetery]: 'cemetery',
    [ProductCategoryEnum.memorial]: 'memorial',
    [ProductCategoryEnum.flowers]: 'flowers',
    [ProductCategoryEnum.cash_advance]: 'cash-advances',
};

interface FuneralHomeRoute extends BaseRoute {
    type: AppRouteType.FUNERALHOME;
    funeralHomeKey: string;
    page: FuneralHomeRoutePage;
}

interface WorkflowRoute extends BaseRoute {
    type: AppRouteType.WORKFLOW;
    funeralHomeKey: string;
    workflowId?: number;
}

interface ReportRoute extends BaseRoute {
    type: AppRouteType.REPORT;
    funeralHomeKey: string | null;
    reportUuid: string | LegacyReportPage;
    reportState?: ReportState;
}

export type AppRoute = RememberRoute | RememberStoreRoute | FamilyRoute | TaskRoute | TrackingRoute | DocPacketRoute |
    DisabledRoute | FlowerProductRoute | FlowerCategoryRoute |
    FuneralHomeRoute | ShowroomRoute | PublicGPLRoute |
    KeepTrackRoute | RootRoute | LoginRoute | KeepTrackECommRoute | ReportRoute |
    WorkflowRoute | AdminRoute | ServiceTemplatesRoute | GuestPaymentRoute | TreeProjectRoute;

export class RouteBuilder {
    public static RememberPage = (
        caseName: string,
        page: RememberRoutePage = RememberRoutePage.DEFAULT,
        dialog?: RememberDialog | GlobalDialog,
        query?: URLQueryPart
    ): RememberRoute => {
        return {
            type: AppRouteType.REMEMBER,
            caseName,
            page,
            dialog,
            query
        };
    };

    public static RememberStore = (params: Omit<RememberStoreRoute, 'type'>): RememberStoreRoute => {
        return {
            type: AppRouteType.REMEMBERSTORE,
            ...params,
        };
    };

    public static GuestPayment = (params: Omit<GuestPaymentRoute, 'type'>): GuestPaymentRoute => {
        return {
            type: AppRouteType.GUESTPAYMENT,
            ...params,
        };
    };

    public static FlowerCategory = (caseName: string, category: FlowerSalesProductCategory): FlowerCategoryRoute => {
        return {
            type: AppRouteType.FLOWERCATEGORY,
            caseName,
            category,
        };
    };

    public static PhotoSlideShow = (
        params: Omit<TaskRoute, 'type' | 'taskType'>
    ): TaskRoute => {
        return {
            type: AppRouteType.TASK,
            taskType: TaskRouteType.PHOTO_SLIDESHOW,
            ...params,
        };
    };

    public static ProductPage = (caseName: string, product: string, cta?: RememberStoreCTA): FlowerProductRoute => {
        return {
            type: AppRouteType.FLOWERPRODUCT,
            caseName,
            product,
            cta,
        };
    };

    public static FamilyPage = (
        params: Omit<FamilyRoute, 'type' | 'page'> & Pick<Partial<FamilyRoute>, 'page'>,
    ): FamilyRoute => {
        return {
            type: AppRouteType.FAMILY,
            page: FamilyRoutePage.DEFAULT,
            ...params,
        };
    };

    public static PaymentPage = (
        params: Omit<FamilyRoute, 'type' | 'page'> & Pick<Partial<FamilyRoute>, 'page'>,
    ): FamilyRoute => {
        return {
            type: AppRouteType.FAMILY,
            page: FamilyRoutePage.PAYMENTS,
            ...params,
        };
    };

    public static TrackingPage = (params: Omit<TrackingRoute, 'type'>): TrackingRoute => {
        return {
            type: AppRouteType.TRACKING,
            ...params,
        };
    };

    public static TaskPage = (params: Omit<TaskRoute, 'type'>): TaskRoute => {
        return {
            type: AppRouteType.TASK,
            ...params,
        };
    };

    public static DocPacketPage = (params: Omit<DocPacketRoute, 'type'>): DocPacketRoute => {
        return {
            type: AppRouteType.DOCPACKET,
            ...params,
        };
    };

    public static FuneralHome = (
        funeralHomeKey: string,
        page?: FuneralHomeRoutePage | null,
    ): FuneralHomeRoute => {
        return {
            type: AppRouteType.FUNERALHOME,
            funeralHomeKey,
            page: page ?? FuneralHomeRoutePage.ROOT,
        };
    };

    public static WorkflowPage = (
        funeralHomeKey: string,
        workflowId?: number | null
    ): WorkflowRoute => {
        return {
            type: AppRouteType.WORKFLOW,
            funeralHomeKey,
            workflowId: workflowId || undefined,
        };
    };

    public static ReportPage = (params: {
        funeralHomeKey: string | null;
        reportUuid: string;
        reportState?: ReportState;
    }): ReportRoute => {
        return {
            type: AppRouteType.REPORT,
            ...params,
        };
    };

    public static LegacyReportPage = (params: {
        funeralHomeKey: string;
        legacyReport: LegacyReportPage;
    }): ReportRoute => {
        return {
            type: AppRouteType.REPORT,
            ...params,
            reportUuid: params.legacyReport,
        };
    };

    public static KeepTrack = (
        params: Omit<KeepTrackRoute, 'type'>
    ): KeepTrackRoute => {
        return {
            type: AppRouteType.KEEPTRACK,
            ...params,
        };
    };

    public static KeepTrackEComm = (
        params?: Omit<KeepTrackECommRoute, 'type'>,
    ): KeepTrackECommRoute => ({
        type: AppRouteType.KEEPTRACKECOMM,
        ...params,
    });

    public static Disabled: DisabledRoute = { type: AppRouteType.DISABLED };

    public static Root: RootRoute = { type: AppRouteType.ROOT };

    public static Login = (
        params?: Omit<LoginRoute, 'type'>
    ): LoginRoute => {
        return {
            type: AppRouteType.LOGIN,
            ...params,
        };
    };

    public static Admin = (page: AdminRoutePage): AdminRoute => {
        return {
            type: AppRouteType.ADMIN,
            page,
        };
    };

    public static ServiceTemplates = (key?: string): ServiceTemplatesRoute => {
        return {
            type: AppRouteType.SERVICETEMPLATES,
            key,
        };
    };

    public static Showroom = (params: Omit<ShowroomRoute, 'type'>): ShowroomRoute => {
        return {
            type: AppRouteType.SHOWROOM,
            ...params
        };
    };

    public static PublicGPL = (params: Omit<PublicGPLRoute, 'type'>): PublicGPLRoute => {
        return {
            type: AppRouteType.PUBLICGPL,
            ...params
        };
    };

    public static Subscription = (funeralHomeKey: string): SubscriptionRoute => {
        return {
            type: AppRouteType.SUBSCRIPTION,
            funeralHomeKey
        };
    };
}

/* Helper Functions to avoid boilerplate link creation */
export const buildRelativePathURLParts = (to: AppRoute): Pick<URLParts, 'path' | 'anchor' | 'query'> => {
    const { type } = to;

    // eslint-disable-next-line default-case
    switch (type) {
        case AppRouteType.REMEMBER: {
            const { caseName, page, dialog, query } = to;
            const dialogAsQueryPart = dialog
                ? isGlobalDialog(dialog)
                    ? buildQueryParamForGlobalDialog(dialog)
                    : buildQueryParamForRememberDialog(dialog)
                : undefined;
            return {
                path: `/remember/${caseName}${page}`,
                query: {
                    ...query,
                    ...dialogAsQueryPart
                },
            };
        }
        case AppRouteType.REMEMBERSTORE: {
            const { caseName, cta, storeType, projectCode } = to;
            let path: string;
            // eslint-disable-next-line default-case
            switch (storeType) {
                case RememberStoreType.flowers:
                    path = `/remember/${caseName}/store`;
                    break;
                case RememberStoreType.trees:
                    if (projectCode) {
                        path = `/remember/${caseName}/trees/${projectCode}`;
                    } else {
                        path = `/remember/${caseName}/trees`;
                    }
                    break;
            }
            const query = cta ? buildQueryParamForRememberStoreCTA(cta) : undefined;
            return {
                path,
                query,
            };
        }
        case AppRouteType.GUESTPAYMENT: {
            const { caseName } = to;
            return {
                path: `/remember/${caseName}/guestpayment`
            };
        }
        case AppRouteType.FLOWERPRODUCT: {
            const { caseName, product } = to;
            return {
                path: `/remember/${caseName}/flowers/order/${product}`
            };
        }
        case AppRouteType.FLOWERCATEGORY: {
            const { caseName, category } = to;
            if (category === FlowerSalesProductCategory.trees) {
                return {
                    path: `/remember/${caseName}/trees`,
                    query: buildQueryParamForRememberStoreCTA(RememberStoreCTA.FlowerStore),
                };
            }
            return {
                path: `/remember/${caseName}/flowers/${category}`,
            };
        }
        case AppRouteType.TREEPROJECT: {
            const { caseName, projectCode, cta } = to;
            const query = cta ? buildQueryParamForRememberStoreCTA(cta) : undefined;
            return {
                path: `/remember/${caseName}/trees/${projectCode}`,
                query,
            };
        }
        case AppRouteType.FAMILY: {
            const { caseName, funeralHomeKey, page, dialog, query } = to;
            const dialogAsQueryPart = dialog ? buildQueryParamForCaseDialog(dialog) : {};

            return {
                path: `/fh/${funeralHomeKey}/family/${caseName}${page}`,
                query: {
                    ...query,
                    ...dialogAsQueryPart,
                },
            };
        }
        case AppRouteType.TASK: {
            const { taskId, taskType, caseName, funeralHomeKey } = to;
            return { path: `/fh/${funeralHomeKey}/family/${caseName}/task/${taskId}/${taskType}` };
        }
        case AppRouteType.TRACKING: {
            const { caseName, funeralHomeKey, tab, allowFHRedirect } = to;
            return {
                path: `/fh/${funeralHomeKey}/family/${caseName}/tracking`,
                anchor: tab ? `#${tab}` : undefined,
                query: !allowFHRedirect ? undefined : {
                    [ALLOW_FH_REDIRECT_QUERY_PARAM]: 'true',
                },
            };
        }
        case AppRouteType.DOCPACKET: {
            const { docPacketId, caseName, funeralHomeKey } = to;
            let subPath = '';
            if (docPacketId !== undefined) {
                subPath = `/${docPacketId}`;
            }
            return {
                path: `/fh/${funeralHomeKey}/family/${caseName}/docs${subPath}`,
            };
        }
        case AppRouteType.SHOWROOM: {
            const { caseName, category, funeralHomeKey } = to;

            const link: string = (category && GPLLinkLookup[category]) ?? GPLLinkLookup.care_of_loved_one;
            return {
                path: `/fh/${funeralHomeKey}/family/${caseName}/contract/${link}`
            };
        }
        case AppRouteType.FUNERALHOME: {
            const { funeralHomeKey, page } = to;
            return { path: `/fh/${funeralHomeKey}${page}` };
        }
        case AppRouteType.WORKFLOW: {
            const { funeralHomeKey, workflowId } = to;
            return { path: `/fh/${funeralHomeKey}/workflows/${workflowId || ''}` };
        }
        case AppRouteType.REPORT: {
            const { funeralHomeKey, reportUuid, reportState } = to;

            const query = reportState ? encodeReportURLSearchParams(reportState) : undefined;
            if (funeralHomeKey) {
                return { path: `/fh/${funeralHomeKey}/report/${reportUuid}`, query };
            } else {
                return { path: `${AdminRoutePage.GATHERANALYTICS}/${reportUuid}`, query };
            }
        }
        case AppRouteType.KEEPTRACK: {
            const { cardType, funeralHomeKey, caseName, trackerId, keepsakeId } = to;

            const query = {
                [AssignKeepTrackSearchParam.funeralHomeKey]: funeralHomeKey,
                [AssignKeepTrackSearchParam.caseName]: caseName,
                [AssignKeepTrackSearchParam.trackerId]: trackerId,
                [AssignKeepTrackSearchParam.keepsakeId]: keepsakeId,
            };
            if (cardType === KeeptrackCardType.additional) {
                return { path: `/keeptrack/additional`, query };
            } else {
                return { path: `/keeptrack`, query };
            }
        }
        case AppRouteType.KEEPTRACKECOMM: {
            const { page } = to;
            return { path: `/buy/keeptrack${page ?? ''}` };
        };

        case AppRouteType.ADMIN: {
            const { page } = to;

            return { path: `/admin${page}` };
        }

        case AppRouteType.SERVICETEMPLATES: {
            const { key } = to;
            return {
                path: `/admin/service-templates/${key ?? ''}`,
            };
        }
        case AppRouteType.LOGIN: {
            const { destination, page } = to;
            let query: URLQueryPart | undefined = undefined;
            if (destination) {
                let destinationStr: string;
                if (typeof destination === 'string') {
                    destinationStr = destination;
                } else {
                    destinationStr = buildRelativePathURLString(destination);
                }
                query = {
                    [DESTINATION_QUERY_PARAM]: destinationStr,
                };
            }

            return { path: `/login${page ?? ''}`, query };
        }
        case AppRouteType.PUBLICGPL: {
            const { funeralHomeKey, category } = to;

            const link: string = (category && GPLLinkLookup[category]) ?? GPLLinkLookup.care_of_loved_one;
            return {
                path: `/gpl/${funeralHomeKey}/${link}`
            };
        }
        case AppRouteType.ROOT:
        case AppRouteType.DISABLED: {
            return { path: `/` };
        }
    }
};
