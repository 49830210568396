import Grid from "@mui/material/Grid";
import GTextField from "../common/GTextField";

interface Props {
    saveAttempted: boolean;
    fname: string;
    onChangeFname: (fname: string) => void;
    mname: string | null;
    onChangeMname: (mname: string | null) => void;
    lname: string;
    onChangeLname: (lname: string) => void;
    suffix: string | null;
    onChangeSuffix: (suffix: string | null) => void;
    isFNameInvalid: boolean;
    isLNameInvalid: boolean;
};

const ChangeDecedentNameForm = (props: Props) => {
    const {
        saveAttempted,
        fname,
        lname,
        mname,
        suffix,
        onChangeFname,
        onChangeLname,
        onChangeMname,
        onChangeSuffix,
        isFNameInvalid,
        isLNameInvalid,
    } = props;

    return (
        <Grid item xs={12} md={6}>
            <Grid item xs={12} maxWidth={{ xs: '400px', md: 'unset' }} m="auto">
                <GTextField
                    value={fname}
                    label="Legal First Name"
                    required
                    error={isFNameInvalid && saveAttempted}
                    onValueChange={onChangeFname}
                />

                <GTextField
                    value={mname ?? ''}
                    label="Legal Middle Name"
                    onValueChange={onChangeMname}
                />

                <GTextField
                    value={lname}
                    label="Legal Last Name"
                    required
                    error={isLNameInvalid && saveAttempted}
                    onValueChange={onChangeLname}
                />

                <GTextField
                    value={suffix ?? ''}
                    label="Suffix"
                    onValueChange={onChangeSuffix}
                />
            </Grid>
        </Grid>
    );
};

export default ChangeDecedentNameForm;
