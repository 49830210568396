import * as t from 'io-ts';
import { CaseType, CaseTypeDefinition, getValidator, TaskTemplateSummary } from '.';
import { UserProfileBasics } from './user';

export interface WorkflowRecord {
    id: number;
    name: string;
    case_type: CaseType;
    created_by: number;
    created_time: Date;
    updated_by: number;
    updated_time: Date;
    cloned_from: number | null;
    imported_case: boolean;
}

export interface WorkflowTaskSummary {
    task_id: number;
    workflow_task_id: number;
    title: string;
    is_after_care: boolean;
    prereq_workflow_task_ids: number[];
    visible_to_family: boolean;
}

export interface WorkflowSummaryRecord extends Pick<WorkflowRecord,
    | 'id'
    | 'name'
    | 'case_type'
    | 'created_by'
    | 'created_time'
    | 'updated_by'
    | 'updated_time'
    | 'cloned_from'
    | 'imported_case'
> {
    fhs_mirrored_with: number[];
    rank: number;
    selected_checklist_tasks: WorkflowTaskSummary[];
    selected_tracking_steps: WorkflowTaskSummary[];
}

export interface WorkflowSummary extends WorkflowSummaryRecord {
}

export interface WorkflowTaskForWorkflowUX {
    workflow_task_id: number;
    task: TaskTemplateSummary;
    prereq_workflow_task_ids: number[];
}

export interface WorkflowUXRecord extends
    Omit<WorkflowSummary, 'selected_checklist_tasks' | 'selected_tracking_steps'> {
    selected_checklist_tasks: WorkflowTaskForWorkflowUX[];
    selected_tracking_steps: WorkflowTaskForWorkflowUX[];
}

export interface WorkflowUX extends WorkflowUXRecord {
    available_checklist_tasks: TaskTemplateSummary[];
    available_tracking_steps: TaskTemplateSummary[];
}

// ---> WorkflowCreateRequest <---
const workflowCreateRequestDefinition = {
    name: t.string,
    case_type: CaseTypeDefinition,
    rank: t.number,
};

const WorkflowCreateRequestType = t.type(workflowCreateRequestDefinition);

export interface WorkflowCreateRequest extends t.TypeOf<typeof WorkflowCreateRequestType> {
    case_type: CaseType;
}

export class WorkflowCreateRequest {
    public static fromRequest = getValidator<WorkflowCreateRequest>(WorkflowCreateRequestType);
}

// ---> WorkflowUpdateRequest <---
const workflowUpdateRequestDefinition = {
    name: t.string,
    case_type: CaseTypeDefinition,
    fhs_mirrored_with: t.array(t.number),
};

const WorkflowUpdateRequestType = t.partial(workflowUpdateRequestDefinition);

export interface WorkflowUpdateRequest extends t.TypeOf<typeof WorkflowUpdateRequestType> {
    case_type?: CaseType;
}

export class WorkflowUpdateRequest {
    public static fromRequest = getValidator<WorkflowUpdateRequest>(WorkflowUpdateRequestType);
}


export interface FuneralHomeWorkflowRecord {
    funeral_home_id: number;
    workflow_id: number;
    rank: number;
    created_time: Date;
    created_by: number;
    deleted_time: Date | null;
    deleted_by: number | null;
}

export interface WorkflowTaskRecord {
    id: number;
    task_id: number;
    workflow_id: number;
    rank: number;
    created_time: Date;
    created_by: number;
    deleted_time: Date | null;
    deleted_by: number | null;
}

export interface WorkflowTaskPrerequisiteRecord {
    workflow_task_id: number;
    prereq_workflow_task_id: number;
    created_time: Date;
    created_by: number;
    deleted_time: Date | null;
    deleted_by: number | null;
}

// ---> WorkflowTaskUpdateRequest <---
const WorkflowTaskUpdateRequestType = t.partial({
    prereq_workflow_task_ids: t.array(t.number),
});

export interface WorkflowTaskUpdateRequest extends t.TypeOf<typeof WorkflowTaskUpdateRequestType> {
}

export class WorkflowTaskUpdateRequest {
    public static fromRequest = getValidator<WorkflowTaskUpdateRequest>(WorkflowTaskUpdateRequestType);
}

// ---> WorkflowTaskUpdateRankRequest <---
const WorkflowTaskUpdateRankRequestType = t.type({
    workflow_task_ids: t.array(t.number),
});

export interface WorkflowTaskUpdateRankRequest extends t.TypeOf<typeof WorkflowTaskUpdateRankRequestType> {
}

export class WorkflowTaskUpdateRankRequest {
    public static fromRequest = getValidator<WorkflowTaskUpdateRankRequest>(WorkflowTaskUpdateRankRequestType);
}

export interface CaseTaskPrerequisiteRecord {
    case_task_id: number;
    prereq_case_task_id: number;
    created_time: Date;
    created_by: number;
    deleted_time: Date | null;
    deleted_by: number | null;
}

export enum WorkflowLogType {
    task = 'task',
    task_component = 'task_component',
    workflow = 'workflow',
}

export interface WorkflowLogRecord {
    id: number;
    workflow_id: number;
    action_type: WorkflowLogType;
    changes: string;
    note: string | null;
    action_time: Date;
    action_by: number;
}

export const taskTemplateToWorkflowTaskForWorkflowUX = (tt: TaskTemplateSummary): WorkflowTaskForWorkflowUX => ({
    workflow_task_id: -tt.id,
    task: tt,
    prereq_workflow_task_ids: [],
});

export interface WorkflowChangeSummary {
    caseLogId: number;
    changedTime: Date;
    changedBy: UserProfileBasics | null;
    oldWorkflow: {
        id: number;
        name: string;
    } | null;
    newWorkflow: {
        id: number;
        name: string;
    } | null;
}
