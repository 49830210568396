import {
    EventType,
    GatherCaseUX,
    GatherEvent,
    LocationUX,
    UserProfile,
    UserRoles
} from "../../../shared/types";
import momentTz from "moment-timezone";
import { sortByProperty } from "../../../services";
import { useGSelector } from "../../../types";
import { Direction } from "react-beautiful-dnd";
import { useMemo, useState } from "react";
import useEventDetailPopperAndDialog from "./widgets/useEventDetailPopperAndDialog";
import GroupedEventDetails from "./GroupedEventDetails";

export const isGroupedEvent = (event: GroupedGatherEvent | GatherEvent): event is GroupedGatherEvent => {
    return 'events' in event && event.events !== undefined;
};
export interface GroupedGatherEvent {
    isExpired: boolean;
    key: number;
    date: string;
    dayOfWeek: string;
    events: GatherEvent[];
}

export const getGroupedEvents = (params: {
    events: GatherEvent[];
    gatherCaseId: number | undefined;
    userData: UserProfile | null;
    locations: LocationUX[];
}): GroupedGatherEvent[] => {
    let { events, gatherCaseId, userData, locations } = params;

    const property = 'start_time';

    const isFamilyUser = !!gatherCaseId && UserRoles.isFamilyOnCase(userData, gatherCaseId);

    // Filter events => visible_to_family if logged in user has FAMILY role before grouping events
    if (isFamilyUser) {
        events = events.filter((gatherEvent) => gatherEvent.visible_to_family);
    }

    // events dates and time are sorted in ascending order
    events.sort(sortByProperty(property, false, 'asc'));

    const reducedEvents = events.reduce(
        (obj, event) => {
            const key = momentTz(event[property]).toDate().toDateString();

            obj[key] = obj[key] || [];
            obj[key].push(event);
            return obj;
        },
        {}
    );

    let groupedEvents: GroupedGatherEvent[] = Object.keys(reducedEvents).map((key) => {
        // get the first event from the grouped List ---- we can't use the key here as it doesn't includes time
        const gatherEvent: GatherEvent = reducedEvents[key][0];

        const eventLocation = locations.find((loc) =>
            loc.id === gatherEvent.location_id
        );

        const eventTimezoneName = eventLocation && eventLocation.address.timezone || momentTz.tz.guess();

        const startMoment = momentTz(gatherEvent.start_time).tz(eventTimezoneName);

        return {
            key: startMoment.unix(),
            isExpired: startMoment.toDate().setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0),
            date: startMoment.format('DD'),
            dayOfWeek: startMoment.format('dddd'),
            events: reducedEvents[key]
        };
    });

    // events dates are sorted in descending order and the grouped events time will still be in ascending order
    return groupedEvents.sort((a: GroupedGatherEvent, b: GroupedGatherEvent) => {
        return momentTz(b.key).unix() < momentTz(a.key).unix() ? 1 : -1;
    });
};

interface Props {
    zIndex: number;
    direction: Direction;
    activeCase: GatherCaseUX | null;
    events: GatherEvent[];
    openArrangementConferenceDialog: (event: GatherEvent | null) => void;
    openScheduleServicesDialog: (event: GatherEvent | null) => void;
}

const EventList = (props: Props) => {
    const {
        events,
        activeCase,
        zIndex,
        direction,
        openArrangementConferenceDialog,
        openScheduleServicesDialog,
    } = props;

    const userData = useGSelector((state) => state.userSession.userData);
    const locations = useGSelector(({ locationState }) => locationState.locations);

    const [activeEvent, setActiveEvent] = useState<GatherEvent | null>(null);

    const editEvent = () => {
        if (!activeEvent) {
            return;
        }

        if (activeEvent.event_type === EventType.arrangement) {
            openArrangementConferenceDialog(activeEvent);
        } else {
            openScheduleServicesDialog(activeEvent);
        }
    };

    const {
        eventNoteDialogElement,
        eventDetailsPopperElement,
        openEventDetailsPopper
    } = useEventDetailPopperAndDialog(activeEvent?.id, zIndex, editEvent);

    const sortedEvents = useMemo(() => getGroupedEvents({
        events,
        gatherCaseId: activeCase?.id,
        userData,
        locations
    }) || [], [activeCase?.id, events, userData, locations]);

    return (
        <>
            {sortedEvents.map(groupedEvent =>
                <GroupedEventDetails
                    key={groupedEvent.key}
                    groupedEvent={groupedEvent}
                    isDirectionVertical={direction === 'vertical'}
                    onEventClick={(event, gatherEvent) => (openEventDetailsPopper(event), setActiveEvent(gatherEvent))}
                />
            )}

            {eventNoteDialogElement}

            {eventDetailsPopperElement}
        </>
    );
};

export default EventList;