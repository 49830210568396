import classNames from 'classnames';
import { values } from 'lodash';

import Grid from '@mui/material/Grid';

import {
    GatherCaseUX,
    DeathCertificateMarriageType,
    getCaseEntity,
    EntitySummary,
    getCaseEntityById,
    MaritalStatusEnum,
    GmapsSearchType,
    isShortAddress,
    NullShortAddress,
} from '../../../../shared/types';
import { FormParent, ParentProps } from './FormParent';
import { marriageValidators, marriageMasterValidators } from '../../../../shared/death_certificate/validators/marriage';
import { DCEntityEnum } from '../../../assignmentPoppers/SelectHelper';
import { StoreState } from '../../../../types';
import FormTitle from '../common/FormTitle';
import ConfigurableAddressSearchWithCheckbox
    from '../common/fields/addressSearchWithCheckbox/ConfigurableAddressSearchWithCheckbox';
import ConfigurableTextFieldWithCheckbox
    from '../common/fields/textFieldWithCheckbox/ConfigurableTextFieldWithCheckbox';
import { ConfigurableMarriageFieldKey } from '../../../../shared/death_certificate/validators/config';
import ConfigurableSelectWrapper from '../common/fields/select/ConfigurableSelect';
import ConfigurableDateFieldWithCheckbox
    from '../common/fields/dateFieldWithCheckbox/ConfigurableDateFieldWithCheckbox';
import ConfigurableDateField from '../common/fields/dateField/ConfigurableDateField';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles, withStyles } from '@mui/styles';
import withState from '../../../common/utilHOC/WithState';
import { GStyles } from '../../../../styles/GStyles';
import { GmapsSearchAddress } from '../../../gmapsSearch/GmapsSearch';
import DCInvitationForm from './DCInvitaionForm';

const ConfigurableSelect = ConfigurableSelectWrapper<MaritalStatusEnum>();

function mapStateToProps({ casesState, userSession }: StoreState) {
    return {
        userData: userSession.userData,
        helpers: casesState.helpers,
    };
}

interface Props extends ReturnType<typeof mapStateToProps> {
    activeCase: GatherCaseUX;
    isEditMode: boolean;
    zIndex: number;
    onSpouseChange: (caseEntityId: number | null) => void;
    handleOpenSnackbar: () => void;
}

interface State {
    localData: DeathCertificateMarriageType;
    localSpouse: number | null;
    maritalStatusValidator: boolean | null;
    marriageDateValidator: boolean | null;
    marriagePlaceValidator: boolean | null;
    maidenNameValidator: boolean | null;
    spousesDateOfDeathValidator: boolean | null;
    baseAssignAnchorEl: HTMLElement | null;
}

const styles: StyleRulesCallback<Theme, Props & ParentProps<DeathCertificateMarriageType>> = (theme) => ({
    root: {
        justifyContent: 'center',
        flexGrow: 1,
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
    },
    inviteHelperForm: {
        '@media (max-width: 599px)': {
            padding: '0 24px',
        },
        margin: 'auto',
        '@media (min-width: 420px)': {
            maxWidth: 360,
        }
    },
    dcEntityContainer: {
        margin: '20px 0 12px',
        textAlign: 'center',
    },
    inputDateWidth: {
        width: 240,
        '@media (min-width: 421px)': {
            width: 320,
        },
    },
});

type StyledProps = Props & WithStyles<'root' | 'inviteHelperForm' | 'dcEntityContainer' | 'inputDateWidth'>;

class MarriageForm extends FormParent<DeathCertificateMarriageType, StyledProps, State> {

    state = {
        localData: this.props.data,
        localSpouse: this.props.activeCase.dc_spouse,
        maritalStatusValidator: null,
        marriageDateValidator: null,
        marriagePlaceValidator: null,
        maidenNameValidator: null,
        baseAssignAnchorEl: null,
        spousesDateOfDeathValidator: null
    };

    handleMarriageDateChange = (value: string) => {

        this.updateData({ marriageDate: value }, this.validateMarriageDate);
    };

    handleMaidenNameChange = (spouseMaidenName: string) => {

        this.updateData({ spouseMaidenName }, this.validateSpouseMaidenName);
    };

    handleMaritalStatusChange = (maritalStatus: MaritalStatusEnum) => {
        const changes: Partial<DeathCertificateMarriageType> = {
            maritalStatus,
        };

        if (maritalStatus === undefined
            || maritalStatus === 'Never Married'
            || maritalStatus === 'Divorced'
            || maritalStatus === 'Unknown'
        ) {
            changes.spouseMaidenName = '';
            changes.marriageDate = '';
            changes.marriageDate_unknown = false;
            changes.marriagePlace = {
                ...NullShortAddress,
            };
            this.handleSpouseChange(null);
        }

        this.updateData(changes, this.validateMaritalStatus);
    };

    handleMarriageCityUnknown = () => {

        this.updateData(
            (prevData) => ({
                marriagePlace: {
                    ...NullShortAddress,
                    unknown: !prevData.marriagePlace.unknown,
                },
            }),
            this.validateMarriagePlace,
        );
    };

    handleMarriageDateUnknown = (isChecked: boolean) => {

        const changes: Partial<DeathCertificateMarriageType> = {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            marriageDate_unknown: isChecked,
        };

        if (isChecked) {
            changes.marriageDate = '';
        }

        this.updateData(changes, this.validateMarriageDate);
    };

    handleMaidenNameNA = (isChecked: boolean) => {
        this.updateData({ spouseMaidenName: isChecked ? 'N/A' : '' }, this.validateSpouseMaidenName);
    };

    handleSpousesDateOfPassingChange = (value: string) => {
        this.updateData({ spousesDateOfPassing: value }, this.validateSpousesDateOfPassing);
    };

    // VALIDATION

    validateMaritalStatus = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            maritalStatusValidator: marriageValidators.maritalStatus(localData, dcConfig, user),
        });
    };

    validateSpouseMaidenName = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            maidenNameValidator: marriageValidators.spouseMaidenName(localData, dcConfig, user),
        });
    };

    validateMarriageDate = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            marriageDateValidator: marriageValidators.marriageDate(localData, dcConfig, user),
        });
    };

    validateMarriagePlace = () => {
        const { user, dcConfig } = this.props;
        const { localData } = this.state;

        this.setState({
            marriagePlaceValidator: marriageValidators.marriagePlace(localData, dcConfig, user),
        });
    };

    validateSpousesDateOfPassing = () => {
        const { user, dcConfig } = this.props;
        const { localData } = this.state;

        this.setState({
            spousesDateOfDeathValidator: marriageValidators.spousesDateOfDeath(localData, dcConfig, user),
        });
    };

    handleMarriagePlace = (marriagePlace: GmapsSearchAddress) => {
        if (isShortAddress(marriagePlace)) {
            this.updateData({ marriagePlace }, this.validateMarriagePlace);
        }
    };

    checkValid() {
        const { activeCase, helpers, user, dcConfig } = this.props;
        const { localData } = this.state;

        return marriageMasterValidators.valid(localData, dcConfig, activeCase, user, helpers);
    }

    checkIfTouched() {
        const { activeCase, user, dcConfig } = this.props;
        const { localData } = this.state;

        return marriageMasterValidators.touched(localData, dcConfig, activeCase, user);
    }

    validateAll() {
        this.validateMaritalStatus();
        this.validateSpouseMaidenName();
        this.validateMarriageDate();
        this.validateMarriagePlace();
    }

    getContentIfMarried = () => {
        const {
            classes,
            isDeathCertificateLocked,
            zIndex,
            activeCase,
            helpers,
            handleOpenSnackbar,
            isEditMode,
        } = this.props;
        const {
            localData,
            localSpouse,
        } = this.state;

        const { marriagePlace } = localData;

        const {
            marriageDateValidator,
            marriagePlaceValidator,
            maidenNameValidator,
            spousesDateOfDeathValidator
        } = this.state;

        const selectedSpouse = localSpouse
            ? helpers.find((helper) => Boolean(getCaseEntityById(helper, localSpouse)))
            : null;

        const isMaidenNameNA = localData.spouseMaidenName === 'N/A';

        return (
            <>
                <div className={classes.dcEntityContainer}>
                    <DCInvitationForm
                        DCEntity='Spouse'
                        activeCase={activeCase}
                        isDeathCertificateLocked={isDeathCertificateLocked || isEditMode}
                        onEntityChange={this.handleSpouseChange}
                        relationToDeceased={DCEntityEnum.Spouse}
                        selectedCaseEntityId={localSpouse}
                        selectedEntityId={activeCase.dc_spouse_entity_id}
                        updateState={(helper) => {
                            this.handleSpouseChange(helper);
                        }}
                        zIndex={zIndex + 1}
                    />
                </div>

                {(localData.maritalStatus === MaritalStatusEnum['Married, died at the same time'] ||
                    localData.maritalStatus === MaritalStatusEnum.Widowed) &&
                    <ConfigurableDateField
                        id={ConfigurableMarriageFieldKey.spousesDateOfDeath}
                        error={spousesDateOfDeathValidator === false}
                        label={`${selectedSpouse && selectedSpouse.fname || 'Spouse'}'s Date of Passing`}
                        value={localData.spousesDateOfPassing || ''}
                        onChange={this.handleSpousesDateOfPassingChange}
                        onBlur={this.validateSpousesDateOfPassing}
                        disabled={isDeathCertificateLocked}
                        handleOpenSnackbar={handleOpenSnackbar}
                        isEditMode={isEditMode}
                    />
                }

                <ConfigurableTextFieldWithCheckbox
                    id={ConfigurableMarriageFieldKey.spouseMaidenName}
                    error={maidenNameValidator === false}
                    label={`${selectedSpouse && selectedSpouse.fname || 'Spouse'}'s Maiden Name`}
                    value={isMaidenNameNA ? '' : localData.spouseMaidenName || ''}
                    onChange={this.handleMaidenNameChange}
                    onBlur={this.validateSpouseMaidenName}
                    disabled={isDeathCertificateLocked}
                    handleOpenSnackbar={handleOpenSnackbar}
                    isEditMode={isEditMode}
                    checkboxLabel="Maiden name not applicable"
                    isChecked={isMaidenNameNA}
                    onCheckboxChange={this.handleMaidenNameNA}
                />

                <Grid
                    container
                    alignItems="flex-end"
                    justifyContent="center"
                >
                    <ConfigurableAddressSearchWithCheckbox
                        id={ConfigurableMarriageFieldKey.marriagePlace}
                        label="In which city was the marriage?"
                        searchType={GmapsSearchType.shortAddress}
                        value={marriagePlace}
                        isChecked={!!marriagePlace.unknown}
                        onChange={this.handleMarriagePlace}
                        error={marriagePlaceValidator === false}
                        disabled={isDeathCertificateLocked}
                        zIndex={zIndex + 1}
                        isEditMode={isEditMode}
                        checkboxLabel="City of marriage unknown"
                        handleOpenSnackbar={handleOpenSnackbar}
                        onCheckboxChange={this.handleMarriageCityUnknown}
                    />
                </Grid>
                <Grid
                    container
                    alignItems="flex-end"
                    justifyContent="center"
                >
                    <Grid item className={classes.inputDateWidth}>
                        <ConfigurableDateFieldWithCheckbox
                            id={ConfigurableMarriageFieldKey.dateOfMarriage}
                            label="Date of Marriage"
                            onChange={this.handleMarriageDateChange}
                            value={localData.marriageDate || ''}
                            error={marriageDateValidator === false}
                            onBlur={this.validateMarriageDate}
                            handleOpenSnackbar={handleOpenSnackbar}
                            disabled={isDeathCertificateLocked}
                            isEditMode={isEditMode}
                            checkboxLabel="Marriage date unknown"
                            onCheckboxChange={this.handleMarriageDateUnknown}
                            isChecked={localData.marriageDate_unknown ?? false}
                            rootClass={GStyles.textLeft}
                        />
                    </Grid>
                </Grid>
            </>
        );
    };

    render() {
        const {
            classes,
            activeCase,
            isDeathCertificateLocked,
            handleOpenSnackbar,
            zIndex,
            isEditMode
        } = this.props;
        const {
            localData,
        } = this.state;

        const {
            maritalStatusValidator,
        } = this.state;

        return (
            <div ref={this.props.formRef}>
                <Grid
                    item
                    xs={12}
                >
                    <FormTitle title="Marriage" complete={this.checkIfTouched() ? this.checkValid() : null} />
                    <form
                        className={classNames(GStyles.overflowHidden, classes.inviteHelperForm)}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container alignItems="flex-end" justifyContent="center" >
                            <Grid item className={GStyles.width100}>
                                <ConfigurableSelect
                                    id={ConfigurableMarriageFieldKey.maritalStatus}
                                    error={maritalStatusValidator === false}
                                    inputLabelClass={'width-100'}
                                    label={`${activeCase.fname}'s Marital Status`}
                                    selectedOption={localData.maritalStatus || null}
                                    onSelect={this.handleMaritalStatusChange}
                                    onClick={handleOpenSnackbar}
                                    zIndex={zIndex}
                                    validator={this.validateMaritalStatus}
                                    options={values(MaritalStatusEnum)}
                                    isEditMode={isEditMode}
                                    disabled={isDeathCertificateLocked}
                                />
                            </Grid>
                        </Grid>

                        {(localData.maritalStatus !== undefined
                            && localData.maritalStatus !== MaritalStatusEnum['Never Married']
                            && localData.maritalStatus !== MaritalStatusEnum.Divorced
                            && localData.maritalStatus !== MaritalStatusEnum.Unknown
                            && localData.maritalStatus !== MaritalStatusEnum['Not Obtainable'])
                            ? this.getContentIfMarried() : null}
                    </form>
                </Grid>
            </div >
        );
    }

    handleSpouseChange = (helper: EntitySummary | null) => {
        const { onSpouseChange, activeCase } = this.props;

        const caseEntity = getCaseEntity(helper, activeCase.id);
        const spouse = caseEntity?.case_entity_id || null;
        this.setState({
            localSpouse: spouse,
        });
        onSpouseChange(spouse);
    };
}

export default withState(mapStateToProps)(withStyles(styles)(MarriageForm));
