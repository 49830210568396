import { useState } from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import BaseAssign, { BaseAssignEntity, BaseAssignProps } from './baseAssign/BaseAssign';
import { AvatarUser } from '../../shared/types';
import TaskAssignAvatar from './TaskAssignAvatar';
import GTooltip from '../common/GTooltip';
import makeStyles from '@mui/styles/makeStyles';

export interface ButtonText {
    label: string | JSX.Element;
    assignedTo: AvatarUser[];
    hideAvatar?: boolean;
}

const useStyles = makeStyles(
    {
        rootButton: {
            padding: '8px 4px 8px',
            textTransform: 'none',
        },
    },
    { name: 'AssignmentPopper' },
);

export interface AssignmentPopperProps<T extends BaseAssignEntity>
    extends Partial<Omit<BaseAssignProps<T>, 'onSelect' | 'anchorEl'>> {
    zIndex: BaseAssignProps<T>['zIndex'];
    items: BaseAssignProps<T>['items'];
    headerText: BaseAssignProps<T>['headerText'];
    setAssignee: (assignee: T | null, assignedTo: T[], assignedToAll: boolean) => void;
    onPopperOpened?: () => void;
    buttonText?: ButtonText;
    popoverTextClass?: string;
    popoverContainerClass?: string;
    isDisabled?: boolean;
    disabledTooltip?: string;
    shouldClosePopperOnInvite?: boolean;
    anchorElement?: HTMLElement;
    isLoading?: boolean;
    showFamilyHelpers: boolean;
    showTeamMembers: boolean;
    hideAvatar?: boolean;
}

const AssignmentPopper = <T extends BaseAssignEntity>(props: AssignmentPopperProps<T>) => {
    const {
        setAssignee,
        buttonText,
        popoverTextClass,
        popoverContainerClass,
        isDisabled,
        disabledTooltip,
        shouldClosePopperOnInvite,
        anchorElement,
        items,
        zIndex,
        headerText,
        addButtonText,
        assignedTo,
        assignedToAll,
        onAdd,
        handleClose,
        tooltip,
        onSecondaryAdd,
        onPopperOpened,
        secondaryAddButtonText,
        isMultiSelect,
        canAssignToAll,
        isEmailRequired,
        showToolipForAllUsers,
        showTooltipOnCheckbox,
        canUnassign,
        addButtonClass,
        isLoading,
        onAddTeamMember,
        showFamilyHelpers,
        showTeamMembers,
        hideAvatar,
    } = props;

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const toggleMenuPopover = (event?: React.MouseEvent<HTMLElement> | null) => {
        setAnchorEl(event?.currentTarget || null);
        if (event?.currentTarget) {
            onPopperOpened?.();
        }
    };

    const popoverElement =
        props.popoverElement ??
        (!buttonText ? undefined : (
            <GTooltip title={isDisabled ? disabledTooltip || 'Disabled' : ''} enterDelay={400}>
                <div className={popoverContainerClass}>
                    <Button
                        className={classes.rootButton}
                        disabled={isDisabled}
                        onClick={(e) => {
                            e.persist();
                            toggleMenuPopover(e);
                        }}
                    >
                        {!buttonText.hideAvatar &&
                            <TaskAssignAvatar
                                assigned_to={buttonText.assignedTo}
                                assigned_to_all={false}
                                hideAvatar={hideAvatar}
                            />
                        }
                        &nbsp;
                        <Typography color="secondary" className={popoverTextClass}>
                            {buttonText.label}
                        </Typography>
                    </Button>
                </div>
            </GTooltip>
        ));

    return (
        <BaseAssign
            anchorEl={anchorElement ?? anchorEl}
            zIndex={zIndex}
            items={items}
            handleClose={handleClose ?? toggleMenuPopover}
            canUnassign={canUnassign}
            onSelect={(_, assignee, assignees, isAssignedToAll) =>
                setAssignee(assignee, assignees || [], isAssignedToAll || false)
            }
            headerText={headerText}
            popoverElement={popoverElement}
            tooltip={tooltip}
            isEmailRequired={isEmailRequired}
            addButtonText={addButtonText}
            onAdd={(_zIndex) => {
                onAdd?.(_zIndex);

                if (shouldClosePopperOnInvite) {
                    if (handleClose) {
                        handleClose();
                    } else {
                        toggleMenuPopover();
                    }
                }
            }}
            onSecondaryAdd={(_zIndex) => {
                onSecondaryAdd?.(_zIndex);

                if (shouldClosePopperOnInvite) {
                    if (handleClose) {
                        handleClose();
                    } else {
                        toggleMenuPopover();
                    }
                }
            }}
            onAddTeamMember={!onAddTeamMember
                ? undefined
                : (_zIndex) => {
                    onAddTeamMember(_zIndex);

                    if (shouldClosePopperOnInvite) {
                        if (handleClose) {
                            handleClose();
                        } else {
                            toggleMenuPopover();
                        }
                    }
                }}
            secondaryAddButtonText={secondaryAddButtonText}
            isMultiSelect={isMultiSelect}
            canAssignToAll={canAssignToAll}
            showToolipForAllUsers={showToolipForAllUsers}
            showTooltipOnCheckbox={showTooltipOnCheckbox}
            assignedToAll={assignedToAll}
            assignedTo={assignedTo}
            addButtonClass={addButtonClass}
            isLoading={isLoading}
            showFamilyHelpers={showFamilyHelpers}
            showTeamMembers={showTeamMembers}
        />
    );
};

export default AssignmentPopper;
