import { useState } from 'react';
import classNames from 'classnames';

import { SxProps, Theme } from '@mui/material/styles';

import { WithStyles } from '@mui/styles';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import AddIcon from '@mui/icons-material/Add';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import PriceWidget from '../../widgets/PriceWidget';
import {
    Transaction,
    GatherCaseUX,
    PaymentMethod,
    FuneralHomeUX,
    GuestPaymentCaseTotals
} from '../../../../../shared/types';
import { useGSelector } from '../../../../../types';
import { convertHexToRGBA, getFormattedPhoneNumber } from '../../../../../services';
import { formatPrice } from '../../../../../shared/goods_and_services/pricing';
import GCircularProgress from '../../../../common/GCircularProgress';
import { smallTyphographyStyle, typographyStyle } from '../PaymentReceipt.dialog';
import PrintReceiptDialog from './PrintReceipt.dialog';
import Item from './Item';
import FuneralHomeLogo from '../../../../common/FuneralHomeLogo';
import { } from '../../../../common/DisabledPermissionTooltip';
import { Permission } from '../../../../../shared/types/permissions';
import { usePermissionEnabled } from '../../../../common/hooks/usePermissionEnabled';
import makeStyles from "@mui/styles/makeStyles";
import { GStyles } from '../../../../../styles/GStyles';
import { GLOBAL_STYLED_PROPS } from '../../../../../styles';

export const useDetailsHeaderStyles = makeStyles((theme: Theme) => ({
    header: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        height: 120,
        padding: '0 4px',
        borderBottom: 'none !important',
        color: theme.palette.primary.main,
        position: 'relative',
        '@media (min-width: 440px)': { // width of the root ele + margin offsets 
            height: 108,
            padding: 0,
        },
    },
    progressWrapper: {
        position: 'relative',
        height: 80,
        width: 80,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        '& $fabProgress': {
            zIndex: 1,
        },
        '& $progressText': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            lineHeight: '1em',
            zIndex: 2,
            '& span': {
                '&:first-of-type': {
                    fontSize: 24,
                    lineHeight: '1em',
                    marginTop: 4,
                },
                '&:last-child': {
                    fontSize: 11
                }
            }
        },
    },
    cents: {
        marginLeft: '0 !important',
        '@media (min-width: 380px)': {
            marginLeft: '2px !important'
        }
    },
    customSign: {
        margin: '0 !important'
    },
    left: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        padding: '28px 0 0',
        '&$responsive': {
            '@media (min-width: 440px)': { // width of the root ele + margin offsets 
                minWidth: 'calc(100% - 90px)', // add the padding offset too,
                padding: '0 4px',
            },
        },
        '& $inner': {
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            minWidth: 80,
            '&$symbol': {
                minWidth: 'max-content',
                margin: '36px 8px 0',
                '& p': {
                    fontSize: 24,
                    lineHeight: '1em'
                }
            }
        },
        '& $divider': {
            height: 1,
            width: '100%',
            background: theme.palette.primary.main,
            margin: '2px 0 10px',
        },
        '& p': {
            margin: 0
        }
    },
    right: {
        color: theme.palette.primary.main,
        padding: 0,
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        '&$responsive': {
            '@media (min-width: 440px)': { // width of the root ele + margin offsets 
                padding: '0 0 0 2px',
                borderLeft: '1px solid',
                position: 'unset',
                top: 0,
                left: 0,
                transform: 'none',
            }
        }
    },
    inner: {},
    responsive: {},
    symbol: {},
    divider: {},
    progressText: {},
    fabProgress: {}
}), { name: 'DetailsHeaderStyles' });

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        maxWidth: 440,
        padding: 0,
        borderRadius: 12,
        boxShadow: theme.shadows[8],
        margin: '40px 8px 0',
        zIndex: 1,
        height: 'auto',
        '@media (min-width: 440px)': {
            margin: '0 8px',
        },
        '@media (min-width: 960px)': {
            borderRadius: '0 12px 0 0',
            margin: 0,
            color: theme.palette.primary.main,
            height: 'calc(100vh - 227px)',
        }
    },
    footer: {
        background: convertHexToRGBA(theme.palette.primary.main, 0.12),
        height: 'calc(100% - 108px)', // subtract the header's height,
        padding: 4,
        overflowY: 'auto',
        '& p': {
            textTransform: 'capitalize',
            fontSize: 14,
            lineHeight: '1.25em',
            display: 'flex',
            alignItems: 'center',
        },
        '& $projected': {
            fontStyle: 'italic',
            opacity: 0.6,
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            '@media (min-width: 460px)': {
                width: 'calc(100% - 84px)',
            }
        },
        '& $actual': {
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            '@media (min-width: 460px)': {
                width: 'calc(100% - 84px)',
            }
        },
        '& $inner': {
            margin: '2px 28px 0 0',
            paddingTop: 2,
            borderTop: '1px solid',
            justifyContent: 'flex-end',
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'column',
        }
    },
    recordAChargeBtnContainer: {
        borderTop: `1px solid ${theme.palette.primary.main}`,
        marginTop: 8,
        padding: '4px 8px',
        '& button': {
            background: theme.palette.common.white,
            minWidth: 178,
            margin: '2px 0px',
            padding: '0px 8px',
        }
    },
    addExpenseButtonContainer: {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        paddingBottom: '4px',
        '& button': {
            background: theme.palette.common.white,
            minWidth: 178,
            margin: '2px 0px',
            padding: '0px 8px',
        },
    },
    noTransactions: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        minHeight: 120,
        '@media (min-width: 960px)': {
            minHeight: 'calc(100% - 24px)',
        },
        '& p': {
            textTransform: 'none',
        }
    },
    isRestricted: {
        opacity: 0,
        visibility: 'hidden',
        pointerEvents: 'none'
    },

    statementReportText: {
        justifyContent: 'center',
        margin: '8px 0',
        textTransform: 'none !important' as 'none',
    },
    actual: {},
    projected: {},
    left: {},
    right: {},
    inner: {},
    translucent: {},
}), { name: 'DetailsStyles' });

interface WithTransaction {
    loggedInUserId: number | null;
    isFamilyUser: boolean | null;
    selectedCase: GatherCaseUX;
    isContractFrozen: boolean;
    funeralHome: FuneralHomeUX | null;
    onRecordExpenseClick: () => void;
    openDetailMenu: (anchorEle: HTMLElement, transaction: Transaction) => void;
    navigateToContract: () => void;
    showReceipt: (paymentId: number) => void;
    editInvoice: (invoice: Transaction) => void;
    setItemizedStatement: (isItemized: boolean, callback?: () => void) => void;
}
export type StyledProps = GLOBAL_STYLED_PROPS & WithStyles<'root' | 'label' | 'divider' | 'projected' | 'actual'
    | 'footer' | 'left' | 'right' | 'statementReportText' | 'fabProgress' | 'progressText'
    | 'inner' | 'addExpenseButtonContainer' | 'symbol' | 'noTransactions' | 'translucent'
    | 'isRestricted' | 'responsive' | 'recordAChargeBtnContainer'>;

export const renderPaymentMethodLabel = (
    paymentMethod: PaymentMethod | null,
    paymentType: 'INVOICE' | 'PAYMENT',
    payerName: string | null,
    description: string,
    externalId: string | null,
    amount: string,
    isFamilyUser: boolean | null
): string => {
    switch (paymentMethod) {
        case PaymentMethod.card:
            return paymentType === 'INVOICE'
                ? `Card Reader Convenience Fee`
                : `Card Reader Payment${payerName && !isFamilyUser && ` (${payerName})` || ``}`;
        case PaymentMethod.cash:
            return `Cash Payment${payerName && !isFamilyUser && ` (${payerName})` || ''}`;
        case PaymentMethod.check:
            return `Check Payment${externalId && ` (${externalId})` || ''}`;
        case PaymentMethod.online:
            return paymentType === 'INVOICE'
                ? `Manual Card Convenience Fee`
                : `Manual Card Payment${payerName && !isFamilyUser && ` (${payerName})` || ``}`;
        case PaymentMethod.plaid:
            return paymentType === 'INVOICE'
                ? 'Bank Transfer Fee'
                : `Bank Transfer${payerName && !isFamilyUser && ` (${payerName})` || ''}`;
        case PaymentMethod.other:
            return paymentType === 'INVOICE'
                ? 'Other Payment Fee'
                : `Other Payment Method${payerName && !isFamilyUser && ` (${payerName})` || ''}`;
        case PaymentMethod.insurance:
            return paymentType === 'INVOICE'
                ? 'Insurance Processing Fee'
                : `Insurance Payment${payerName && !isFamilyUser && ` (${payerName})` || ''}`;
        case PaymentMethod.unknown:
            return `Expected payment${payerName && !isFamilyUser && ` (${payerName})` || ''}`;
        default:
            // we will reach here if method === null
            if (paymentType === 'INVOICE') {
                return `${amount.startsWith('-')
                    ? 'Credit' : 'Charge'}: ${description === 'Processing Fee' ? 'Convenience Fee' : description}`;
            }
            return description;
    }
};

export const StatementValues = (props: {
    isPrintMode?: boolean;
    statementValue: string;
    heading: string;
}) => {
    const classes = useDetailsHeaderStyles();
    const { isPrintMode, statementValue, heading } = props;
    return (
        <div className={classes.inner}
            style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                minWidth: 80,
            }}>
            <Typography color="secondary" className={GStyles.fontSize16}
                style={{
                    margin: isPrintMode ? 0 : undefined
                }}>
                {heading}
            </Typography>

            <div className={classes.divider}
                style={{
                    height: 1,
                    width: '100%',
                    backgroundColor: '#000',
                    margin: '2px 0 10px',
                }}
            />

            <PriceWidget
                size="small"
                color="primary"
                priceStr={statementValue}
                isPrintMode={isPrintMode}
                customClasses={{
                    cents: classes.cents,
                    customSign: classes.customSign
                }}
            />
        </div>
    );
};

export const Header = (props: {
    caseTotals: GuestPaymentCaseTotals;
    responsive?: boolean;
    isPrintMode?: boolean;
}) => {
    const classes = useDetailsHeaderStyles();
    const { caseTotals, responsive, isPrintMode } = props;

    const total = formatPrice(caseTotals.expense_total || 0, 'USD');
    const payments = formatPrice(Math.abs(caseTotals.collected_total || 0), 'USD');
    const balanceFormatted = formatPrice((caseTotals.outstanding_total || 0), 'USD');
    const percentCollected = (caseTotals.payment_progress || 0);

    const displaySymbol = (symbol: string) => {
        return (
            <div
                className={classNames(
                    classes.inner,
                    classes.symbol
                )}
                style={{
                    minWidth: 'max-content',
                    margin: '36px 8px 0',
                }}
            >
                <Typography
                    color="secondary"
                    style={{
                        fontSize: 24,
                    }}>
                    {symbol}
                </Typography>
            </div>
        );
    };

    return (
        <Grid item xs={12} className={classes.header}>
            <Grid
                item
                xs={12}
                className={classNames(
                    classes.left,
                    responsive && classes.responsive
                )}
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    width: '100%',
                }}>

                <StatementValues heading='Total' isPrintMode={isPrintMode} statementValue={total} />

                {displaySymbol('-')}

                <StatementValues heading='Payments' isPrintMode={isPrintMode} statementValue={payments} />

                {displaySymbol('=')}

                <StatementValues heading='Outstanding' isPrintMode={isPrintMode} statementValue={balanceFormatted} />
            </Grid>

            {!isPrintMode &&
                <CollectedPercentage statementValue={percentCollected} responsive={responsive} />
            }
        </Grid>
    );
};

export const CollectedPercentage = (props: {
    statementValue: number;
    responsive?: boolean;
    rootStyles?: SxProps<Theme>;
}) => {
    const { statementValue, responsive, rootStyles } = props;

    const classes = useDetailsHeaderStyles();

    const statementValueFormatted = Math.floor(statementValue * 100);

    return (
        <Grid
            item
            xs={12}
            sx={rootStyles}
            className={classNames(
                classes.right,
                responsive && classes.responsive,
            )}
        >
            <div className={classes.progressWrapper}>

                <Typography color="primary" className={classes.progressText}>
                    <span>{(statementValue * 100).toFixed(0)}%</span>
                    <span>Collected</span>
                </Typography>

                <GCircularProgress
                    color="primary"
                    variant="determinate"
                    size={80}
                    thickness={3}
                    className={classes.fabProgress}
                    value={statementValueFormatted > 100
                        ? 100 : statementValueFormatted === 0 ? 2 : statementValueFormatted}
                />
            </div>
        </Grid>
    );
};

const Footer = (props: WithTransaction & {
    isPrintMode: boolean;
    openPrintReceiptDialog: () => void;
}) => {
    const classes = useStyles();
    const {
        isContractFrozen,
        selectedCase,
        openDetailMenu,
        navigateToContract,
        showReceipt,
        editInvoice,
        isFamilyUser,
        loggedInUserId,
        isPrintMode,
        onRecordExpenseClick,
        openPrintReceiptDialog
    } = props;
    const transactions = useGSelector(({ financeState }) => financeState.transactions);
    const { totals } = transactions;

    const balance = ((totals.expense_total - totals.collected_total) / 100.0).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    const proposedBalance = (
        (totals.expense_total - totals.collected_total - totals.proposed_total) / 100.0).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });

    const canModifyPrices = usePermissionEnabled(Permission.MODIFY_PRICES);

    return (
        <Grid item xs={12} className={classes.footer}
            style={{
                background: isPrintMode && 'transparent' || undefined,
                borderTop: !isPrintMode && `1px solid` || undefined,
                borderBottom: !isPrintMode && `1px solid` || undefined
            }}
        >
            {!isPrintMode &&
                <>
                    <div className={classes.addExpenseButtonContainer}>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={openPrintReceiptDialog}
                            className={classNames(
                                isFamilyUser && classes.isRestricted || undefined,
                                GStyles.backgroundWhite
                            )}
                        >
                            <LocalPrintshopIcon />&nbsp;Case Statements
                        </Button>
                    </div>

                    <Typography
                        align="center"
                        color="primary"
                        className={classes.statementReportText}
                    >
                        Statement Report for&nbsp;
                        <span>{selectedCase.full_name}</span>
                    </Typography>
                </>
            }

            {transactions.transactions.map((v, i) => (
                <Item
                    key={v.key}
                    transaction={v}
                    canModifyPrices={canModifyPrices}
                    openDetailMenu={openDetailMenu}
                    showReceipt={showReceipt}
                    editInvoice={editInvoice}
                    isContractFrozen={isContractFrozen}
                    navigateToContract={navigateToContract}
                    isFamilyUser={isFamilyUser}
                    loggedInUserId={loggedInUserId}
                    isPrintMode={isPrintMode}
                    caseFirstName={selectedCase.fname}
                    isShowPayments={false}
                />
            ))}

            {transactions.transactions.length !== 0 &&
                <div className={classes.inner}
                    style={{
                        paddingTop: 2,
                        borderTop: '1px solid',
                        justifyContent: 'flex-end',
                        display: 'flex',
                        alignItems: 'flex-end',
                        flexDirection: 'column',
                        width: isPrintMode ? '100%' : undefined,
                        maxWidth: isPrintMode ? 420 : undefined,
                        margin: isPrintMode ? 'auto' : undefined
                    }}>
                    <div className={classes.actual}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            fontStyle: 'italic',
                            alignItems: 'center',
                            fontSize: isPrintMode ? 12 : 14,
                            fontWeight: isPrintMode ? 200 : 400,
                            width: isPrintMode ? 'calc(100% - 84px)' : undefined,
                        }}>
                        <Typography color="secondary"
                            style={{
                                margin: isPrintMode ? 0 : undefined
                            }}>
                            Actual Balance Owed
                        </Typography>
                        <Typography color="secondary" className="notranslate"
                            style={{
                                margin: isPrintMode ? 0 : undefined
                            }}>
                            {balance}
                        </Typography>
                    </div>
                    <div className={classes.projected}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            fontStyle: 'italic',
                            alignItems: 'center',
                            fontSize: isPrintMode ? 12 : 14,
                            fontWeight: isPrintMode ? 200 : 400,
                            width: isPrintMode ? 'calc(100% - 84px)' : undefined,
                            margin: isPrintMode ? 0 : undefined
                        }}>
                        <Typography color="secondary"
                            style={{
                                margin: isPrintMode ? 0 : undefined
                            }}>
                            Expected Balance Owed
                        </Typography>
                        <Typography color="secondary" className="notranslate"
                            style={{
                                margin: isPrintMode ? 0 : undefined
                            }}>
                            {proposedBalance}
                        </Typography>
                    </div>
                </div>
            }

            {!isPrintMode &&
                <div className={classes.recordAChargeBtnContainer}>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={onRecordExpenseClick}
                        className={isFamilyUser && classes.isRestricted || undefined}
                    >
                        <AddIcon />&nbsp;Record a Charge
                    </Button>
                </div>
            }

            {
                transactions.transactions.length === 0 &&
                <div className={classes.noTransactions}>
                    <FormatListBulletedIcon color="secondary" />
                    <Typography color="secondary">
                        All charges and payments will appear here
                    </Typography>
                </div>
            }
        </Grid >
    );
};

interface DetailsProps extends WithTransaction {
    caseTotals: GuestPaymentCaseTotals;
    zIndex: number;
}
const Details = (props: DetailsProps) => {

    const classes = useStyles();

    const {
        funeralHome,
        caseTotals,
        selectedCase,
        setItemizedStatement,
        zIndex,
    } = props;

    const [isPrintMode, setIsPrintMode] = useState(false);
    const [isPrintReceiptDialogOpen, setIsPrintReceiptDialogOpen] = useState(false);


    const openPrintReceiptDialog = () => {
        setIsPrintReceiptDialogOpen(true);
    };

    const closePrintReceiptDialog = () => {
        setIsPrintReceiptDialogOpen(false);
    };

    const renderPrintContent = () => {
        return (
            <Grid item={true} xs={12} id="print-receipt-detail" style={{ textAlign: 'center', display: 'none' }}>
                {funeralHome &&
                    <div style={{
                        marginBottom: 12,
                        display: isPrintMode ? 'flex' : 'none',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <FuneralHomeLogo
                            logoSize={'large'}
                            ignoreTranformationsHeight={isPrintMode}
                            style={{ maxWidth: 360, maxHeight: 80 }}
                        />
                        <Typography
                            style={{
                                ...smallTyphographyStyle,
                                borderTop: '1px solid',
                                paddingTop: 4,
                                textAlign: 'center',
                                marginTop: 10
                            }}
                        >
                            {funeralHome.address1}
                        </Typography>
                        <Typography style={{ ...smallTyphographyStyle, textAlign: 'center' }}>
                            {funeralHome.city && <span>{funeralHome.city}, </span>}
                            {funeralHome.state && <span>{funeralHome.state} </span>}
                            {funeralHome.postal_code && <span>{funeralHome.postal_code}</span>}
                        </Typography>
                        <Typography style={{ ...smallTyphographyStyle, textAlign: 'center' }}>
                            {getFormattedPhoneNumber(selectedCase.funeral_home.phone)}
                        </Typography>
                        <Typography style={{ ...smallTyphographyStyle, textAlign: 'center' }}>
                            {selectedCase.assignee.email}
                        </Typography>
                    </div>
                }

                {isPrintMode &&
                    <Typography
                        style={{
                            ...typographyStyle,
                            textAlign: 'center',
                            fontSize: 16
                        }}
                    >
                        Statement Report for: {selectedCase.full_name}
                    </Typography>
                }

                <Header {...props} caseTotals={caseTotals} responsive isPrintMode={isPrintMode} />
                <Footer
                    {...props}
                    isPrintMode={isPrintMode}
                    openPrintReceiptDialog={openPrintReceiptDialog}
                />
            </Grid>
        );
    };

    const printItemizedStatement = () => {
        setItemizedStatement(true, printStatement);
    };

    const printStatement = () => {
        const printContents = document.getElementById('print-content');
        const printContainer = document.getElementById('print-container');

        if (!printContents || !printContainer) {
            return;
        }

        const itemizedStatementStyles = `
        <style>
            @page {
                margin: 24px;
            }
        </style>`;

        printContainer.innerHTML = printContents.innerHTML + itemizedStatementStyles;
        window.print();

        setItemizedStatement(false);
    };

    // for Printing QuickStatement
    const printReceipt = () => {
        setIsPrintMode(true);
        setTimeout(() => print(), 100);
    };

    const print = () => {
        const printContents = document.getElementById('print-receipt-detail');
        const printContainer = document.getElementById('print-container');

        if (!printContents || !printContainer) {
            return;
        }

        const materialIconStyles = `
        <style>
        @font-face {
            font-family: 'Material Icons';
            font-style: normal;
            font-weight: 400;
            src: url(https://fonts.gstatic.com/s/materialicons/v47/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
        }

        .material-icons {
            font-family: 'Material Icons';
            font-weight: normal;
            font-style: normal;
            font-size: 24px;
            line-height: 1;
            letter-spacing: normal;
            text-transform: none;
            display: inline-block;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
            -webkit-font-feature-settings: 'liga';
            -webkit-font-smoothing: antialiased;
        }
        </style>`;

        printContainer.innerHTML = printContents.innerHTML + materialIconStyles;
        window.print();
        setIsPrintMode(false);
    };

    return (
        <Grid
            item
            xs={12}
            className={classes.root}
            style={{ textAlign: 'center' }}
        >
            <Header {...props} caseTotals={caseTotals} responsive isPrintMode={false} />
            <Footer
                {...props}
                isPrintMode={false}
                openPrintReceiptDialog={openPrintReceiptDialog}
            />
            <PrintReceiptDialog
                selectedCase={selectedCase}
                isDialogOpen={isPrintReceiptDialogOpen}
                closeDialog={closePrintReceiptDialog}
                zIndex={zIndex + 1}
                printItemizedStatement={printItemizedStatement}
                printReceipt={printReceipt}
            />
            {renderPrintContent()}
        </Grid>
    );
};

export default Details;
