import classNames from 'classnames';

import GSwitch from '../../../../../common/inputElements/GSwitch';
import CaseFingerprints from './caseFingerprints/CaseFingerprints';
import { FingerprintsConfig } from '../../../../../../shared/types';
import { useGSelector } from '../../../../../../types';
import StepComponentWrapper from '../StepComponentWrapper';
import { useCompleteStepSwitchStyles } from '../dialogContent.styles';
import { OnValidityChangedFunction } from './types';
import { useEffect, useMemo, useState } from 'react';
import { TrackingContentContext } from '../CompleteStep.dialog';

interface Props {
    isStepCompleted: boolean;
    config: FingerprintsConfig | null;
    componentId: number;
    saveAttempted: boolean;
    caseUuid: string | null; // null case is for Back Office preview
    caseFname: string;
    zIndex: number;
    onValidityChanged: OnValidityChangedFunction;
}

const FingerprintsComponent = (props: Props) => {
    const {
        config,
        componentId,
        saveAttempted,
        caseUuid,
        caseFname,
        zIndex,
        isStepCompleted,
        onValidityChanged,
    } = props;

    const classes = useCompleteStepSwitchStyles();

    const fingerprintsCount = useGSelector(({ casesState }) => casesState.activeCaseFingerprints.prints.length);

    const count = config?.count;
    const instructions = config?.instructions;
    const isRequired = config?.is_required;
    const fingerprintSlots = Math.max(count ?? 1, fingerprintsCount);

    // if step completed assume required things were completed
    const [isChecked, setIsChecked] = useState<boolean>(Boolean(isRequired && isStepCompleted));

    const isValid = useMemo(() => {
        if (isRequired) {
            return isChecked === true && fingerprintsCount > 0;
        } else {
            return true;
        }
    }, [isChecked, isRequired, fingerprintsCount]);

    useEffect(() => {
        onValidityChanged(componentId, isValid);
    }, [onValidityChanged, componentId, isValid]);

    const hasError = saveAttempted && !isValid;
    const isRequiredAndValid = isValid && isRequired;

    return (
        <StepComponentWrapper
            heading={`${fingerprintsCount} of ${fingerprintSlots} Fingerprint(s) Captured${isRequired ? ' *' : ''}`}
            error={hasError}
            success={isRequiredAndValid}
            extendedWidth
        >
            <CaseFingerprints
                disableEdit={isStepCompleted}
                context={TrackingContentContext.dialog}
                zIndex={zIndex + 1}
                caseUuid={caseUuid}
                caseFname={caseFname}
                fingerprintText={instructions}
                numEmptyPrints={fingerprintSlots}
            />

            {fingerprintsCount > 0 && isRequired &&
                <div className={classNames(
                    isRequiredAndValid
                        ? classes.backgroundGreenColor
                        : classes.backgroundPrimary,
                    hasError && classes.backgroundRed,
                    classes.switchRadius
                )}
                >
                    <GSwitch
                        required={true}
                        disabled={isStepCompleted}
                        label={`I captured an accurate fingerprint *`}
                        checked={isChecked}
                        onChangeCallBack={setIsChecked}
                        formControlLabelClasses={classes.formControlLabel}
                        labelClasses={classNames(
                            classes.marginLeft_4,
                            classes.whiteColor
                        )}
                        switchBaseClasses={classes.whiteColor}
                        switchCheckedClass={classes.whiteColor}
                        barClass={classes.secondaryBackground}
                    />
                </div>
            }
        </StepComponentWrapper>
    );
};

export default FingerprintsComponent;
