import { Typography, Theme, Avatar, IconButton, MenuItem, Tooltip, Grid } from "@mui/material";
import moment from 'moment';
import { useState } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { AppRoute, isValidEmail } from "../../../services";
import { downloadFromURL } from "../../../services/doc.service";
import {
    DocPacketSignerUX,
    DocDownloadResponse,
    DocPacketUX,
    GatherCaseUX,
    getCaseEntity,
    AvatarUser,
    DocUX
} from "../../../shared/types";
import makeGStyles from "../../../styles/makeGStyles";
import { useGDispatch, useGSelector } from "../../../types";

import EmailIcon from '@mui/icons-material/Email';
import CloudUploadIcon from '@mui/icons-material/CloudDownload';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getRelationshipDisplayValue } from "../../common/AcquaintanceButtons";
import UserAvatar from "../../common/UserAvatar";
import { GREEN_COLOR, ORANGE_COLOR, RED_COLOR } from "../../../constants/colorVariables";
import HelperPopper from "../../family/helperPopper/HelperPopper";
import classNames from "classnames";
import { canEditPersonPhoneOrEmail } from "../../../shared/authority/can";
import GPopper from "../../common/GPopper";
import GLink from "../../common/GLink";
import SendEmailReminderPopover from "./SendEmailReminderPopover";
import { remindDocPacketSigner, updateDocPacketSignerEmail } from "../../../actions/DocPacket.action";
import ChangeOriginalValueDialog from "../../common/ChangeOriginalValueDialog";
import GButton from "../../common/GButton";

const useStyles = makeGStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        '& a': {
            width: '100%',
            height: '100%',
            color: 'inherit',
            textDecoration: 'none',
        },
    },
    upperSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    counterSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 8,
    },
    packetCount: {
        fontSize: 32,
        lineHeight: 'normal',
    },
    middleText: {
        lineHeight: 'normal',
        fontSize: 16,
        fontWeight: 300,
        padding: '5px 0',
        margin: '0 2px',
    },
    signatureStatus: {
        textAlign: 'center',
        //     borderTop: `1px solid ${theme.palette.primary.main}`,
    },
    statusText: {
        fontWeight: 200,
        fontSize: 10,
        letterSpacing: '0.1px',
        borderTop: `1px solid ${theme.palette.primary.main}`,
        margin: '0 auto',
        width: 'fit-content',
    },
    styleUserName: {
        textTransform: 'capitalize',
        padding: '0 8px',
    },
    textUppercase: {
        textTransform: 'uppercase',
    },
    listContainer: {
        height: 'auto',
        width: '100%',
        maxHeight: 480,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'relative',
    },
    fabExtended: {
        width: 'max-content',
        lineHeight: '12px',
        textTransform: 'none',
        backgroundColor: 'white',
        fontWeight: 400,
        fontSize: 10,
        padding: '2px 8px',
        borderRadius: 24,
        border: '1px solid',
        '&$started': {
            color: theme.palette.secondary.main,
        },
        '&$signed': {
            color: GREEN_COLOR,
        },
        '&$viewed': {
            color: ORANGE_COLOR,
        },
    },
    primary: {
        lineHeight: '1.1em',
        fontWeight: 300,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    secondary: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    link: {
        width: '100%',
        textDecoration: 'none',
        display: 'block',
    },
    signerAvatar: {
        textTransform: 'uppercase',
        width: 52,
        height: 52,
    },
    listItem: {
        padding: 8,
        display: 'flex',
        alignItems: 'end',
    },
    sendToEmail: {
        // overflow: 'hidden',
        // lineHeight: '1.4em',
        lineHeight: '1.2em',
        fontSize: 13,
        fontWeight: 300,
        // whiteSpace: 'nowrap',
        // textOverflow: 'ellipsis',
        maxWidth: 'calc(100% - 16px)',
        textTransform: 'none',
    },
    signerName: {
        fontWeight: 400,
        lineHeight: '1.1em',
        fontSize: 14,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: 240,
        overflow: 'hidden',
        '& span': {
            fontWeight: 300,
        },
    },
    moreIconButton: {
        width: 24,
        height: 24,
        position: 'absolute',
        right: 4,
        '& svg': {
            fontSize: 18,
        },
    },
    packetSignerList: {
        padding: '8px 0 8px 8px',
        display: 'flex',
        flexDirection: 'row',
    },
    fontSize18: {
        fontSize: 18,
    },
    reminderPopoverText: {
        marginBottom: '20px',
    },
    menu: {},
    removeSigner: {
        color: RED_COLOR,
        '& svg': {
            color: RED_COLOR,
        },
    },
    remindFabButton: {
        marginLeft: 4,
        cursor: 'pointer',
        minHeight: 'auto',
        '& svg': {
            fontSize: 11,
            verticalAlign: 'text-bottom',
        },
    },
    chipButtons: {
        marginLeft: '-2px',
        display: 'flex',
    },
    pointerEventsNone: {
        pointerEvents: 'none',
    },
    fabButtonLabel: {
        lineHeight: '1.1em',
    },
    avatarClass: {
        width: 52,
        height: 52,
    },
    marginLeft8: {
        marginLeft: 8,
    },
    menuItem: {
        '&:focus': {
            outline: 'transparent',
        },
    },
    secondaryTextContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    updatedEmail: {
        fontSize: 11,
        textTransform: 'none',
        lineHeight: '1.3em',
        color: RED_COLOR,
        margin: '1px auto',
    },
    textTransnformCaptalization: {
        textTransform: 'capitalize',
    },
    fontWeight500: {
        fontWeight: 500,
    },
    paper: {
        overflow: 'auto',
        padding: '8px 0',
    },
    started: {},
    signed: {},
    viewed: {},
}), { name: 'ESignSignatures' });

interface Props {
    packetLink?: AppRoute;
    signedDoc: DocUX;
    packetSigners: DocPacketSignerUX[];
    zIndex: number;
    onDownloadSignedDoc: (doc: DocUX) => Promise<DocDownloadResponse | null>;
    onDownloadInProgressDoc: (docPacket: DocPacketUX) => Promise<DocDownloadResponse | null>;
    setReminderAnchorEl: (anchorEl: HTMLElement | null) => void;
    reminderAnchorEl: HTMLElement | null;
    menuAnchorEle: HTMLElement | null;
    setMenuAnchorEle: (anchorEl: HTMLElement | null) => void;
    docPacket: DocPacketUX;
    activeCase: GatherCaseUX;
    canDownloadIncomplete: boolean;
}

const ESignSignatures = (props: Props) => {
    const {
        packetLink,
        packetSigners,
        signedDoc,
        onDownloadSignedDoc,
        onDownloadInProgressDoc,
        zIndex,
        setReminderAnchorEl,
        reminderAnchorEl,
        menuAnchorEle,
        setMenuAnchorEle,
        docPacket,
        activeCase,
        canDownloadIncomplete
    } = props;
    const dispatch = useGDispatch();
    const classes = useStyles();

    const userSession = useGSelector((state) => state.userSession);
    const [helperPopperAnchorEle, setHelperPopperAnchorEle] = useState<HTMLElement | null>(null);
    const [targetSigner, setTargetSigner] = useState<DocPacketSignerUX | null>(null);
    const [isChangeEmailDialogOpen, setIsChangeEmailDialogOpen] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);

    const openMenu = (e: React.MouseEvent<HTMLButtonElement>, person: DocPacketSignerUX) => {
        e.preventDefault();
        e.stopPropagation();
        setMenuAnchorEle(e.currentTarget);
        setTargetSigner(person);
    };

    const openHelperPopper = (e: React.MouseEvent<HTMLDivElement>, person: DocPacketSignerUX) => {
        e.preventDefault();
        e.stopPropagation();
        setTargetSigner(person);
        setHelperPopperAnchorEle(e.currentTarget);
    };

    const openReminderPopup = (e: React.MouseEvent<HTMLElement>, person: DocPacketSignerUX) => {
        e.preventDefault();
        e.stopPropagation();
        setReminderAnchorEl(e.currentTarget);
        setTargetSigner(person);
    };

    const closeMenu = () => {
        if (isChangeEmailDialogOpen || reminderAnchorEl) {
            return;
        }
        setMenuAnchorEle(null);
        setTargetSigner(null);
    };

    const closeReminderPopup = () => {
        setReminderAnchorEl(null);
        setTargetSigner(null);
        closeMenu();
    };

    const closeHelperPopper = () => {
        setHelperPopperAnchorEle(null);
        setTargetSigner(null);
    };

    const handleChangeEmailClick = () => {
        setIsChangeEmailDialogOpen(true);
    };

    const handleSendReminderClick = async () => {
        if (!targetSigner || !targetSigner.person.email) {
            return;
        }
        await dispatch(remindDocPacketSigner(docPacket, activeCase, targetSigner));
        closeReminderPopup();
    };

    const handleChangeEmailDialogConfirm = async (newEmail: string) => {
        if (!newEmail || !targetSigner) {
            return;
        }
        const isValid = isValidEmail(newEmail);
        if (!isValid) {
            return;
        }
        await dispatch(updateDocPacketSignerEmail(docPacket, activeCase, targetSigner, newEmail));
        setIsChangeEmailDialogOpen(false);
        setMenuAnchorEle(null);
        setTargetSigner(null);
    };

    const handleDownload = async (docPacketToDownload: DocPacketUX, signedDocToDownload: DocUX) => {
        if (docPacketToDownload.status === 'signed') {
            if (!signedDocToDownload) {
                return;
            }
            const downloadDocResult = await onDownloadSignedDoc(signedDocToDownload);
            if (!downloadDocResult) {
                return;
            }
            downloadFromURL(downloadDocResult.presignedurl, downloadDocResult.downloadfilename);
        }
    };
    
    const handleDownloadInProgressPacket = async (docPacketToDownload: DocPacketUX) => {
        setIsDownloading(true);
        if (docPacketToDownload.status !== 'signed' && canDownloadIncomplete) {
            await onDownloadInProgressDoc(docPacketToDownload);
        }
        setIsDownloading(false);
    };

    const secondaryText = (signer: DocPacketSignerUX) => {
        const isSigned = signer.hellosign_status === 'signed';
        const isViewed = signer.hellosign_status === 'viewed';
        const fromNow = signer.hellosign_status_time ? moment(signer.hellosign_status_time).fromNow() : '';

        const { history } = signer;
        const thisHistory = history && history.filter(obj => (
            obj.doc_packet_id === docPacket.id && obj.person_id === signer.person_id));
        const emailChangeDetected = thisHistory && thisHistory.length > 0 ?
            thisHistory[0].email !== signer.person.email : false;
        const lastSentDetails = thisHistory && thisHistory.length > 0 ? thisHistory[0] : null;
        const lastSentFromNow = lastSentDetails ? moment(lastSentDetails.sent_time).fromNow() : '';

        const signerEmail = signer.person.email;
        const displayMessage = docPacket.status === 'started' && docPacket.type === 'fill_esign'
            ? signerEmail && `Will send to ${signerEmail}`
            : emailChangeDetected && lastSentDetails
                ? `Sent to ${lastSentDetails.email}`
                : signerEmail && `Sent to ${signerEmail}`;
        const emailChangedMessage = emailChangeDetected && lastSentDetails ?
            `${signer.person.fname}'s email was updated to ${signerEmail || ''}. You may need to send a new reminder`
            : '';

        return (
            <span className={classes.secondaryTextContainer}>
                <Typography component="span" className={classes.sendToEmail}>
                    {displayMessage}
                    {lastSentFromNow &&
                        <span>
                            &nbsp;({lastSentFromNow})
                        </span>
                    }
                </Typography>
                {emailChangeDetected &&
                    <Typography component={'span'} className={classes.updatedEmail}>
                        {emailChangedMessage}
                    </Typography>
                }
                <span className={classes.chipButtons}>
                    <span
                        className={classNames(
                            classes.fabExtended,
                            isSigned ? classes.signed : classes.started,
                            isViewed && classes.viewed,
                        )}
                    >
                        {isSigned ? `Signed ${fromNow}` :
                            signer.hellosign_status === 'viewed' ? `Viewed ${fromNow}` :
                                docPacket.status === 'started' ? 'Not yet sent' :
                                    'Awaiting Signature'}
                    </span>
                    {!isSigned && docPacket.status !== 'started' && <span

                        className={classNames(
                            classes.fabExtended,
                            classes.remindFabButton,
                            classes.menu,
                        )}
                        onClick={e => openReminderPopup(e, signer)}
                    >
                        <AccessAlarmIcon /> Remind
                    </span>}
                </span>
            </span>
        );
    };

    const renderHelperPopper = () => {
        if (!activeCase) {
            return null;
        }

        return (
            <HelperPopper
                key={targetSigner && targetSigner.person.entity_id}
                zIndex={zIndex + 1}
                clickAwayListener={closeHelperPopper}
                selectedCase={activeCase}
                activeEntityId={targetSigner && targetSigner.person.entity_id}
                popperAnchorEle={helperPopperAnchorEle}
                closeHelperPopper={closeHelperPopper}
            />
        );
    };

    const renderUserListItem = (signer: DocPacketSignerUX) => {
        const { fname, lname, photo, photo_transformations } = signer.person;
        const user: AvatarUser = {
            fname,
            lname,
            photo,
            photo_transformations,
        };
        const caseEntity = getCaseEntity(signer.person, activeCase.id);
        // const caseDetail = entityCaseDetails.find((c) => c.entity_id === signer.person_id);
        const { relationship, relationship_alias, relationship_type } = caseEntity || {
            relationship: null, relationship_alias: '', relationship_type: null
        };
        const displayRelationship = getRelationshipDisplayValue(
            { relationship, relationship_alias, relationship_type }, true);

        return (
            <ListItem key={signer.id} className={classes.listItem}>
                <Avatar className={classes.avatarClass}>
                    <UserAvatar
                        user={user}
                        className={classes.signerAvatar}
                        onClick={(e) => openHelperPopper(e, signer)}
                        size={52}
                    />
                </Avatar>
                <div className={classes.marginLeft8}>
                    <ListItemText
                        className={classes.styleUserName}
                        primary={
                            <>
                                <span className={classes.signerName}>
                                    {fname} {lname || ''}
                                    <span>{displayRelationship && `(${displayRelationship})`}</span>
                                </span>
                                <IconButton
                                    className={classes.moreIconButton}
                                    onClick={
                                        (e) => openMenu(e, signer)
                                    }
                                    size="large">
                                    <MoreVertIcon />
                                </IconButton>
                            </>
                        }
                        secondary={secondaryText(signer)}
                        classes={{
                            primary: classes.primary,
                            secondary: classes.secondary
                        }}
                    />
                </div>
            </ListItem>
        );
    };

    const renderMenuOptions = () => {
        if (!targetSigner || !userSession.userData) {
            return null;
        }
        const canSendReminder = targetSigner.hellosign_status !== 'signed' &&
            docPacket.status !== 'started' && docPacket.type !== 'fill_print';
        const reminderTooltip = canSendReminder ? '' :
            docPacket.type === 'fill_print' ? 'Printed documents do not have email reminders' :
                docPacket.status === 'started' ? 'Finalize packet to send notification email' :
                    'Signer has already signed, no reminder can be sent';

        const canChangeEmail = targetSigner.hellosign_status !== 'signed' &&
            canEditPersonPhoneOrEmail(
                userSession.userData,
                targetSigner.person,
                activeCase.funeral_home_id,
                activeCase.id,
            );
        const editSignerTooltip = targetSigner.hellosign_status === 'signed'
            ? 'Signer has already signed using current email'
            : canChangeEmail ? ''
                : 'Please contact the user to change their own email';

        return (
            <GPopper
                anchorEle={menuAnchorEle}
                className={classes.menu}
                paperClass={classes.paper}
                zIndex={zIndex + 2}
                closePopper={closeMenu}
                hideArrow
            >
                <Tooltip
                    title={reminderTooltip}
                    placement="top"
                >
                    <span className={classes.menuItem}>
                        <MenuItem disabled={!canSendReminder} onClick={
                            e => openReminderPopup(e, targetSigner)
                        }>
                            <AccessAlarmIcon />&nbsp;Send a Reminder
                        </MenuItem>
                    </span>
                </Tooltip>
                <Tooltip
                    title={editSignerTooltip}
                    placement="top"
                >
                    <div>
                        <MenuItem disabled={!canChangeEmail} onClick={handleChangeEmailClick}>
                            <EmailIcon />&nbsp;Change Email Address
                        </MenuItem>
                    </div>
                </Tooltip>
            </GPopper>
        );
    };

    const renderEmailReminder = () => {
        if (!targetSigner || !reminderAnchorEl) {
            return null;
        }
        const lastSentTime = targetSigner.last_reminder_sent_time || docPacket.notification_sent_time;
        return (
            <SendEmailReminderPopover
                zIndex={zIndex + 2}
                targetSigner={targetSigner.person}
                reminderAnchorEl={reminderAnchorEl}
                clickAwayListener={closeReminderPopup}
                sendReminderClick={handleSendReminderClick}
                lastSentTime={lastSentTime}
                docPacketStatus={docPacket.status}
            />
        );
    };

    const renderChangeEmailDialog = () => {
        if (!targetSigner) {
            return;
        }
        const headerMessage = `Change ${targetSigner.person.fname}'s Email`;
        const subHeaderMessage = targetSigner.person.user
            ? `${targetSigner.person.fname} will be reinvited to Gather using new email`
            : '';
        const inputMessage = `Enter ${targetSigner.person.fname}'s new email`;
        const confirmationButtonText = targetSigner.person.user
            ? 'Update Email & Resend Invite'
            : 'Update Email';
        const cancelButtonText = '';
        const currentDetail = <>Current Email: <b>{targetSigner.person.email}</b></>;
        return (
            <ChangeOriginalValueDialog
                header={headerMessage}
                subHeader={subHeaderMessage}
                confirmationButtonText={confirmationButtonText}
                cancelButtonText={cancelButtonText}
                open={isChangeEmailDialogOpen}
                onClose={() => setIsChangeEmailDialogOpen(false)}
                onSave={handleChangeEmailDialogConfirm}
                zIndex={zIndex + 1}
                originalValue={targetSigner.person.email}
                validator={isValidEmail}
                newValueInputTextMessage={inputMessage}
                currentDetail={currentDetail}
            />
        );
    };

    const renderContent = () => {
        return (
            <>
                <div className={classes.upperSection}>
                    <div className={classes.counterSection}>
                        <Typography color="primary" className={classes.packetCount}>
                            {packetSigners.filter((signer) => signer.hellosign_status === 'signed').length}
                        </Typography>
                        <Typography color="primary" className={classes.middleText}>
                            of
                        </Typography>
                        <Typography color="primary" className={classes.packetCount}>
                            {packetSigners.length}
                        </Typography>
                    </div>
                    <div className={classes.signatureStatus}>
                        <Typography color="primary" className={classes.statusText}>
                            Signatures Completed
                        </Typography>
                    </div>
                </div>
                <List className={classes.listContainer}>
                    {packetSigners.map((signer) => renderUserListItem(signer))}
                </List>
                {(canDownloadIncomplete || signedDoc) && (
                    <div className={classes.upperSection}>
                        <GButton
                            isSpinning={isDownloading}
                            variant="contained"
                            color="primary"
                            aria-label="download doc packet"
                            onClick={() =>
                                docPacket.status === 'signed'
                                    ? handleDownload(docPacket, signedDoc) :
                                    handleDownloadInProgressPacket(docPacket)
                            }
                        >
                            <CloudUploadIcon />
                            &nbsp;Download {docPacket.status === 'signed' ? 'Signed' : 'Incomplete'} Packet
                        </GButton>
                    </div>
                )}
            </>
        );
    };

    return (
        <Grid container justifyContent="center" alignItems="center" className={classes.root}>
            {packetLink ?
                <GLink
                    to={packetLink}
                    linkClass={classNames(
                        classes.link,
                        targetSigner && classes.pointerEventsNone
                    )}
                >
                    {renderContent()}
                </GLink>
                : renderContent()
            }
            {renderMenuOptions()}
            {renderHelperPopper()}
            {renderEmailReminder()}
            {renderChangeEmailDialog()}
        </Grid>
    );
};

export default ESignSignatures;