import { Component } from 'react';

import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';

import { openHelperInvitationDialog, openMySettingsDialog } from '../../../actions/Dialog.action';
import { GREEN_COLOR, RED_COLOR } from '../../../constants/colorVariables';
import { getEntityHomeAddress, getFormattedPhoneNumber } from '../../../services';
import { canEditNonSensitivePersonDetails } from '../../../shared/authority/can';
import {
    EntityCaseRole,
    EntityRelationship,
    EntityRelationshipType,
    EntitySummary,
    GatherCaseUX,
    getCaseEntity,
    getCaseEntityById,
    getDisplayRelationshipForPerson,
    UserProfile,
} from '../../../shared/types';
import { joinNameParts } from '../../../shared/utils';
import { GLOBAL_STYLED_PROPS } from '../../../styles';
import SelectHelper, { DCEntityEnum } from '../../assignmentPoppers/SelectHelper';
import { HelperUserSettingsTab } from '../../dialogs/mySettings/HelperUserSettings';
import { setRolodexListDialogOpen } from '../../../actions/Rolodex.action';
import { AppDispatch } from '../../../store';
import withState from '../../common/utilHOC/WithState';
import withGStyles from '../../../styles/WithGStyles';
import DCEntityDetails, { DISPLAY_FIELD_LOOKUP } from './common/DCEntityDetails';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        position: 'relative',
        borderRadius: 12,
        border: `2px solid ${GREEN_COLOR}`,
        minHeight: 162,
        '&$hasNoSelectedEntity': {
            border: `2px dashed ${theme.palette.primary.main}`
        },
        '&$hasMissingField': {
            border: `2px solid ${RED_COLOR}`
        }
    },
    label: {
        position: 'absolute',
        top: '-7%',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: theme.palette.common.white,
        padding: '0 8px'
    },
    field: {
        textAlign: 'left',
        display: 'flex',
        '&$hasNoData': {
            '& $noTextClass': {
                opacity: 0.6,
                fontStyle: 'italic',
                '&:hover': {
                    textDecoration: 'underline !important',
                    opacity: 1
                }
            }
        }
    },
    popoverElement: {
        margin: '8px 0 4px'
    },
    whiteSpaceNoWrap: {
        whiteSpace: 'nowrap'
    },
    noTextClass: {
        textDecoration: 'none !important',
    },
    contactIconButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: 40,
        height: 40,
        '& svg': {
            fontSize: 22
        }
    },
    hasNoData: {},
    hasMissingField: {},
    hasNoSelectedEntity: {}
});

interface Props {
    dispatch: AppDispatch;
    helpers: EntitySummary[];
    selectedCaseEntityId: number | null;
    onEntityChange: (entity: EntitySummary | null) => void;
    activeCase: GatherCaseUX;
    zIndex: number;
    userData: UserProfile | null;
    relationToDeceased: DCEntityEnum;
    fieldsToDisplay: (keyof EntitySummary | 'relationship' | 'full_name')[];
    requiredFields: (keyof EntitySummary | 'relationship' | 'full_name')[];
    isDeathCertificateLocked: boolean;
}

type StyledProps = GLOBAL_STYLED_PROPS & WithStyles<'root' | 'label' | 'hasMissingField' | 'hasNoSelectedEntity'
    | 'hasNoData' | 'field' | 'popoverElement' | 'whiteSpaceNoWrap' | 'noTextClass' | 'contactIconButton'>;

class DCRelatedEntities extends Component<StyledProps & Props> {

    openRolodexSearchDialog = () => {
        const { dispatch } = this.props;
        dispatch(setRolodexListDialogOpen(true));
    };

    canEdit = () => {
        const { userData, isDeathCertificateLocked, activeCase } = this.props;

        const selectedEntity = this.getSelectedEntity();

        if (!userData
            || !selectedEntity
            || isDeathCertificateLocked
            || !canEditNonSensitivePersonDetails(userData, selectedEntity, activeCase.funeral_home_id, activeCase.id)
        ) {
            return false;
        }

        return true;
    };

    onEdit = (
        event: React.MouseEvent<HTMLElement>,
        field: keyof EntitySummary | 'full_name' | 'relationship'
    ) => {
        const { dispatch, zIndex } = this.props;

        const selectedEntity = this.getSelectedEntity();

        if (!selectedEntity) {
            return;
        }

        dispatch(openMySettingsDialog(
            zIndex + 1,
            selectedEntity.entity_id,
            field === 'relationship' ? HelperUserSettingsTab.relationship : HelperUserSettingsTab.details
        ));
    };

    getFieldValue = (field: keyof EntitySummary | 'full_name' | 'relationship') => {
        const selectedEntity = this.getSelectedEntity();

        const fieldValue = selectedEntity && selectedEntity[field];

        let text: string | null = null;
        switch (field) {
            case 'home_address':
                text = getEntityHomeAddress(selectedEntity);
                break;
            case 'full_name':
                text = selectedEntity && joinNameParts(selectedEntity);
                break;
            case 'phone':
                text = selectedEntity && getFormattedPhoneNumber(selectedEntity.phone);
                break;
            case 'relationship':
                text = this.getRelationship();
                break;
            default:
                text = typeof fieldValue === 'string'
                    ? fieldValue
                    : null;
        }

        return text;
    };

    render() {
        const {
            onEntityChange,
            activeCase,
            helpers,
            classes,
            relationToDeceased,
            zIndex,
            isDeathCertificateLocked,
            fieldsToDisplay,
            requiredFields,
        } = this.props;

        const hasMissingField = this.hasMissingField();
        const selectedEntity = this.getSelectedEntity();

        const data = selectedEntity
            ? {
                ...selectedEntity,
                relationship: this.getRelationship(),
                full_name: selectedEntity && joinNameParts(selectedEntity) || ''
            }
            : null;

        return (
            <DCEntityDetails
                sx={{
                    minHeight: 'unset',
                    border: 'none',
                }}
                data={data}
                hasMissingField={!!hasMissingField || !selectedEntity}
                label={null}
                fieldsToDisplay={fieldsToDisplay}
                requiredFields={requiredFields}
                valueMapper={this.getFieldValue}
                fieldMapper={(field) => {
                    return field === 'relationship'
                        ? `Relation to ${activeCase.fname}`
                        : typeof DISPLAY_FIELD_LOOKUP[field] === 'string'
                            ? DISPLAY_FIELD_LOOKUP[field]
                            : null;
                }}
                onEdit={this.canEdit() ? this.onEdit : undefined}
            >

                <SelectHelper
                    zIndex={zIndex}
                    setHelper={(e, helper) => onEntityChange(helper)}
                    selectedHelper={selectedEntity}
                    openInvitationDialog={_zIndex => this.openInvitationDialog(_zIndex)}
                    caseFname={activeCase.fname}
                    helpers={helpers}
                    relationToDeceased={relationToDeceased}
                    isDisabled={isDeathCertificateLocked}
                    disabledTooltip={'Death Certificate is locked'}
                    addButtonClass={classes.textTransformNone}
                />
            </DCEntityDetails>
        );
    }

    getSelectedEntity(): EntitySummary | null {
        const { selectedCaseEntityId, helpers } = this.props;
        return selectedCaseEntityId
            && helpers.find((helper) => Boolean(getCaseEntityById(helper, selectedCaseEntityId)))
            || null;
    }

    hasMissingField = () => {
        const { fieldsToDisplay, requiredFields } = this.props;
        const selectedEntity = this.getSelectedEntity();

        if (!selectedEntity) {
            return;
        }

        let hasMissingField = false;
        for (let field of fieldsToDisplay) {
            const isRequired = requiredFields.find(requiredField => requiredField === field);
            if (!isRequired) {
                continue;
            }
            const text: string | null =
                field === 'home_address' && getEntityHomeAddress(selectedEntity)
                || field === 'full_name' && joinNameParts(selectedEntity)
                || field === 'phone' && getFormattedPhoneNumber(selectedEntity.phone)
                || field === 'relationship' && this.getRelationship()
                || selectedEntity[field];
            if (!text) {
                hasMissingField = true;
                break;
            }
        }

        return hasMissingField;
    };

    getRelationship = () => {
        const { activeCase } = this.props;
        const selectedEntity = this.getSelectedEntity();
        const caseEntity = selectedEntity && getCaseEntity(selectedEntity, activeCase.id);
        const relationship = caseEntity && getDisplayRelationshipForPerson(caseEntity);

        return relationship;
    };

    openInvitationDialog = (zIndex: number) => {
        const { dispatch, relationToDeceased, onEntityChange } = this.props;

        let relationshipType: EntityRelationshipType | undefined = undefined;
        let relationship: EntityRelationship | undefined = undefined;

        if (relationToDeceased === DCEntityEnum.Father) {
            relationshipType = EntityRelationshipType.family;
            relationship = EntityRelationship.father;
        } else if (relationToDeceased === DCEntityEnum.Mother) {
            relationshipType = EntityRelationshipType.family;
            relationship = EntityRelationship.mother;
        } else if (relationToDeceased === DCEntityEnum.Spouse) {
            relationshipType = EntityRelationshipType.family;
        }

        dispatch(openHelperInvitationDialog({
            zIndex: zIndex + 1,
            defaultTab: EntityCaseRole.guest,
            relationshipType: relationshipType,
            relationship,
            relationToDeceased,
            onAddedCallback: onEntityChange,
        }));
    };
}

export default withState()(withGStyles(styles)(DCRelatedEntities));