import classNames from 'classnames';

import Grid from '@mui/material/Grid';

import {
    GatherCaseUX,
    EntityRelationshipType,
    LongAddress,
    EntityRelationship,
    GmapsSearchType,
    isLongAddress,
} from '../../shared/types';

import { splitFullName } from '../../shared/utils';

import GmapsSearch, { GmapsSearchAddress } from '../gmapsSearch/GmapsSearch';
import AcquaintanceButtons from '../common/AcquaintanceButtons';
import { DCEntityEnum } from '../assignmentPoppers/SelectHelper';
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from '@mui/material';
import { GStyles } from '../../styles/GStyles';
import { InvitationFormStyles } from './InvitationFormStyles';
import { HelperInvitationDialogContext } from '../../actions/Dialog.action';

interface Props {
    activeCase: GatherCaseUX;
    formType: 'INVITE_ADMIN' | 'INVITE_GUEST';
    setInvitationFormDirty: (isFormDirty: boolean) => void;
    zIndex: number;
    relationToDeceased: DCEntityEnum | null;
    fullName: string;
    homeAddress: LongAddress;
    inviteAttempted: boolean;
    entityIdKey: string | number;
    isSendInvitation: boolean;
    relationshipType: EntityRelationshipType | null;
    relationship: EntityRelationship | null;
    relationshipAlias: string;
    useAddressDescription: boolean;
    isDeceased: boolean;
    context: HelperInvitationDialogContext | null;
    handleSetRelationship: (
        relationshipType: EntityRelationshipType | null,
        relationship: EntityRelationship | null,
        relationshipAlias: string) => void;
    setHomeAddressAndDescription: (homeAddress: LongAddress, useAddressDescription: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    button: {
        padding: '8px 20px',
        marginBottom: 8
    },
    rightSection: {
        width: '100%',
        maxWidth: 352,
        textAlign: 'center',
        padding: '0 16px',
        '@media (min-width: 760px)': {
            minHeight: 400,
        },
        '&$active': {
            minHeight: 372
        }
    },
    acquaintanceContainer: {
        marginTop: 24,
        transition: 'top 600ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        top: 0,
        position: 'relative',
        '&$isDeceased': {
            top: -80,
        },
        '&$active': {
            '@media (min-width: 760px)': {
                marginTop: '8px !important'
            }
        }
    },
    ...InvitationFormStyles(theme),
}), { name: 'InvitationFormRightSection' });

const InvitationFormRightSection = (props: Props) => {

    const {
        activeCase,
        formType,
        setInvitationFormDirty,
        zIndex,
        relationToDeceased,
        fullName,
        homeAddress,
        entityIdKey,
        inviteAttempted,
        isSendInvitation,
        relationshipType,
        relationship,
        relationshipAlias,
        useAddressDescription,
        isDeceased,
        context,
        handleSetRelationship,
        setHomeAddressAndDescription,
    } = props;

    const classes = useStyles();

    const { fname } = splitFullName(fullName);
    const firstName = fname
        || formType === 'INVITE_ADMIN' && 'Admin'
        || relationToDeceased
        || 'Guest';

    const isObituaryAddPerson = context === HelperInvitationDialogContext.addPreceededInDeath
        || context === HelperInvitationDialogContext.addSurvivors;

    const relationError = Boolean(relationToDeceased || isObituaryAddPerson)
        && inviteAttempted
        && ((!Boolean(relationship && relationship.trim().length)
            && relationshipType === EntityRelationshipType.family)
            || (relationshipType === EntityRelationshipType.other
                && Boolean(relationshipAlias.trim().length === 0)));

    return (
        <Grid
            item
            xs={12}
            className={classNames(classes.rightSection, (relationToDeceased || isObituaryAddPerson) && classes.active)}
        >
            {(!relationToDeceased && !isObituaryAddPerson) &&
                <Grid
                    item
                    xs={12}
                    className={classNames(
                        classes.gmapSearch,
                        GStyles.marginAuto,
                    )}
                >
                    <div
                        className={classNames(
                            classes.gmapSearchContainer,
                            !isDeceased && classes.active,
                        )}
                    >
                        <GmapsSearch
                            controlClasses={classes.gmapSearch}
                            textLabel={`${firstName}'s Home Address`}
                            type={GmapsSearchType.longAddress}
                            value={homeAddress}
                            controlSuggestionClasses={classes.controlSuggestionClasses}
                            onSetPlace={(addr: GmapsSearchAddress, useDesc?: boolean) => {
                                if (isLongAddress(addr)) {
                                    setHomeAddressAndDescription(addr, useDesc || false);
                                    setInvitationFormDirty(true);
                                }
                            }}
                            useDescription={useAddressDescription}
                            error={false}
                            required={false}
                            variant="outlined"
                            size="small"
                            zIndex={zIndex + 2}
                        />
                    </div>
                </Grid>
            }
            <Grid
                item
                xs={12}
                className={classNames(
                    isDeceased && (!relationToDeceased && !isObituaryAddPerson) && classes.isDeceased,
                    classes.acquaintanceContainer,
                    (relationToDeceased || isObituaryAddPerson) && GStyles.margin0,
                    (relationToDeceased || isObituaryAddPerson) && classes.active
                )}
            >
                <AcquaintanceButtons
                    key={entityIdKey}
                    caseFName={activeCase.fname}
                    isDeceased={isDeceased}
                    formType={formType}
                    setRelationship={handleSetRelationship}
                    userFName={firstName}
                    saveAttempted={inviteAttempted}
                    isSendInvitation={isSendInvitation}
                    relationshipType={relationshipType || null}
                    relationship={relationship || null}
                    relationshipAlias={relationshipAlias || null}
                    hasError={relationError}
                    buttonsDisabled={isObituaryAddPerson}
                    isRequired={Boolean(relationToDeceased || isObituaryAddPerson)
                        && relationshipType === EntityRelationshipType.family
                    }
                />
            </Grid>
        </Grid>
    );
};

export default InvitationFormRightSection;