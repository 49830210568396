import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { GatherCaseUX } from '../../shared/types';

import { splitFullName } from '../../shared/utils';
import InviteHelpersSVG from '../taskTemplates/svg/InviteHelpersSVG';

import makeStyles from "@mui/styles/makeStyles";
import { Theme } from '@mui/material';
import { GStyles } from '../../styles/GStyles';

interface Props {
    activeCase: GatherCaseUX;
    fullName: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    step2Right: {
        padding: '0 16px',
        maxWidth: 420,
        minHeight: 372,
        '& p': {
            fontSize: 14,
            lineHeight: 1.325
        },
        '@media (min-width: 600px)': {
            padding: '0 30px',
            '& p': {
                fontSize: 16
            }
        },
        '@media (min-width: 760px)': {
            maxWidth: '50%',
        }
    },
    accessDetails: {
        margin: '12px auto',
        maxWidth: 'fit-content'
    },
    step2RightBottomText: {
        fontStyle: 'italic',
        textAlign: 'center',
        marginBottom: 20,
        lineHeight: 1.2,
        '@media (min-width: 400px)': {
            padding: '0 58px'
        },
        '@media (min-width: 600px)': {
            padding: '0 36px'
        },
        '@media (min-width: 760px)': {
            padding: '0 16px',
        },
        '@media (min-width: 960px)': {
            padding: '0 20px'
        }
    },
    inviteSvgGrid: {
        marginTop: 60,
        '@media (min-width: 760px)': {
            margin: 0
        }
    },
}), { name: 'InvitationFormRightSectionForDC' });

const InvitationFormRightSectionForDC = (props: Props) => {
    const { activeCase, fullName } = props;

    const { fname } = splitFullName(fullName);
    const classes = useStyles();

    return (
        <Grid item xs={12} className={classes.step2Right}>
            <Grid item xs={12} className={classes.inviteSvgGrid}>
                <InviteHelpersSVG themeFillClass={GStyles.fillPrimary} />
            </Grid>
            <Grid item xs={12}>
                <Typography color="secondary" component="p" align="center">
                    By sending a secure invite, {fname} will be able to...
                </Typography>
                <Grid item xs={12} className={classes.accessDetails}>
                    <Typography color="secondary" component="p">
                        - Stay informed and feel connected
                    </Typography>
                    <Typography color="secondary" component="p">
                        - View details about {activeCase.fname}'s services
                    </Typography>
                    <Typography color="secondary" component="p">
                        - View any LiveStream information
                    </Typography>
                    <Typography color="secondary" component="p">
                        - Upload their favorite photos
                    </Typography>
                    <Typography color="secondary" component="p">
                        - View {activeCase.fname}'s completed obituary
                    </Typography>
                    {/* To be Added later */}
                    {/* <Typography color="secondary" component="p">
                            - Share condolences and memories
                        </Typography> */}
                </Grid>
                <Typography color="secondary" component="p" className={classes.step2RightBottomText}>
                    Once invited, you can assign {fname} to help with specific tasks, if you’d like.
                </Typography>

            </Grid>
        </Grid>
    );
};

export default InvitationFormRightSectionForDC;