import momentTz from 'moment-timezone';

import {
    StoreState,
    DashboardCasesType,
    MONTHLY_DASHBOARD_CASES,
    RECENT_DASHBOARD_CASES,
    MONTHLY_DASHBOARD_CASES_WITH_ASSIGNEE,
    RECENT_DASHBOARD_CASES_WITH_ASSIGNEE,
    CASE_PREVIEW_STATES,
    UserSession,
    GatherPhoto,
} from '../types';

import { SortEnd } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import {
    getFromAPI,
    putToAPI,
    postToAPI,
    deleteFromAPI,
    patchAPI,
    advancedAPIRequest,
    downloadFromAPI,
    parseJSON,
} from '.';
import { registerAppError, handleException } from './errors';
import {
    GatherCaseRecord,
    GatherCaseUX,
    UserRoles,
    Obituary,
    ServiceDetail,
    ServiceDetailRequest,
    ServiceDetailFieldEnum,
    GatherCaseDashboardUx,
    GatherCaseDashboardRollup,
    GatherCaseDashboardAssignee,
    VitalDates,
    CaseType,
    PaginatedResponse,
    GatherCaseUpdateRequest,
    GatherCaseCreateRequest,
    GatherCaseReportType,
    LoadPrivateCaseResponse,
    GatherCasePreview,
    GatherCaseUXTable,
    CaseTypeSummary,
    GatherCaseDashboardMonthlyRollup,
    UnauthenticatedRememberResponse,
    GatherCaseName,
    CaseOptions,
    CaseOptionsUpdateRequest,
    AuthenticatedRememberResponse,
    WebsiteVendor,
    UserProfile,
    RecentGatherCasesResponse,
    DeathCertificateUpdateRequest,
    RememberPageResponse,
    CaseExportVendor,
    GatherCasePublic,
    ThemeUX,
    SaveDeathCertificateResponse,
    DeathCertificateUpdateInfo,
    TrackingPageResponse,
    OrganizePageResponse,
    UpdateCaseResponse,
    GatherCaseFHUpdateRequest,
    GatherCaseFHUpdateRequestUX,
    PhotoTransformationsType,
    isAlbumEntry,
    GatherCaseDuplicateRequest,
    GatherCaseSummary,
    CaseCheckResponse,
    LoadMoveCaseOptionsResponse,
    GatherCaseMoveRequest,
    DeathCertificatePatchRequest,
    ObituaryRequest,
    GatherCaseNumberUpdateRequest,
    InsurancePolicySummaryUX,
    InsurancePolicyRecord,
    GuestPaymentCaseTotals,
} from '../shared/types';
import { loadCaseHelpers } from './CaseHelper.action';
import { loadCaseTasksWithExistingCaseData } from './task/Task.action';
import { loadTeam } from './Team.action';
import { loadLocations, loadCaseLocations } from './Location.action';
import { loadGiftCasePhotos, setCaseProfilePhotoSaving } from './Photo.action';
import { setAppSnackbar, setSnackbarSuccess } from './AppSnackbar.action';
import {
    IncreaseCaseNoteCountBy1,
    DecreaseCaseNoteCountBy1
} from './Note.action';
import { updateLoginUser } from './UserSession.action';
import { SelfLoginSrcAction } from '../components/family/remember/selfLogin/SelfLoginDialog';
import { canCreateMemory } from '../shared/authority/can';
import { openSelfLoginDialog } from './Dialog.action';
import { log } from '../logger';
import { AppDispatch } from '../store';
import {
    ALLOW_FH_REDIRECT_QUERY_PARAM,
    buildRelativePathURLString,
    redirectPage,
    RouteBuilder,
} from '../services';
import moment from 'moment-timezone';
import { parse } from 'query-string';

const getLoadRecentCasesResource = (params: {
    funeralHomeId: number;
    page: number;
    pageSize: number;
    selectedCaseTypes: CaseType[];
    hideArchived: boolean;
    assigneeId?: number;
    search?: string;
}): string => {
    const { funeralHomeId, page, pageSize, selectedCaseTypes, hideArchived, search, assigneeId } = params;
    const pageQuery = `page=${encodeURIComponent(page.toString())}`;
    const pageSizeQuery = `pageSize=${encodeURIComponent(pageSize.toString())}`;
    const selectedCaseTypesEncodedString =
        `selectedCaseTypesEncodedString=${encodeURIComponent(selectedCaseTypes.join(','))}`;
    let resource =
        `funeralhome/${funeralHomeId}/case/recent?${pageQuery}`
        + `&${pageSizeQuery}`
        + `&${selectedCaseTypesEncodedString}`
        + `&hideArchived=${hideArchived ? 'true' : 'false'}`;
    if (assigneeId) {
        resource += `&assignee=${assigneeId}`;
    }
    if (search) {
        resource += `&search=${search}`;
    }
    return resource;
};

export function loadRecentCasesForFuneralHome(
    funeralHomeId: number,
    pageSize: number,
    assigneeId?: number
) {
    return async (
        dispatch: AppDispatch, getState: () => StoreState
    ): Promise<RecentGatherCasesResponse | null> => {

        dispatch(setDashboardCasesLoading());

        const { casesState } = getState();
        const { selectedCaseTypes } = casesState;

        if (assigneeId) {
            dispatch(setDashboardCasesType({
                type: RECENT_DASHBOARD_CASES_WITH_ASSIGNEE,
                assignee: assigneeId
            }));
        } else {
            dispatch(setDashboardCasesType({
                type: RECENT_DASHBOARD_CASES,
            }));
        }

        const resource = getLoadRecentCasesResource({
            page: 0,
            pageSize,
            selectedCaseTypes,
            funeralHomeId,
            assigneeId,
            hideArchived: true,
        });

        const recentCaseData = await getFromAPI<RecentGatherCasesResponse>(resource, dispatch);
        if (recentCaseData) {
            const { recentCases, totalCaseCount, unattachedPolicies } = recentCaseData;
            dispatch(setDashboardCases(recentCases, unattachedPolicies, 0, funeralHomeId, assigneeId, totalCaseCount));
        } else {
            dispatch(registerAppError('Unable to load recent dashboard cases.'));
            dispatch(setDashboardCasesLoading(false));
        }

        return recentCaseData;
    };
}

export function loadMonthlyCasesForFuneralHome(
    id: number,
    year: number,
    month: number,
    monthAbbv: string,
    pageSize: number,
    assigneeId?: number
) {
    return async (
        dispatch: AppDispatch, getState: () => StoreState
    ): Promise<RecentGatherCasesResponse | null> => {
        dispatch(setDashboardCasesLoading());
        if (assigneeId) {
            dispatch(setDashboardCasesType({
                year,
                month,
                monthAbbv,
                assigneeId,
                type: MONTHLY_DASHBOARD_CASES_WITH_ASSIGNEE,
            }));
        } else {
            dispatch(setDashboardCasesType({
                year,
                month,
                monthAbbv,
                type: MONTHLY_DASHBOARD_CASES,
            }));
        }
        const { casesState } = getState();
        const { selectedCaseTypes } = casesState;

        const pageQuery = 'page=0';
        const pageSizeQuery = `pageSize=${encodeURIComponent(pageSize.toString())}`;
        const yearParam = encodeURIComponent(year.toString());
        const monthParam = encodeURIComponent(month.toString());
        const selectedCaseTypesEncodedString =
            `selectedCaseTypesEncodedString=${encodeURIComponent(selectedCaseTypes.join(','))}`;
        const resource =
            `funeralhome/${id}/case/month/${yearParam}/${monthParam}?${pageQuery}&${pageSizeQuery}`
            + `&${selectedCaseTypesEncodedString}&hideArchived=false`;

        const cases = await getFromAPI<RecentGatherCasesResponse | null>(
            assigneeId ? resource + `&assignee=${assigneeId}` : resource,
            dispatch
        );
        if (cases) {
            const { recentCases, totalCaseCount, unattachedPolicies } = cases;
            dispatch(setDashboardCases(recentCases, unattachedPolicies, 0, id, assigneeId, totalCaseCount));
        } else {
            dispatch(registerAppError('Unable to load recent dashboard cases.'));
            dispatch(setDashboardCasesLoading(false));
        }

        return cases;
    };
}

export function searchDashboardCases(id: number, pageSize: number, increasePage: boolean, searchValue: string) {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<RecentGatherCasesResponse | null> => {
        const { casesState } = getState();
        const {
            dashboardCasesPage,
            dashboardCasesSelectedAssignee,
            selectedCaseTypes
        } = casesState;

        const page = (dashboardCasesPage !== null && increasePage) ? dashboardCasesPage + 1 : 0;

        if (selectedCaseTypes.length === 0) {
            dispatch(setDashboardCasesAssignees([]));
            dispatch(setDashboardCasesAssigneesLoading(false));
        }

        dispatch(setDashboardCasesLoading(true));

        const resource = getLoadRecentCasesResource({
            funeralHomeId: id,
            page,
            pageSize,
            selectedCaseTypes,
            hideArchived: false,
            search: searchValue,
        });
        const caseData = await getFromAPI<RecentGatherCasesResponse>(resource, dispatch);
        if (caseData) {
            const { totalCaseCount, recentCases, unattachedPolicies } = caseData;
            dispatch(setDashboardCases(
                recentCases,
                unattachedPolicies,
                page,
                id,
                dashboardCasesSelectedAssignee || undefined,
                totalCaseCount
            ));
        } else {
            dispatch(registerAppError('Unable to load recent dashboard cases.'));
            dispatch(setDashboardCasesLoading(false));
        }

        return caseData;
    };
}

export function loadMoreDashboardCases(id: number, pageSize: number) {
    return async (dispatch: AppDispatch, getState: () => StoreState):
        Promise<RecentGatherCasesResponse | null> => {
        let resource: string;

        const { casesState } = getState();
        const {
            dashboardCasesPage,
            dashboardCasesType,
            dashboardCasesSelectedAssignee,
            selectedCaseTypes
        } = casesState;

        const page = dashboardCasesPage !== null ? dashboardCasesPage + 1 : 0;

        const pageQuery = `page=${encodeURIComponent(page.toString())}`;
        const pageSizeQuery = `pageSize=${encodeURIComponent(pageSize.toString())}`;

        if (selectedCaseTypes.length === 0) {
            dispatch(setDashboardCasesAssignees([]));
            dispatch(setDashboardCasesAssigneesLoading(false));
        }
        const selectedCaseTypesEncodedString =
            `selectedCaseTypesEncodedString=${encodeURIComponent(selectedCaseTypes.join(','))}`;

        dispatch(setDashboardCasesLoading(true));

        switch (dashboardCasesType.type) {
            case RECENT_DASHBOARD_CASES:
                resource = getLoadRecentCasesResource({
                    funeralHomeId: id,
                    page,
                    pageSize,
                    selectedCaseTypes,
                    hideArchived: true,
                });
                break;
            case RECENT_DASHBOARD_CASES_WITH_ASSIGNEE:
                resource = getLoadRecentCasesResource({
                    funeralHomeId: id,
                    page,
                    pageSize,
                    selectedCaseTypes,
                    hideArchived: true,
                    assigneeId: dashboardCasesType.assignee,
                });
                break;
            case MONTHLY_DASHBOARD_CASES:
                const { month, year } = dashboardCasesType;
                const yearParam = encodeURIComponent(year.toString());
                const monthParam = encodeURIComponent(month.toString());

                resource = `funeralhome/${id}/case/month/${yearParam}/${monthParam}?${pageQuery}&${pageSizeQuery}`
                    + `&${selectedCaseTypesEncodedString}`;
                break;
            case MONTHLY_DASHBOARD_CASES_WITH_ASSIGNEE:
                const yParam = encodeURIComponent(dashboardCasesType.year.toString());
                const mParam = encodeURIComponent(dashboardCasesType.month.toString());
                const assigneeIdParam = `assignee=${dashboardCasesType.assigneeId}`;

                resource =
                    `funeralhome/${id}/case/month/`
                    + `${yParam}/${mParam}?${pageQuery}&${pageSizeQuery}&${assigneeIdParam}`
                    + `&${selectedCaseTypesEncodedString}`;
                break;
            default:
                log.warn('Somehow have an unsupported type of dashboard case type', { dashboardCasesType });
                resource = getLoadRecentCasesResource({
                    funeralHomeId: id,
                    page,
                    pageSize,
                    selectedCaseTypes,
                    hideArchived: false,
                });
                break;
        }

        const caseData = await getFromAPI<RecentGatherCasesResponse | null>(resource, dispatch);
        if (caseData) {
            const { totalCaseCount, recentCases, unattachedPolicies } = caseData;
            dispatch(setDashboardCases(
                recentCases,
                unattachedPolicies,
                page,
                id,
                dashboardCasesSelectedAssignee || undefined,
                totalCaseCount
            ));
        } else {
            dispatch(registerAppError('Unable to load recent dashboard cases.'));
            dispatch(setDashboardCasesLoading(false));
        }

        return caseData;
    };
}

export function loadAssigneeDistribution(id: number) {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<GatherCaseDashboardAssignee[] | null> => {
        dispatch(setDashboardCasesAssigneesLoading());
        const { casesState } = getState();
        const { selectedCaseTypes } = casesState;
        if (selectedCaseTypes.length === 0) {
            dispatch(setDashboardCasesAssignees([]));
            dispatch(setDashboardCasesAssigneesLoading(false));
        }
        const selectedCaseTypesEncodedString =
            `selectedCaseTypesEncodedString=${encodeURIComponent(selectedCaseTypes.join(','))}`;
        const resource = `funeralhome/${id}/case/assignees`
            + `?${selectedCaseTypesEncodedString}`;

        const assignees = await getFromAPI<GatherCaseDashboardAssignee[] | null>(resource, dispatch);
        if (assignees) {
            dispatch(setDashboardCasesAssignees(assignees));
        } else {
            dispatch(registerAppError('Unable to load recent dashboard case assignees.'));
            dispatch(setDashboardCasesAssigneesLoading(false));
        }

        return assignees;
    };
}

export function loadAssigneeDistrbutionByMonth(id: number, year: number, month: number) {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<GatherCaseDashboardAssignee[] | null> => {
        dispatch(setDashboardCasesAssigneesLoading());
        const yearParam = encodeURIComponent(year.toString());
        const monthParam = encodeURIComponent(month.toString());

        const { casesState } = getState();
        const { selectedCaseTypes } = casesState;
        if (selectedCaseTypes.length === 0) {
            dispatch(setDashboardCasesAssignees([]));
            dispatch(setDashboardCasesAssigneesLoading(false));
        }
        const selectedCaseTypesEncodedString =
            `selectedCaseTypesEncodedString=${encodeURIComponent(selectedCaseTypes.join(','))}`;

        const resource = `funeralhome/${id}/case/assignees/month/${yearParam}/${monthParam}`
            + `?${selectedCaseTypesEncodedString}`;

        const assignees = await getFromAPI<GatherCaseDashboardAssignee[] | null>(resource, dispatch);

        if (assignees) {
            dispatch(setDashboardCasesAssignees(assignees));
        } else {
            dispatch(registerAppError('Unable to load recent dashboard case assignees.'));
            dispatch(setDashboardCasesAssigneesLoading(false));
        }

        return assignees;
    };
}

export function loadCasesRollupForFuneralHome(id: number) {
    return async (
        dispatch: AppDispatch, getState: () => StoreState
    ): Promise<GatherCaseDashboardMonthlyRollup[] | null> => {
        dispatch(setCaseRollupLoading());
        const { casesState } = getState();
        const { selectedCaseTypes } = casesState;
        const queryEncodedString = encodeURIComponent(selectedCaseTypes.join(','));
        const resource = `funeralhome/${id}/case/dashboardrollup?selectedCaseTypesEncodedString=${queryEncodedString}`;
        const rollupResponse = await getFromAPI<GatherCaseDashboardRollup | null>(resource, dispatch);
        if (rollupResponse) {
            dispatch(setDashboardRollup(rollupResponse.monthly_rollup, id));
            dispatch(setDashboardCaseTypeTotals({ ...rollupResponse.case_type_summary }));
        } else {
            dispatch(registerAppError('Unable to load dashboard rollup'));
            dispatch(setCaseRollupLoading(false));
            dispatch(setDashboardCaseTypeTotals({
                [CaseType.at_need]: 0,
                [CaseType.pre_need]: 0,
                [CaseType.trade]: 0,
                [CaseType.one_off]: 0,
            }));
        }
        return rollupResponse ? rollupResponse.monthly_rollup : [];
    };
}

export const ADD_SELECTED_CASE_TYPE = 'ADD_SELECTED_CASE_TYPE';
export type ADD_SELECTED_CASE_TYPE = typeof ADD_SELECTED_CASE_TYPE;
interface AddSelectedCaseType {
    type: ADD_SELECTED_CASE_TYPE;
    caseType: CaseType;
}

export function addSelectedCaseType(caseType: CaseType): AddSelectedCaseType {
    localStorage.setItem(`${caseType}`, 'true');

    return {
        type: ADD_SELECTED_CASE_TYPE,
        caseType,
    };
}

export const DELETE_SELECTED_CASE_TYPE = 'DELETE_SELECTED_CASE_TYPE';
export type DELETE_SELECTED_CASE_TYPE = typeof DELETE_SELECTED_CASE_TYPE;
interface DeleteSelectedCaseType {
    type: DELETE_SELECTED_CASE_TYPE;
    caseType: CaseType;
}

export function deleteSelectedCaseType(caseType: CaseType): DeleteSelectedCaseType {
    localStorage.setItem(`${caseType}`, 'false');

    return {
        type: DELETE_SELECTED_CASE_TYPE,
        caseType,
    };
}

export function getSelectedCaseTypesFromLocalStorage(): CaseType[] {
    const atNeed = localStorage.getItem(`${CaseType.at_need}`);
    const preNeed = localStorage.getItem(`${CaseType.pre_need}`);
    const trade = localStorage.getItem(`${CaseType.trade}`);
    const oneOff = localStorage.getItem(`${CaseType.one_off}`);

    let selectedCaseTypes: CaseType[] = [];
    if (atNeed !== 'false') {
        selectedCaseTypes.push(CaseType.at_need);
    }
    if (preNeed !== 'false') {
        selectedCaseTypes.push(CaseType.pre_need);
    }
    if (trade !== 'false') {
        selectedCaseTypes.push(CaseType.trade);
    }
    if (oneOff !== 'false') {
        selectedCaseTypes.push(CaseType.one_off);
    }
    return selectedCaseTypes;
}

export const REMEMBER_THEME_CHANGED = 'GATHER_CASE_THEME_CHANGED';

interface RememberThemeChanged {
    type: typeof REMEMBER_THEME_CHANGED;
    theme: ThemeUX | null;
}
function rememberThemeChanged(theme: ThemeUX | null): RememberThemeChanged {
    return {
        type: REMEMBER_THEME_CHANGED,
        theme
    };
}

export const SET_DASHBOARD_CASES_LOADING = 'SET_DASHBOARD_CASES_LOADING';
export type SET_DASHBOARD_CASES_LOADING = typeof SET_DASHBOARD_CASES_LOADING;

interface SetDashboardCasesLoading {
    type: SET_DASHBOARD_CASES_LOADING;
    isLoading: boolean;
}

export function setDashboardCasesLoading(isLoading: boolean = true): SetDashboardCasesLoading {
    return {
        isLoading,
        type: SET_DASHBOARD_CASES_LOADING,
    };
}

export const SET_CASE_ROLLUP_LOADING = 'SET_CASE_ROLLUP_LOADING';
export type SET_CASE_ROLLUP_LOADING = typeof SET_CASE_ROLLUP_LOADING;

interface SetCaseRollupLoading {
    type: SET_CASE_ROLLUP_LOADING;
    isLoading: boolean;
}

export function setCaseRollupLoading(isLoading: boolean = true): SetCaseRollupLoading {
    return {
        isLoading,
        type: SET_CASE_ROLLUP_LOADING
    };
}

export const SET_DASHBOARD_CASES_ASSIGNEES_LOADING = 'SET_DASHBOARD_CASES_ASSIGNEES_LOADING';
export type SET_DASHBOARD_CASES_ASSIGNEES_LOADING = typeof SET_DASHBOARD_CASES_ASSIGNEES_LOADING;

interface SetDashboardCasesAssigneesLoading {
    type: SET_DASHBOARD_CASES_ASSIGNEES_LOADING;
    isLoading: boolean;
}

export function setDashboardCasesAssigneesLoading(isLoading: boolean = true): SetDashboardCasesAssigneesLoading {
    return {
        isLoading,
        type: SET_DASHBOARD_CASES_ASSIGNEES_LOADING,
    };
}

export const SET_DASHBOARD_CASES = 'SET_DASHBOARD_CASES';
export type SET_DASHBOARD_CASES = typeof SET_DASHBOARD_CASES;

interface SetDashboardCases {
    type: SET_DASHBOARD_CASES;
    dashboardCases: GatherCaseDashboardUx[];
    dashboardPolicies: InsurancePolicySummaryUX[];
    dashboardCasesPage: number;
    id: number;
    assignee?: number;
    totalCaseCount: number | null;
}

export function setDashboardCases(
    dashboardCases: GatherCaseDashboardUx[],
    dashboardPolicies: InsurancePolicySummaryUX[],
    dashboardCasesPage: number,
    id: number,
    assignee?: number,
    totalCaseCount: number | null = null,
): SetDashboardCases {
    return {
        type: SET_DASHBOARD_CASES,
        dashboardCases,
        dashboardPolicies,
        dashboardCasesPage,
        id,
        assignee,
        totalCaseCount
    };
}

export const SET_DASHBOARD_CASE_TYPE_TOTALS = 'SET_DASHBOARD_CASE_TYPE_TOTALS';
export type SET_DASHBOARD_CASE_TYPE_TOTALS = typeof SET_DASHBOARD_CASE_TYPE_TOTALS;

interface SetDashboardCaseTypeTotals {
    type: SET_DASHBOARD_CASE_TYPE_TOTALS;
    dashboardCaseTypeTotals: CaseTypeSummary;
}

export function setDashboardCaseTypeTotals(
    dashboardCaseTypeTotals: CaseTypeSummary
): SetDashboardCaseTypeTotals {
    return {
        dashboardCaseTypeTotals,
        type: SET_DASHBOARD_CASE_TYPE_TOTALS
    };
}

export const SET_DASHBOARD_CASES_ASSIGNEES = 'SET_DASHBOARD_CASES_ASSIGNEES';
export type SET_DASHBOARD_CASES_ASSIGNEES = typeof SET_DASHBOARD_CASES_ASSIGNEES;

interface SetDashboardCasesAssignees {
    type: SET_DASHBOARD_CASES_ASSIGNEES;
    dashboardCasesAssigneesList: GatherCaseDashboardAssignee[];
}

export function setDashboardCasesAssignees(
    dashboardCasesAssigneesList: GatherCaseDashboardAssignee[]
): SetDashboardCasesAssignees {
    return {
        dashboardCasesAssigneesList,
        type: SET_DASHBOARD_CASES_ASSIGNEES
    };
}

export const SELECT_DASHBOARD_CASES_ASSIGNEE = 'SELECT_DASHBOARD_CASES_ASSIGNEE';
export type SELECT_DASHBOARD_CASES_ASSIGNEE = typeof SELECT_DASHBOARD_CASES_ASSIGNEE;

interface SelectDashboardCasesAssignee {
    type: SELECT_DASHBOARD_CASES_ASSIGNEE;
    dashboardCasesSelectedAssignee: number | null;
}

export function selectDashboardCasesAssignee(
    dashboardCasesSelectedAssignee: number | null
): SelectDashboardCasesAssignee {
    return {
        dashboardCasesSelectedAssignee,
        type: SELECT_DASHBOARD_CASES_ASSIGNEE
    };
}

export const SET_DASHBOARD_CASES_TYPE = 'SET_DASHBOARD_CASES_TYPE';
export type SET_DASHBOARD_CASES_TYPE = typeof SET_DASHBOARD_CASES_TYPE;

interface SetDashboardCasesType {
    type: SET_DASHBOARD_CASES_TYPE;
    dashboardCasesType: DashboardCasesType;
}

export function setDashboardCasesType(dashboardCasesType: DashboardCasesType): SetDashboardCasesType {
    return {
        dashboardCasesType,
        type: SET_DASHBOARD_CASES_TYPE
    };
}

export const SET_DASHBOARD_ROLLUP = 'SET_DASHBOARD_ROLLUP';
export type SET_DASHBOARD_ROLLUP = typeof SET_DASHBOARD_ROLLUP;

interface SetDashboardRollup {
    type: SET_DASHBOARD_ROLLUP;
    dashboardCaseMonthlyRollup: GatherCaseDashboardMonthlyRollup[];
    id: number;
}

export function setDashboardRollup(
    dashboardCaseMonthlyRollup: GatherCaseDashboardMonthlyRollup[],
    id: number
): SetDashboardRollup {
    return {
        type: SET_DASHBOARD_ROLLUP,
        dashboardCaseMonthlyRollup,
        id
    };
}

export const SET_CASE_SAVING = 'SET_CASE_SAVING';
export type SET_CASE_SAVING = typeof SET_CASE_SAVING;

interface SetCaseSaving {
    type: SET_CASE_SAVING;
    isSaving: boolean;
}

export function setCaseSaving(isSaving: boolean): SetCaseSaving {
    return {
        type: SET_CASE_SAVING,
        isSaving,
    };
}

export const CASE_CREATED = 'CASE_CREATED';

interface CaseCreated {
    type: typeof CASE_CREATED;
    gatherCase: GatherCaseUX;
}

function caseCreated(gatherCase: GatherCaseUX): CaseCreated {
    return {
        type: CASE_CREATED,
        gatherCase,
    };
}

export function checkForDuplicates(params: {caseToCheck: GatherCaseDuplicateRequest; funeralHomeId: number}) {
    return async (dispatch: AppDispatch): Promise<GatherCaseSummary[] | null> => {
        const { caseToCheck, funeralHomeId } = params;
        // Validate the object before sending to the server :)
        try {
            GatherCaseDuplicateRequest.fromRequest(caseToCheck);
            GatherCaseRecord.trimNames(caseToCheck);
        } catch (ex) {
            log.warn('Failed to validate GatherCaseDuplicateRequest:', { caseToCheck, ex, funeralHomeId });
            return null;
        }
        const cases = await postToAPI<GatherCaseSummary[]>(
            `funeralhome/${funeralHomeId}/case/checkduplicate`,
            {
                case: caseToCheck,
            },
            dispatch,
        );
        if (cases) {
            return cases;
        } else {
            dispatch(registerAppError('Unable to check for duplicates.'));
            return null;
        }
    };
}

export function findPolicyMatchesForCase(params: { fname: string; lname: string; funeralHomeId: number }) {
    return async (dispatch: AppDispatch): Promise<InsurancePolicyRecord[] | null> => {
        const { fname, lname, funeralHomeId } = params;

        const query = `fname=${encodeURIComponent(fname)}&lname=${encodeURIComponent(lname)}`;

        const policies = await getFromAPI<InsurancePolicyRecord[]>(
            `funeralhome/${funeralHomeId}/case/policymatch?${query}`, dispatch);
        if (policies) {
            return policies;
        } else {
            dispatch(registerAppError('Unable to find policy matches.'));
            return null;
        }
    };

};

export function createNewCase(caseToCreate: GatherCaseCreateRequest, funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<GatherCaseUX | null> => {
        // Validate the object before sending to the server :)
        try {
            GatherCaseCreateRequest.fromRequest(caseToCreate);
            GatherCaseRecord.trimNames(caseToCreate);
        } catch (ex) {
            log.warn('Failed to validate GatherCaseCreateRequest:', { caseToCreate, ex });
            return null;
        }
        dispatch(setCaseSaving(true));
        const newCase = await postToAPI<GatherCaseUX>(
            `funeralhome/${funeralHomeId}/case/`,
            {
                case: caseToCreate,
                defaultTimezone: momentTz.tz.guess(),
            },
            dispatch,
        );
        dispatch(setCaseSaving(false));
        if (newCase) {
            dispatch(caseCreated(newCase));
            dispatch(setAppSnackbar(`${newCase.fname}'s Case successfully created`, 'success'));
            return newCase;
        } else {
            dispatch(registerAppError('Unable to save new case.'));
            return null;
        }
    };
}

export function createOneOffCase(name: string | null, funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<GatherCaseUX | null> => {

        dispatch(setCaseSaving(true));
        const newCase = await postToAPI<GatherCaseUX>(
            `funeralhome/${funeralHomeId}/case/one-off`,
            {
                name,
            },
            dispatch,
        );
        dispatch(setCaseSaving(false));
        if (newCase) {
            dispatch(caseCreated(newCase));
            return newCase;
        } else {
            dispatch(registerAppError('Unable to setup one-off transaction.'));
            return null;
        }
    };
}

export const SET_ONE_OFF_PRODUCT_SELECTION_DIALOG_OPEN = 'SET_ONE_OFF_PRODUCT_SELECTION_DIALOG_OPEN';

interface SetOneOffProductSelectionDialogOpen {
    type: typeof SET_ONE_OFF_PRODUCT_SELECTION_DIALOG_OPEN;
    isOpen: boolean;
}
export function setOneOffProductSelectionDialogOpen(
    isOpen: boolean,
): SetOneOffProductSelectionDialogOpen {
    return {
        type: SET_ONE_OFF_PRODUCT_SELECTION_DIALOG_OPEN,
        isOpen,
    };
}

export const REMOVE_CASE = 'REMOVE_CASE';

interface RemoveCase {
    type: typeof REMOVE_CASE;
    caseUuid: string;
}

export function removeCase(caseUuid: string): RemoveCase {
    return {
        type: REMOVE_CASE,
        caseUuid,
    };
}

export const UPDATING_CASE = 'UPDATING_CASE';

interface UpdatingCase {
    type: typeof UPDATING_CASE;
    caseUuid: string;
    changes: Partial<GatherCaseUX>;
}

function updatingCase(caseUuid: string, changes: Partial<GatherCaseUX>): UpdatingCase {
    return {
        type: UPDATING_CASE,
        caseUuid,
        changes,
    };
}

export const UPDATING_CASE_NUMBER = 'UPDATING_CASE_NUMBER';

interface UpdatingCaseNumber {
    type: typeof UPDATING_CASE_NUMBER;
    caseUuid: string;
    caseNumber: string;
}

function updatingCaseNumber(caseUuid: string, caseNumber: string): UpdatingCaseNumber {
    return {
        type: UPDATING_CASE_NUMBER,
        caseUuid,
        caseNumber,
    };
}

export const CASE_NUMBER_UPDATED = 'CASE_NUMBER_UPDATED';
interface CaseNumberUpdated {
    type: typeof CASE_NUMBER_UPDATED;
    gatherCase: GatherCaseUX;
}

export function caseNumberUpdated(params: { gatherCase: GatherCaseUX }): CaseNumberUpdated {
    return {
        type: CASE_NUMBER_UPDATED,
        gatherCase: params.gatherCase,
    };
}

export const CASE_UPDATED = 'CASE_UPDATED';

interface CaseUpdated extends UpdateCaseResponse {
    type: typeof CASE_UPDATED;
}

export function caseUpdated(response: UpdateCaseResponse): CaseUpdated {
    return {
        type: CASE_UPDATED,
        ...response,
    };
}

export const UPDATING_CASE_FAILED = 'UPDATING_CASE_FAILED';

interface UpdatingCaseFailed {
    type: typeof UPDATING_CASE_FAILED;
}

function updatingCaseFailed(): UpdatingCaseFailed {
    return {
        type: UPDATING_CASE_FAILED,
    };
}

export enum UpdateCaseFailReason {
    caseNameUsed = 'caseNameUsed',
}

export type UpdateCaseParameters = Parameters<typeof updateCase>;
export function updateCase(caseUuid: string, caseChanges: GatherCaseUpdateRequest) {
    return async (dispatch: AppDispatch): Promise<GatherCaseUX | UpdateCaseFailReason | null> => {

        try {
            GatherCaseUpdateRequest.fromRequest(caseChanges);
            GatherCaseRecord.trimNames(caseChanges);
        } catch (ex) {
            log.warn('Failed to validate GatherCaseUpdateRequest', { caseChanges, ex });
            return null;
        }

        dispatch(updatingCase(caseUuid, caseChanges));
        const path = `api/case/${caseUuid}`;
        const response = await advancedAPIRequest(path, 'PATCH', { case: caseChanges }, dispatch);
        if (response) {
            if (response.ok) {
                const body: UpdateCaseResponse = await parseJSON(response);
                dispatch(caseUpdated(body));
                return body.gatherCase;
            } else if (response.status === 409) {
                dispatch(registerAppError('Case URL has already been used.'));

                return UpdateCaseFailReason.caseNameUsed;
            }
        }

        dispatch(updatingCaseFailed());
        dispatch(registerAppError('Unable to update case.'));
        return null;
    };
}

export function updateCaseForFuneralHome(params: {
    funeralHomeId: number;
    caseUuid: string;
    caseChanges: GatherCaseFHUpdateRequestUX;
}) {
    return async (dispatch: AppDispatch): Promise<GatherCaseUX | null> => {
        const { funeralHomeId, caseUuid, caseChanges } = params;
        const { assignee, workflow, ...otherChanges } = caseChanges;
        const reduxUpdates: Partial<GatherCaseUX> = { ...otherChanges };
        const caseReq: GatherCaseFHUpdateRequest = {
            ...otherChanges,
        };
        if (assignee && assignee.user_id !== null) {
            caseReq.assignee_id = assignee.user_id;
            reduxUpdates.assignee = assignee;
        }
        if (workflow) {
            caseReq.workflow_id = workflow.id;
            reduxUpdates.workflow_id = workflow.id;
            reduxUpdates.workflow_name = workflow.name;
        }

        try {
            GatherCaseFHUpdateRequest.fromRequest(caseReq);
        } catch (ex) {
            log.warn('Failed to validate GatherCaseFHUpdateRequest', { caseReq, ex });
            return null;
        }

        dispatch(updatingCase(caseUuid, reduxUpdates));
        const route = `funeralhome/${funeralHomeId}/case/${caseUuid}`;
        const updatedCase = await patchAPI<GatherCaseUX>(route, { case: caseReq }, dispatch);

        if (!updatedCase) {
            dispatch(registerAppError('Unable to update case.'));
            dispatch(updatingCaseFailed());
            return null;
        }
        dispatch(caseUpdated({ gatherCase: updatedCase }));
        return updatedCase;
    };
}

export function updateCaseNumber(caseUuid: string, caseNumber: string) {
    return async (dispatch: AppDispatch): Promise<GatherCaseUX | null> => {
        try {
            GatherCaseNumberUpdateRequest.fromRequest({ caseNumber });

        } catch (ex) {
            log.warn('Failed to validate GatherCaseNumberUpdateRequest', { caseNumber, ex });
            return null;
        }
        dispatch(updatingCaseNumber(caseUuid, caseNumber));
        const updatedCase = await patchAPI<GatherCaseUX>(`api/case/${caseUuid}/casenumber`, { caseNumber }, dispatch);
        if (!updatedCase) {
            dispatch(registerAppError('Unable to update case number. Please check to make sure it is unique.'));
            dispatch(updatingCaseFailed());
            return null;
        }
        dispatch(caseNumberUpdated({ gatherCase: updatedCase }));
        return updatedCase;

    };
}

export function updateCaseOptions(caseUuid: string, options: CaseOptionsUpdateRequest) {
    return async (dispatch: AppDispatch): Promise<GatherCaseUX | UpdateCaseFailReason | null> => {
        try {
            CaseOptionsUpdateRequest.fromRequest(options);
            GatherCaseRecord.trimNames(options);
        } catch (ex) {
            log.warn('Failed to validate partial CaseOptionsUpdateRequest', { options, ex });
            return null;
        }

        dispatch(updatingCase(caseUuid, options));
        const path = `api/case/${caseUuid}/options`;
        const response = await advancedAPIRequest(path, 'PATCH', { options }, dispatch);
        if (response) {
            if (response.ok) {
                const updatedCase: GatherCaseUX = await parseJSON(response);
                dispatch(caseUpdated({ gatherCase: updatedCase }));
                return updatedCase;
            } else if (response.status === 409) {
                dispatch(registerAppError('Case URL has already been used.'));
                return UpdateCaseFailReason.caseNameUsed;
            }
        }

        dispatch(updatingCaseFailed());
        dispatch(registerAppError('Unable to update case.'));
        return null;
    };
}

export function updateVitalDates(caseUuid: string, vitalDates: VitalDates) {
    return async (dispatch: AppDispatch): Promise<GatherCaseUX | null> => {
        dispatch(updatingCase(caseUuid, { ...vitalDates }));
        const updatedCase = await patchAPI<GatherCaseUX>(
            `api/case/${caseUuid}/vitaldates`,
            { vitalDates },
            dispatch
        );
        if (!updatedCase) {
            dispatch(updatingCaseFailed());
            dispatch(registerAppError('Unable to update case.'));
            return null;
        }
        dispatch(caseUpdated({ gatherCase: updatedCase }));
        return updatedCase;
    };
}

export function deleteCase(caseUuid: string) {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<GatherCaseUX | null> => {

        const { userData } = getState().userSession;
        if (!userData) {
            return null;
        }

        const isGOM = UserRoles.isGOMUser(userData);
        dispatch(updatingCase(caseUuid, {}));

        const updatedCase = await deleteFromAPI<GatherCaseUX>(`api/case/${caseUuid}`, dispatch);
        if (!updatedCase) {
            dispatch(updatingCaseFailed());
            dispatch(registerAppError(
                'Unable to delete case. Cases with recorded financial transactions may not be deleted.'
            ));
        } else if (isGOM) {
            dispatch(caseUpdated({ gatherCase: updatedCase }));
        } else {
            dispatch(removeCase(caseUuid));
        }
        return updatedCase;
    };
}

export function restoreCase(caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<GatherCaseUX | null> => {

        dispatch(updatingCase(caseUuid, { deleted_time: null }));

        const updatedCase = await postToAPI<GatherCaseUX>(`api/case/${caseUuid}/restore`, {}, dispatch);
        if (!updatedCase) {
            dispatch(updatingCaseFailed());
            dispatch(registerAppError('Unable to restore case.'));
            return null;
        }
        dispatch(caseUpdated({ gatherCase: updatedCase }));
        return updatedCase;
    };
}

export function updateCaseCoverPhotos(
    desktopPhotoId: number | null | undefined,
    mobilePhotoId: number | null | undefined,
    desktopTransformations: PhotoTransformationsType | undefined,
    mobileTransformations: PhotoTransformationsType | undefined,
    caseUuid: string,
) {
    return async (dispatch: AppDispatch): Promise<GatherCaseUX | null> => {
        dispatch(updatingCase(caseUuid, {}));

        const photoViewURL = `api/case/${caseUuid}/photo/coverphotoview`;
        const updatedCase = await postToAPI<GatherCaseUX>(
            photoViewURL,
            {
                desktopPhotoId,
                mobilePhotoId,
                desktopTransformations,
                mobileTransformations
            },
            dispatch
        );

        if (updatedCase) {
            dispatch(caseUpdated({ gatherCase: updatedCase }));
            return updatedCase;
        } else {
            dispatch(updatingCaseFailed());
            dispatch(registerAppError('Unable to set cover photo.'));
        }
        return null;
    };
}

export function setCaseProfilePhoto(
    gatherPhoto: GatherPhoto,
    caseUuid: string,
    transformations: PhotoTransformationsType,
) {
    return async (dispatch: AppDispatch): Promise<GatherCaseUX | null> => {
        if (!gatherPhoto.photo || !gatherPhoto.photo.id) {
            return null;
        }
        let result: GatherCaseUX | null = null;
        dispatch(updatingCase(caseUuid, {}));
        dispatch(setCaseProfilePhotoSaving(true));

        const photoId = isAlbumEntry(gatherPhoto.photo) ? gatherPhoto.photo.photo_id : gatherPhoto.photo.id;
        const photoViewURL = `api/case/${caseUuid}/photo/${photoId}/photo_view`;
        const updatedCase = await postToAPI<GatherCaseUX>(photoViewURL, { transformations }, dispatch);

        if (updatedCase) {
            dispatch(caseUpdated({ gatherCase: updatedCase }));

            // Create an event for pages to listen for
            const event = new Event('gather.case.profile_photo_update', { bubbles: true, cancelable: true });

            document.dispatchEvent(event);
            result = updatedCase;
        } else {
            dispatch(updatingCaseFailed());
            dispatch(registerAppError('Unable to set case profile photo.'));
        }

        dispatch(setCaseProfilePhotoSaving(false));
        return result;
    };
}

function loadCaseRelatedData(gatherCase: GatherCaseUX) {
    return async (dispatch: AppDispatch, getState: () => StoreState) => {

        // TODO: JJT (AKT) all these dispatches should be returned with the case/<case-name> route
        // after they are moved we can remove this dispatch
        // get helpers
        dispatch(loadCaseHelpers(gatherCase.uuid));

        // get tasks
        dispatch(loadCaseTasksWithExistingCaseData(gatherCase));

        // Team
        dispatch(loadTeam(gatherCase.funeral_home_id));

        // get gift photos
        dispatch(loadGiftCasePhotos(gatherCase.uuid));

        const { userSession } = getState();
        const { userData } = userSession;

        if (!userData) {
            return;
        }

        // Load locations, Family User can't edit the event Schedule which require locations
        if (UserRoles.isFHorGOMUser(userData)) {
            dispatch(loadLocations(gatherCase.funeral_home_id));
        } else {
            dispatch(loadCaseLocations(gatherCase.uuid));
        }
    };
}


export const CHECKING_FOR_CASE = 'CHECKING_FOR_CASE';

export interface CheckingForCase {
    type: typeof CHECKING_FOR_CASE;
    caseName: string;
}

function checkingForCase(caseName: string): CheckingForCase {
    return {
        type: CHECKING_FOR_CASE,
        caseName,
    };
}

export const CHECKED_FOR_CASE = 'CHECKED_FOR_CASE';

export interface CheckedForCase {
    type: typeof CHECKED_FOR_CASE;
    caseName: string;
    caseExists: boolean;
    fhKeyWithAccess: null;
}

function checkedForCase(params: Omit<CheckedForCase, 'type' | 'fhKeyWithAccess'>): CheckedForCase {
    return {
        type: CHECKED_FOR_CASE,
        ...params,
        fhKeyWithAccess: null,
    };
}

function checkIfCaseExists(params: {
    caseName: string;
    funeralHomeKey: string;
}) {
    return async (dispatch: AppDispatch): Promise<void> => {
        const { caseName, funeralHomeKey } = params;
        dispatch(checkingForCase(caseName));
        const resource = `app/funeralhome/${funeralHomeKey}/case/${caseName}`;
        const response = await getFromAPI<CaseCheckResponse>(resource, dispatch);
        if (response?.redirectTo) {
            redirectPage(RouteBuilder.FamilyPage(response.redirectTo));
        } else {
            dispatch(checkedForCase({
                caseName,
                caseExists: Boolean(response),
            }));
        }
    };
};

export const LOADING_PRIVATE_CASE = 'LOADING_PRIVATE_CASE';

interface LoadingPrivateCase {
    type: typeof LOADING_PRIVATE_CASE;
    caseName: string;
}

function loadingPrivateCase(caseName: string): LoadingPrivateCase {
    return {
        type: LOADING_PRIVATE_CASE,
        caseName,
    };
}

export const LOADED_PRIVATE_CASE = 'LOADED_PRIVATE_CASE';

interface LoadedPrivateCase {
    type: typeof LOADED_PRIVATE_CASE;
    gatherCase: GatherCaseUX;
}

function loadedPrivateCase(gatherCase: GatherCaseUX): LoadedPrivateCase {
    return {
        type: LOADED_PRIVATE_CASE,
        gatherCase,
    };
}

export const LOAD_PRIVATE_CASE_FAILED = 'LOAD_PRIVATE_CASE_FAILED';

interface LoadPrivateCaseFailed {
    type: typeof LOAD_PRIVATE_CASE_FAILED;
    caseName: string;
    caseExists: boolean;
    fhKeyWithAccess: string | null;
}

function loadPrivateCaseFailed(params: Omit<LoadPrivateCaseFailed, 'type'>): LoadPrivateCaseFailed {
    return {
        type: LOAD_PRIVATE_CASE_FAILED,
        ...params,
    };
}

export function loadPrivateCaseByName(params: {
    caseName: string;
    funeralHomeKey: string;
}) {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<GatherCaseUX | null> => {
        const { caseName, funeralHomeKey } = params;
        const { pathname, search } = window.location;
        const decodedPathname = decodeURI(pathname);
        const queryParams = parse(search);
        const allowFHRedirect = Boolean(queryParams[ALLOW_FH_REDIRECT_QUERY_PARAM]);

        const { userData } = getState().userSession;
        if (!userData) {
            // if the user isn't logged in, check to see if the case exists
            dispatch(checkIfCaseExists(params));
            return null;
        }

        // expect this to be /fh/:funeralHomeKey/family/:caseName
        const familyRouterBasePath = buildRelativePathURLString(RouteBuilder.FamilyPage({
            caseName,
            funeralHomeKey,
        }));
        if (!decodedPathname.startsWith(familyRouterBasePath)) {
            log.warn('Uh oh, we expected this function to only be called from FamilyRouter', {
                decodedPathname,
                params,
                familyRouterBasePath,
            });
        }

        // we are trying to keep track of where the user was trying to go so IF/when we redirect them it is seamless.
        // So we rip off the family base and keep the rest
        // i.e. /fh/cloverdale/family/john-doe/tracking becomes /tracking
        // then we will paste /tracking back on the end of the correct case name
        const rest = decodedPathname.replace(familyRouterBasePath, '');

        dispatch(loadingPrivateCase(caseName));
        const resource = `funeralhome/${funeralHomeKey}/case/${caseName}`;
        const response = await getFromAPI<LoadPrivateCaseResponse>(resource, dispatch);

        const requestedCase = response?.requestedCase;
        const fhKeyWithAccess = response?.fhKeyWithAccess ?? null;
        if (!requestedCase) {
            // if we got a 200 then that means the case exists but this user doesn't have private access for this FH
            if (!allowFHRedirect || !fhKeyWithAccess) {
                // show the 404 page if this isn't a KT tag or the user doesn't have access to an associated FH 
                dispatch(loadPrivateCaseFailed({
                    caseName,
                    caseExists: Boolean(response),
                    fhKeyWithAccess,
                }));
            } else {
                // if user scanned a KT tracker tag and they have access to a FH associated with this case then redirect
                redirectPage(RouteBuilder.FamilyPage({
                    caseName: `${caseName}${rest}`,
                    funeralHomeKey: fhKeyWithAccess,
                }));
            }
            return null;

        } else if (requestedCase.redirect) {
            redirectPage(RouteBuilder.FamilyPage({
                caseName: `${requestedCase.gatherCase.name}${rest}`,
                funeralHomeKey: requestedCase.gatherCase.funeral_home.key,
            }));
            return null;
        } else {
            if (allowFHRedirect) {
                // remove query param from URL if this is a KT scan and we successfully navigate to the case
                const url = new URL(window.location.href);
                url.searchParams.delete(ALLOW_FH_REDIRECT_QUERY_PARAM);
                window.history.pushState({}, '', url);
            }
            dispatch(loadedPrivateCase(requestedCase.gatherCase));
            dispatch(loadCaseRelatedData(requestedCase.gatherCase));
            return requestedCase.gatherCase;
        }
    };
};

export const LOADING_PUBLIC_CASE = 'LOADING_PUBLIC_CASE';

export interface LoadingPublicCase {
    type: typeof LOADING_PUBLIC_CASE;
    caseName: string;
}

function loadingPublicCase(caseName: string): LoadingPublicCase {
    return {
        type: LOADING_PUBLIC_CASE,
        caseName,
    };
}

export const LOADED_PUBLIC_CASE = 'LOADED_PUBLIC_CASE';

export interface LoadedPublicCase extends RememberPageResponse {
    type: typeof LOADED_PUBLIC_CASE;
}

function loadedPublicCase(response: RememberPageResponse): LoadedPublicCase {
    return {
        type: LOADED_PUBLIC_CASE,
        ...response,
    };
}

export const LOAD_PUBLIC_CASE_FAILED = 'LOAD_PUBLIC_CASE_FAILED';

export interface LoadPublicCaseFailed {
    type: typeof LOAD_PUBLIC_CASE_FAILED;
    caseName: string;
}

function loadPublicCaseFailed(caseName: string): LoadPublicCaseFailed {
    return {
        type: LOAD_PUBLIC_CASE_FAILED,
        caseName,
    };
}

function loadUnauthenticatedRememberPage(caseName: string) {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch(loadingPublicCase(caseName));
        const response = await getFromAPI<UnauthenticatedRememberResponse>(`app/remember/${caseName}`, dispatch);
        if (response) {
            const { rememberPage, redirect } = response;
            if (redirect) {
                redirectPage(RouteBuilder.RememberPage(rememberPage.publicCase.name));
                return;
            } else {
                dispatch(loadedPublicCase(rememberPage));
            }
        } else {
            dispatch(loadPublicCaseFailed(caseName));
        }
    };
}

function loadAuthenticatedRememberPage(caseName: string) {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch(loadingPublicCase(caseName));
        const response = await getFromAPI<AuthenticatedRememberResponse>(`api/remember/${caseName}`, dispatch);
        if (response) {
            const { rememberPage, redirect, gatherCase } = response;
            if (redirect) {
                redirectPage(RouteBuilder.RememberPage(rememberPage.publicCase.name));
                return;
            } else {
                dispatch(loadedPublicCase(rememberPage));
                if (gatherCase) {
                    dispatch(loadedPrivateCase(gatherCase));
                    dispatch(loadCaseRelatedData(gatherCase));
                }
            }
        } else {
            dispatch(loadPublicCaseFailed(caseName));
        }
    };
}

export function loadRememberPage(caseName: string) {
    return async (dispatch: AppDispatch, getState: () => StoreState) => {
        const { userData } = getState().userSession;
        if (userData) {
            dispatch(loadAuthenticatedRememberPage(caseName));
        } else {
            dispatch(loadUnauthenticatedRememberPage(caseName));
        }

        return null;
    };
}

export const ORGANIZE_PAGE_LOADED = 'ORGANIZE_PAGE_LOADED';

interface LoadedOrganizePage extends OrganizePageResponse {
    type: typeof ORGANIZE_PAGE_LOADED;
}

function loadedOrganizePage(
    organizePage: OrganizePageResponse,
): LoadedOrganizePage {
    return {
        type: ORGANIZE_PAGE_LOADED,
        ...organizePage
    };
}

export function loadOrganizePage(caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<OrganizePageResponse | null> => {
        const route = `api/case/${caseUuid}/organize`;
        let organizePage = await getFromAPI<OrganizePageResponse>(route, dispatch);
        if (organizePage) {
            dispatch(loadedOrganizePage(organizePage));
            return organizePage;
        } else {
            dispatch(registerAppError('Unable to load case data.'));
            return null;
        }
    };
}

export const TRACKING_PAGE_LOADING = 'TRACKING_PAGE_LOADING';

interface LoadingTrackingPage {
    type: typeof TRACKING_PAGE_LOADING;
}

function loadingTrackingPage(
): LoadingTrackingPage {
    return {
        type: TRACKING_PAGE_LOADING,
    };
}

export const TRACKING_PAGE_LOADED = 'TRACKING_PAGE_LOADED';

interface LoadedTrackingPage extends TrackingPageResponse {
    type: typeof TRACKING_PAGE_LOADED;
}

function loadedTrackingPage(
    trackingPage: TrackingPageResponse,
): LoadedTrackingPage {
    return {
        type: TRACKING_PAGE_LOADED,
        ...trackingPage
    };
}


export const FAILED_TRACKING_PAGE_LOAD = 'FAILED_TRACKING_PAGE_LOAD';

interface FailedTrackingPageLoad {
    type: typeof FAILED_TRACKING_PAGE_LOAD;
}

function failedTrackingPageLoad(
): FailedTrackingPageLoad {
    return {
        type: FAILED_TRACKING_PAGE_LOAD,
    };
}

export function loadCaseTrackingPage(caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<TrackingPageResponse | null> => {
        const route = `api/case/${caseUuid}/tracking`;
        dispatch(loadingTrackingPage());
        let trackingPage = await getFromAPI<TrackingPageResponse>(route, dispatch);
        if (trackingPage) {
            dispatch(loadedTrackingPage(trackingPage));
            return trackingPage;
        } else {
            dispatch(failedTrackingPageLoad());
            dispatch(registerAppError('Unable to load tracking data.'));
            return null;
        }
    };
}

export const DEATH_CERTIFICATE_OUTDATED = 'DEATH_CERTIFICATE_OUTDATED';

interface DeathCertificateOutdated {
    type: typeof DEATH_CERTIFICATE_OUTDATED;
    info: DeathCertificateUpdateInfo;
}

const deathCertificateOutdated = (info: DeathCertificateUpdateInfo): DeathCertificateOutdated => {
    return {
        type: DEATH_CERTIFICATE_OUTDATED,
        info,
    };
};

export const DEATH_CERTIFICATE_OUTDATED_DISMISSED = 'DEATH_CERTIFICATE_OUTDATED_DISMISSED';

interface DeathCertificateOutdatedDismissed {
    type: typeof DEATH_CERTIFICATE_OUTDATED_DISMISSED;
}

export const deathCertificateOutdatedDismissed = (): DeathCertificateOutdatedDismissed => {
    return {
        type: DEATH_CERTIFICATE_OUTDATED_DISMISSED,
    };
};

export function patchDeathCertificate(params: {
    changes: DeathCertificatePatchRequest;
    caseUuid: string;
}) {
    const { changes, caseUuid } = params;
    return async (dispatch: AppDispatch): Promise<GatherCaseUX | null> => {
        try {
            DeathCertificatePatchRequest.fromRequest(changes);
        } catch (ex) {
            log.warn('Failed to validate DeathCertificatePatchRequest', { changes, ex });
            return null;
        }

        const updatedCase = await patchAPI<GatherCaseUX>(
            `api/case/${caseUuid}/deathcertificate`,
            {
                dc_updates: changes,
            },
            dispatch
        );
        if (updatedCase) {
            dispatch(caseUpdated({ gatherCase: updatedCase }));
            return updatedCase;
        } else {
            dispatch(registerAppError('Failed to update death certificate field.'));
            return null;
        }
    };
}

export function saveDeathCertificate(
    updateRequest: DeathCertificateUpdateRequest,
    selectedCase: Pick<GatherCaseUX, 'uuid' | 'dc_updated_time'>,
) {
    return async (dispatch: AppDispatch): Promise<GatherCaseUX | null> => {
        try {
            DeathCertificateUpdateRequest.fromRequest(updateRequest);
        } catch (ex) {
            log.warn('Failed to validate partial DeathCertificateUpdateRequest', { updateRequest, ex });
            return null;
        }

        dispatch(updatingCase(selectedCase.uuid, updateRequest));

        const response = await advancedAPIRequest(
            `api/case/${selectedCase.uuid}/deathcertificate`,
            'POST',
            {
                dc_updates: updateRequest,
                previous_dc_updated_time: selectedCase.dc_updated_time,
            },
            dispatch
        );
        try {
            const body: SaveDeathCertificateResponse | null = response && (response.ok || response.status === 409)
                ? await parseJSON(response)
                : null;
            if (!body || !response) {
                dispatch(updatingCaseFailed());
                dispatch(registerAppError('Failed to save death certificate. May be locked.'));
                return null;
            } else if (body.case) {
                if (response.status === 409) {
                    if (!body.dc_updated) {
                        log.warn('Missing dc_updated information', { body, selectedCase, updateRequest });
                    }
                    dispatch(deathCertificateOutdated(body.dc_updated || {
                        id: -1,
                        name: 'Someone',
                        updated_time: new Date(),
                    }));
                }
                dispatch(caseUpdated({ gatherCase: body.case }));
                return body.case;
            } else {
                log.warn('Invalid state of Save Death Certificate Response', { body });
                dispatch(updatingCaseFailed());
                return null;
            }
        } catch (ex) {
            dispatch(updatingCaseFailed());
            dispatch(handleException({ ex, showSnackbar: false, sendToSentry: true }));
            return null;
        }
    };
}

export const UPDATE_SYNC_STATE = 'UPDATE_SYNC_STATE';
export type UPDATE_SYNC_STATE = typeof UPDATE_SYNC_STATE;

interface UpdateSyncState {
    type: UPDATE_SYNC_STATE;
    caseUuid: string;
    vendor: WebsiteVendor;
    isAuto: boolean;
    publish: boolean;
}

export function updateSyncState(
    caseUuid: string,
    vendor: WebsiteVendor,
    isAuto: boolean,
    publish: boolean,
) {
    return async (dispatch: AppDispatch) => {
        dispatch({ type: UPDATE_SYNC_STATE, caseUuid, vendor, isAuto, publish });
        const resource = `api/case/${caseUuid}/sync/${vendor}`;
        const result = await putToAPI<{ caseId: number; vendor: WebsiteVendor }>
            (resource, { isAuto, publish }, dispatch);
        return result;
    };
}

export const UPDATE_DEATH_NOTICE = 'UPDATE_DEATH_NOTICE';
export type UPDATE_DEATH_NOTICE = typeof UPDATE_DEATH_NOTICE;

interface UpdateDeathNotice {
    type: UPDATE_DEATH_NOTICE;
    caseUuid: string;
    deathNotice: string;
}

function updateDeathNoticeAction(deathNotice: string, caseUuid: string): UpdateDeathNotice {
    return {
        type: UPDATE_DEATH_NOTICE,
        caseUuid,
        deathNotice
    };
}

export function updateDeathNotice(
    caseUuid: string,
    deathNotice: string,
) {
    return async (dispatch: AppDispatch) => {
        dispatch(updateDeathNoticeAction(deathNotice, caseUuid));
        const resource = `api/case/${caseUuid}/deathNotice`;
        await putToAPI(resource, { text: deathNotice }, dispatch);
    };
}

export const SET_OBITUARY_ERROR = 'SET_OBITUARY_ERROR';
export type SET_OBITUARY_ERROR = typeof SET_OBITUARY_ERROR;

interface SetObituaryError {
    type: SET_OBITUARY_ERROR;
    error: string | null;
}

function setObituaryError(error: string | null): SetObituaryError {
    return {
        type: SET_OBITUARY_ERROR,
        error,
    };
}

export const UPDATE_OBITUARY = 'UPDATE_OBITUARY';
export type UPDATE_OBITUARY = typeof UPDATE_OBITUARY;

interface UpdateObituary {
    type: UPDATE_OBITUARY;
    obituary: Obituary | null;
}

function updateObituaryInStore(obituary: Obituary | null): UpdateObituary {
    return {
        type: UPDATE_OBITUARY,
        obituary,
    };
}

export enum ObitUpdateStatus {
    Success = 'Success',
    Conflict = 'Conflict',
    Error = 'Error',
}

type ObituaryUpdateResponse = {
    status: ObitUpdateStatus.Success;
    latestObituary: Obituary;
} | {
    status: ObitUpdateStatus.Error;
} | {
    status: ObitUpdateStatus.Conflict;
    latestObituary: Obituary;
};

export function updateObituary(params: {
    gatherCase: GatherCaseUX;
    obituary: ObituaryRequest;
    autoGenerate?: true;
    forceSave?: true;
}) {
    const { gatherCase, obituary, autoGenerate = false, forceSave = false } = params;
    return async (dispatch: AppDispatch): Promise<ObituaryUpdateResponse> => {

        try {
            ObituaryRequest.fromRequest(obituary);
        } catch (ex) {
            log.warn('Failed to validate ObituaryRequest', { obituary, ex });
            return { status: ObitUpdateStatus.Error };
        }

        dispatch(setObituaryError(null));
        try {
            // update existing obituary
            const response = await advancedAPIRequest(
                `api/case/${gatherCase.uuid}/obituary`,
                'PUT',
                { obituary, autoGenerate, forceSave },
                dispatch,
            );
            const updatedObituary: Obituary | null = response?.ok ? await response.json() : null;
            if (response?.status === 409) {
                const latestObit: Obituary = (await response.json()).obituary;
                return { status: ObitUpdateStatus.Conflict, latestObituary: latestObit };
            }

            if (!updatedObituary) {
                if (forceSave) {
                    dispatch(setObituaryError('Could not overwrite. Obituary may be locked.'));
                } else {
                    dispatch(registerAppError('Unable to update obituary.'));
                }
                return { status: ObitUpdateStatus.Error };
            } else {
                // make sure dialogs get closed
                dispatch(updateObituaryInStore(updatedObituary));
            }
            return { status: ObitUpdateStatus.Success, latestObituary: updatedObituary };
        } catch (ex) {
            dispatch(handleException({ ex, userMessage: 'Failed to update obituary' }));
            return { status: ObitUpdateStatus.Error };
        }
    };
}

export function loadObituary(caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<Obituary | null> => {
        dispatch(setObituaryError(null));
        try {
            let obituary = await getFromAPI<Obituary | string>(`api/case/${caseUuid}/obituary`, dispatch);
            if (obituary) {
                obituary = typeof obituary === 'string' ? null : obituary;
                dispatch(updateObituaryInStore(obituary));
                return obituary;
            } else {
                dispatch(setObituaryError('Failed to load Obituary'));
                dispatch(registerAppError('Failed to load Obituary'));
            }
        } catch (ex) {
            dispatch(setObituaryError('Failed to load Obituary'));
            dispatch(registerAppError('Failed to load Obituary'));
        }
        return null;
    };
}

export const SET_CASE_OPTIONS = 'SET_CASE_OPTIONS';
export type SET_CASE_OPTIONS = typeof SET_CASE_OPTIONS;
interface SetCaseOptions {
    type: SET_CASE_OPTIONS;
    options: CaseOptions;
}
export function setCaseOptions(options: CaseOptions): SetCaseOptions {
    return { type: SET_CASE_OPTIONS, options };
}

export const OPEN_ARRANGEMENT_CONFERENCE_DIALOG = 'OPEN_ARRANGEMENT_CONFERENCE_DIALOG';
export type OPEN_ARRANGEMENT_CONFERENCE_DIALOG = typeof OPEN_ARRANGEMENT_CONFERENCE_DIALOG;

interface OpenArrangementConferenceDialog {
    type: OPEN_ARRANGEMENT_CONFERENCE_DIALOG;
    zIndex: number;
}

export function openArrangementConferenceDialog(zIndex: number): OpenArrangementConferenceDialog {
    return {
        type: OPEN_ARRANGEMENT_CONFERENCE_DIALOG,
        zIndex
    };
}

export const CLOSE_ARRANGEMENT_CONFERENCE_DIALOG = 'CLOSE_ARRANGEMENT_CONFERENCE_DIALOG';
export type CLOSE_ARRANGEMENT_CONFERENCE_DIALOG = typeof CLOSE_ARRANGEMENT_CONFERENCE_DIALOG;

interface CloseArrangementConferenceDialog {
    type: CLOSE_ARRANGEMENT_CONFERENCE_DIALOG;
}

export function closeArrangementConferenceDialog(): CloseArrangementConferenceDialog {
    return {
        type: CLOSE_ARRANGEMENT_CONFERENCE_DIALOG
    };
}

export function assignCaseServiceTemplateDetails(caseUuid: string, serviceTemplateId: number) {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<ServiceDetail[] | null> => {
        dispatch(setCaseSaving(true));
        try {
            const resource = `api/case/${caseUuid}/servicetemplate/${serviceTemplateId}`;
            const details = await putToAPI<ServiceDetail[] | null>(resource, {}, dispatch);
            if (details === null) {
                dispatch(registerAppError('Unable to assign Service Template.'));
                dispatch(setCaseSaving(false));
                return null;
            } else {
                const { serviceDetailState } = getState();
                const { templates } = serviceDetailState;
                const serviceTemplate = templates.find(t => t.id === serviceTemplateId);
                const serviceTemplateName = serviceTemplate && serviceTemplate.name || null;

                dispatch(setCaseServiceDetails(caseUuid, details, serviceTemplateId, serviceTemplateName));
                dispatch(setCaseSaving(false));
                return details;
            }
        } catch (ex) {
            dispatch(registerAppError('Unable to assign Service Template.'));
        }
        dispatch(setCaseSaving(false));
        return null;
    };
}

export function loadCaseServiceDetails(caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<ServiceDetail[] | null> => {
        dispatch(setCaseSaving(true));
        try {
            const resource = `api/case/${caseUuid}/servicedetail`;
            const details = await getFromAPI<ServiceDetail[] | null>(resource, dispatch);

            if (details === null) {
                dispatch(registerAppError('Unable to assign Service Template.'));
                dispatch(setCaseSaving(false));
                return null;
            } else {
                dispatch(setCaseServiceDetails(caseUuid, details));
                dispatch(setCaseSaving(false));
                return details;
            }
        } catch (ex) {
            dispatch(registerAppError('Unable to assign Service Template.'));
        }
        dispatch(setCaseSaving(false));
        return null;
    };
}

export const SET_CASE_SERVICE_DETAILS = 'SET_CASE_SERVICE_DETAILS';
export type SET_CASE_SERVICE_DETAILS = typeof SET_CASE_SERVICE_DETAILS;

interface SetCaseServiceDetails {
    type: SET_CASE_SERVICE_DETAILS;
    caseUuid: string;
    details: ServiceDetail[];
    serviceTemplateId: number | null;
    serviceTemplateName: string | null;
}

function setCaseServiceDetails(
    caseUuid: string,
    details: ServiceDetail[],
    serviceTemplateId?: number,
    serviceTemplateName?: string | null,
):
    SetCaseServiceDetails {
    return {
        caseUuid,
        details,
        type: SET_CASE_SERVICE_DETAILS,
        serviceTemplateId: serviceTemplateId || null,
        serviceTemplateName: serviceTemplateName || null
    };
}

export function addCaseDefaultTemplateDetail(caseUuid: string, defaultDetailId: number) {
    return async (dispatch: AppDispatch): Promise<ServiceDetail | null> => {
        dispatch(setCaseSaving(true));
        try {
            const resource = `api/case/${caseUuid}/servicedetail/defaultservicedetail/${defaultDetailId}`;
            const detail: ServiceDetail | null = await putToAPI<ServiceDetail | null>(resource, {}, dispatch);

            if (detail === null) {
                dispatch(registerAppError('Unable to assign Default Service Detail.'));
                dispatch(setCaseSaving(false));
                return null;
            } else {
                dispatch(pushCaseServiceDetail(caseUuid, detail));
                dispatch(setCaseSaving(false));
                return detail;
            }
        } catch (ex) {
            dispatch(registerAppError('Unable to assign Service Template.'));
        }
        dispatch(setCaseSaving(false));
        return null;
    };
}

export function upsertCaseDefaultTemplateDetail(caseUuid: string, defaultDetailId: number) {
    return async (dispatch: AppDispatch): Promise<ServiceDetail | null> => {
        dispatch(setCaseSaving(true));
        try {
            const resource = `api/case/${caseUuid}/servicedetail/defaultservicedetail/${defaultDetailId}/upsert`;
            const detail: ServiceDetail | null = await putToAPI<ServiceDetail | null>(resource, {}, dispatch);

            if (detail === null) {
                dispatch(registerAppError('Unable to assign Default Service Detail.'));
                dispatch(setCaseSaving(false));
                return null;
            } else {
                dispatch(pushCaseServiceDetail(caseUuid, detail));
                dispatch(setCaseSaving(false));
                return detail;
            }
        } catch (ex) {
            dispatch(registerAppError('Unable to assign Service Template.'));
        }
        dispatch(setCaseSaving(false));
        return null;
    };
}

export const PUSH_CASE_SERVICE_DETAIL = 'PUSH_CASE_SERVICE_DETAIL';
export type PUSH_CASE_SERVICE_DETAIL = typeof PUSH_CASE_SERVICE_DETAIL;

interface PushCaseServiceDetail {
    type: PUSH_CASE_SERVICE_DETAIL;
    caseUuid: string;
    detail: ServiceDetail;
}

function pushCaseServiceDetail(caseUuid: string, detail: ServiceDetail): PushCaseServiceDetail {
    return {
        caseUuid,
        detail,
        type: PUSH_CASE_SERVICE_DETAIL
    };
}

export function syncCaseServiceDetail(
    caseUuid: string,
    serviceDetailId: number
) {
    return async (dispatch: AppDispatch, getStore: () => StoreState): Promise<ServiceDetail | null> => {
        dispatch(setCaseSaving(true));
        try {
            const { casesState } = getStore();
            const { caseServiceDetails } = casesState;
            const targetDetail = caseServiceDetails.find(sd => sd.id === serviceDetailId);

            if (!targetDetail) {
                return null;
            }

            const serviceDetail = ServiceDetailRequest.fromDefaultServiceDetail(targetDetail);

            try {
                ServiceDetailRequest.fromRequest(serviceDetail);
            } catch (ex) {
                log.warn('Failed to validate ServiceDetailRequest:', { serviceDetail, ex });
                return null;
            }

            const resource = `api/case/${caseUuid}/servicedetail/${serviceDetailId}`;
            const detail = await postToAPI<ServiceDetail | null>(
                resource,
                {
                    serviceDetail,
                },
                dispatch,
            );

            if (detail === null) {
                dispatch(registerAppError('Unable to sync service detail.'));
                dispatch(setCaseSaving(false));
                return null;
            } else {
                dispatch(setCaseSaving(false));
                return detail;
            }
        } catch (ex) {
            dispatch(registerAppError('Unable to sync service detail.'));
        }
        dispatch(setCaseSaving(false));
        return null;
    };
}

export const UPDATE_CASE_SERVICE_DETAIL = 'UPDATE_CASE_SERVICE_DETAIL';
export type UPDATE_CASE_SERVICE_DETAIL = typeof UPDATE_CASE_SERVICE_DETAIL;

interface UpdateCaseServiceDetail {
    type: UPDATE_CASE_SERVICE_DETAIL;
    caseId: number;
    detail: ServiceDetail;
}

export function updateCaseServiceDetail(caseId: number, detail: ServiceDetail): UpdateCaseServiceDetail {
    return {
        caseId,
        detail,
        type: UPDATE_CASE_SERVICE_DETAIL
    };
}

export const UPDATE_CASE_SERVICE_DETAIL_FIELD = 'UPDATE_CASE_SERVICE_DETAIL_FIELD';
export type UPDATE_CASE_SERVICE_DETAIL_FIELD = typeof UPDATE_CASE_SERVICE_DETAIL_FIELD;

interface UpdateCaseServiceDetailField {
    type: UPDATE_CASE_SERVICE_DETAIL_FIELD;
    detailId: number;
    index: number;
    value: string;
}

export function updateCaseServiceDetailField(
    detailId: number,
    index: number,
    value: string
): UpdateCaseServiceDetailField {
    return {
        detailId,
        index,
        value,
        type: UPDATE_CASE_SERVICE_DETAIL_FIELD,
    };
}

export const REMOVE_CASE_SERVICE_DETAIL_FIELD = 'REMOVE_CASE_SERVICE_DETAIL_FIELD';
export type REMOVE_CASE_SERVICE_DETAIL_FIELD = typeof REMOVE_CASE_SERVICE_DETAIL_FIELD;

interface RemoveCaseServiceDetailField {
    type: REMOVE_CASE_SERVICE_DETAIL_FIELD;
    detailId: number;
    index: number;
}

export function removeCaseServiceDetailField(
    detailId: number,
    index: number
): RemoveCaseServiceDetailField {
    return {
        detailId,
        index,
        type: REMOVE_CASE_SERVICE_DETAIL_FIELD
    };
}

export const ADD_CASE_SERVICE_DETAIL_FIELD = 'ADD_CASE_SERVICE_DETAIL_FIELD';
export type ADD_CASE_SERVICE_DETAIL_FIELD = typeof ADD_CASE_SERVICE_DETAIL_FIELD;

interface AddCaseServiceDetailField {
    type: ADD_CASE_SERVICE_DETAIL_FIELD;
    detailId: number;
    fieldType: ServiceDetailFieldEnum;
}

export function addCaseServiceDetailField(
    detailId: number,
    fieldType: ServiceDetailFieldEnum
): AddCaseServiceDetailField {
    return {
        detailId,
        fieldType,
        type: ADD_CASE_SERVICE_DETAIL_FIELD
    };
}

export const REORDER_CASE_SERVICE_DETAIL_FIELDS = 'REORDER_CASE_SERVICE_DETAIL_FIELDS';
export type REORDER_CASE_SERVICE_DETAIL_FIELDS = typeof REORDER_CASE_SERVICE_DETAIL_FIELDS;

interface ReorderCaseServiceDetailFields {
    type: REORDER_CASE_SERVICE_DETAIL_FIELDS;
    serviceDetailId: number;
    sort: SortEnd;
}

export function reorderCaseServiceDetailFields(serviceDetailId: number, sort: SortEnd): ReorderCaseServiceDetailFields {
    return {
        sort,
        serviceDetailId,
        type: REORDER_CASE_SERVICE_DETAIL_FIELDS,
    };
}

export function removeCaseServiceDetail(
    caseUuid: string,
    serviceDetailId: number
) {
    return async (dispatch: AppDispatch): Promise<ServiceDetail | null> => {
        dispatch(setCaseSaving(true));
        try {
            const resource = `api/case/${caseUuid}/servicedetail/${serviceDetailId}`;
            const detail = await deleteFromAPI<ServiceDetail | null>(resource, dispatch);

            if (detail === null) {
                dispatch(registerAppError('Unable to update service detail.'));
                dispatch(setCaseSaving(false));
                return null;
            } else {
                dispatch(deleteCaseServiceDetail(caseUuid, serviceDetailId));
                dispatch(setCaseSaving(false));
                return detail;
            }
        } catch (ex) {
            dispatch(registerAppError('Unable to assign Service Template.'));
        }
        dispatch(setCaseSaving(false));
        return null;
    };
}

export function reorderCaseDetails(
    caseUuid: string,
    sort: SortEnd
) {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<ServiceDetail[] | null> => {
        dispatch(setCaseSaving(true));
        try {
            const state = getState();
            const { caseServiceDetails } = state.casesState;

            const reordered = arrayMove(caseServiceDetails, sort.oldIndex, sort.newIndex);

            dispatch(setCaseServiceDetails(caseUuid, reordered));

            const serviceDetailIds = ServiceDetailRequest
                .filterNonCasketBearerServiceDetails(reordered)
                .map(({ id }) => id);

            const resource = `api/case/${caseUuid}/servicedetail`;
            const details = await postToAPI<ServiceDetail[] | null>(resource, { serviceDetailIds }, dispatch);

            if (details === null) {
                dispatch(registerAppError('Unable to update service detail.'));
                dispatch(setCaseSaving(false));
                return null;
            } else {
                dispatch(setCaseSaving(false));
                return details;
            }
        } catch (ex) {
            dispatch(registerAppError('Unable to assign Service Template.'));
        }
        dispatch(setCaseSaving(false));
        return null;
    };
}

export const DELETE_CASE_SERVICE_DETAIL = 'DELETE_CASE_SERVICE_DETAIL';
export type DELETE_CASE_SERVICE_DETAIL = typeof DELETE_CASE_SERVICE_DETAIL;

interface DeleteCaseServiceDetail {
    type: DELETE_CASE_SERVICE_DETAIL;
    caseUuid: string;
    serviceDetailId: number;
}

export function deleteCaseServiceDetail(caseUuid: string, serviceDetailId: number): DeleteCaseServiceDetail {
    return {
        caseUuid,
        serviceDetailId,
        type: DELETE_CASE_SERVICE_DETAIL
    };
}

export function getGuestPaymentCaseTotals(caseName: string) {
    return async (dispatch: AppDispatch): Promise<GuestPaymentCaseTotals | null> => {
        const resource = `app/remember/${caseName}/guestpayments`;
        const totals = await getFromAPI<GuestPaymentCaseTotals | null>(resource, dispatch);
        return totals;
    };
}

/**
 * CaseTable Actions
 */

export enum CASE_TABLE {
    'CASE_TABLE_LOADING' = 'CASE_TABLE_LOADING',
    'CASE_TABLE_LOADED' = 'CASE_TABLE_LOADED',
    'CASE_TABLE_LOAD_FAILED' = 'CASE_TABLE_LOAD_FAILED',
    'SET_TOTAL_CASE_COUNT' = 'SET_TOTAL_CASE_COUNT',
}

/**
 *   CaseTableLoading
 */
interface CaseTableLoading {
    type: CASE_TABLE.CASE_TABLE_LOADING;
    data: GatherCaseReportType[];
    searchText: string;
    sortBy: keyof GatherCaseReportType;
    sortDirection: 'desc' | 'asc';
    isLoading: boolean;
}
export function caseTableLoading(
    data: GatherCaseReportType[],
    searchText: string,
    sortBy: keyof GatherCaseReportType,
    sortDirection: 'asc' | 'desc'
): CaseTableLoading {
    return {
        type: CASE_TABLE.CASE_TABLE_LOADING,
        data,
        searchText,
        sortBy,
        sortDirection,
        isLoading: true,
    };
}

/**
 * CaseTableLoaded
 */
interface CaseTableLoaded {
    type: CASE_TABLE.CASE_TABLE_LOADED;
    data: GatherCaseReportType[];
    hasMoreData: boolean;
    isLoading: boolean;
    isOverwrite: boolean;
}
export function caseTableLoaded(
    data: GatherCaseReportType[],
    hasMoreData: boolean,
    isOverwrite: boolean,
): CaseTableLoaded {
    return {
        type: CASE_TABLE.CASE_TABLE_LOADED,
        data,
        hasMoreData,
        isLoading: false,
        isOverwrite,
    };
}

/**
 * CaseTableLoadFailed
 */
interface CaseTableLoadFailed {
    type: CASE_TABLE.CASE_TABLE_LOAD_FAILED;
    errorDetail: object;
}
export function caseTableLoadFailed(
    errorDetail: object,
): CaseTableLoadFailed {
    return {
        type: CASE_TABLE.CASE_TABLE_LOAD_FAILED,
        errorDetail,
    };
}

/**
 * SetTotalCaseCount
 */
interface SetTotalCaseCount {
    type: CASE_TABLE.SET_TOTAL_CASE_COUNT;
    count: number;
}
export function setTotalCaseCount(
    count: number,
): SetTotalCaseCount {
    return {
        type: CASE_TABLE.SET_TOTAL_CASE_COUNT,
        count,
    };
}
/* *
 * 
 */

/* **********************************************************************
*  Define the THUNKS here
* **********************************************************************
*/

export function getCaseTableRecordsForFuneralHome(
    newOffset: number = 0,
    newSearchText: string,
    passedSortBy: keyof GatherCaseUXTable,
    newSortDirection: 'asc' | 'desc',
) {
    return getCaseTableRecordsForFuneralHomeWithLimit(newOffset, newSearchText, passedSortBy, newSortDirection);
}

export function getCaseTableRecordsForFuneralHomeWithLimit(
    newOffset: number = 0,
    newSearchText: string,
    passedSortBy: keyof GatherCaseUXTable,
    newSortDirection: 'asc' | 'desc',
    limit: number = 50,
) {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<GatherCaseReportType[] | null> => {
        const sortByMap = {
            death_date_formatted: 'dod_start_date',
            created_time_formatted: 'created_time',
            updated_time_formatted: 'updated_time',
            age_days: 'age',
        };
        newSearchText = newSearchText ? newSearchText : '';
        const newSortBy: keyof GatherCaseReportType = passedSortBy && sortByMap[passedSortBy]
            ? sortByMap[passedSortBy]
            : passedSortBy ? passedSortBy : 'id';
        newSortDirection = newSortDirection ? newSortDirection : 'asc';
        let newData: GatherCaseReportType[] = [];
        const { funeralHomeState, caseTableState, casesState, userSession } = getState();
        const { activeFuneralHome } = funeralHomeState;
        const { userData } = userSession;
        if (!activeFuneralHome || !userData) {
            return [];
        }
        const { data, searchText, sortBy, sortDirection } = caseTableState;
        const { selectedCaseTypes } = casesState;

        if (newOffset === 0 || newSearchText !== searchText
            || newSortBy !== sortBy || newSortDirection !== sortDirection) {
            newOffset = 0;
            newData = [];
        } else {
            newOffset = data.length;
            newData = data;
        }

        const queryParamOffset = `offset=${newOffset}`;
        const queryParamSearchText = 'filter=' + encodeURIComponent(newSearchText);
        const queryParamSortBy = 'sortBy=' + encodeURIComponent(newSortBy);
        const queryParamLimit = `limit=${limit}`;
        const queryParamSortDirection = 'sortDirection=' + encodeURIComponent(newSortDirection);
        const selectedCaseTypesEncodedString =
            `selectedCaseTypesEncodedString=${encodeURIComponent(selectedCaseTypes.join(','))}`;
        const includeDeleted = UserRoles.isGOMUser(userData);

        dispatch(caseTableLoading(newData, newSearchText, newSortBy, newSortDirection));
        const resource = `funeralhome/${activeFuneralHome.id}/case/`
            + `?${queryParamOffset}`
            + `&${queryParamSearchText}`
            + `&${queryParamSortBy}`
            + `&${queryParamLimit}`
            + `&${queryParamSortDirection}`
            + `&${selectedCaseTypesEncodedString}`
            + (includeDeleted ? '&includeDeleted=true' : '');

        const response: PaginatedResponse<GatherCaseReportType> | null
            = await getFromAPI<PaginatedResponse<GatherCaseReportType>>(resource, dispatch);
        if (response !== null) {
            const fixDates: GatherCaseReportType[] = response.data.map((entry): GatherCaseReportType => ({
                ...entry,
                created_time: moment(entry.created_time).format('YYYY-MM-DD'),
                updated_time: moment(entry.updated_time).format('YYYY-MM-DD'),
                deleted_time: entry.deleted_time ? moment(entry.deleted_time).toDate() : null,
            }));
            dispatch(caseTableLoaded(fixDates, response.hasMoreData, newOffset === 0));
            dispatch(setTotalCaseCount(data.length || 0));
            return response.data;
        } else {
            dispatch(caseTableLoadFailed({ message: 'failed to load Case records' }));
            dispatch(registerAppError('Failed to load Cases'));
        }
        return [];
    };
}

export function getCaseTableRecords(
    newOffset: number = 0,
    newSearchText: string,
    passedSortBy: keyof GatherCaseUXTable,
    newSortDirection: 'asc' | 'desc',
    overrideCaseTypes?: CaseType[],
) {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<GatherCaseReportType[] | null> => {
        const sortByMap = {
            death_date_formatted: 'dod_start_date',
            created_time_formatted: 'created_time',
            updated_time_formatted: 'updated_time',
            age_days: 'age',
        };

        newSearchText = newSearchText ? newSearchText : '';
        const newSortBy: keyof GatherCaseReportType = passedSortBy && sortByMap[passedSortBy]
            ? sortByMap[passedSortBy]
            : passedSortBy ? passedSortBy : 'id';
        newSortDirection = newSortDirection ? newSortDirection : 'asc';
        let newData: GatherCaseReportType[] = [];
        const { caseTableState, casesState } = getState();
        const { data, searchText, sortBy, sortDirection } = caseTableState;
        const { selectedCaseTypes } = casesState;

        if (newOffset === 0 || newSearchText !== searchText
            || newSortBy !== sortBy || newSortDirection !== sortDirection) {
            newOffset = 0;
            newData = [];
        } else {
            newOffset = data.length;
            newData = data;
        }

        const queryParamOffset = `offset=${newOffset}`;
        const queryParamSearchText = 'filter=' + encodeURIComponent(newSearchText);
        const queryParamSortBy = 'sortBy=' + encodeURIComponent(newSortBy);
        const queryParamSortDirection = 'sortDirection=' + encodeURIComponent(newSortDirection);
        const selectedCaseTypesEncodedString =
            `selectedCaseTypesEncodedString=${encodeURIComponent((overrideCaseTypes || selectedCaseTypes).join(','))}`;

        dispatch(caseTableLoading(newData, newSearchText, newSortBy, newSortDirection));
        const resource = 'api/case/'
            + `?${queryParamOffset}`
            + `&${queryParamSearchText}`
            + `&${queryParamSortBy}`
            + `&${queryParamSortDirection}`
            + `&${selectedCaseTypesEncodedString}`
            ;

        const response: PaginatedResponse<GatherCaseReportType> | null
            = await getFromAPI<PaginatedResponse<GatherCaseReportType>>(resource, dispatch);
        if (response !== null) {
            dispatch(caseTableLoaded(response.data, response.hasMoreData, newOffset === 0));
            dispatch(setTotalCaseCount(response.totalcount || 0));
            return response.data;
        } else {
            dispatch(caseTableLoadFailed({ message: 'failed to load Case records' }));
            dispatch(registerAppError('Failed to load Cases'));
        }
        return [];
    };
}
/**
 *  End CaseTable 
 */

/**
 *  Define CasePreview Actions
 * 
 * ***** 
 *  CasePreviewLoading
 */

interface CasePreviewLoading {
    type: CASE_PREVIEW_STATES.CASE_PREVIEW_LOADING;
    data: GatherCasePreview[];
    sortBy: keyof GatherCasePreview;
    sortDirection: 'desc' | 'asc';
    isLoading: boolean;
}
function casePreviewLoading(
    data: GatherCasePreview[],
    sortBy: keyof GatherCasePreview,
    sortDirection: 'asc' | 'desc'
): CasePreviewLoading {
    return {
        type: CASE_PREVIEW_STATES.CASE_PREVIEW_LOADING,
        data,
        sortBy,
        sortDirection,
        isLoading: true,
    };
}

/**
 * CasePreviewLoaded
 */
interface CasePreviewLoaded {
    type: CASE_PREVIEW_STATES.CASE_PREVIEW_LOADED;
    data: GatherCasePreview[];
    hasMoreData: boolean;
    isLoading: boolean;
}
export function casePreviewLoaded(
    data: GatherCasePreview[],
    hasMoreData: boolean,
): CasePreviewLoaded {
    return {
        type: CASE_PREVIEW_STATES.CASE_PREVIEW_LOADED,
        data,
        hasMoreData,
        isLoading: false,
    };
}

/**
 * CasePreviewLoadFailed
 */
interface CasePreviewLoadFailed {
    type: CASE_PREVIEW_STATES.CASE_PREVIEW_LOAD_FAILED;
    errorDetail: object;
}
export function casePreviewLoadFailed(
    errorDetail: object,
): CasePreviewLoadFailed {
    return {
        type: CASE_PREVIEW_STATES.CASE_PREVIEW_LOAD_FAILED,
        errorDetail,
    };
}

export function getCasePreviews(user: UserSession, funeralHomeId: number, newOffset: number = 0) {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<GatherCasePreview[] | null> => {
        if (!user || !user.userData) {
            return [];
        }
        const { casePreviewState } = getState();
        let newData: GatherCasePreview[] = [];
        const newSortBy = 'id';
        const newSortDirection = 'desc';
        if (newOffset === 0) {
            newOffset = 0;
            newData = [];
        } else {
            newOffset = casePreviewState.data.length;
            newData = casePreviewState.data;
        }
        const queryParamOffset = `offset=${newOffset}`;
        const queryParamSortBy = 'sortBy=' + encodeURIComponent(newSortBy);
        const queryParamSortDirection = 'sortDirection=' + encodeURIComponent(newSortDirection);
        dispatch(casePreviewLoading(newData, newSortBy, newSortDirection));

        const resource = `funeralhome/${funeralHomeId}/case/preview`
            + `?${queryParamOffset}`
            + `&${queryParamSortBy}`
            + `&${queryParamSortDirection}`;

        const response = await getFromAPI<PaginatedResponse<GatherCasePreview>>(resource, dispatch);
        if (response !== null) {
            dispatch(casePreviewLoaded(response.data, response.hasMoreData));
            return response.data;
        } else {
            dispatch(casePreviewLoadFailed({ message: 'failed to load Case Preview' }));
        }
        return [];
    };
}

export function loadCaseSummaries(params: {
    funeralHomeId: number;
    offset: number;
    searchText: string;
}) {
    return async (dispatch: AppDispatch): Promise<PaginatedResponse<GatherCaseSummary> | null> => {
        const { funeralHomeId, offset, searchText } = params;

        const qOffset = `offset=${offset}`;
        const qSearch = searchText.trim() ? `&search=${encodeURIComponent(searchText)}` : '';

        const resource = `funeralhome/${funeralHomeId}/case/summary?${qOffset}${qSearch}`;
        const response = await getFromAPI<PaginatedResponse<GatherCaseSummary>>(resource, dispatch);
        return response;
    };
}

export function loadCaseSummaryByUuid(params: { caseUuid: string }) {
    return async (dispatch: AppDispatch): Promise<GatherCaseSummary | null> => {
        const { caseUuid } = params;

        const resource = `case/${caseUuid}/summary`;
        return getFromAPI<GatherCaseSummary>(resource, dispatch);
    };
}

export function loadCaseNames(params: {funeralHomeId: number; searchText: string}) {
    const { funeralHomeId , searchText } = params;
    return async (dispatch: AppDispatch): Promise<GatherCaseName[]> => {
        const queryParamSearchText = 'filter=' + encodeURIComponent(searchText);
        const resource = `funeralhome/${funeralHomeId}/case/name_search?${queryParamSearchText}`;

        const caseNames = await getFromAPI<GatherCaseName[]>(resource, dispatch);
        if (caseNames) {
            return caseNames;
        } else {
            dispatch(registerAppError('Failed to load cases'));
        }
        return [];
    };
}

/**
 * accept helpers startup terms
 */
export function acceptStartup(caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<UserProfile | null> => {
        const user = await postToAPI<UserProfile | null>(
            `api/case/${caseUuid}/acceptstartup`,
            {},
            dispatch,
        );

        if (!user) {
            dispatch(registerAppError('Error accepting startup terms.'));
            return null;
        }

        dispatch(updateLoginUser(user));
        return user;
    };
}

export function downloadCaseFile(caseUuid: string, vendorName: CaseExportVendor) {
    return async (dispatch: AppDispatch): Promise<void> => {
        await downloadFromAPI(`api/case/${caseUuid}/download/${vendorName}`, dispatch);
    };
}

export function loginAndSaveMemory(params: {
    user: UserProfile | null;
    publicCase: GatherCasePublic;
    srcAction: SelfLoginSrcAction;
    zIndex: number;
    onLoginSuccess: ((userId?: number) => void) | null;
    onAbort: (() => void) | null;
    onClose: (() => void) | null;
    photosToUploadCount?: number;
}) {
    return async (dispatch: AppDispatch): Promise<void> => {
        const { user, publicCase, srcAction, zIndex, onLoginSuccess, onAbort, onClose, photosToUploadCount } = params;
        if (canCreateMemory(user, { funeralHomeId: publicCase.funeral_home.id, caseId: publicCase.id })) {
            if (onLoginSuccess) {
                onLoginSuccess(user?.id);
            }
            return;
        } else {
            dispatch(openSelfLoginDialog({
                zIndex,
                srcAction,
                photosToUploadCount: photosToUploadCount !== undefined ? photosToUploadCount : null,
                onLoginSuccessCallback: onLoginSuccess,
                onAbortCallback: onAbort,
                onCloseCallback: onClose,
            }));
        }
    };
}

export function updateRememberTheme(
    theme: ThemeUX | null,
    caseUuid: string,
) {
    return async (dispatch: AppDispatch): Promise<void> => {
        const newThemeId = (theme && theme.id) || null;
        dispatch(rememberThemeChanged(theme));
        await dispatch(updateCaseOptions(caseUuid, { theme_id: newThemeId }));
    };
}


export function loadMoveCaseOptionsForFuneralHome(params: {
    caseUuid: string;
    srcFuneralHomeId: number;
    destFuneralHomeId: number;
}) {
    const { caseUuid, srcFuneralHomeId, destFuneralHomeId } = params;
    return async (dispatch: AppDispatch): Promise<LoadMoveCaseOptionsResponse | null> => {
        const response = await getFromAPI<LoadMoveCaseOptionsResponse>(
            `funeralhome/${srcFuneralHomeId}/case/${caseUuid}/move/funeralhome/${destFuneralHomeId}`,
            dispatch,
        );
        if (!response) {
            dispatch(registerAppError('Unable to load options for this funeral home.'));
        }
        return response;
    };
}

export const CASE_MOVED = 'CASE_MOVED';
interface CaseMoved {
    type: typeof CASE_MOVED;
    updatedCase: GatherCaseUX;
}
export function caseMoved(updatedCase: GatherCaseUX): CaseMoved {
    return {
        type: CASE_MOVED,
        updatedCase,
    };
}

export function moveCase(params: {
    caseUuid: string;
    srcFuneralHomeId: number;
    moveRequest: GatherCaseMoveRequest;
}) {
    const { caseUuid, srcFuneralHomeId, moveRequest } = params;
    return async (dispatch: AppDispatch): Promise<GatherCaseUX | null> => {
        try {
            GatherCaseMoveRequest.fromRequest(moveRequest);
        } catch (ex) {
            log.warn('Failed to validate GatherCaseMoveRequest', { moveRequest, ex });
            return null;
        }
        const newCase = await postToAPI<GatherCaseUX>(
            `funeralhome/${srcFuneralHomeId}/case/${caseUuid}/move`,
            moveRequest,
            dispatch,
        );
        if (newCase) {
            return newCase;
        } else {
            dispatch(registerAppError('Failed to move case.'));
            return null;
        }
    };
}

export function renewCaseWorkflow(params: {
    caseUuid: string;
}) {
    const { caseUuid } = params;
    return async (dispatch: AppDispatch): Promise<GatherCaseUX | null> => {

        dispatch(updatingCase(caseUuid, {}));
        const response = await postToAPI<UpdateCaseResponse>(`api/case/${caseUuid}/workflow/renew`, {}, dispatch);
        if (!response) {
            dispatch(updatingCaseFailed());
            dispatch(registerAppError('Unable to renew workflow.'));
            return null;
        }

        dispatch(setSnackbarSuccess('Case workflow has been updated'));
        dispatch(caseUpdated(response));
        return response.gatherCase;
    };
}

export type GatherCaseAction =
    | SetDashboardCases
    | SetDashboardCasesAssignees
    | SetDashboardCasesType
    | SetDashboardRollup
    | SetDashboardCasesLoading
    | SetDashboardCaseTypeTotals
    | SetDashboardCasesAssigneesLoading
    | SetCaseRollupLoading
    | SetCaseSaving
    | CaseCreated
    | UpdatingCase
    | UpdatingCaseNumber
    | UpdatingCaseFailed
    | CaseNumberUpdated
    | CaseUpdated
    | SelectDashboardCasesAssignee
    | SetObituaryError
    | UpdateSyncState
    | UpdateDeathNotice
    | UpdateObituary
    | OpenArrangementConferenceDialog
    | CloseArrangementConferenceDialog
    | SetCaseServiceDetails
    | PushCaseServiceDetail
    | UpdateCaseServiceDetail
    | UpdateCaseServiceDetailField
    | RemoveCaseServiceDetailField
    | AddCaseServiceDetailField
    | ReorderCaseServiceDetailFields
    | DeleteCaseServiceDetail
    | RemoveCase
    | SetOneOffProductSelectionDialogOpen
    | LoadedOrganizePage
    | LoadedTrackingPage
    | LoadingTrackingPage
    | FailedTrackingPageLoad
    | RememberThemeChanged
    | IncreaseCaseNoteCountBy1
    | DecreaseCaseNoteCountBy1
    | AddSelectedCaseType
    | DeleteSelectedCaseType
    | DeathCertificateOutdated
    | DeathCertificateOutdatedDismissed
    | LoadingPrivateCase
    | LoadedPrivateCase
    | LoadPrivateCaseFailed
    | LoadingPublicCase
    | LoadedPublicCase
    | LoadPublicCaseFailed
    | CheckingForCase
    | CheckedForCase
    | CaseMoved
    ;

export type CaseTableAction =
    | CaseTableLoading
    | CaseTableLoaded
    | CaseTableLoadFailed
    | SetTotalCaseCount
    | CaseUpdated
    | RemoveCase
    ;

export type CasePreviewAction =
    | CasePreviewLoading
    | CasePreviewLoaded
    | CasePreviewLoadFailed
    ;