// UI-only helper functions for Goods & Services
import { ProductCategory, ProductCategoryEnum, ProductUX, ProductContractItemUX, ProductItem } from '../shared/types';
import { isAllowanceCredit } from '../shared/goods_and_services/utils';

export const removeHiddenProductItems = (productItems: ProductItem[]): ProductItem[] =>
    productItems.filter((productItem) =>
        !productItem.product && (!productItem.contractItem || !isAllowanceCredit(productItem.contractItem)) ||
        productItem.product && !productItem.product.is_hidden && !productItem.packageItem
    );

export const filterProductsByTabType = (
    products: ProductUX[],
    tabType: ProductCategory | 'contract' | 'payment' | 'details',
) => products.filter((product) => {
    if (tabType === ProductCategoryEnum.care_of_loved_one) {
        return (
            product.category === ProductCategoryEnum.care_of_loved_one ||
            product.category === ProductCategoryEnum.transportation ||
            product.category === ProductCategoryEnum.equipment_facilities_staff
        );
    } else if (tabType === 'contract' || tabType === 'payment' || tabType === 'details') {
        return true;
    } else {
        return product.category === tabType;
    }
});

export const getProductCategoriesForTabType = (
    tabType: ProductCategory | 'contract' | 'payment' | 'details',
): ProductCategory[] | null => {
    if (tabType === ProductCategoryEnum.care_of_loved_one) {
        return [
            ProductCategoryEnum.care_of_loved_one,
            ProductCategoryEnum.transportation,
            ProductCategoryEnum.equipment_facilities_staff
        ];
    } else if (tabType === 'contract' || tabType === 'payment' || tabType === 'details') {
        return null;
    } else {
        return [tabType];
    }
};

export const filterContractItemsByTabType = (
    items: ProductContractItemUX[],
    tabType: ProductCategory | 'contract' | 'payment' | 'details',
) => items.filter((item) => {
    if (tabType === ProductCategoryEnum.care_of_loved_one) {
        return (
            item.category === ProductCategoryEnum.care_of_loved_one ||
            item.category === ProductCategoryEnum.transportation ||
            item.category === ProductCategoryEnum.equipment_facilities_staff
        );
    } else if (tabType === 'contract' || tabType === 'payment' || tabType === 'details') {
        return true;
    } else {
        return item.category === tabType;
    }
});
