import { EntityCaseRole, isEntityCaseRole } from '../../../shared/types';

import GGroupButtons from '../../common/GGroupButtons';
import { APP_SECONDARY_COLOR, SKYBLUE_COLOR_2 } from '../../../constants/colorVariables';
import { EntityTypeLookup } from './HelperUserSettings';
import { alpha, Theme } from '@mui/material/styles';
import makeStyles from "@mui/styles/makeStyles";
import { Grid } from '@mui/material';
import classNames from 'classnames';

interface Props {
    setSettingsUserButton: (entityCaseRole: EntityCaseRole) => void;
    entityCaseRole: EntityCaseRole | null;
    disabled?: boolean;
    controlClasses?: Object;
    rootClasses?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    userButtonsGrid: {
        textAlign: 'center',
        marginBottom: 10,
        '@supports (-webkit-touch-callout: none)': {
            '& button:nth-of-type(2)': {
                marginTop: -2
            }
        }
    },
    adminButton: {
        borderRight: 0,
        color: SKYBLUE_COLOR_2,
        '&:hover': {
            borderRight: 0,
            background: alpha(SKYBLUE_COLOR_2, 0.2),
        }
    },
    guestButton: {
        color: APP_SECONDARY_COLOR,
        '&:hover': {
            background: alpha(APP_SECONDARY_COLOR, 0.2),
        }
    },
    adminButtonActive: {
        background: SKYBLUE_COLOR_2,
        color: `${theme.palette.common.white} !important`,
        '&:hover': {
            background: `${alpha(SKYBLUE_COLOR_2, 0.8)} !important`
        }
    },
    guestButtonActive: {
        background: APP_SECONDARY_COLOR,
        color: `${theme.palette.common.white} !important`,
        '&:hover': {
            background: `${alpha(APP_SECONDARY_COLOR, 0.8)} !important`
        }
    },
    gGroupButtonsStyles: {
        minWidth: 128,
        minHeight: 40,
    },
}), { name: 'AdminGuestToggleButton' });

const AdminGuestToggleButton = (props: Props) => {

    const { setSettingsUserButton, entityCaseRole, disabled, controlClasses, rootClasses } = props;

    const classes = useStyles();

    return (
        <Grid item xs={12} className={classNames(classes.userButtonsGrid, rootClasses)}>
            <GGroupButtons
                buttons={[
                    {
                        label: EntityTypeLookup[EntityCaseRole.admin],
                        value: EntityCaseRole.admin,
                        className: classes.adminButton
                    }, {
                        label: EntityTypeLookup[EntityCaseRole.guest],
                        value: EntityCaseRole.guest,
                        className: classes.guestButton
                    }
                ]}
                disabled={disabled}
                activeButton={entityCaseRole}
                onClick={(value) => isEntityCaseRole(value) && setSettingsUserButton(value)}
                activeClass={entityCaseRole === EntityCaseRole.admin &&
                    classes.adminButtonActive || classes.guestButtonActive
                }
                controlClasses={controlClasses}
            />
        </Grid>
    );
};

export default AdminGuestToggleButton;
