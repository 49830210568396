import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types';
import { UserProfile, AssetType, FuneralHomePublic, getValidator, GatherCaseUX } from '.';

export type ProductCategory = 'care_of_loved_one'
    | 'transportation'
    | 'equipment_facilities_staff'
    | 'casket'
    | 'urn'
    | 'vault'
    | 'cemetery'
    | 'memorial'
    | 'flowers'
    | 'cash_advance';

export function isProductCategoryType(value: string): value is ProductCategory {
    return (
        value === 'care_of_loved_one' ||
        value === 'transportation' ||
        value === 'equipment_facilities_staff' ||
        value === 'casket' ||
        value === 'urn' ||
        value === 'vault' ||
        value === 'cemetery' ||
        value === 'memorial' ||
        value === 'flowers' ||
        value === 'cash_advance'
    );
}

export enum ProductCategoryEnum {
    care_of_loved_one = 'care_of_loved_one',
    transportation = 'transportation',
    equipment_facilities_staff = 'equipment_facilities_staff',
    casket = 'casket',
    urn = 'urn',
    vault = 'vault',
    cemetery = 'cemetery',
    memorial = 'memorial',
    flowers = 'flowers',
    cash_advance = 'cash_advance',
}

export enum ProductView {
    table_view = 'table_view',
    grid_view = 'grid-view'
}

export const ProductCategoryDisplayLookup = {
    [ProductCategoryEnum.care_of_loved_one]: 'Care of Loved One',
    [ProductCategoryEnum.transportation]: 'Transportation',
    [ProductCategoryEnum.equipment_facilities_staff]: 'Use of Facilities',
    [ProductCategoryEnum.casket]: 'Casket',
    [ProductCategoryEnum.urn]: 'Urn',
    [ProductCategoryEnum.vault]: 'Vault',
    [ProductCategoryEnum.cemetery]: 'Cemetery',
    [ProductCategoryEnum.memorial]: 'Memorial',
    [ProductCategoryEnum.flowers]: 'Flowers',
    [ProductCategoryEnum.cash_advance]: 'Cash Advance',
};

export enum ProductCategoryGroup {
    professional_services = 'professional_services',
    merchandise = 'merchandise',
    cash_advance = 'cash_advance',
    other = 'other',
}
export const ProductCategoryGroupDisplayLookup = {
    [ProductCategoryGroup.professional_services]: 'Professional Services',
    [ProductCategoryGroup.merchandise]: 'Merchandise',
    [ProductCategoryGroup.cash_advance]: 'Cash Advance',
    [ProductCategoryGroup.other]: 'Other',
};

export function isProductCategory(c: unknown): c is ProductCategory {
    const category = c as ProductCategory;
    return Boolean(ProductCategoryEnum[category]);
}

export function isProductCategoryEnum(c: unknown): c is ProductCategoryEnum {
    return Boolean(typeof c === 'string' && ProductCategoryEnum[c]);
}

export type ByProductCategory<T> = {
    [category in ProductCategory]?: T[];
};

export type PricingModel = 'fixed' | 'base_plus_variable' | 'variable' | 'manual' | 'allowance';

export enum PricingModelEnum {
    fixed = 'fixed',
    base_plus_variable = 'base_plus_variable',
    variable = 'variable',
    manual = 'manual',
    allowance = 'allowance',
}

export const PricingModelDisplayLookup = {
    [PricingModelEnum.fixed]: 'Fixed Price',
    [PricingModelEnum.base_plus_variable]: 'Base + Variable',
    [PricingModelEnum.variable]: 'Variable Price',
    [PricingModelEnum.manual]: 'Manual Price',
    [PricingModelEnum.allowance]: 'Allowance',
};

export const isPricingModel = (p: unknown): p is PricingModel => {
    const model = p as PricingModel;
    return Boolean(PricingModelEnum[model]);
};

export enum PrintPageSize {
    Letter = 'Letter',
    Legal = 'Legal',
}

const PrintPageSizeDefinition = t.union([
    t.literal(PrintPageSize.Letter),
    t.literal(PrintPageSize.Legal),
]);

export const PrintPageSizeDisplayLookup = {
    [PrintPageSize.Letter]: 'Letter (8.5 x 11 in)',
    [PrintPageSize.Legal]: 'Legal (8.5 x 14 in)',
};

export const isPrintPageSize = (str: string): str is PrintPageSize => {
    return Boolean(PrintPageSize[str]);
};

export enum ContractPackageView {
    list = 'list',
    grouped = 'grouped',
    package_only = 'package_only',
}

const ContractPackageViewDefinition = t.union([
    t.literal(ContractPackageView.list),
    t.literal(ContractPackageView.grouped),
    t.literal(ContractPackageView.package_only),
]);

// ------> ContractOptions <------

// ---> ContractOptionsRecord <---
export interface ContractOptionsRecord {
    funeral_home_id: number;
    legal_top: string | null;
    legal_bottom: string | null;
    legal_pro_services: string | null;
    legal_merchandise: string | null;
    legal_cash_advances: string | null;
    legal_contract_disclaimer: string | null;
    legal_invoice: string | null;
    default_taxation_method: ContractTaxationMethod;
    default_contract_tax_rate: number | null;
    default_item_tax_rate: number | null;
    include_fh_license: boolean;
    include_fh_extra_field: boolean;
    fh_extra_field_name: string | null;
    is_fh_extra_field_req: boolean;
    include_fam_address: boolean;
    include_fam_phone: boolean;
    include_fam_email: boolean;
    include_fam_extra_field: boolean;
    fam_extra_field_name: string | null;
    is_fam_extra_field_req: boolean;
    hide_package_item_prices: boolean;
    // fh_cloned_from: number | null;  // on table but not used in code
    // fh_cloned_time: Date | null;  // on table but not used in code
    print_page_size: PrintPageSize;
    use_five_category_groups: boolean;
    use_two_category_groups: boolean;
    package_view: ContractPackageView;
    equal_contract_spacing: boolean;
}

// ---> ContractOptionsUX <---
const contractOptionsUXDefinition = {
    legal_top: t.union([t.string, t.null]),
    legal_bottom: t.union([t.string, t.null]),
    legal_pro_services: t.union([t.string, t.null]),
    legal_merchandise: t.union([t.string, t.null]),
    legal_cash_advances: t.union([t.string, t.null]),
    legal_contract_disclaimer: t.union([t.string, t.null]),
    legal_invoice: t.union([t.string, t.null]),
    default_taxation_method: t.string,
    default_contract_tax_rate: t.union([t.number, t.null]),
    default_item_tax_rate: t.union([t.number, t.null]),
    include_fh_license: t.boolean,
    include_fh_extra_field: t.boolean,
    fh_extra_field_name: t.union([t.string, t.null]),
    is_fh_extra_field_req: t.boolean,
    include_fam_address: t.boolean,
    include_fam_phone: t.boolean,
    include_fam_email: t.boolean,
    include_fam_extra_field: t.boolean,
    fam_extra_field_name: t.union([t.string, t.null]),
    is_fam_extra_field_req: t.boolean,
    hide_package_item_prices: t.boolean,
    print_page_size: PrintPageSizeDefinition,
    use_five_category_groups: t.boolean,
    use_two_category_groups: t.boolean,
    package_view: ContractPackageViewDefinition,
    equal_contract_spacing: t.boolean,
};
const ContractOptionsUXType = t.type(contractOptionsUXDefinition);

export interface ContractOptionsUX extends t.TypeOf<typeof ContractOptionsUXType> {
    default_taxation_method: ContractTaxationMethod;
    print_page_size: PrintPageSize;
    package_view: ContractPackageView;
}

export class ContractOptionsUX {
    public static fromPatchRequest = getValidator<Partial<ContractOptionsUX>>(t.partial(ContractOptionsUXType.props));

    public static generateDefaults(): ContractOptionsUX {
        return {
            legal_top: null,
            legal_bottom: null,
            legal_pro_services: null,
            legal_merchandise: null,
            legal_cash_advances: null,
            legal_contract_disclaimer: null,
            legal_invoice: null,
            default_taxation_method: 'per_item_basis',
            default_contract_tax_rate: null,
            default_item_tax_rate: null,
            include_fh_license: false,
            include_fh_extra_field: false,
            fh_extra_field_name: null,
            is_fh_extra_field_req: false,
            include_fam_address: false,
            include_fam_phone: false,
            include_fam_email: false,
            include_fam_extra_field: false,
            fam_extra_field_name: null,
            is_fam_extra_field_req: false,
            hide_package_item_prices: false,
            print_page_size: PrintPageSize.Letter,
            use_five_category_groups: false,
            use_two_category_groups: false,
            package_view: ContractPackageView.list,
            equal_contract_spacing: false,
        };
    }
}

// ------> ProductTag <------

// ---> ProductTagRecord <---
const productTagRecordDefinition = {
    category: t.string,
    name: t.string,
    value: t.string,
};
const ProductTagRecordType = t.type(productTagRecordDefinition);

export interface ProductTagRecord extends t.TypeOf<typeof ProductTagRecordType> {
    category: ProductCategory;
}

export class ProductTagRecord {
    public static fromRequest = getValidator<ProductTagRecord>(ProductTagRecordType);
}

// ------> Product <------

export const isProductSummaryKey = (a: unknown, productSummary: ProductSummary): a is keyof ProductSummary => {
    const summaryKey = a as keyof ProductSummary;
    return productSummary[summaryKey] !== undefined;
};

// ---> ProductSummary <---
export interface ProductSummary {
    id: number;
    name: string;
    asset_type: AssetType;
    category: ProductCategory;
    model_number: string | null;
    photos: string[];
    tags: Record<string, string>;
    pricing_model: PricingModel;
    base_price: number | null;
    base_quantity: number | null;
    var_price: number | null;
    var_increment: number | null;
    var_increment_units: string | null;
    var_default_quantity: number | null;
    display_model_number: boolean;
    display_manufacturer: boolean;
    display_tags: boolean;
    persistent_contract_text: string | null;
    bold_contract_text: boolean;
    underline_contract_text: boolean;
    indent_contract_text: boolean;
    manufacturer_name: string | null;
    tax_rate_id: number | null;
    is_hidden: boolean;
}

// ---> ProductRecord <---
export interface ProductRecord extends Omit<ProductSummary, 'manufacturer_name'> {
    id: number;
    name: string;
    description: string | null;
    funeral_home_id: number | null;
    category_rank: number;
    asset_type: AssetType;
    cloned_from: number | null;
    cloned_time: Date | null;
    // fh_cloned_from: number | null;  // on table but not used in code
    // fh_cloned_time: Date | null;  // on table but not used in code
    created_by: number | null;
    created_time: Date;
    updated_by: number | null;
    updated_time: Date;
    deleted_by: number | null;
    deleted_time: Date | null;
    sku: string | null;
    manufacturer_id: number | null;
    category: ProductCategory;
    model_number: string | null;
    external_link: string | null;
    is_package_exclusive: boolean;
    is_hidden: boolean;
    featured_rank: number | null;
    photos: string[];
    tags: Record<string, string>;
    pricing_model: PricingModel;
    cost: number | null;
    base_price: number | null;
    base_quantity: number | null;
    var_price: number | null;
    var_increment: number | null;
    var_increment_units: string | null;
    var_max_quantity: number | null;
    var_default_quantity: number | null;
    tax_rate_id: number | null;
    is_always_displayed: boolean;
    use_na_when_always_displayed: boolean;
    display_model_number: boolean;
    display_manufacturer: boolean;
    display_tags: boolean;
    persistent_contract_text: string | null;
    bold_contract_text: boolean;
    underline_contract_text: boolean;
    indent_contract_text: boolean;
    show_on_website: boolean;
    show_price_on_website: boolean;
}

// ---> ProductRequest <---
const productRequestRequired = {
    name: t.string,
    description: t.union([t.string, t.null]),
    sku: t.union([t.string, t.null]),
    manufacturer_id: t.union([t.number, t.null]),
    category: t.string,
    model_number: t.union([t.string, t.null]),
    external_link: t.union([t.string, t.null]),
    is_package_exclusive: t.boolean,
    is_hidden: t.boolean,
    featured_rank: t.union([t.number, t.null]),
    photos: t.array(t.string),
    tags: t.dictionary(t.string, t.string),
    pricing_model: t.string,
    cost: t.union([t.number, t.null]),
    base_price: t.union([t.number, t.null]),
    base_quantity: t.union([t.number, t.null]),
    var_price: t.union([t.number, t.null]),
    var_increment: t.union([t.number, t.null]),
    var_increment_units: t.union([t.string, t.null]),
    var_max_quantity: t.union([t.number, t.null]),
    var_default_quantity: t.union([t.number, t.null]),
    tax_rate_id: t.union([t.number, t.null]),
    is_always_displayed: t.boolean,
    use_na_when_always_displayed: t.boolean,
    display_model_number: t.boolean,
    display_manufacturer: t.boolean,
    display_tags: t.boolean,
    persistent_contract_text: t.union([t.string, t.null]),
    bold_contract_text: t.boolean,
    underline_contract_text: t.boolean,
    indent_contract_text: t.boolean,
    show_on_website: t.boolean,
    show_price_on_website: t.boolean,
};

const ProductRequestType = t.type(productRequestRequired);

export interface ProductRequest extends t.TypeOf<typeof ProductRequestType> {
    category: ProductCategory;
    pricing_model: PricingModel;
}

export class ProductRequest {
    public static fromRequest = getValidator<ProductRequest>(ProductRequestType);
}

// ---> ProductPatchRequest <---
const productPatchRequestDefinition = {
    show_on_website: t.boolean,
    show_price_on_website: t.boolean,
};
const ProductPatchRequestType = t.partial(productPatchRequestDefinition);
export interface ProductPatchRequest extends t.TypeOf<typeof ProductPatchRequestType> {
}

export class ProductPatchRequest {
    public static fromRequest = getValidator<ProductPatchRequest>(ProductPatchRequestType);
}

// ---> ProductUXRecord <---
export interface ProductUXRecord extends ProductRecord {
    tax_rate_name: string | null;
    tax_rate_description: string | null;
    manufacturer_name: string | null;
    manufacturer_link: string | null;
}

// ---> ProductUX <---
export interface ProductUX extends ProductUXRecord {
}

// ------> ProductManufacturer <------

// ---> ProductManufacturerRecord <---
export interface ProductManufacturerRecord {
    id: number;
    name: string;
    external_link: string | null;
}

// ---> ProductManufacturerRequest <---
const productManufacturerRequestRequired = {
    name: t.string,
    external_link: t.union([t.string, t.null]),
};
const ProductManufacturerRequestType = t.type(productManufacturerRequestRequired);

export interface ProductManufacturerRequest extends t.TypeOf<typeof ProductManufacturerRequestType> {
}

export class ProductManufacturerRequest {
    public static fromRequest = getValidator<ProductManufacturerRequest>(ProductManufacturerRequestType);
}

// ------> ProductTaxRateBracket <------

// ---> ProductTaxRateBracketRecord <---
export interface ProductTaxRateBracketRecord {
    id: number;
    tax_rate_id: number;
    minimum: number;
    maximum: number | null;
    rate: number;
}

// ---> ProductTaxRateBracketRequest <---
const productTaxRateBracketRequestRequired = {
    minimum: t.number,
    maximum: t.union([t.number, t.null]),
    rate: t.number,
};
const ProductTaxRateBracketRequestType = t.type(productTaxRateBracketRequestRequired);

export interface ProductTaxRateBracketRequest extends t.TypeOf<typeof ProductTaxRateBracketRequestType> {
}

// ------> ProductTaxRate <------

// ---> ProductTaxRateRecord <---
export interface ProductTaxRateRecord {
    id: number;
    asset_type: AssetType;
    name: string;
    description: string | null;
    state: string;
    county: string;
}

// ---> ProductTaxRateUX <---
export interface ProductTaxRateUX extends ProductTaxRateRecord {
    brackets: ProductTaxRateBracketRequest[];
}

// ---> ProductTaxRateRequest <---
const productTaxRateRequestRequired = {
    name: t.string,
    description: t.union([t.string, t.null]),
    state: t.string,
    county: t.string,
};

const productTaxRateRequestOptional = {
    brackets: t.array(ProductTaxRateBracketRequestType),
};
const ProductTaxRateRequestType = t.intersection([
    t.type(productTaxRateRequestRequired),
    t.partial(productTaxRateRequestOptional),
]);

export interface ProductTaxRateRequest extends t.TypeOf<typeof ProductTaxRateRequestType> {
    brackets: ProductTaxRateBracketRequest[];
}

export class ProductTaxRateRequest {
    public static fromRequest = getValidator<ProductTaxRateRequest>(ProductTaxRateRequestType);
}

// ------> ProductPackageItemOption <------

// ---> ProductPackageItemOptionRecord <---
export interface ProductPackageItemOptionRecord {
    id: number;
    package_item_id: number;
    product_id: number | null;
    sub_package_id: number | null;
    // fh_cloned_from: number | null;  // on table but not used in code
    // fh_cloned_time: Date | null;  // on table but not used in code
}

// ---> ProductPackageItemOptionRequest <---
const productPackageItemOptionRequestDefinition = {
    product_id: t.union([t.number, t.null]),
    sub_package_id: t.union([t.number, t.null]),
};
const ProductPackageItemOptionRequestType = t.type(productPackageItemOptionRequestDefinition);

export interface ProductPackageItemOptionRequest extends t.TypeOf<typeof ProductPackageItemOptionRequestType> {
}

// ---> ProductPackageItemOptionUX <---
export interface ProductPackageItemOptionUX {
    product: ProductUX | null;
    sub_package: ProductSubPackage | null;
}

// ---> ProductSubPackageItemOption <---
export interface ProductSubPackageItemOption {
    product: ProductUX;
}

export const isPackageItemOption = (
    opt: ProductPackageItemOptionUX | ProductSubPackageItemOption
): opt is ProductPackageItemOptionUX => {
    return 'sub_package' in opt && opt.sub_package !== undefined;
};

// ------> ProductPackageItem <------

export type ProductItem = {
    key: string;
    category: ProductCategory;
    product?: ProductUX;
    contractItem?: ProductContractItemUX;
    packageItem?: ProductPackageItemUX | ProductSubPackageItem;
};

export const isProductItem = (obj: unknown): obj is ProductItem => {
    const product = obj as ProductItem;
    return Boolean(typeof product.key === 'string' && isProductCategory(product.category));
};

// ---> ProductPackageItemRecord <---
export interface ProductPackageItemRecord {
    id: number;
    package_id: number;
    name: string;
    max_selections: number;
    category: ProductCategory | null;
    // fh_cloned_from: number | null;  // on table but not used in code
    // fh_cloned_time: Date | null;  // on table but not used in code
}

// ---> ProductPackageItemRequest <---
const productPackageItemRequestRequired = {
    name: t.string,
    max_selections: t.number,
    category: t.union([t.string, t.null]),
    options: t.array(ProductPackageItemOptionRequestType),
};
const productPackageItemRequestOptional = {
    id: t.number,
};
const ProductPackageItemRequestType = t.intersection([
    t.type(productPackageItemRequestRequired),
    t.partial(productPackageItemRequestOptional)
]);

export interface ProductPackageItemRequest extends t.TypeOf<typeof ProductPackageItemRequestType> {
    category: ProductCategory | null;
    options: ProductPackageItemOptionRequest[];
}

// ---> ProductPackageItemUXRecord <---
export interface ProductPackageItemUXRecord extends ProductPackageItemRecord {
    options: ProductPackageItemOptionRequest[];
}

// ---> ProductPackageItemUX <---
export interface ProductPackageItemUX extends ProductPackageItemRecord {
    options: ProductPackageItemOptionUX[];
}

// ---> ProductSubPackageItem <---
export interface ProductSubPackageItem extends ProductPackageItemRecord {
    options: ProductSubPackageItemOption[];
}

export const isPackageItem = (
    item: { options: (ProductPackageItemOptionUX | ProductSubPackageItemOption)[] }
): item is ProductPackageItemUX => {
    return item.options.every(isPackageItemOption);
};

// ------> ProductPackage <------

export enum FuneralType {
    burial = 'burial',
    cremation = 'cremation',
    military = 'military',
    service = 'service',
}

const FuneralTypeDefinition = t.union([
    t.literal(FuneralType.burial),
    t.literal(FuneralType.cremation),
    t.literal(FuneralType.military),
    t.literal(FuneralType.service),
]);

// ---> ProductPackageRecord <---
export interface ProductPackageRecord {
    id: number;
    name: string;
    description: string | null;
    funeral_type: FuneralType[];
    category: ProductCategory | null;
    funeral_home_id: number;
    price: number | null;
    asset_type: AssetType;
    photos: string[];
    is_discount_displayed: boolean;
    show_on_website: boolean;
    show_price_on_website: boolean;
    replaces_package: number | null;
    original_package: number | null;
    created_by: number;
    created_time: Date;
    updated_by: number;
    updated_time: Date;
    deleted_by: number | null;
    deleted_time: Date | null;
    fh_cloned_from: number | null;  // on table but not used in code
    fh_cloned_time: Date | null;  // on table but not used in code
    rank: number;
}

// ---> ProductPackageUXRecord <---
export interface ProductPackageUXRecord extends ProductPackageRecord {
    items: ProductPackageItemUXRecord[];
}

// ---> ProductPackageUX <---
export interface ProductPackageUX extends ProductPackageRecord {
    items: ProductPackageItemUX[];
}

export const isProductPackageUX = (obj: unknown): obj is ProductPackageUX => {
    const productPackage = obj as ProductPackageUX;
    return Boolean(
        typeof productPackage.id === 'number' &&
        typeof productPackage.name === 'string' &&
        (typeof productPackage.description === 'string' || productPackage.description === null) &&
        isProductCategory(productPackage.category)
    );
};

export type PackageSummary = Pick<ProductPackageRecord, 'id' | 'name' | 'category'>;

export interface DeleteFHProductResponse {
    deletedProduct: ProductRecord | null;
    packagesUsingProduct: PackageSummary[];
}

// ---> ProductSubPackage <---
export interface ProductSubPackage extends ProductPackageRecord {
    items: ProductSubPackageItem[];
}

export const isSubPackage = (
    pkg: { items: (ProductPackageItemUX | ProductSubPackageItem)[] }
): pkg is ProductSubPackage => {
    return !pkg.items.some(isPackageItem);
};

// ---> ProductPackageRequest <---
const productPackageRequestRequired = {
    name: t.string,
    description: t.union([t.string, t.null]),
    funeral_type: t.array(FuneralTypeDefinition),
    category: t.union([t.string, t.null]),
    price: t.union([t.number, t.null]),
    photos: t.array(t.string),
    is_discount_displayed: t.boolean,
    show_on_website: t.boolean,
    show_price_on_website: t.boolean,
    items: t.array(ProductPackageItemRequestType),
};

const ProductPackageRequestType = t.type(productPackageRequestRequired);

export interface ProductPackageRequest extends t.TypeOf<typeof ProductPackageRequestType> {
    funeral_type: FuneralType[];
    category: ProductCategory | null;
    items: ProductPackageItemRequest[];
}

export class ProductPackageRequest {
    public static fromRequest = getValidator<ProductPackageRequest>(ProductPackageRequestType);
}

export enum ContractItemType {
    normal = 'normal',
    package_discount = 'package_discount',
    package_placeholder = 'package_placeholder',
    allowance_credit = 'allowance_credit',
    contract_discount = 'contract_discount',
}

// ------> ProductContractItem <------

interface BaseProductContractItem {
    id: string;
    contract_id: number;
    package_id: number | null;
    sub_package_id: number | null;
    package_item_id: number | null;
    allowance_item: string | null;
    insert_revision: number;
    delete_revision: number | null;
    replaces_item: string | null;
    original_item: string | null;
    type: ContractItemType;
    category: ProductCategory | null;
    name: string;
    description: string | null;
    sku: string | null;
    note: string | null;
    quantity: number;
    quantity_in_package: number | null;
    list_price: number;
    price_adjustment: number;
    tax_total: number;
    tax_rate_id: number | null;
    tax_rate_brackets: string | null;
    asset_type: AssetType;
    created_time: Date;
    updated_time: Date;
    display_name: string | null;
}

// ---> ProductContractItemRecord <---
export interface ProductContractItemRecord extends BaseProductContractItem {
    product_id: number | null;
}

// ---> ProductContractItemUX <---
export interface ProductContractItemUX extends BaseProductContractItem {
    product: ProductUX | null;
    category_rank: number | null;
}

export const contractItemUXToRecord = (uxItem: Partial<ProductContractItemUX>): Partial<ProductContractItemRecord> => {
    if (uxItem.product === undefined) {
        return uxItem;
    } else {
        const { product, category_rank, ...uxItemWithoutUXFields } = uxItem;
        return {
            ...uxItemWithoutUXFields,
            product_id: product !== null ? product.id : null,
        };
    }
};

// ---> ProductContractItemCreateRequest <---
const contractItemCreateRequestDefinition = {
    product_id: t.number,
    package_item_id: t.union([t.number, t.null]),
    allowance_item: t.union([t.string, t.null]),
    quantity: t.number,
    list_price: t.union([t.number, t.null]),
};
const ProductContractItemCreateRequestType = t.type(contractItemCreateRequestDefinition);

export interface ProductContractItemCreateRequest extends t.TypeOf<typeof ProductContractItemCreateRequestType> {
}

export class ProductContractItemCreateRequest {
    public static fromRequest = getValidator<ProductContractItemCreateRequest>(ProductContractItemCreateRequestType);
}

// ---> ProductCustomContractItemRequest <---
const customContractItemRequestDefinition = {
    category: t.string,
    tax_rate_id: t.union([t.number, t.null]),
    list_price: t.number,
    name: t.string,
};
const ProductCustomContractItemRequestType = t.type(customContractItemRequestDefinition);

export interface ProductCustomContractItemRequest extends t.TypeOf<typeof ProductCustomContractItemRequestType> {
    category: ProductCategory;
}

export class ProductCustomContractItemRequest {
    public static fromRequest = getValidator<ProductCustomContractItemRequest>(ProductCustomContractItemRequestType);
}

// ---> ProductContractItemUpdateRequest <---
const contractItemUpdateRequestDefinition = {
    price_adjustment: t.number,
    note: t.union([t.string, t.null]),
    quantity: t.number,
    allowance_item: t.union([t.string, t.null]),
    list_price: t.number,
    name: t.string,
    display_name: t.union([t.string, t.null]),
};

const ProductContractItemUpdateRequestType = t.partial(contractItemUpdateRequestDefinition);

export interface ProductContractItemUpdateRequest extends t.TypeOf<typeof ProductContractItemUpdateRequestType> {
}

export class ProductContractItemUpdateRequest {
    public static fromRequest = getValidator<ProductContractItemUpdateRequest>(ProductContractItemUpdateRequestType);
}

// ------> ProductContractRevision <------

// ---> ProductContractRevisionRecord <---
export interface ProductContractRevisionRecord {
    id: number;
    photos: string[];
    contract_id: number;
    invoice_id: number | null;
    taxation_method: ContractTaxationMethod;
    created_by: number;
    created_time: Date;
    frozen_by: number | null;
    frozen_time: Date | null;
    revision_number: number;
}

// ---> ProductContractRevisionUX <---
export interface ProductContractRevisionUX extends ProductContractRevisionRecord {

}

// ------> ProductContractViewer <------

// ---> ProductContractViewerRecord <---
export interface ProductContractViewerRecord {
    contract_id: number;
    user_profile_id: number;
}

// ---> ProductContractViewerUX <---
export interface ProductContractViewerUX {
    is_funeral_home_user: boolean;
    user: UserProfile;
}

// ------> ProductContract <------

export type ContractTaxationMethod = 'per_item_basis' | 'contract_basis' | 'exempt';

export enum ContractTaxationMethodEnum {
    per_item_basis = 'per_item_basis',
    contract_basis = 'contract_basis',
    exempt = 'exempt',
}

export const ContractTaxationMethodDisplayLookup = {
    [ContractTaxationMethodEnum.contract_basis]: 'Statement Level',
    [ContractTaxationMethodEnum.exempt]: 'NON-TAXABLE',
    [ContractTaxationMethodEnum.per_item_basis]: 'Item Level',
};

// ---> ProductContractRecord <---
export interface ProductContractRecord {
    id: number;
    funeral_home_case_id: number;
    created_by: number;
    created_time: Date;
    taxation_method: ContractTaxationMethod;
    tax_rate_id: number | null;
    sub_total: string | null;
    tax_total: string | null;
    asset_type: AssetType;
    hide_revisions: boolean;
    statement_date: Date | null;
    latest_revision_id: number | null; // only null for a second between contract creation and revision creation
}

// ---> ProductContractUX <---
export interface ProductContractUX extends Omit<ProductContractRecord, 'sub_total' | 'tax_total'> {
    items: ProductContractItemUX[][];
    persistent_products: ProductUX[];
    viewers: ProductContractViewerUX[];
    is_frozen: boolean;
    revisions: number[];
    photos: string[];
    contract_options: ContractOptionsUX;
    contract_disclaimers: ContractDisclaimerUX[];
    contract_packages: (ProductPackageUX | ProductSubPackage)[];
    sub_total: number | null;
    tax_total: number | null;
    latest_revision_id: number;
}

// ---> ProductContractRequest <---
const productContractRequestDefinition = {
    taxation_method: t.string,
    tax_rate_id: t.union([t.number, t.null]),
    hide_revisions: t.boolean,
    statement_date: t.union([DateFromISOString, t.null]),
};
const ProductContractRequestType = t.partial(productContractRequestDefinition);

export interface ProductContractRequest extends t.TypeOf<typeof ProductContractRequestType> {
    taxation_method?: ContractTaxationMethod;
}

export class ProductContractRequest {
    public static fromRequest = getValidator<ProductContractRequest>(ProductContractRequestType);
}

// ---> ProductContractUXSummary <---
export interface ProductContractUXSummary {
    id: number;
    is_frozen: boolean;
    total: number | null;
    professional_services_total: number;
    merchandise_total: number;
    cash_advances_total: number;
    asset_type: AssetType;
    package_names: string[];
    case_collected_total: GatherCaseUX['collected_total'];
    case_expense_total: GatherCaseUX['expense_total'];
}

// ------> ProductSupplier <------
/**
 * ProductSupplierPhoto
 */

export interface ProductSupplierPhotoRecord {
    id: number;
    supplier_id: number;
    photo_id: number;
    youtube_data: string;
}
export interface ProductSupplierRecord {
    id: number;
    name: string;
}

export interface ProductSupplierFuneralHomeRecord {
    supplier_id: number;
    funeral_home_id: number;
    category: ProductCategory;
    // fh_cloned_from: number | null;  // on table but not used in code
    // fh_cloned_time: Date | null;  // on table but not used in code
}

/**
 * ProductSupplierPhotoUX 
 */
export interface ProductSupplierPhotoUX {
    id: number;
    supplier_id: number;
    photo_id: number;
    youtube_data: string;
    photo_public_id: string;
}

const productSupplierPhotoRequestDefinition = {
    photo_public_id: t.string,
    youtube_data: t.string,
};
const ProductSupplierPhotoRequestType = t.type(productSupplierPhotoRequestDefinition);
export interface ProductSupplierPhotoRequest extends t.TypeOf<typeof ProductSupplierPhotoRequestType> {
}

// ---> ProductSupplierRequest <---
const productSupplierRequestRequired = {
    name: t.string,
    photos: t.array(ProductSupplierPhotoRequestType),
};
const ProductSupplierRequestType = t.type(productSupplierRequestRequired);

export interface ProductSupplierRequest extends t.TypeOf<typeof ProductSupplierRequestType> {
    photos: ProductSupplierPhotoRequest[];
}

export class ProductSupplierRequest {
    public static fromRequest = getValidator<ProductSupplierRequest>(ProductSupplierRequestType);
}

export interface ProductSupplierUX extends ProductSupplierRecord {
    photos: ProductSupplierPhotoRequest[];
}

export interface ProductSupplierUXWithCategory extends ProductSupplierUX {
    category: ProductCategory;
}

// ------> ContractDisclaimer <------
export interface ContractDisclaimerRecord {
    id: number;
    funeral_home_id: number;
    disclaimer: string;
    // fh_cloned_from: number | null;  // on table but not used in code
    // fh_cloned_time: Date | null;  // on table but not used in code
}

export interface ContractDisclaimerUX extends Pick<ContractDisclaimerRecord, 'id' | 'disclaimer'> {
    reason?: string;
}

const contractDisclaimerRequestRequired = {
    disclaimer: t.string,
};
const contractDisclaimerRequestOptional = {
    id: t.number,
};
const ContractDisclaimerRequestType = t.intersection([
    t.type(contractDisclaimerRequestRequired),
    t.partial(contractDisclaimerRequestOptional),
]);

export interface ContractDisclaimerRequest extends t.TypeOf<typeof ContractDisclaimerRequestType> {
}

export class ContractDisclaimerRequest {
    public static fromRequest = getValidator<ContractDisclaimerRequest>(ContractDisclaimerRequestType);
}

// ------> ContractDisclaimerReason <------
export interface ContractDisclaimerReasonRecord {
    disclaimer_id: number;
    contract_id: number;
    reason: string;
}

// ------> ProductRankRequest <------
const productRankRequest = {
    [ProductCategoryEnum.care_of_loved_one]: t.array(t.number),
    [ProductCategoryEnum.transportation]: t.array(t.number),
    [ProductCategoryEnum.equipment_facilities_staff]: t.array(t.number),
};
const ProductRankRequestType = t.partial(productRankRequest);

export interface ProductRankRequest extends t.TypeOf<typeof ProductRankRequestType> {
}

export class ProductRankRequest {
    public static fromRequest = getValidator<ProductRankRequest>(ProductRankRequestType);
}

export interface CloneGPLCheckResult {
    sourcePackageNames: string[];
    sourceProductNames: string[];
    targetPackageNames: string[];
    targetProductNames: string[];
    srcFuneralHome: {
        id: number;
        key: string;
        name: string;
        color: string;
    };
    targetFuneralHome: null | {
        id: number;
        key: string;
        name: string;
        color: string;
    };
}

export interface ProductsInPackages {
    product_id: number;
    product_name: string;
    product_category: ProductCategory;
}

export interface PackageProduct {
    package_id: number;
    package_name: string;
    products: ProductsInPackages[];
}

export interface DeleteGPLCheckResult {
    products: ProductRecord[];
    packageNames: string[];
    packageProducts: PackageProduct[];
    funeralHome: null | {
        id: number;
        key: string;
        name: string;
        color: string;
    };
}

export enum LegalLocation {
    top = 'top',
    bottom = 'bottom',
    service = 'service',
    merchandise = 'merchandise',
    cash_advance = 'cash_advance',
    invoice_language = 'invoice_language',
}

export enum ContractCategoryGrouping {
    Default = 'Default',
    NewYork = 'NewYork',
    NewJersey = 'NewJersey',
}

export interface ContractCategoryGroupConfig {
    title: string;
    subTotalLabel: string;
    legalTextLocation: LegalLocation | null;
    categories: ProductCategoryEnum[];
}

export interface ContractCategoryGroup extends ContractCategoryGroupConfig {
    items: ProductContractItemUX[];
    subTotal: Dinero.Dinero;
}

export interface ContractPackageGroup extends ContractCategoryGroup {
    pkgId: number;
    subPackages: string[];
    pkgPrice: string | null;
}

const ContractCategoryGroupingLookup: Record<ContractCategoryGrouping, ContractCategoryGroupConfig[]> = {

    // Default
    [ContractCategoryGrouping.Default]: [{
        title: 'Professional Services',
        subTotalLabel: 'Total Service Items',
        legalTextLocation: LegalLocation.service,
        categories: [
            ProductCategoryEnum.care_of_loved_one,
            ProductCategoryEnum.transportation,
            ProductCategoryEnum.equipment_facilities_staff,
        ],
    }, {
        title: 'Merchandise',
        subTotalLabel: 'Total Merchandise Items',
        legalTextLocation: LegalLocation.merchandise,
        categories: [
            ProductCategoryEnum.casket,
            ProductCategoryEnum.urn,
            ProductCategoryEnum.vault,
            ProductCategoryEnum.cemetery,
            ProductCategoryEnum.memorial,
            ProductCategoryEnum.flowers,
        ],
    }, {
        title: 'Cash Advances',
        subTotalLabel: 'Total Cash Advance Items',
        legalTextLocation: LegalLocation.cash_advance,
        categories: [
            ProductCategoryEnum.cash_advance,
        ],
    }],

    // New York
    [ContractCategoryGrouping.NewYork]: [{
        title: 'I. Funeral Home Charges',
        subTotalLabel: 'Total Funeral Home Charges',
        legalTextLocation: LegalLocation.service,
        categories: [
            ProductCategoryEnum.care_of_loved_one,
            ProductCategoryEnum.transportation,
            ProductCategoryEnum.equipment_facilities_staff,
            ProductCategoryEnum.casket,
            ProductCategoryEnum.urn,
            ProductCategoryEnum.vault,
            ProductCategoryEnum.cemetery,
            ProductCategoryEnum.memorial,
            ProductCategoryEnum.flowers,
        ],
    }, {
        title: 'II. Cash Advances',
        subTotalLabel: 'Estimated Total of Cash Advances',
        legalTextLocation: LegalLocation.cash_advance,
        categories: [
            ProductCategoryEnum.cash_advance,
        ],
    }],

    // New Jersey
    [ContractCategoryGrouping.NewJersey]: [{
        title: 'I. Professional services',
        subTotalLabel: 'Total Service Items',
        legalTextLocation: LegalLocation.service,
        categories: [
            ProductCategoryEnum.care_of_loved_one,
        ],
    }, {
        title: 'II. Other Staff and Related Facilities',
        subTotalLabel: 'Total Facility Items',
        legalTextLocation: null,
        categories: [
            ProductCategoryEnum.equipment_facilities_staff,
        ],
    }, {
        title: 'III. Transportation',
        subTotalLabel: 'Total Transportation Items',
        legalTextLocation: null,
        categories: [
            ProductCategoryEnum.transportation,
        ],
    }, {
        title: 'IV. Merchandise',
        subTotalLabel: 'Total Merchandise Items',
        legalTextLocation: LegalLocation.merchandise,
        categories: [
            ProductCategoryEnum.casket,
            ProductCategoryEnum.urn,
            ProductCategoryEnum.vault,
            ProductCategoryEnum.cemetery,
            ProductCategoryEnum.memorial,
            ProductCategoryEnum.flowers,
        ],
    }, {
        title: 'V. Cash Disbursements',
        subTotalLabel: 'Total Cash Advance Items',
        legalTextLocation: LegalLocation.cash_advance,
        categories: [
            ProductCategoryEnum.cash_advance
        ],
    }],
};

export const getContractGrouping = (contractCategoryGrouping: ContractCategoryGrouping, hasEqualSpacing: boolean) => {
    const grouping = ContractCategoryGroupingLookup[contractCategoryGrouping];
    const slicingIndex = contractCategoryGrouping === ContractCategoryGrouping.NewJersey
        ? -2
        : -1;
    const leftGrouping = hasEqualSpacing ? grouping : grouping.slice(0, slicingIndex);
    const rightGrouping = hasEqualSpacing ? [] : grouping.slice(slicingIndex);

    return [leftGrouping, rightGrouping];
};

export interface LoadPublicGPLResponse {
    funeralHome: FuneralHomePublic;
    packages: ProductPackageUX[];
    suppliers: ProductSupplierUXWithCategory[];
    categories: ProductCategory[];
}

export enum GPLContext {
    Public = 'Public',
    BackOffice = 'BackOffice',
    App = 'App',
}


export enum ProductBulkAction {
    delete_selected_products = 'delete_selected_products', // deleted_time, deleted_by
    edit_tax_rate = 'edit_tax_rate', // tax_rate_id
    edit_base_price = 'edit_base_price', // base_price
    edit_unit = 'edit_unit', // var_increment_units
    edit_default_unit_quantity = 'edit_default_unit_quantity', // var_default_quantity
    show_hide_on_public_gpl = 'show_hide_on_public_gpl', // is_hidden
    change_category = 'change_category', // category
    edit_variable_price = 'edit_variable_price', // var_price
    show_hide_on_website = 'show_hide_on_website', // show_on_website
    show_hide_price_on_website = 'show_hide_price_on_website', // show_price_on_website
    edit_styling_on_statement = 'edit_styling_on_statement', // persistent_contract_text, 
        // bold_contract_text, underline_contract_text, indent_contract_text, display_model_number
        // display_manufacturer, display_tags
    edit_pricing_model = 'edit_pricing_model', // pricing_model 
    remove_tag = 'remove_tag', // tags
    add_tag = 'add_tag', // tags
}


/**
 * Funeral home Product Bulk API 
 */
export interface ProductBulkDeleteRequest extends BaseBulkRequest {
    bulkAction: ProductBulkAction.delete_selected_products;
    actionDetails: {};
}
export interface ProductBulkEditTaxRateRequest extends BaseBulkRequest {
    bulkAction: ProductBulkAction.edit_tax_rate;
    actionDetails: Pick<ProductRecord, 'tax_rate_id'>;
}

export interface ProductBulkEditBasePriceRequest extends BaseBulkRequest {
    bulkAction: ProductBulkAction.edit_base_price;
    productIdPriceMap: {productId: number; value: number}[]; 
}
export interface ProductBulkShowHideOnPublicGPLRequest extends BaseBulkRequest {
    bulkAction: ProductBulkAction.show_hide_on_public_gpl;
    actionDetails: Pick<ProductRecord, 'is_hidden'>;
}
export interface ProductBulkCategoryRequest extends BaseBulkRequest {
    bulkAction: ProductBulkAction.change_category;
    actionDetails: Pick<ProductRecord, 'category'>;
}
export interface ProductBulkVariablePriceRequest extends BaseBulkRequest {
    bulkAction: ProductBulkAction.edit_variable_price;
    productIdPriceMap: {productId: number; value: number}[]; 
}
export interface ProductBulkShowOnWebsiteRequest extends BaseBulkRequest {
    bulkAction: ProductBulkAction.show_hide_on_website;
    actionDetails: Pick<ProductRecord, 'show_on_website'>;
}
export interface ProductBulkShowPriceOnWebsiteRequest extends BaseBulkRequest {
    bulkAction: ProductBulkAction.show_hide_price_on_website;
    actionDetails: Pick<ProductRecord, 'show_price_on_website'>;
}
export interface ProductBulkEditUnitRequest extends BaseBulkRequest {
    bulkAction: ProductBulkAction.edit_unit;
    actionDetails: Pick<ProductRecord, 'var_increment_units'>;
}
export interface ProductBulkEditDefaultUnitQuantityRequest extends BaseBulkRequest {
    bulkAction: ProductBulkAction.edit_default_unit_quantity;
    actionDetails: Pick<ProductRecord, 'var_default_quantity'>;
}
export interface ProductFormattingOptions extends Pick<ProductRecord, 'is_always_displayed' 
    | 'use_na_when_always_displayed' | 'persistent_contract_text'
    | 'bold_contract_text' | 'underline_contract_text' | 'indent_contract_text'
    | 'display_model_number' | 'display_manufacturer' | 'display_tags'> {
}

export interface ProductEditStylingRequest extends BaseBulkRequest {
    bulkAction: ProductBulkAction.edit_styling_on_statement;
    actionDetails: Partial<ProductFormattingOptions>  
    ;
}

export interface ProductBulkEditPricingModelRequest extends BaseBulkRequest {
    bulkAction: ProductBulkAction.edit_pricing_model;
    actionDetails: Pick<ProductRecord, 'pricing_model'>;
}

export interface ProductBulkRemoveProductTagRequest extends BaseBulkRequest {
    bulkAction: ProductBulkAction.remove_tag;
    actionDetails: ProductTagRecord[];
}

export interface ProductBuilkAddProductTagRequest extends BaseBulkRequest {
    bulkAction: ProductBulkAction.add_tag;
    actionDetails: ProductTagRecord[];
}
interface BaseBulkRequest {
    bulkAction: ProductBulkAction;
    productIds: number[];
    // actionDetails?: Partial<ProductRecord>;
    productList?: ProductUX[];
    productIdPriceMap?: { productId: number; value: number }[]; 
}

type ProductPricingBulkRequest = ProductBulkEditBasePriceRequest | ProductBulkVariablePriceRequest;

export function isProductPricingBulkRequest(r: ProductBulkRequest): r is ProductPricingBulkRequest {
    return Boolean(
        (r.bulkAction === ProductBulkAction.edit_base_price || r.bulkAction === ProductBulkAction.edit_variable_price)
        && r.productIdPriceMap !== undefined
    );
}

export type ProductBulkRequest = ProductBulkEditTaxRateRequest | ProductBulkShowHideOnPublicGPLRequest
    | ProductBulkCategoryRequest  | ProductBulkShowOnWebsiteRequest | ProductBulkShowPriceOnWebsiteRequest
    | ProductBulkDeleteRequest | ProductEditStylingRequest | ProductBulkEditUnitRequest 
    | ProductBulkEditDefaultUnitQuantityRequest
    | ProductPricingBulkRequest 
    | ProductBulkEditPricingModelRequest
    | ProductBulkRemoveProductTagRequest
    | ProductBuilkAddProductTagRequest
    ;