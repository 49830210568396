import classNames from 'classnames';

import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import Zoom from '@mui/material/Zoom';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';

import SmartphoneIcon from '@mui/icons-material/Smartphone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

import { GatherCaseUX } from '../../shared/types';

import PhoneNumberMask from '../common/PhoneNumberMask';

import { splitFullName } from '../../shared/utils';
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from '@mui/material';
import { GStyles } from '../../styles/GStyles';
import { InviteMethod } from './InvitationForm';
import { InvitationFormStyles } from './InvitationFormStyles';

interface Props {
    activeCase: GatherCaseUX;
    setInvitationFormDirty: (isFormDirty: boolean) => void;
    showStep2?: boolean;
    fullName: string;
    email: string;
    inviteAttempted: boolean;
    inviteMethod: InviteMethod;
    phone: string;
    dontSendInvite: boolean;
    isEmailValid: boolean;
    isPhoneValid: boolean;
    renderInvitationGroupButtons: () => JSX.Element;
    showEMailTextField: boolean;
    showPhoneTextField: boolean;
    handleOnChangePhone: (phone: string) => void;
    handleOnChangeEmail: (email: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    step2Left: {
        maxWidth: 320,
        margin: 'auto',
        padding: '0 !important',
        '@media (min-width: 760px)': {
            padding: '0 16px'
        }
    },
    inner: {},
    ...InvitationFormStyles(theme),
}), { name: 'InvitationFormLeftSectionForDC' });

const InvitationFormLeftSectionForDC = (props: Props) => {
    const {
        setInvitationFormDirty,
        showStep2,
        activeCase,
        fullName,
        email,
        inviteAttempted,
        inviteMethod,
        phone,
        dontSendInvite,
        isEmailValid,
        isPhoneValid,
        renderInvitationGroupButtons,
        showEMailTextField,
        showPhoneTextField,
        handleOnChangePhone,
        handleOnChangeEmail,
    } = props;

    const { fname } = splitFullName(fullName);

    const classes = useStyles();

    return (
        <form
            className={classNames(
                classes.leftSection,
                classes.step2Left,
                GStyles.heightAuto,
                classes.leftSectionHeightTransition,
                classes.guestTabLeftSectionHeight
            )}
            noValidate
            autoComplete="off"
        >
            <div className={classNames(classes.inner, classes.formInner)}>
                <Typography color="primary" align="center" className={GStyles.fontSize18}>
                    Invite {fname} to {activeCase.fname}'s Services
                </Typography>
                {renderInvitationGroupButtons()}

                <Zoom
                    in={showPhoneTextField}
                    timeout={600}
                >
                    <Grid
                        container
                        alignItems="flex-end"
                        justifyContent="flex-start"
                        className={classes.formControlContainer}
                    >
                        <Grid item className={classes.height0}>
                            <SmartphoneIcon color="primary" />
                        </Grid>
                        <Grid item className={classNames(classes.textFeildWithIcon)}>
                            <Tooltip
                                id="phone"
                                title={'Only US mobile numbers are currently supported'}
                                placement="top"
                            >
                                <FormControl
                                    className={classNames(GStyles.width100, GStyles.textLeft)}
                                    error={inviteAttempted && !isPhoneValid && showStep2}
                                >
                                    {/* <img src={FLAG_USA_SRC} className={classes.flagIcon} /> */}
                                    <TextField
                                        label={`${fname && `${fname}'s ` || ''}Mobile Number` +
                                            `${dontSendInvite && ' (optional)' || ''}`}
                                        type="tel"
                                        required={!dontSendInvite}
                                        id="formatted-phone"
                                        name="phone"
                                        value={phone}
                                        error={inviteAttempted && !isPhoneValid}
                                        className={GStyles.width100}
                                        onChange={(e) => {
                                            handleOnChangePhone(e.target.value);
                                            setInvitationFormDirty(true);
                                        }}
                                        InputProps={{ inputComponent: PhoneNumberMask }}
                                        autoComplete="new-password"
                                        inputProps={{
                                            tabIndex: inviteMethod === InviteMethod.email ? -1 : undefined
                                        }}
                                        size="small"
                                    />
                                    {inviteAttempted && !isPhoneValid && showStep2 &&
                                        <FormHelperText>Mobile number is invalid.</FormHelperText>}
                                </FormControl>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Zoom>

                <Grid
                    container
                    alignItems="flex-end"
                    justifyContent="center"
                    className={classNames(
                        classes.formControlContainer,
                        classes.scaleMeOut,
                        inviteMethod === InviteMethod.email && classes.moveMeUpAndScaleMeOut,
                        showEMailTextField && classes.active
                    )}
                >
                    <Grid
                        item
                        className={classes.height0}
                    >
                        <AlternateEmailIcon color="primary" />
                    </Grid>
                    <Grid item className={classes.textFeildWithIcon}>
                        <FormControl
                            className={classNames(GStyles.width100, GStyles.textLeft)}
                            error={inviteAttempted && !isEmailValid && showStep2}
                        >
                            <TextField
                                required={!dontSendInvite}
                                error={inviteAttempted && !isEmailValid}
                                label={`${fname && `${fname}'s ` || ''} Email Address` +
                                    `${dontSendInvite && ' (optional)' || ''}`}
                                id="email"
                                name="email"
                                type="email"
                                value={email}
                                className={GStyles.width100}
                                onChange={(e) => {
                                    handleOnChangeEmail(e.target.value);
                                    setInvitationFormDirty(true);
                                }}
                                autoComplete="new-password"
                                inputProps={{
                                    tabIndex: inviteMethod === InviteMethod.phone ? -1 : undefined
                                }}
                                size="small"
                            />
                            {inviteAttempted && !isEmailValid && showStep2 &&
                                <FormHelperText>Email Address is invalid.</FormHelperText>}
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
        </form>
    );
};

export default InvitationFormLeftSectionForDC;