import * as React from 'react';
import classNames from 'classnames';
import * as appService from '../../../services/app.service';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import CircularProgress from '@mui/material/CircularProgress';

import { StoreState } from '../../../types';

import MyDetailsForm from './MyDetailsForm';
import SettingsForm from './SettingsForm';
import {
    UserUpdateRequest,
    UserRole,
    PhotoTransformationsType,
    LongAddress,
    NullLongAddress,
    UserRoles,
    EntityRelationship,
    EntityRelationshipType,
    EntityCaseRole,
    EntitySummary,
    UserFuneralHomeSettings,
    mapUserFHUXtoUserFHSettings,
    UsePreference,
    getUserFuneralHomeSettings,
    getGatherCaseId,
    EntityUpdateValidateErrorEnum,
    isEntityUpdateValidateError,
    getFuneralHomeId,
    getFuneralHomeKey,
} from '../../../shared/types';
import { isValidPhoneNumber, isValidEmail } from '../../../shared/utils';
import { logoutUserSession, setFeaturesOverride } from '../../../actions/UserSession.action';
import { canEditPersonPhoneOrEmail, canManageFuneralHomeUserPermission } from '../../../shared/authority/can';
import { updateTeamMember, updateTeamMemberPhoto } from '../../../actions/Team.action';
import CommunicationTab from './CommunicationTab';
import SecurityTab from './SecurityTab';
import { isEqual } from 'lodash';
import { MySettingsTab } from '.';
import { AppPermissions } from '../../../shared/types/permissions';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import { AppDispatch } from '../../../store';
import withState from '../../common/utilHOC/WithState';
import withGStyles, { WithGStyles } from '../../../styles/WithGStyles';
import { compose } from 'redux';
import { log } from '../../../logger';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    userDetails: {
        maxWidth: 760,
        margin: 'auto',
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 140,
        fontWeight: theme.typography.fontWeightRegular,
        opacity: 1,
        margin: '8px 8px 4px',
        minHeight: 30,
        padding: 0,
        fontSize: 18,
    },
    tabAppbar: {
        background: theme.palette.background.paper,
        boxShadow: 'none',
        margin: '5px 0 0',
    },
    tabsFlexContainerFhUser: {
        '@media (min-width: 520px)': {
            justifyContent: 'center',
        },
    },
    tabsFlexContainer: {
        justifyContent: 'center',
    },
    tabsScrollableContainer: {
        overflow: 'auto',
        overflowX: 'auto',
        marginBottom: '6px !important',
    },
    tabContainer: {
        marginTop: 24,
    },
    margin0: {
        margin: 0,
    },
    footer: {
        left: 0,
        bottom: 0,
        width: '100%',
        padding: 8,
        maxWidth: 760,
        margin: '96px auto 0',
    },
    button: {
        margin: theme.spacing(),
    },
    teamUserSettingsGrid: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        '@media (min-width: 768px)': {
            flexDirection: 'row',
        },
    },
    relationshipTab: {
        display: 'flex',
        justifyContent: 'center',
        minHeight: 332,
    },
    marginTop24: {
        marginTop: 24,
    },
    label: {
        padding: '6px 12px',
        '@media (min-width: 768px)': {
            padding: '6px 24px',
        },
    },
});

type StyledProps = Props &
    WithGStyles<
        | 'root'
        | 'userDetails'
        | 'tabRoot'
        | 'tabAppbar'
        | 'tabsFlexContainerFhUser'
        | 'tabsFlexContainer'
        | 'tabsScrollableContainer'
        | 'tabContainer'
        | 'margin0'
        | 'footer'
        | 'button'
        | 'teamUserSettingsGrid'
        | 'relationshipTab'
        | 'marginTop24'
        | 'label'
    >;

function mapStateToProps({ userSession, dialogState, casesState, funeralHomeState }: StoreState) {
    const { mySettings } = dialogState;
    const { userData } = userSession;
    const { selectedCase, publicCase } = casesState;

    return {
        loggedInUser: userData,
        isUserPhotoSaving: userSession.isUserPhotoSaving,
        isViewAsGOM: appService.isViewAsGOM(userSession),
        isGOMUser: UserRoles.isGOMUser(userData),
        caseId: getGatherCaseId(selectedCase, publicCase),
        funeralHomeIdInContext: getFuneralHomeId(funeralHomeState.activeFuneralHome, selectedCase, publicCase),
        funeralHomeKeyInContext: getFuneralHomeKey(funeralHomeState.activeFuneralHome, selectedCase, publicCase),
        zIndex: mySettings.zIndex,
        tab: mySettings.tab,
        callback: mySettings.callback,
    };
}

interface InjectedProps extends ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
}

interface Props {
    selectedEntity: EntitySummary;
    onClose: (params: { confirm: boolean }) => void;
}

export const EntityTypeLookup = {
    [EntityCaseRole.admin]: 'ADMIN',
    [EntityCaseRole.guest]: 'GUEST',
};

export enum TeamUserSettingsTab {
    details = 'details',
    communications = 'communications',
    permissions = 'permissions',
}

const isTeamSettingTab = (tab: MySettingsTab | null): tab is TeamUserSettingsTab => {
    switch (tab || '') {
        case TeamUserSettingsTab.details:
        case TeamUserSettingsTab.communications:
        case TeamUserSettingsTab.permissions:
            return true;
        default:
            return false;
    }
};

interface State {
    selectedTab: TeamUserSettingsTab;
    isSaving: boolean;
    targetEntity: EntitySummary | null;
    fname: string;
    mname: string | null;
    lname: string;
    email: string;
    phone: string;
    workPhone: string;
    homePhone: string;
    role: UserRole | null;
    saveAttempted: boolean;
    stateLicenseNumber: string;
    homeAddress: LongAddress;
    useAddressDescription: boolean;
    entityCaseRole: EntityCaseRole | null;
    relationshipType: EntityRelationshipType | null;
    relationship: EntityRelationship | null;
    relationshipAlias: string | null;
    selectedFuneralHomeId: number | null;
    funeralHomeSettings: UserFuneralHomeSettings[];
    entityValidateError: EntityUpdateValidateErrorEnum | null;
}

type CombinedProps = StyledProps & InjectedProps;

class TeamUserSettings extends React.Component<CombinedProps, State> {
    constructor(props: CombinedProps) {
        super(props);
        const { selectedEntity, funeralHomeIdInContext, tab } = this.props;

        const initialFuneralHomeSettings: UserFuneralHomeSettings[] = selectedEntity
            ? mapUserFHUXtoUserFHSettings(selectedEntity.funeral_homes)
            : [];

        this.state = {
            selectedTab: isTeamSettingTab(tab) ? tab : TeamUserSettingsTab.details,
            isSaving: false,
            targetEntity: selectedEntity,
            fname: (selectedEntity && selectedEntity.fname) || '',
            mname: (selectedEntity && selectedEntity.mname) || null,
            lname: (selectedEntity && selectedEntity.lname) || '',
            email: (selectedEntity && selectedEntity.email) || '',
            phone: (selectedEntity && selectedEntity.phone) || '',
            homePhone: (selectedEntity && selectedEntity.home_phone) || '',
            workPhone: (selectedEntity && selectedEntity.work_phone) || '',
            stateLicenseNumber: (selectedEntity && selectedEntity.state_license_number) || '',
            role: selectedEntity && selectedEntity.user ? selectedEntity.user.role : null,
            homeAddress: (selectedEntity && selectedEntity.home_address) || { ...NullLongAddress },
            useAddressDescription: (selectedEntity && selectedEntity.use_address_description) || false,
            selectedFuneralHomeId: funeralHomeIdInContext,
            saveAttempted: false,
            entityCaseRole: null,
            relationshipType: null,
            relationship: null,
            relationshipAlias: null,
            funeralHomeSettings: initialFuneralHomeSettings,
            entityValidateError: null,
        };
    }

    logoutUserSession = () => this.props.dispatch(logoutUserSession());

    updateUserPhoto = async (
        entity: EntitySummary,
        photo: string,
        transformations: PhotoTransformationsType,
    ): Promise<void> => {
        const { dispatch, funeralHomeIdInContext } = this.props;

        const updatedEntity = await dispatch(
            updateTeamMemberPhoto(entity, photo, transformations, funeralHomeIdInContext),
        );

        const { targetEntity } = this.state;
        if (targetEntity && updatedEntity) {
            this.setState({
                targetEntity: {
                    ...targetEntity,
                    photo: updatedEntity.photo,
                    photo_transformations: updatedEntity.photo_transformations,
                    photo_view_id: updatedEntity.photo_view_id,
                },
            });
        }
    };

    changeTab = (selectedTab: MySettingsTab) => {
        if (isTeamSettingTab(selectedTab)) {
            this.setState({
                selectedTab,
            });
        }
    };

    handleTabChange = (e: React.ChangeEvent<{}>, selectedTab: TeamUserSettingsTab) => {
        this.setState({ selectedTab });
    };

    handlePhoneFieldKeyUpEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const { phone } = this.state;

        if (((event.keyCode === 8 || event.which === 8) && phone && phone.length === 10) || phone.length === 5) {
            this.setState({
                phone: phone.slice(0, -1),
            });
        }
    };

    handleViewAsGOMChange = (isViewAsGOM: boolean) => {
        const { dispatch, isGOMUser } = this.props;

        if (!isGOMUser) {
            console.warn('Non-Gather user should not have access to features override');
            return;
        }

        dispatch(setFeaturesOverride(isViewAsGOM));
    };

    handleSetRelationship = (
        relationshipType: EntityRelationshipType | null,
        relationship: EntityRelationship | null,
        relationshipAlias: string,
    ) => {
        if (
            relationshipType &&
            relationshipType === EntityRelationshipType.family &&
            relationshipAlias &&
            relationshipAlias.trim().length > 0
        ) {
            relationship = EntityRelationship.other;
        }
        this.setState({
            relationshipType,
            relationship,
            relationshipAlias,
        });
    };

    handleCommunicationPreference = (type: 'phone' | 'email', funeralHomeId: number, pref: UsePreference) => {
        const { funeralHomeSettings } = this.state;
        const updatedSettings = funeralHomeSettings.map((fhs) => {
            if (fhs.funeral_home_id === funeralHomeId) {
                return {
                    ...fhs,
                    use_email_pref: type === 'email' ? pref : fhs.use_email_pref,
                    use_phone_pref: type === 'phone' ? pref : fhs.use_phone_pref,
                };
            } else {
                return fhs;
            }
        });

        this.setState({
            funeralHomeSettings: updatedSettings,
        });
    };

    handleUserFHSettingsSetValue = (
        type: 'other_phone' | 'other_email' | 'title',
        funeralHomeId: number,
        value: string,
    ) => {
        this.setState((prevState) => ({
            funeralHomeSettings: prevState.funeralHomeSettings.map((fhs) => {
                if (fhs.funeral_home_id === funeralHomeId) {
                    return {
                        ...fhs,
                        visible_email: type === 'other_email' ? value : fhs.visible_email,
                        visible_phone: type === 'other_phone' ? value : fhs.visible_phone,
                        visible_title: type === 'title' ? value : fhs.visible_title,
                    };
                } else {
                    return fhs;
                }
            }),
        }));
    };

    handleUserFHPermissions = (funeralHomeId: number, newPermissions: AppPermissions) => {
        const { funeralHomeSettings } = this.state;
        const updatedSettings = funeralHomeSettings.map((fhs) => {
            if (fhs.funeral_home_id === funeralHomeId) {
                return {
                    ...fhs,
                    permissions: newPermissions,
                };
            } else {
                return fhs;
            }
        });
        this.setState({
            funeralHomeSettings: updatedSettings,
        });
    };

    closeDialog = () => {
        this.props.onClose({
            confirm: this.hasChanges(),
        });
    };

    saveSettings = async () => {
        const { caseId,
            dispatch,
            loggedInUser,
            funeralHomeKeyInContext,
            funeralHomeIdInContext,
            selectedEntity,
            callback,
        } = this.props;
        const {
            fname,
            mname,
            lname,
            email,
            phone,
            role,
            stateLicenseNumber,
            homeAddress,
            funeralHomeSettings,
            useAddressDescription,
            selectedFuneralHomeId,
        } = this.state;

        if (!this.isEmailValid() || !this.isPhoneValid(phone) || !loggedInUser || !this.areUserFHSettingsValid()) {
            this.setState({ saveAttempted: true });
            return;
        }

        this.setState({ isSaving: true, entityValidateError: null });

        if (selectedEntity) {
            const cannotEditPhoneOrEmail = !canEditPersonPhoneOrEmail(
                loggedInUser,
                selectedEntity,
                selectedFuneralHomeId || null,
                caseId,
            );

            if (selectedEntity.user && UserRoles.isFHorGOMUser(selectedEntity)) {
                const userToBeUpdated: UserUpdateRequest = {
                    fname,
                    mname,
                    lname,
                    email: cannotEditPhoneOrEmail ? selectedEntity.email : email || null,
                    phone: cannotEditPhoneOrEmail ? selectedEntity.phone : phone || null,
                    role: role || selectedEntity.user.role,
                    state_license_number: stateLicenseNumber,
                    home_address: homeAddress,
                    funeral_home_settings: funeralHomeSettings,
                    use_address_description: useAddressDescription,
                    org_role: null,
                    title: null,
                    fax_number: null,
                    organization_id: null,
                };
                const result = await dispatch(
                    updateTeamMember(selectedEntity, userToBeUpdated, funeralHomeKeyInContext, funeralHomeIdInContext),
                );
                if (result && isEntityUpdateValidateError(result)) {
                    this.setState({ isSaving: false, entityValidateError: result.validateError });
                    return;
                }
                if (callback && result) {
                    callback(result);
                }
            } else {
                log.warn('Unable to make request to update user in TeamUserSettings', { selectedEntity });
            }
        }
        this.setState({ isSaving: false });
        this.props.onClose({
            confirm: false,
        });
    };

    areUserFHSettingsValid = (): boolean => {
        const { funeralHomeSettings } = this.state;
        return funeralHomeSettings.every(
            (fhs) =>
                (fhs.use_email_pref === UsePreference.override ? isValidEmail(fhs.visible_email || '') : true) &&
                (fhs.use_phone_pref === UsePreference.override ? isValidPhoneNumber(fhs.visible_phone || '') : true),
        );
    };

    isDisplayEmailValid = (): boolean => {
        const { selectedFuneralHomeId, funeralHomeSettings } = this.state;
        const thisUFHSettings = getUserFuneralHomeSettings(funeralHomeSettings, selectedFuneralHomeId);
        if (thisUFHSettings) {
            if (thisUFHSettings.use_email_pref === UsePreference.override) {
                return isValidEmail(thisUFHSettings.visible_email || '');
            }
        }
        return true;
    };

    isDisplayPhoneValid = (): boolean => {
        const { selectedFuneralHomeId, funeralHomeSettings } = this.state;
        const thisUFHSettings = getUserFuneralHomeSettings(funeralHomeSettings, selectedFuneralHomeId);
        if (thisUFHSettings) {
            if (thisUFHSettings.use_phone_pref === UsePreference.override) {
                return isValidPhoneNumber(thisUFHSettings.visible_phone || '');
            }
        }
        return true;
    };

    isEmailValid = () => {
        const { email } = this.state;
        return email.trim().length === 0 || isValidEmail(email);
    };

    isPhoneValid = (phone: string) => {
        return phone.trim().length === 0 || isValidPhoneNumber(phone);
    };

    selectFuneralHome = (id: number) => {
        this.setState({ selectedFuneralHomeId: id });
    };

    hasChanges = (): boolean => {
        const { selectedEntity } = this.props;
        const { targetEntity, fname, mname, lname, homeAddress, stateLicenseNumber, funeralHomeSettings } = this.state;
        const existing = selectedEntity;
        if (!targetEntity) {
            return false;
        }
        const existingSettings = mapUserFHUXtoUserFHSettings(targetEntity.funeral_homes);
        const settingsIsEqual = isEqual(funeralHomeSettings, existingSettings);
        const existingHomeAddress = existing.home_address || NullLongAddress;
        const homeAddressIsEqual = isEqual(homeAddress, existingHomeAddress);
        const existingStateLicenseNumber = existing.state_license_number || '';
        const existingMname = existing.mname;

        const val = Boolean(
            !existing ||
            fname !== existing.fname ||
            mname !== existingMname ||
            lname !== existing.lname ||
            stateLicenseNumber !== existingStateLicenseNumber ||
            !settingsIsEqual ||
            !homeAddressIsEqual,
        );

        return val;
    };

    render() {
        const {
            classes,
            loggedInUser,
            isUserPhotoSaving,
            isViewAsGOM,
            caseId,
            zIndex,
            funeralHomeIdInContext,
            selectedEntity,
        } = this.props;

        const {
            selectedTab,
            fname,
            mname,
            lname,
            role,
            email,
            phone,
            workPhone,
            homePhone,
            isSaving,
            saveAttempted,
            stateLicenseNumber,
            homeAddress,
            entityCaseRole,
            selectedFuneralHomeId,
            funeralHomeSettings,
            useAddressDescription,
            entityValidateError,
        } = this.state;

        const thisFuneralHomeId = selectedFuneralHomeId ?? funeralHomeIdInContext;

        if (!loggedInUser) {
            return null;
        }

        const isMe = loggedInUser.entity_id === selectedEntity.entity_id;

        const currentPerson: EntitySummary = {
            ...selectedEntity,
            fname,
            mname,
            lname,
            home_address: homeAddress,
            state_license_number: stateLicenseNumber,
        };
        const disableLoginCredentials = !canEditPersonPhoneOrEmail(
            loggedInUser,
            currentPerson,
            thisFuneralHomeId || null,
            caseId,
        );

        const isFHUser = UserRoles.isFHUserOnFH(selectedEntity, funeralHomeIdInContext);

        const showSecurity = isFHUser && canManageFuneralHomeUserPermission(loggedInUser, funeralHomeIdInContext);

        const showCommunication = isFHUser;

        const isFhAdminAtLoc = canManageFuneralHomeUserPermission(selectedEntity, thisFuneralHomeId);

        return (
            <>
                <AppBar position="static" className={classes.tabAppbar}>
                    <Tabs
                        value={selectedTab}
                        onChange={this.handleTabChange}
                        classes={{
                            flexContainer: classNames(
                                (isFHUser && classes.tabsFlexContainerFhUser) || classes.tabsFlexContainer,
                            ),
                            scrollableX: classes.tabsScrollableContainer,
                        }}
                        variant="scrollable"
                        scrollButtons="auto"
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab
                            label={
                                isMe ? (
                                    'My Details'
                                ) : (
                                    <span className={classes.label}>
                                        <span className={classes.textCapitalize}>{currentPerson.fname}</span>'s Details
                                    </span>
                                )
                            }
                            classes={{
                                root: classes.tabRoot,
                            }}
                            key={TeamUserSettingsTab.details}
                            value={TeamUserSettingsTab.details}
                        />
                        {showCommunication && (
                            <Tab
                                label="Communication"
                                classes={{
                                    root: classes.tabRoot,
                                }}
                                key={TeamUserSettingsTab.communications}
                                value={TeamUserSettingsTab.communications}
                            />
                        )}
                        {showSecurity && (
                            <Tab
                                label="Security"
                                classes={{
                                    root: classes.tabRoot,
                                }}
                                key={TeamUserSettingsTab.permissions}
                                value={TeamUserSettingsTab.permissions}
                            />
                        )}
                    </Tabs>
                </AppBar>
                <div
                    className={classNames(
                        !isFHUser && classes.tabContainer,
                        classes.userDetails,
                        !isFHUser && classes.marginTop24,
                        selectedTab === TeamUserSettingsTab.details
                            ? classes.teamUserSettingsGrid
                            : classes.displayNone,
                    )}
                >
                    <MyDetailsForm
                        selectedPerson={currentPerson}
                        onFirstNameChange={(fn: string) => this.setState({ fname: fn })}
                        onMiddleNameChange={(fn: string) => this.setState({ mname: fn })}
                        onLastNameChange={(fn: string) => this.setState({ lname: fn })}
                        onStateLicenseChange={(val: string) => this.setState({ stateLicenseNumber: val })}
                        updateUserPhoto={this.updateUserPhoto}
                        isUserPhotoSaving={isUserPhotoSaving}
                        isMe={isMe}
                        isSaving={isSaving}
                        isViewAsGOM={isViewAsGOM}
                        onViewAsGOMChange={this.handleViewAsGOMChange}
                        setSettingsUserButton={(r) => this.setState({ entityCaseRole: r })}
                        entityCaseRole={entityCaseRole}
                        loggedInUser={loggedInUser}
                        gatherCaseId={caseId}
                        entityValidation={entityValidateError}
                        zIndex={zIndex + 1}
                        homeAddressLong={homeAddress}
                        onHomeAddressChange={(val, useDesc) =>
                            this.setState({
                                homeAddress: val,
                                useAddressDescription: useDesc,
                            })
                        }
                        useAddressDescription={useAddressDescription}
                        isFHUser={Boolean(isFHUser)}
                        email={email}
                        phone={phone}
                        onEmailChange={(val) => this.setState({ email: val })}
                        onPhoneChange={(val) => this.setState({ phone: val })}
                        isEmailValid={!saveAttempted || this.isEmailValid()}
                        isPhoneValid={!saveAttempted || this.isPhoneValid(phone)}
                        disableLoginCredentials={disableLoginCredentials}
                        changeTab={this.changeTab}
                        logoutUserSession={this.logoutUserSession}
                    />
                    {!isFHUser && (
                        <SettingsForm
                            selectedPerson={currentPerson}
                            role={role}
                            email={email}
                            phone={phone}
                            workPhone={workPhone}
                            homePhone={homePhone}
                            homeAddressLong={homeAddress}
                            logoutUserSession={this.logoutUserSession}
                            onRoleChange={(val) => this.setState({ role: val })}
                            onEmailChange={(val) => this.setState({ email: val })}
                            onPhoneChange={(val) => this.setState({ phone: val })}
                            onWorkPhoneChange={(val) => this.setState({ workPhone: val })}
                            onHomePhoneChange={(val) => this.setState({ homePhone: val })}
                            onHomeAddressChange={(val, useDesc) =>
                                this.setState({
                                    homeAddress: val,
                                    useAddressDescription: useDesc,
                                })
                            }
                            useAddressDescription={useAddressDescription}
                            handlePhoneFieldKeyUpEvent={this.handlePhoneFieldKeyUpEvent}
                            isSaving={isSaving}
                            isEmailValid={!saveAttempted || this.isEmailValid()}
                            isPhoneValid={!saveAttempted || this.isPhoneValid(phone)}
                            isHomePhoneValid={!saveAttempted || this.isPhoneValid(homePhone)}
                            isWorkPhoneValid={!saveAttempted || this.isPhoneValid(workPhone)}
                            entityValidation={entityValidateError}
                            user={loggedInUser}
                            isMe={isMe}
                            disableLoginCredentials={disableLoginCredentials}
                            zIndex={zIndex + 1}
                        />
                    )}
                </div>
                <div
                    className={classNames(
                        selectedTab === TeamUserSettingsTab.communications && isFHUser
                            ? classes.relationshipTab
                            : classes.displayNone,
                    )}
                >
                    {showCommunication && (
                        <CommunicationTab
                            key={thisFuneralHomeId || 0}
                            isSaving={isSaving}
                            disableLoginCredentials={disableLoginCredentials}
                            primaryEmail={email}
                            primaryPhone={phone}
                            fname={fname}
                            lname={lname}
                            funeralHomeSettings={funeralHomeSettings}
                            onPreferenceChanges={this.handleCommunicationPreference}
                            onEmailChange={(val) =>
                                thisFuneralHomeId &&
                                this.handleUserFHSettingsSetValue('other_email', thisFuneralHomeId, val)
                            }
                            onPhoneChange={(val) =>
                                thisFuneralHomeId &&
                                this.handleUserFHSettingsSetValue('other_phone', thisFuneralHomeId, val)
                            }
                            funeralHomes={selectedEntity.funeral_homes || []}
                            selectedFuneralHomeId={thisFuneralHomeId}
                            selectFuneralHome={this.selectFuneralHome}
                            isEmailValid={!saveAttempted || this.isDisplayEmailValid()}
                            isPhoneValid={!saveAttempted || this.isDisplayPhoneValid()}
                        />
                    )}
                </div>
                <div
                    className={classNames(
                        selectedTab === TeamUserSettingsTab.permissions && isFHUser
                            ? classes.relationshipTab
                            : classes.displayNone,
                    )}
                >
                    {showSecurity && thisFuneralHomeId && (
                        <SecurityTab
                            funeralHomeIdInContext={funeralHomeIdInContext}
                            key={thisFuneralHomeId}
                            selectedFuneralHomeId={thisFuneralHomeId}
                            selectFuneralHome={this.selectFuneralHome}
                            funeralHomeSettings={funeralHomeSettings}
                            onTitleChange={(val) => this.handleUserFHSettingsSetValue('title', thisFuneralHomeId, val)}
                            onPermissionChange={(val: AppPermissions) =>
                                this.handleUserFHPermissions(thisFuneralHomeId, val)
                            }
                            isMe={isMe}
                            isSaving={isSaving}
                            selectedPerson={currentPerson}
                            zIndex={zIndex}
                            user={loggedInUser}
                            isFHAdminAtLoc={isFhAdminAtLoc}
                        />
                    )}
                </div>

                <Grid
                    item
                    xs={12}
                    className={classNames(
                        classes.footer,
                        selectedTab === TeamUserSettingsTab.permissions && classes.margin0,
                    )}
                >
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Button
                                size="large"
                                className={classNames(classes.button, classes.colorBlack2)}
                                onClick={this.closeDialog}
                            >
                                CANCEL
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                size="large"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={this.saveSettings}
                                disabled={isSaving}
                            >
                                SAVE
                                {isSaving && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default compose(withState(mapStateToProps), withGStyles(styles))(TeamUserSettings) as React.ComponentType<Props>;
