import * as React from 'react';
import { find, keyBy } from 'lodash';

import Typography from '@mui/material/Typography';

import AcUnitIcon from '@mui/icons-material/AcUnit';
import EditIcon from '@mui/icons-material/Edit';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PersonIcon from '@mui/icons-material/Person';

import { StyleRulesCallback } from '@mui/styles';

import { StoreState } from '../../../types';
import {
    GatherCaseUX,
    ProductCategoryEnum,
    ProductCategory,
    ProductUX,
    UserRoles,
    ProductPackageUX,
    ProductContractItemUX,
    ProductSubPackage,
    ProductContractRequest,
    ProductItem,
    PaymentTransaction,
    UserPatchRequest,
    CaseType,
} from '../../../shared/types';

import GoodsAndServicesAppBar from '../../appBars/family/GoodsAndServices.appBar';
import CaseNotFound from '../../cases/CaseNotFound';

import GoodsAndServicesTab from './GoodsAndServicesTab';
import ContractPopover from './contractPopover';
import {
    calculateAndDisplayContractTotal,
} from '../../../shared/goods_and_services/pricing';
import {
    isAllowanceItem,
    isPackagePlaceholder,
    getLatestItems,
} from '../../../shared/goods_and_services/utils';

import PriceWidget from './widgets/PriceWidget';
import { FamilyRoutePage, RouteBuilder, toggleFullScreen } from '../../../services';
import {
    getPackagesInContract,
    getProductOptionsForPackageItem,
    findMultiOptionPackageItem,
    getVisiblePackagesForContract,
} from '../../../shared/goods_and_services/packages';
import {
    filterProductsByTabType,
    filterContractItemsByTabType,
} from '../../../services/goodsandservices.service';

import FreezeContractDialog from './dialogs/FreezeContract.dialog';

import { ContractPrintView } from './overview/ContractPrintView';
import {
    loadProductTaxRates,
    loadProductPackages,
    loadAllProductSummariesForFuneralHome,
} from '../../../actions/product/FHProduct.action';
import {
    freezeProductContract,
    loadProductContractForCase,
    updateProductContract,
} from '../../../actions/product/Contract.action';
import { loadCaseDocs } from '../../../actions/Doc.action';
import { loadDocPackets } from '../../../actions/DocPacket.action';
import UploadDocsPopper from '../../documentLibrary/UploadDocsPopper';
import { loadFuneralHomeSuppliers } from '../../../actions/product/ProductSupplier.action';
import { canEditContract } from '../../../shared/authority/can';
import GCircularProgress from '../../common/GCircularProgress';
import TabsSelector from '../../common/TabsSelector';
import { AppDispatch } from '../../../store';
import { Theme } from '@mui/material';
import withGStyles, { WithGStyles } from '../../../styles/WithGStyles';
import withState from '../../common/utilHOC/WithState';
import { Payer } from './payment';
import { patchTeamMember } from '../../../actions/Team.action';
import PrintContractsDialog from './overview/PrintContracts.dialog';

export function mapStateToProps({
    userSession,
    casesState,
    productState,
    financeState,
}: StoreState) {
    const { userData } = userSession;
    return {
        userSession,
        isShowPayments: Boolean(userData && userData.show_payments_on_statement),
        contractItems: productState.activeContract ? getLatestItems(productState.activeContract.items) : [],
        products: productState.funeralHomeProducts,
        activeContract: productState.activeContract,
        isContractLoading: productState.isContractLoading,
        allPackages: productState.packages,
        isTransactionsLoading: financeState.transactions.isLoading,
        transactions: financeState.transactions,
        helpers: casesState.helpers,
    };
}

interface Props extends ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
    printContract: boolean;
    selectedCase: GatherCaseUX;
    activeTab: string | null;
    isAutoFormsEnabled: boolean;
}

export type PrintModeType = 'draft' | 'official';

export type PayerDetails = {
    payer: Payer | undefined;
    balanceOwedByPayer: number;
};

interface State {
    selectedTabKey: TabKey;
    gsPopperAnchorEle: HTMLElement | null;
    docsPopperAnchorEle: HTMLElement | null;
    isShowPrices: boolean;
    isCondensedView: boolean;
    isFreezeContractDialogOpen: boolean;
    isPrintDialogOpen: boolean;
    docPacketSignerReminderAnchorEle: HTMLElement | null;
    topSelectedTab: TopTab;
    isItemizedStatement: boolean;
    isDownloadingInvoice: boolean;
    payerId: number;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        marginTop: 50,
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(3),
        '@media (max-width: 584px)': {
            marginTop: 58,
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: 66,
        },
    },

    priceWidget: {
        color: 'white',
        fontSize: 18,
        lineHeight: '1.25em',
        margin: '6px 0px 0px',
        cursor: 'pointer',
        fontWeight: 500,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        '& $status': {
            fontSize: 11,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '& > p': {
            '@media (max-width: 416px)': {
                fontSize: '20px !important',
            },
        },
    },
    gCircular: {
        background: 'none',
        width: '32px !important',
        height: '32px !important',
        top: '36%',
        color: theme.palette.secondary.main
    },
    status: {},
    icon: {},
});

export type StyledProps = WithGStyles<'root' | 'icon' | 'gCircular' | 'priceWidget' | 'loadingContainer'
    | 'loadingText' | 'status'>;

export type TabKey = ProductCategory | 'contract' | 'payment' | 'details';
enum TopTab {
    details = 'details',
    showroom = 'showroom',
    contract = 'contract',
    payment = 'payment',
}

export interface GPLTabType {
    key: TabKey;
    categories: ProductCategory[];
    label: { primary: string };
    link: string;
}

export const GPLLinkLookup: Record<ProductCategoryEnum, string> = {
    [ProductCategoryEnum.equipment_facilities_staff]: 'professional-services',
    [ProductCategoryEnum.transportation]: 'professional-services',
    [ProductCategoryEnum.care_of_loved_one]: 'professional-services',
    [ProductCategoryEnum.casket]: 'casket',
    [ProductCategoryEnum.urn]: 'urn',
    [ProductCategoryEnum.vault]: 'vault',
    [ProductCategoryEnum.cemetery]: 'cemetery',
    [ProductCategoryEnum.memorial]: 'memorial',
    [ProductCategoryEnum.flowers]: 'flowers',
    [ProductCategoryEnum.cash_advance]: 'cash-advances',
};

const generateGPLCategoryLink = () => {
    const gplKeys = Object.keys(GPLLinkLookup);
    const categoryLookup = gplKeys.reduce<Record<string, ProductCategoryEnum>>(
        (prev, key) => {
            if (ProductCategoryEnum[key]) {
                return { ...prev, [GPLLinkLookup[key]]: ProductCategoryEnum[key] };
            }
            return { ...prev };
        },
        {}
    );

    return categoryLookup;
};

export const GPLCategoryLinkLookup: Record<string, ProductCategoryEnum> = generateGPLCategoryLink();

export const SHOWROOM_TAB_LIST: GPLTabType[] = [
    {
        key: ProductCategoryEnum.care_of_loved_one,
        categories: [
            ProductCategoryEnum.care_of_loved_one,
            ProductCategoryEnum.transportation,
            ProductCategoryEnum.equipment_facilities_staff,
        ],
        label: {
            primary: 'professional services',
        },
        link: GPLLinkLookup.care_of_loved_one
    },
    {
        key: ProductCategoryEnum.casket,
        categories: [ProductCategoryEnum.casket],
        label: {
            primary: 'casket',
        },
        link: GPLLinkLookup.casket
    },
    {
        key: ProductCategoryEnum.urn,
        categories: [ProductCategoryEnum.urn],
        label: {
            primary: 'urn',
        },
        link: GPLLinkLookup.urn
    },
    {
        key: ProductCategoryEnum.vault,
        categories: [ProductCategoryEnum.vault],
        label: {
            primary: 'vault',
        },
        link: GPLLinkLookup.vault
    },
    {
        key: ProductCategoryEnum.cemetery,
        categories: [ProductCategoryEnum.cemetery],
        label: {
            primary: 'cemetery',
        },
        link: GPLLinkLookup.cemetery
    },
    {
        key: ProductCategoryEnum.memorial,
        categories: [ProductCategoryEnum.memorial],
        label: {
            primary: 'memorial',
        },
        link: GPLLinkLookup.memorial
    },
    {
        key: ProductCategoryEnum.flowers,
        categories: [ProductCategoryEnum.flowers],
        label: {
            primary: 'flowers',
        },
        link: GPLLinkLookup.flowers
    },
    {
        key: ProductCategoryEnum.cash_advance,
        categories: [ProductCategoryEnum.cash_advance],
        label: {
            primary: 'cash advances',
        },
        link: GPLLinkLookup.cash_advance
    }
];
const EXTRA_TAB_LINKS: GPLTabType[] = [
    {
        key: 'contract',
        categories: [],
        label: {
            primary: 'contract',
        },
        link: 'contract',
    },
    {
        key: 'payment',
        categories: [],
        label: {
            primary: 'payment',
        },
        link: 'contract/payment'
    },
    {
        key: 'details',
        categories: [],
        label: {
            primary: 'details',
        },
        link: 'details',
    },
];

export function generateProductItems(
    products: ProductUX[],
    contractItems: ProductContractItemUX[],
    contractPackages: ProductPackageUX[],
    contractSubPackages: ProductSubPackage[],
) {

    const visibleProducts = products.filter((product) => !product.is_hidden);

    // Create a ProductItem object for each visible product
    const productItems: ProductItem[] = visibleProducts.map((product) => ({
        key: `pId${product.id}`,
        product,
        category: product.category,
    }));

    // Create ProductItems for each package placeholder contractItem (multi-option package items)
    const contractPackagesAndSubPackages = [...contractPackages, ...contractSubPackages];
    const placeholderItems = contractItems.filter((i) => !i.delete_revision && isPackagePlaceholder(i));
    placeholderItems.forEach((placeholderItem) => {

        if (!placeholderItem.package_item_id) {
            return;
        }
        const packageItem = findMultiOptionPackageItem(
            contractPackagesAndSubPackages,
            placeholderItem.package_item_id,
            placeholderItem.sub_package_id || placeholderItem.package_id || undefined,
        );
        if (!packageItem) {
            return;
        }

        const productOptions = getProductOptionsForPackageItem(packageItem);
        const productItemsForPackage: ProductItem[] = productOptions.map((product) => ({
            key: `pId${product.id}_piId${packageItem.id}`,
            product,
            category: product.category,
            packageItem,
        }));
        productItems.push(...productItemsForPackage);
    });

    contractItems.forEach((contractItem) => {
        if (contractItem.delete_revision
            || !contractItem.category
            || isPackagePlaceholder(contractItem)
        ) {
            return; // skip this contractItem
        }

        const { id, product, package_item_id } = contractItem;

        if (package_item_id && product) {
            const productItemWithPackageItem = find(productItems, (pi) =>
                pi.product !== undefined &&
                pi.product.id === product.id &&
                !pi.contractItem &&
                pi.packageItem !== undefined &&
                pi.packageItem.id === package_item_id
            );
            if (productItemWithPackageItem) {
                productItemWithPackageItem.contractItem = contractItem;
                productItemWithPackageItem.key += `_ciId${id}`;
            }
        }

        const productItem = !product ? undefined :
            find(productItems, (pi) =>
                pi.product !== undefined &&
                pi.product.id === product.id &&
                !pi.contractItem &&
                !pi.packageItem
            );

        if (productItem) {
            productItem.contractItem = contractItem;
            productItem.key += `_ciId${contractItem.id}`;
        } else {
            if (!product || !product.is_hidden || isAllowanceItem(contractItem) || contractItem.package_id) {
                productItems.push({
                    key: `pId${product ? product.id : ''}_ciId${contractItem.id}`,
                    contractItem,
                    category: contractItem.category,
                    product: product || undefined,
                });
            }
        }
    });
    return productItems;
}

type CombinedProps = Props & StyledProps;
class GoodsAndServices extends React.Component<CombinedProps, State> {
    protected priceWidgetAnchorEle: HTMLDivElement | null;
    protected showPricesKey = 'isShowPrices';

    constructor(props: CombinedProps) {
        super(props);

        const isShowPrices = localStorage.getItem(this.showPricesKey) === 'true';
        this.state = {
            gsPopperAnchorEle: null,
            docsPopperAnchorEle: null,
            selectedTabKey: 'care_of_loved_one',
            isShowPrices,
            isCondensedView: true,
            isFreezeContractDialogOpen: false,
            isPrintDialogOpen: false,
            docPacketSignerReminderAnchorEle: null,
            topSelectedTab: TopTab.showroom,
            isItemizedStatement: false,
            isDownloadingInvoice: false,
            payerId: 0,
        };
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    UNSAFE_componentWillReceiveProps(props: Props) {
        const { activeTab, printContract } = props;
        const { selectedTabKey } = this.state;

        const filterActiveTab = activeTab ?
            SHOWROOM_TAB_LIST.find(t => t.link.includes(activeTab))
            : null;

        const filterExtraTabs = activeTab
            ? EXTRA_TAB_LINKS.find(t => t.link.includes(activeTab))
            : null;

        const key = filterActiveTab
            ? filterActiveTab.key
            :
            filterExtraTabs
                ? filterExtraTabs.key
                : 'contract';

        if (key === selectedTabKey) {
            return;
        }

        // print contract if query-params contains ?print=true
        if (key === 'contract' && printContract) {
            this.printContract();
        }

        this.handleChangeSelectedTabKey(key);
    }

    componentDidMount() {
        const {
            selectedCase,
            activeTab,
            dispatch,
        } = this.props;

        const filterActiveTab = activeTab && SHOWROOM_TAB_LIST.find(t => {
            return t.link.includes(activeTab);
        });

        this.handleChangeSelectedTabKey(filterActiveTab ? filterActiveTab.key : 'contract');

        const funeralHomeId = selectedCase.funeral_home.id;
        dispatch(loadProductContractForCase(selectedCase.uuid));
        dispatch(loadProductTaxRates());
        dispatch(loadProductPackages(funeralHomeId));
        dispatch(loadAllProductSummariesForFuneralHome(funeralHomeId));
        dispatch(loadFuneralHomeSuppliers(funeralHomeId));
    }

    canUserEditContract = (): boolean => {
        const { userSession, activeContract } = this.props;

        return userSession && userSession.userData !== null && activeContract !== null
            && canEditContract(userSession.userData, activeContract);
    };

    getPayerDetails = (): PayerDetails => {
        const { helpers, transactions } = this.props;

        const { payerId } = this.state;

        const helper = helpers.find(h => Boolean(h.entity_id === payerId));
        const payments = transactions.payments.find((payment) => payment.payer_id === payerId);

        const firstPayment = payments && payments.transactions[0];
        const payer: Payer | undefined = firstPayment && PaymentTransaction.constructPayer(firstPayment, helper);

        const payerTransactions = payments && payments.transactions;
        const balanceOwedByPayer = (payerTransactions || [])
            .reduce(
                (prevValue, transaction) => {
                    if (transaction.is_proposed) {
                        return prevValue + transaction.credit + transaction.debit;
                    }
                    return prevValue;
                },
                0
            );

        return { payer, balanceOwedByPayer };
    };

    renderTabContent = (
        contractPackages: ProductPackageUX[],
        contractSubPackages: ProductSubPackage[],
    ) => {
        const {
            contractItems,
            userSession,
            allPackages,
            products,
            activeContract,
            selectedCase
        } = this.props;
        const {
            selectedTabKey,
            isShowPrices,
            isCondensedView,
            isItemizedStatement,
            isDownloadingInvoice,
        } = this.state;

        if (!userSession.userData || !activeContract) {
            return <div key={selectedTabKey} />;
        }

        const filteredContractItems = filterContractItemsByTabType(contractItems, selectedTabKey);
        const filteredProducts = filterProductsByTabType(products, selectedTabKey);
        const productItems = generateProductItems(
            filteredProducts,
            filteredContractItems,
            contractPackages,
            contractSubPackages,
        );

        const isContractFrozen = this.isContractFrozen();
        const contractPackageIdList = [...contractPackages, ...contractSubPackages].map((pkg) => pkg.id);
        const contractPackageIds = keyBy(contractPackageIdList);
        const availablePackages = getVisiblePackagesForContract(allPackages, contractPackageIds);

        const payerDetails = this.getPayerDetails();

        return (
            <div key={selectedTabKey}>
                <GoodsAndServicesTab
                    tabType={selectedTabKey}
                    contractItems={contractItems}
                    availablePackages={availablePackages}
                    contractPackages={contractPackages}
                    contractSubPackages={contractSubPackages}
                    contractPackageIds={contractPackageIds}
                    activeContract={activeContract}
                    productItems={productItems}
                    isShowPrices={isShowPrices}
                    isCondensedView={isCondensedView}
                    isContractFrozen={isContractFrozen}
                    canUserEditContract={this.canUserEditContract()}
                    toggleCondensedView={this.toggleCondensedView}
                    openFreezeContractDialog={this.openFreezeContractDialog}
                    printContract={this.printContract}
                    zIndex={1320}
                    isItemizedStatement={isItemizedStatement}
                    setItemizedStatement={this.setItemizedStatementValue}
                    isDownloadingInvoice={isDownloadingInvoice}
                    setDownloadInvoice={this.setDownloadInvoice}
                    payerDetails={payerDetails}
                    selectedCase={selectedCase}
                    handleChangeSelectedTabKey={this.handleChangeSelectedTabKey}
                />
            </div>
        );
    };

    setItemizedStatementValue = (isItemized: boolean, callback?: () => void) => {
        this.setState({ isItemizedStatement: isItemized }, callback);
    };

    setDownloadInvoice = (isDownloadingInvoice: boolean, payerId: number, callback?: () => void) => {
        this.setState({ isDownloadingInvoice, payerId }, callback);
    };

    isContractFrozen = () => {
        const { activeContract } = this.props;

        return activeContract ? activeContract.is_frozen : false;
    };

    generatePriceWidget = () => {
        const { classes, activeContract } = this.props;
        const { isShowPrices } = this.state;

        return (
            <div
                ref={ref => this.priceWidgetAnchorEle = ref}
                className={classes.priceWidget}
                onClick={e => this.openGSPopper(e)}
            >
                {isShowPrices && activeContract &&
                    <PriceWidget
                        priceStr={calculateAndDisplayContractTotal(activeContract, '$0,0') || ''}
                        size={'small'}
                    />
                }
            </div>
        );
    };

    renderContractPopover = () => {
        const { gsPopperAnchorEle, isShowPrices } = this.state;
        const {
            selectedCase,
            activeContract,
            isAutoFormsEnabled,
            isShowPayments,
        } = this.props;

        if (!activeContract) {
            return null;
        }

        return (
            <ContractPopover
                activeCase={selectedCase}
                openFreezeContractDialog={this.openFreezeContractDialog}
                printContract={this.printContract}
                canEditContract={this.canUserEditContract()}
                isShowPrices={isShowPrices}
                isFrozen={activeContract.is_frozen}
                isShowRevisions={!activeContract.hide_revisions}
                isAutoFormsEnabled={isAutoFormsEnabled}
                toggleShowPrices={this.toggleShowPrices}
                toggleShowContractRevisions={this.handleToggleShowContractRevisions}
                popperAnchorEle={gsPopperAnchorEle}
                closePopper={this.closePopper}
                isShowPayments={isShowPayments}
                toggleShowPayments={this.toggleShowPayments}
            />
        );
    };

    render() {
        const {
            userSession,
            selectedCase,
            contractItems,
            allPackages,
            isContractLoading,
            activeContract,
            isAutoFormsEnabled,
        } = this.props;

        const {
            isCondensedView,
            isFreezeContractDialogOpen,
            isPrintDialogOpen,
            docsPopperAnchorEle,
            docPacketSignerReminderAnchorEle,
            topSelectedTab,
            isItemizedStatement,
            isDownloadingInvoice,
        } = this.state;

        if (!userSession.userData ||
            (!activeContract && !UserRoles.isFHorGOMUser(userSession.userData) && !isContractLoading)) {
            return <CaseNotFound />;
        }

        const { contractPackages, contractSubPackages } = getPackagesInContract(contractItems, allPackages);

        const isContractFrozen = this.isContractFrozen();

        const payerDetails = this.getPayerDetails();

        const goodsAndServicesTabs = this.getGoodsAndServicesTabs();
        return (
            <>
                <GoodsAndServicesAppBar
                    userSession={userSession}
                    activeCase={selectedCase}
                    priceWidget={this.generatePriceWidget()}
                    goBackCallBack={e => toggleFullScreen(true)}
                    icon={isContractFrozen ? <AcUnitIcon /> : <EditIcon />}
                    openDocsPopper={this.openDocsPopper}
                    openGSPopper={this.openGSPopper}
                />
                <TabsSelector
                    selectedKey={topSelectedTab}
                    tabs={goodsAndServicesTabs}
                />
                {this.renderTabContent(contractPackages, contractSubPackages)}
                {this.renderContractPopover()}

                {activeContract &&
                    <>
                        <FreezeContractDialog
                            isBatch={false}
                            batchSize={0}
                            caseName={selectedCase.fname}
                            isDialogOpen={isFreezeContractDialogOpen}
                            closeDialog={(doFreeze) => {
                                this.closeFreezeContractDialog();
                                if (doFreeze) {
                                    this.handleFreezeProductContract();
                                }
                            }}
                            zIndex={1320}
                        />

                        <PrintContractsDialog
                            isCondensedView={isCondensedView}
                            selectedCase={selectedCase}
                            isDialogOpen={isPrintDialogOpen}
                            isAutoFormsEnabled={isAutoFormsEnabled}
                            toggleCondensedView={this.toggleCondensedView}
                            closeDialog={() => this.togglePrintDialog(false)}
                            printContract={this.printContract}
                            zIndex={1320}
                        />
                    </>
                }

                <ContractPrintView
                    printElementId="print-content"
                    printModeType="draft"
                    activeContract={activeContract}
                    isCondensedView={isCondensedView}
                    activeCase={selectedCase}
                    isItemizedStatement={isItemizedStatement}
                    isDownloadingInvoice={isDownloadingInvoice}
                    payerDetails={payerDetails}
                />
                <UploadDocsPopper
                    activeCase={selectedCase}
                    anchorEle={docsPopperAnchorEle}
                    closePopper={this.closePacketPopper}
                    zIndex={1330}
                    reminderAnchorEl={docPacketSignerReminderAnchorEle}
                    setReminderAnchorEl={this.setDocPacketSignerReminderAnchorEle}
                    popperPosition="left"
                />
            </>
        );
    }

    getGoodsAndServicesTabs = () => {
        const { selectedCase, classes, transactions } = this.props;
        const isContractFrozen = this.isContractFrozen();

        const { totals } = transactions;
        const percentPaid = Math.floor(
            totals.expense_total === 0 ? 0 : totals.collected_total / totals.expense_total * 100.0
        );

        const tabs = [{
            key: TopTab.showroom,
            link: RouteBuilder.Showroom({
                caseName: selectedCase.name,
                funeralHomeKey: selectedCase.funeral_home.key,
            }),
            element: (
                <>
                    <Typography color="secondary">Showroom</Typography>
                    <LoyaltyIcon className={classes.fontSize32} color="secondary" />
                </>
            ),
        }, {
            key: TopTab.contract,
            link: RouteBuilder.FamilyPage({
                caseName: selectedCase.name,
                funeralHomeKey: selectedCase.funeral_home.key,
                page: FamilyRoutePage.CONTRACT,
            }),
            element: (
                <>
                    <Typography color="secondary">Statement</Typography>
                    {isContractFrozen ?
                        <AcUnitIcon className={classes.fontSize32} color="secondary" /> :
                        <InsertDriveFileIcon className={classes.fontSize32} color="secondary" />
                    }
                </>
            ),
        }, {
            key: TopTab.payment,
            link: RouteBuilder.FamilyPage({
                caseName: selectedCase.name,
                funeralHomeKey: selectedCase.funeral_home.key,
                page: FamilyRoutePage.PAYMENTS,
            }),
            element: (
                <>
                    <Typography color="secondary">Payment</Typography>
                    <GCircularProgress
                        variant="determinate"
                        value={percentPaid > 100 ? 100 : percentPaid === 0 ? 2 : percentPaid}
                        className={classes.gCircular}
                    />
                    <AttachMoneyIcon
                        className={classes.fontSize28}
                        color="secondary"
                    />
                </>
            ),
        }];

        if (selectedCase.case_type === CaseType.one_off) {
            tabs.unshift({
                key: TopTab.details,
                link: RouteBuilder.FamilyPage({
                    caseName: selectedCase.name,
                    funeralHomeKey: selectedCase.funeral_home.key,
                    page: FamilyRoutePage.DETAILS,
                }),
                element: (
                    <>
                        <Typography color="secondary">Details</Typography>
                        <PersonIcon className={classes.fontSize32} color="secondary" />
                    </>
                ),
            });
        };
        return tabs;
    };

    handleFreezeProductContract = () => {
        const { dispatch, activeContract, selectedCase } = this.props;

        if (activeContract && !activeContract.is_frozen) {
            dispatch(freezeProductContract(activeContract.id, selectedCase.uuid, selectedCase.fname));
        }
    };

    openFreezeContractDialog = () => {
        this.setState({ isFreezeContractDialogOpen: true });
    };

    closeFreezeContractDialog = () => {
        this.setState({ isFreezeContractDialogOpen: false });
    };

    setDocPacketSignerReminderAnchorEle = (anchorEle: HTMLElement | null) => {
        this.setState({
            docPacketSignerReminderAnchorEle: anchorEle,
        });
    };

    togglePrintDialog = (isPrintDialogOpen: boolean) => {
        this.setState({ isPrintDialogOpen });
    };

    handleChangeSelectedTabKey = (key: TabKey) => {
        const topSelectedTab = key === 'details'
            ? TopTab.details
            : key === 'contract'
                ? TopTab.contract
                : key === 'payment'
                    ? TopTab.payment
                    : TopTab.showroom;
        this.setState({
            selectedTabKey: key,
            topSelectedTab,
        });
    };

    /**
     * popper operations start
     */

    openGSPopper = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        this.setState({ gsPopperAnchorEle: event.currentTarget, docsPopperAnchorEle: null });
    };

    closePopper = () => {
        this.setState({ gsPopperAnchorEle: null });
    };

    openDocsPopper = (event: React.MouseEvent<HTMLElement>) => {
        const { dispatch, selectedCase } = this.props;

        dispatch(loadCaseDocs(selectedCase.uuid));
        dispatch(loadDocPackets(selectedCase));
        this.setState({ docsPopperAnchorEle: event.currentTarget, gsPopperAnchorEle: null });
    };

    closePacketPopper = () => {
        this.setState({ docsPopperAnchorEle: null });
    };

    /**
     * popper operations ends
     */

    printContract = (printConclusively?: boolean) => {

        // show print dialog only for FH user and if status is draft
        if (this.canUserEditContract() && !printConclusively) {
            this.setState({ selectedTabKey: 'contract' });
            this.togglePrintDialog(true);
            return;
        }
        this.setState({ selectedTabKey: 'contract' }, this.print);
    };

    print = () => {
        const printContents = document.getElementById('print-content');
        const printContainer = document.getElementById('print-container');

        if (!printContents || !printContainer) {
            return;
        }

        const materialIconStyles = `       
        <style>
        @font-face {
            font-family: 'Material Icons';
            font-style: normal;
            font-weight: 400;
            src: url(https://fonts.gstatic.com/s/materialicons/v47/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
        }

        @page {
            margin: 24px;
        }

        .material-icons {
            font-family: 'Material Icons';
            font-weight: normal;
            font-style: normal;
            font-size: 24px;
            line-height: 1;
            letter-spacing: normal;
            text-transform: none;
            display: inline-block;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
            -webkit-font-feature-settings: 'liga';
            -webkit-font-smoothing: antialiased;
        }
        </style>`;

        printContainer.innerHTML = materialIconStyles + printContents.innerHTML;
        // Scroll to the top before printing - See APP-3785
        window.scrollTo(0, 0);
        window.print();
    };

    toggleShowPrices = (isChecked: boolean) => {
        this.setState({ isShowPrices: isChecked });
        localStorage.setItem(this.showPricesKey, JSON.stringify(isChecked));
    };

    toggleShowPayments = (isShowPayments: boolean) => {
        const { dispatch, userSession, selectedCase } = this.props;
        const { userData } = userSession;
        if (!userData) {
            console.warn('User not found');
            return;
        }
        const changes: UserPatchRequest = {
            show_payments_on_statement: isShowPayments,
        };
        dispatch(patchTeamMember(userData.user_id, userData.entity_id, changes, selectedCase.funeral_home.id));
    };

    toggleCondensedView = (isChecked: boolean) => {
        this.setState({ isCondensedView: isChecked });
    };

    handleToggleShowContractRevisions = (isShowRevisions: boolean) => {
        const { dispatch, activeContract, selectedCase } = this.props;

        if (!activeContract) {
            return;
        }

        const changes: ProductContractRequest = {
            hide_revisions: !isShowRevisions,
        };

        dispatch(updateProductContract(selectedCase.uuid, activeContract.id, changes));
    };
}

export default withState(mapStateToProps)(withGStyles(styles)(GoodsAndServices));
