import { CloudinaryTransformationsType } from './photo';
import { getValidator } from './utils';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';
import * as t from 'io-ts';
import { getStateAbbreviation } from '../utils';
import { FlowerSaleType } from './flowersales';

export interface TreeProjectFAQ {
    data: {
        q: string;
        a: string;
    }[];
}

interface TreeProjectRecord {
    id: number;
    rank: number;
    states: string;
    name: string;
    description: string;
    subtitle: string;
    faq: TreeProjectFAQ;
    arbor_day_project_id: string;
    hero_photo_view_id: number | null;
    cost_per_tree: number;
    detail_photo_view_id: number | null;
    start_date: Date;
    created_by: number;
    created_time: Date;
    deleted_by: number;
    deleted_time: Date | null;
    updated_by: number;
    updated_time: Date;
    all_fh_can_access: boolean;
    featured_project: boolean;
}

export interface TreeProjectUX extends TreeProjectRecord {
    detail_photo: string | null; // The cloudinary public_id of the the photo
    detail_transformations: CloudinaryTransformationsType; // The cloudinary transformations to apply to the photo

    hero_photo: string | null; // The cloudinary public_id of the the photo
    hero_transformations: CloudinaryTransformationsType; // The cloudinary transformations to apply to the photo
    distance?: number;
    funeral_home_ids_with_access: number[];
}

const TreeProjectRequestDefinition = {
    name: t.string,
    subtitle: t.string,
    faq: t.type({
        data: t.array(t.type({
            q: t.string,
            a: t.string,
        })),
    }),
    description: t.string,
    rank: t.number,
    states: t.string,
    arbor_day_project_id: t.string,
    start_date: DateFromISOString,
    cost_per_tree: t.number,
    all_fh_can_access: t.boolean,
    funeral_home_ids_with_access: t.union([t.literal('ALL'), t.array(t.number)]),
};

const TreeProjectCreateRequestType = t.type(TreeProjectRequestDefinition);
export interface TreeProjectCreateRequest extends t.TypeOf<typeof TreeProjectCreateRequestType> {
}

export class TreeProjectCreateRequest {
    public static fromRequest = getValidator<TreeProjectCreateRequest>(TreeProjectCreateRequestType);
}

const TreeProjectUpdateRequestType = t.partial(TreeProjectRequestDefinition);

export interface TreeProjectUpdateRequest extends t.TypeOf<typeof TreeProjectUpdateRequestType> {
}

export class TreeProjectUpdateRequest {
    public static fromRequest = getValidator<TreeProjectUpdateRequest>(TreeProjectUpdateRequestType);
}

export interface FuneralHomeWithTreeProjectAccessRecord {
    tree_project_id: number;
    funeral_home_id: number;
}

export interface PriceMatrixItem {
    quantity: number;
    name: string;
    price: number;
    deleted_time: Date | null;
    type: FlowerSaleType;
}

export type PriceMatrix = PriceMatrixItem[];

export interface TreeProjectAndPriceResponse {
    project: TreeProjectUX;
    priceMatrix: PriceMatrix;
}

export const getPriceMatrixItemLabel = (quantity: number, label: string, labelPl?: string): string => {
    const plural = labelPl || `${label}s`;
    return quantity < 2
        ? `A single ${label}`
        : `A grove of ${quantity} ${plural}`;
};

export const ecomListId = (project: TreeProjectUX): string => `Trees-${project.arbor_day_project_id}`;

export const ecomItemFromPriceMatrixItem = (params: {
    item: PriceMatrixItem;
    funeralHomeKey: string;
    project: TreeProjectUX;
}) => {
    const { item, funeralHomeKey, project } = params;
    return {
        affiliation: funeralHomeKey,
        item_list_id: ecomListId(project),
        item_id: `${item.type}-quantity-${item.quantity}`,
        item_name: getPriceMatrixItemLabel(item.quantity, 'tree'),
        item_variant: project.name,
        currency: 'USD',
        item_brand: 'ArborDay',
        item_category: 'Tree',
        price: item.price / 100.0,
        quantity: 1,
    };
};

const TreePaymentDataType = t.type({
    caseName: t.string,
    name: t.string,
    displayName: t.union([t.string, t.undefined]),
    email: t.string,
    message: t.string,
    shareMessage: t.boolean,
    arbor_day_project_id: t.string,
    quantity: t.number,
    total: t.number,
    projectName: t.string,
    created_by: t.union([t.number, t.undefined]),
});

export interface TreePaymentData extends t.TypeOf<typeof TreePaymentDataType> { }

export class TreePaymentData {
    public static fromRequest = getValidator<TreePaymentData>(TreePaymentDataType);
}

export interface UpdateTreePaymentData extends TreePaymentData {
    paymentIntentId: string;
    caseUuid: string;
    gatherCaseId: number;
}

export class UpdateTreePaymentData {
    public static fromRequest = getValidator<UpdateTreePaymentData>(t.type({
        ...TreePaymentDataType.props,
        caseUuid: t.string,
        gatherCaseId: t.number,
        paymentIntentId: t.string,
    }));
}

export interface StateIcon {
    name: string;
    icon: string;
    lat: number | null;
    lon: number | null;
}

export const StateIconMap: Record<string, StateIcon> = {
    AL: { name: 'Alabama', icon: 'Alabama.png', lat: 32.7794, lon: -86.8287 },
    AK: { name: 'Alaska', icon: 'Alaska.png', lat: 64.0685, lon: -152.2782 },
    AZ: { name: 'Arizona', icon: 'Arizona.png', lat: 34.2744, lon: -111.6602 },
    AR: { name: 'Arkansas', icon: 'Arkansas.png', lat: 34.8938, lon: -92.4426 },
    CA: { name: 'California', icon: 'California.png', lat: 37.1841, lon: -119.4696 },
    CO: { name: 'Colorado', icon: 'Colorado.png', lat: 38.9972, lon: -105.5478 },
    CT: { name: 'Connecticut', icon: 'Connecticut.png', lat: 41.6219, lon: -72.7273 },
    DE: { name: 'Delaware', icon: 'Delaware.png', lat: 38.9896, lon: -75.505 },
    FL: { name: 'Florida', icon: 'Florida.png', lat: 28.6305, lon: -82.4497 },
    GA: { name: 'Georgia', icon: 'Georgia.png', lat: 32.6415, lon: -83.4426 },
    HI: { name: 'Hawaii', icon: 'Hawaii.png', lat: 20.2927, lon: -156.3737 },
    ID: { name: 'Idaho', icon: 'Idaho.png', lat: 44.3509, lon: -114.613 },
    IL: { name: 'Illinois', icon: 'Illinois.png', lat: 40.0417, lon: -89.1965 },
    IN: { name: 'Indiana', icon: 'Indiana.png', lat: 39.8942, lon: -86.2816 },
    IA: { name: 'Iowa', icon: 'Iowa.png', lat: 42.0751, lon: -93.496 },
    KS: { name: 'Kansas', icon: 'Kansas.png', lat: 38.4937, lon: -98.3804 },
    KY: { name: 'Kentucky', icon: 'Kentucky.png', lat: 37.5347, lon: -85.3021 },
    LA: { name: 'Louisiana', icon: 'Louisiana.png', lat: 31.0689, lon: -91.9968 },
    ME: { name: 'Maine', icon: 'Maine.png', lat: 45.3695, lon: -69.2428 },
    MD: { name: 'Maryland', icon: 'Maryland.png', lat: 39.055, lon: -76.7909 },
    MA: { name: 'Massachusetts', icon: 'Massachusetts.png', lat: 42.2596, lon: -71.8083 },
    MI: { name: 'Michigan', icon: 'Michigan.png', lat: 44.3467, lon: -85.4102 },
    MN: { name: 'Minnesota', icon: 'Minnesota.png', lat: 46.2807, lon: -94.3053 },
    MO: { name: 'Missouri', icon: 'Missouri.png', lat: 38.3566, lon: -92.458 },
    MT: { name: 'Montana', icon: 'Montana.png', lat: 47.0527, lon: -109.6333 },
    NE: { name: 'Nebraska', icon: 'Nebraska.png', lat: 41.5378, lon: -99.7951 },
    NV: { name: 'Nevada', icon: 'Nevada.png', lat: 39.3289, lon: -116.6312 },
    NH: { name: 'New Hampshire', icon: 'New Hampshire.png', lat: 43.6805, lon: -71.5811 },
    NJ: { name: 'New Jersey', icon: 'New Jersey.png', lat: 40.1907, lon: -74.6728 },
    NM: { name: 'New Mexico', icon: 'New Mexico.png', lat: 34.4071, lon: -106.1126 },
    NY: { name: 'New York', icon: 'New York.png', lat: 42.9538, lon: -75.5268 },
    NC: { name: 'North Carolina', icon: 'North Carolina.png', lat: 35.5557, lon: -79.3877 },
    ND: { name: 'North Dakota', icon: 'North Dakota.png', lat: 47.4501, lon: -100.4659 },
    OH: { name: 'Ohio', icon: 'Ohio.png', lat: 40.2862, lon: -82.7937 },
    OK: { name: 'Oklahoma', icon: 'Oklahoma.png', lat: 35.5889, lon: -97.4943 },
    OR: { name: 'Oregon', icon: 'Oregon.png', lat: 43.9336, lon: -120.5583 },
    PA: { name: 'Pennsylvania', icon: 'Pennsylvania.png', lat: 40.8781, lon: -77.7996 },
    PR: { name: 'Puerto Rico', icon: 'Puerto Rico.png', lat: 18.2223, lon: -66.4303 },
    RI: { name: 'Rhode Island', icon: 'Rhode Island.png', lat: 41.6762, lon: -71.5562 },
    SC: { name: 'South Carolina', icon: 'South Carolina.png', lat: 33.9169, lon: -80.8964 },
    SD: { name: 'South Dakota', icon: 'South Dakota.png', lat: 44.4443, lon: -100.2263 },
    TN: { name: 'Tennessee', icon: 'Tennessee.png', lat: 35.858, lon: -86.3505 },
    TX: { name: 'Texas', icon: 'Texas.png', lat: 31.4757, lon: -99.3312 },
    US: { name: 'USA', icon: 'USA.png', lat: null, lon: null },
    UT: { name: 'Utah', icon: 'Utah.png', lat: 39.3055, lon: -111.6703 },
    VT: { name: 'Vermont', icon: 'Vermont.png', lat: 44.0687, lon: -72.6658 },
    VA: { name: 'Virginia', icon: 'Virginia.png', lat: 37.5215, lon: -78.8537 },
    WA: { name: 'Washington', icon: 'Washington.png', lat: 47.3826, lon: -120.4472 },
    WV: { name: 'West Virginia', icon: 'West Virginia.png', lat: 38.6409, lon: -80.6227 },
    WI: { name: 'Wisconsin', icon: 'Wisconsin.png', lat: 44.6243, lon: -89.9941 },
    WY: { name: 'Wyoming', icon: 'Wyoming.png', lat: 42.9957, lon: -107.5512 },
    MS: { name: 'Mississippi', icon: 'Mississippi.png', lat: 32.7364, lon: -89.6678 },
};

export const deg2rad = (deg: number): number => deg * (Math.PI / 180);

interface Point {
    lat: number | null;
    lon: number | null;
}

const EartRadius = 6371; // Radius of the earth in km 
const MaxDistance = EartRadius * Math.PI;

// Given two pairs of latitude and a longitude as numbers, get the distance between them in Km 
// As the crow flies, BTW
export const getDistance = (point1: Point, point2: Point): number => {
    // If either of the points has a null component then return the max distance
    if (point1.lat === null || point1.lon === null || point2.lat === null || point2.lon === null) {
        return MaxDistance;
    }

    const dLat = deg2rad(point2.lat - point1.lat);
    const dLon = deg2rad(point2.lon - point1.lon);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(point1.lat)) * Math.cos(deg2rad(point2.lat)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
    const distanceInKm = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return distanceInKm;
};

export const getDistanceBetweenStateAndProject = (stateAbb: string | null, projects: TreeProjectUX): number => {
    if (!stateAbb) {
        return MaxDistance;
    }
    const state = StateIconMap[stateAbb];
    if (!state) {
        return MaxDistance;
    }
    const statePoint = { lat: state.lat, lon: state.lon };
    const projectStates = projects.states.split(',');
    const projectPoints = projectStates.map((projectState) => {
        const pstate = StateIconMap[projectState];
        if (!pstate) {
            return { lat: null, lon: null };
        }
        return { lat: pstate.lat, lon: pstate.lon };
    });
    const distances = projectPoints.map((projectPoint) => getDistance(statePoint, projectPoint));
    return Math.min(...distances);
};

export const sortProjectsByDistanceFromState = (state: string, projects: TreeProjectUX[]): TreeProjectUX[] => {
    const projectsWithDistance = projects.map((project) => {
        const distance = getDistanceBetweenStateAndProject(getStateAbbreviation(state), project);
        return { ...project, distance };
    });
    const sortedProjects = projectsWithDistance.sort((a, b) => {
        if (a.featured_project && !b.featured_project) {
            return -1;
        }
        if (!a.featured_project && b.featured_project) {
            return 1;
        }
        return a.distance - b.distance;
    });
    return sortedProjects;
};

export const getStateIcons = (stateAbbCsv: string): string[] => {
    const states = stateAbbCsv.split(',');
    const statesImages = states.map((stateAbb) => {
        if (!StateIconMap[stateAbb]) {
            return '';
        }
        const iconName = StateIconMap[stateAbb].icon;
        return `/static/images/stateOutlines/${iconName}`;
    }).filter((icon) => icon !== '');
    if (statesImages.length > 0) {
        return statesImages;
    } else {
        return ['/static/images/stateOutlines/World.png'];
    }
};
