import * as React from 'react';
import { debounce } from 'lodash';
import {
    DeathCertificateAboutType,
    DeathCertificateEducationType,
    DeathCertificateLifeType,
    DeathCertificateMarriageType,
    DeathCertificateMilitaryType,
    DeathCertificateParentsType,
    DeathCertificateRaceType,
    DeathCertificateRestingPlaceType,
    DeathCertificateWorkHistoryType,
    DeathCertificateConfigUX,
    UserProfile,
} from '../../../../shared/types';
import { InformantData } from '../../../../shared/death_certificate/validators/approval';

export type SupportedFormData =
    DeathCertificateAboutType |
    DeathCertificateEducationType |
    DeathCertificateLifeType |
    DeathCertificateMarriageType |
    DeathCertificateMilitaryType |
    DeathCertificateParentsType |
    DeathCertificateRaceType |
    DeathCertificateRestingPlaceType |
    DeathCertificateWorkHistoryType |
    InformantData
    ;

export interface ParentProps<T> {
    onSaveData: (data: T) => void;
    data: T;
    dcConfig: DeathCertificateConfigUX | null;
    user: UserProfile;
    active: boolean;
    isDeathCertificateLocked: boolean;
    isEditMode: boolean;
    formRef?: React.RefObject<HTMLDivElement>;
}

interface ParentState<T extends SupportedFormData> {
    localData: T;
}

export abstract class FormParent<
    T extends SupportedFormData,
    Props,
    State,
> extends React.Component<Props & ParentProps<T>, State & ParentState<T>> {

    protected debouncedSave = debounce(() => this.save(), 500);

    componentDidMount() {
        // if any initially pass validation, the form has been touched and
        // all properties should be validated
        if (this.checkIfTouched()) {
            this.validateAll();
        }
    }

    componentWillUnmount() {
        // save changes prior to unmounting
        this.debouncedSave.flush();
    }

    componentDidUpdate(prevProps: ParentProps<T>) {
        // if form is going from inactive to active, and is touched, validateAll
        if (!prevProps.active && this.props.active && this.checkIfTouched()) {
            this.validateAll();
        }
    }

    updateLocalData = <K extends keyof T>(
        changes: Partial<T> | ((prevState: Readonly<T>) => Pick<T, K>),
        callback?: () => void,
    ) => {
        if (typeof changes === 'function') {
            this.setState(
                (prevState) => ({
                    localData: Object.assign(
                        {},
                        prevState.localData,
                        changes(prevState.localData),
                    ),
                }),
                callback
            );
        } else {
            this.setState(
                (prevState) => ({
                    localData: Object.assign(
                        {},
                        prevState.localData,
                        changes,
                    ),
                }),
                callback,
            );
        }
    };

    updateData = <K extends keyof T>(
        changes: Partial<T> | ((prevState: Readonly<T>) => Pick<T, K>),
        callback?: () => void,
    ) => {

        this.updateLocalData(changes, callback);
        this.debouncedSave();
    };

    private save() {
        const { onSaveData } = this.props;
        const { localData } = this.state;

        onSaveData(localData);
    }

    abstract checkValid(): boolean;
    abstract checkIfTouched(): boolean;
    abstract validateAll(): void;
}
