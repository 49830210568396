import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import ListItem from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";
import classNames from "classnames";
import { GatherEvent } from "../../../shared/types";
import EventDetails from "./EventDetails";
import { GroupedGatherEvent, isGroupedEvent } from "./EventList";
import makeStyles from "@mui/styles/makeStyles";
import { SxProps, Theme, alpha } from "@mui/material/styles";
import moment from "moment-timezone";
import { useGSelector } from "../../../types";

const useStyles = makeStyles((theme: Theme) => ({
    gridListTitle: {
        boxSizing: 'border-box',
        flexShrink: 0,
        display: 'flex',
        padding: 0,
        flexWrap: 'wrap',
        overflowY: 'auto',
        listStyle: 'none',
        '-webkit-overflow-scrolling': 'touch',
        margin: '0 13px'
    },
    buttonBoxShadow: {
        boxShadow: `${theme.shadows[5]} !important`,
        borderRadius: 8,
    },
    buttonGroup: {
        minWidth: 270,
        height: 72,
        padding: 0,
        borderRadius: 8,
        position: 'relative',
        color: theme.palette.primary.main,
        background: '#fff',
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.08),
        },
        '&:hover, &$buttonGroupFocus': {
            '& $buttonGroupPrepend': {
                opacity: 0.8,
            },
        }
    },
    buttonGroupDisabled: {
        opacity: 0.60,
        pointerEvents: 'auto !important' as 'auto'
    },
    buttonGroupPrepend: {
        width: 84,
        minWidth: 84,
        height: 58,
        padding: '6px 0px',
        display: 'inline-block',
        borderRadius: '6px 0px 0px 6px',
        '& $buttonPrependTypography': {
            color: '#fff',
            fontSize: 14,
            lineHeight: 1,
        },
        '& $prependBigText': {
            color: '#fff',
            display: 'block',
            fontSize: 40,
            marginTop: 2,
            lineHeight: '40px',
            fontWeight: 300
        }
    },
    verticalOuter: {
        width: '100%',
        overflowX: 'auto'
    },
    verticalList: {
        margin: '0 auto',
        padding: '12px 8px',
        width: 'max-content',
        '& $gridListTitle': {
            margin: 0,
            width: 'fit-content'
        }
    },
    backgroundPrimary: {
        backgroundColor: theme.palette.primary.main
    },
    prependBigText: {},
    buttonPrependTypography: {},
    buttonGroupFocus: {}
}), { name: 'GroupedEventDetails' });

interface Props<T extends GatherEvent | GroupedGatherEvent> {
    isDirectionVertical: boolean;
    groupedEvent: T;
    sx?: SxProps<Theme>;
    onEventClick: (event: React.MouseEvent<HTMLElement>, gatherEvent: GatherEvent) => void;
    renderAdditionalEventContent?: (event: T) => JSX.Element;
}
const GroupedEventDetails = <T extends GatherEvent | GroupedGatherEvent>(props: Props<T>) => {
    const { isDirectionVertical, groupedEvent, sx, onEventClick, renderAdditionalEventContent } = props;

    const classes = useStyles();
    const locations = useGSelector(({ locationState }) => locationState.locations);

    let isExpired = false;
    let dayOfWeek = '';
    let date = '';
    let events: GatherEvent[] = [];

    if (isGroupedEvent(groupedEvent)) {
        isExpired = groupedEvent.isExpired;
        dayOfWeek = groupedEvent.dayOfWeek;
        date = groupedEvent.date;
        events = groupedEvent.events;
    } else {
        const eventLocation = locations.find((loc) => loc.id === groupedEvent.location_id);

        const eventTimezoneName = eventLocation && eventLocation.address.timezone || moment.tz.guess();

        const startMoment = moment(groupedEvent.start_time).tz(eventTimezoneName);
        date = startMoment.format('DD');
        dayOfWeek = startMoment.format('dddd');
        events = [groupedEvent];
    }

    return (
        <>
            <Grid item className={isDirectionVertical ? classes.verticalOuter : undefined} sx={sx}>
                <Grid
                    item
                    width="max-content"
                    className={isDirectionVertical ? classes.verticalList : undefined}
                >
                    <ListItem
                        className={classNames(
                            classes.gridListTitle,
                            !isExpired && classes.buttonBoxShadow
                        )}
                    >
                        <ButtonBase
                            focusRipple
                            className={classNames(
                                classes.buttonGroup,
                                isExpired && classes.buttonGroupDisabled
                            )}
                            focusVisibleClassName={classes.buttonGroupFocus}
                        >
                            <div
                                className={classNames(
                                    classes.buttonGroupPrepend,
                                    classes.backgroundPrimary
                                )}
                            >
                                <Typography
                                    align="center"
                                    className={classes.buttonPrependTypography}
                                    component="p"
                                >
                                    {dayOfWeek}
                                    <span className={classes.prependBigText}>{date}</span>
                                </Typography>
                            </div>
                            {(events || []).map((gatherEvent: GatherEvent, index: number) =>
                                <EventDetails
                                    key={gatherEvent.id}
                                    event={gatherEvent}
                                    index={index}
                                    onClick={(clickEvent) => onEventClick(clickEvent, gatherEvent)}
                                    groupedEventsCount={(events || []).length}
                                />
                            )}
                        </ButtonBase>
                    </ListItem>

                    {renderAdditionalEventContent?.(groupedEvent)}
                </Grid>
            </Grid>
        </>
    );
};

export default GroupedEventDetails;