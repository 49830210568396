import classNames from 'classnames';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';

import {
    GatherCaseUX,
    DeathCertificateType,
    EntitySummary,
    getCaseEntity,
    DeathCertificateConfigUX,
    UserProfile,
} from '../../../../shared/types';
import { FormParent, ParentProps } from './FormParent';
import {
    approvalMasterValidators,
    approvalValidators,
    InformantData,
} from '../../../../shared/death_certificate/validators/approval';
import { deathCertificateValidator } from '../../../../shared/death_certificate/validators';
import LockContent from '../LockContent';
import { DCEntityEnum } from '../../../assignmentPoppers/SelectHelper';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles, withStyles } from '@mui/styles';
import { GStyles } from '../../../../styles/GStyles';
import DCInvitationForm from './DCInvitaionForm';

interface Props {
    user: UserProfile;
    helpers: EntitySummary[];
    activeCase: GatherCaseUX;
    dc: DeathCertificateType;
    dcConfig: DeathCertificateConfigUX;
    onLockedChange: (isLocked: boolean) => void;
    editHelper: (helper: EntitySummary) => void;
}

interface State {
    localData: InformantData;
}

const styles: StyleRulesCallback<Theme, Props & ParentProps<InformantData>> = theme => ({
    root: {
        justifyContent: 'center',
        flexGrow: 1,
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
    },
    inviteHelperForm: {
        margin: 'auto',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 500,
        },
        '@media (max-width: 599px)': {
            padding: '0 24px',
        },
        minHeight: 605,
    },
    toggleButtonGroupLabel: {
        position: 'relative',
        transform: 'none',
        textAlign: 'center',
        whiteSpace: 'unset',
    },
    lockContentBox: {
        width: '300px',
        margin: '30px auto',
        borderRadius: '12px',
    },
    dcRelatedEntitiesContainer: {
        margin: '30px 0 0px',
        maxWidth: 360,
        textAlign: 'center',
    }
});

type StyledProps = Props & WithStyles<'root' | 'inviteHelperForm' | 'toggleButtonGroupLabel'
    | 'lockContentBox' | 'dcRelatedEntitiesContainer'>;

class ApprovalForm extends FormParent<InformantData, StyledProps, State> {

    state: State = {
        localData: this.props.data,
    };

    checkValid() {
        const { helpers, activeCase } = this.props;
        const { localData } = this.state;

        return approvalMasterValidators.valid(
            {
                ...activeCase,
                dc_informant: localData.dc_informant
            },
            helpers,
        );
    }

    validateAll() {
        const { helpers, activeCase } = this.props;
        const { localData } = this.state;
        approvalValidators.informant(
            {
                ...activeCase,
                dc_informant: localData.dc_informant,
            },
            helpers,
        );
    }

    checkIfTouched() {
        const { activeCase } = this.props;
        const { localData } = this.state;

        return approvalMasterValidators.touched({
            ...activeCase,
            dc_informant: localData.dc_informant,
        });
    }

    render() {
        const {
            classes,
            activeCase,
            user,
            dc,
            isDeathCertificateLocked,
            isEditMode,
            helpers,
            dcConfig,
            onLockedChange,
        } = this.props;
        const {
            localData,
        } = this.state;

        if (!user) {
            return null;
        }

        return (
            <div>
                <Grid
                    item
                    xs={12}
                >

                    <form
                        className={classNames(GStyles.overflowHidden, classes.inviteHelperForm)}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid
                            container
                            alignItems="flex-end"
                            justifyContent="center"
                        >
                            <Grid item className={GStyles.width100}>
                                <FormControl
                                    className={classNames(GStyles.width100, GStyles.textLeft, GStyles.marginTop40)}
                                >
                                    <InputLabel
                                        className={classNames(classes.toggleButtonGroupLabel, 'width-100')}
                                    >
                                        Please select the person who supplied
                                        the majority of these personal facts about {activeCase.fname}
                                    </InputLabel>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} className={classes.dcRelatedEntitiesContainer}>
                                <DCInvitationForm
                                    DCEntity='Informant'
                                    activeCase={activeCase}
                                    isDeathCertificateLocked={isDeathCertificateLocked || isEditMode}
                                    onEntityChange={this.handleInformantChange}
                                    relationToDeceased={DCEntityEnum.Informant}
                                    selectedCaseEntityId={localData.dc_informant}
                                    selectedEntityId={activeCase.dc_informant_entity_id}
                                    updateState={(helper) => {
                                        this.handleInformantChange(helper);
                                    }}
                                    zIndex={1320}
                                />
                            </Grid>
                            <div
                                className={classNames(
                                    GStyles.background_primary_0_2,
                                    classes.lockContentBox
                                )}
                            >
                                <LockContent
                                    activeCase={activeCase}
                                    isLocked={isDeathCertificateLocked}
                                    onLockedChange={onLockedChange}
                                    dcStatus={deathCertificateValidator(dc, dcConfig, activeCase, helpers, user)}
                                    zIndex={1320}
                                />
                            </div>

                        </Grid>

                    </form>

                </Grid>
            </div>
        );
    }

    handleInformantChange = (helper: EntitySummary | null) => {
        const { activeCase } = this.props;

        const caseEntity = getCaseEntity(helper, activeCase.id);
        this.setState({
            localData: {
                dc_informant: caseEntity && caseEntity.case_entity_id || null,
            },
        });
        this.debouncedSave();
    };
}

export default withStyles(styles)(ApprovalForm);

