import classNames from 'classnames';

import Grid from '@mui/material/Grid';

import {
    DeathCertificateRestingPlaceType,
    RestingPlaceOptionsEnum,
    YesNoEnum,
    GmapsSearchType,
    CaseType,
    GatherCaseUX,
    NullLongAddress,
    isLongAddress,
} from '../../../../shared/types';
import { FormParent, ParentProps } from './FormParent';
import {
    restingPlaceValidators,
    restingPlaceMasterValidators
} from '../../../../shared/death_certificate/validators/restingPlace';
import FormTitle from '../common/FormTitle';
import ConfigurableDateField from '../common/fields/dateField/ConfigurableDateField';
import { ConfigurableRestingPlaceFieldKey } from '../../../../shared/death_certificate/validators/config';
import ConfigurableToggleButtonsWrapper from '../common/fields/toggleButtons/ConfigurableToggleButtons';
import { values } from 'lodash';
import
ConfigurableAddressSearchWithCheckbox
    from '../common/fields/addressSearchWithCheckbox/ConfigurableAddressSearchWithCheckbox';
import ConfigurableTextField from '../common/fields/textField/ConfigurableTextField';
import { StyleRulesCallback, WithStyles, withStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { GmapsSearchAddress } from '../../../gmapsSearch/GmapsSearch';
import { ConfigurableToggleButtonsForYesNo } from './AboutForm';
import { log } from '../../../../logger';
import { GStyles } from '../../../../styles/GStyles';

const ConfigurableToggleButtons = ConfigurableToggleButtonsWrapper<RestingPlaceOptionsEnum>();

interface Props {
    caseFname: string;
    activeCase: GatherCaseUX;
    handleOpenSnackbar: () => void;
}

interface State {
    localData: DeathCertificateRestingPlaceType;
    optionValidator: boolean | null;
    specifyOtherValidator: boolean | null;
    laidToRestDateValidator: boolean | null;
    ownsCemeteryPropertyValidator: boolean | null;
    dispositionPlaceValidator: boolean | null;
    crematoryNameValidator: boolean | null;
    secondDispositionPlaceValidator: boolean | null;
    cemeteryPropertyDetailsValidator: boolean | null;
}

const styles: StyleRulesCallback<Theme, Props & ParentProps<DeathCertificateRestingPlaceType>> = (theme) => ({
    root: {
        justifyContent: 'center',
        flexGrow: 1,
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
    },
    inviteHelperForm: {
        '@media (max-width: 599px)': {
            padding: '0 24px',
        },
        margin: 'auto',
        '@media (min-width: 500px)': {
            maxWidth: 'fit-content',
        },
    },
    toggleButton: {
        fontSize: '13px !important',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    inputTextWidth: {
        width: '100%',
        maxWidth: '100%',
        '@media (min-width: 421px)': {
            width: 360,
            maxWidth: 360,
        },
    },
    paddingBottom0: {
        paddingBottom: '0 !important'
    },
    gmapSearch: {
        width: 240,
        maxWidth: 240,
        '@media (min-width: 421px)': {
            width: 320,
            maxWidth: 320,
        },
    },
    maxWidth450: {
        margin: 'auto',
        '@media (min-width: 500px)': {
            maxWidth: '450px !important',
        },
    },
    restingButton: {
        '@supports (-webkit-touch-callout: none)': {
            '& button:nth-of-type(5)': {
                marginTop: -2
            }
        }
    }
});

type StyledProps = Props & WithStyles<'root' | 'inviteHelperForm' | 'toggleButton' | 'inputTextWidth'
    | 'paddingBottom0' | 'gmapSearch' | 'maxWidth450' | 'restingButton'>;

class RestingPlaceForm extends FormParent<DeathCertificateRestingPlaceType, StyledProps, State> {

    state: State = {
        localData: this.props.data,
        optionValidator: null,
        specifyOtherValidator: null,
        laidToRestDateValidator: null,
        ownsCemeteryPropertyValidator: null,
        dispositionPlaceValidator: null,
        crematoryNameValidator: null,
        secondDispositionPlaceValidator: null,
        cemeteryPropertyDetailsValidator: null
    };

    otherSpecify = () => {
        const {
            isDeathCertificateLocked,
            handleOpenSnackbar,
            isEditMode
        } = this.props;
        const {
            specifyOtherValidator,
            localData,
        } = this.state;
        return (
            <Grid container alignItems="flex-end" justifyContent="center" >
                <Grid item className={classNames(GStyles.width100, GStyles.textCenter)}>
                    <ConfigurableTextField
                        error={specifyOtherValidator === false}
                        label={'Please specify "Other" here'}
                        id={ConfigurableRestingPlaceFieldKey.specifyOtherBurialMethod}
                        value={localData.specifyOther || ''}
                        onChange={this.handleSpecifyOtherChange}
                        onBlur={this.validateSpecifyOther}
                        disabled={isDeathCertificateLocked}
                        handleOpenSnackbar={handleOpenSnackbar}
                        isEditMode={isEditMode}
                    />
                </Grid>
            </Grid>
        );
    };

    handleCemeteryPropertyDetailsChange = (value: string) => {
        this.updateData({ cemeteryPropertyDetails: value }, this.validateCemeteryPropertyDetails);
    };

    handleSpecifyOtherChange = (value: string) => {

        this.updateData({ specifyOther: value }, this.validateSpecifyOther);
    };

    handleDispositionDateChange = (dispositionDate: string) => {
        this.updateLocalData({ dispositionDate }, this.validateLaidToRestDate);
    };

    handleOptionChange = (options: RestingPlaceOptionsEnum) => {

        const changes: Partial<DeathCertificateRestingPlaceType> = {
            options,
        };

        // if is not other, clear the specifyOther field
        if (options !== 'Other') {
            changes.specifyOther = '';
        }
        this.updateData(changes, this.validateOption);
    };

    handleDispositionPlace = (dispositionPlace: GmapsSearchAddress) => {
        if (!isLongAddress(dispositionPlace)) {
            log.warn('Invalid disposition place entered', { dispositionPlace });
            return;
        }
        this.updateData({ dispositionPlace });
    };

    handleCemeteryPropertyChange = (ownsCemeteryProperty: YesNoEnum) => {

        this.updateData({ ownsCemeteryProperty }, this.validateOwnsCemeteryProperty);
    };

    handleCrematoryNameChange = (value: string) => {
        this.updateData({ crematoryName: value }, this.validateCrematoryName);
    };

    handleSecondDispositionLocation = (place: GmapsSearchAddress) => {
        if (!isLongAddress(place)) {
            log.warn('Invalid second disposition place entered', { place });
            return;
        }
        this.updateData({ secondDispositionPlace: place }, this.validateSecondDispositionPlace);
    };

    handleSecondDispositionPlaceUnknown = (isChecked: boolean) => {
        const updatedPlace = {
            ...NullLongAddress,
            unknown: isChecked,
        };

        this.updateData(
            { secondDispositionPlace: updatedPlace },
            this.validateSecondDispositionPlace
        );
    };

    // VALIDATION
    validateSpecifyOther = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            specifyOtherValidator: restingPlaceValidators.specifyOther(localData, dcConfig, user),
        });
    };

    validateLaidToRestDate = () => {
        const { dcConfig, user, activeCase } = this.props;
        const { localData } = this.state;
        this.setState({
            laidToRestDateValidator: restingPlaceValidators.laidToRestDate(localData, dcConfig, activeCase, user),
        });
    };

    validateOption = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            optionValidator: restingPlaceValidators.option(localData, dcConfig, user),
        });
    };

    validateOwnsCemeteryProperty = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;
        this.setState({
            ownsCemeteryPropertyValidator: restingPlaceValidators.ownsCemeteryProperty(localData, dcConfig, user),
        });
    };

    validateDispositionPlace = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            dispositionPlaceValidator: restingPlaceValidators.dispositionPlace(localData, dcConfig, user)
        });
    };

    validateCrematoryName = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            crematoryNameValidator: restingPlaceValidators.crematoryName(localData, dcConfig, user)
        });
    };

    validateCemeteryPropertyDetails = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            cemeteryPropertyDetailsValidator: restingPlaceValidators.cemeteryPropertyDetails(localData, dcConfig, user)
        });
    };

    validateSecondDispositionPlace = () => {
        const { dcConfig, user } = this.props;
        const { localData } = this.state;

        this.setState({
            secondDispositionPlaceValidator: restingPlaceValidators.secondDispositionPlace(localData, dcConfig, user)
        });
    };

    checkValid() {
        const { dcConfig, user, activeCase } = this.props;
        const { localData } = this.state;
        return restingPlaceMasterValidators.valid(localData, dcConfig, activeCase, user);
    }

    validateAll() {
        this.validateOption();
        this.validateSpecifyOther();
        this.validateLaidToRestDate();
        this.validateOwnsCemeteryProperty();
        this.validateDispositionPlace();
        this.validateCrematoryName();
        this.validateCemeteryPropertyDetails();
        this.validateSecondDispositionPlace();
    }

    checkIfTouched() {
        const { dcConfig, user, activeCase } = this.props;
        const { localData } = this.state;
        return restingPlaceMasterValidators.touched(localData, dcConfig, activeCase, user);
    }

    getContentIfOwnsCemeteryProperty = () => {
        const {
            isDeathCertificateLocked,
            handleOpenSnackbar,
            isEditMode
        } = this.props;
        const {
            localData,
            cemeteryPropertyDetailsValidator
        } = this.state;

        return (
            <>
                <Grid container alignItems="flex-end" justifyContent="center" >
                    <Grid item className={GStyles.width100}>
                        <ConfigurableTextField
                            label="Details about property (type, section, lot, etc.)"
                            multiline
                            id={ConfigurableRestingPlaceFieldKey.cemeteryPropertyDetails}
                            value={localData.cemeteryPropertyDetails}
                            onChange={this.handleCemeteryPropertyDetailsChange}
                            onBlur={this.validateCemeteryPropertyDetails}
                            disabled={isDeathCertificateLocked}
                            handleOpenSnackbar={handleOpenSnackbar}
                            error={cemeteryPropertyDetailsValidator === false}
                            isEditMode={isEditMode}
                        />
                    </Grid>
                </Grid>
            </>
        );
    };

    render() {
        const {
            classes,
            caseFname,
            isDeathCertificateLocked,
            activeCase,
            handleOpenSnackbar,
            isEditMode,
        } = this.props;
        const {
            laidToRestDateValidator,
            ownsCemeteryPropertyValidator,
            localData,
            optionValidator,
            dispositionPlaceValidator,
            crematoryNameValidator,
            secondDispositionPlaceValidator
        } = this.state;

        return (
            <div ref={this.props.formRef}>
                <Grid
                    item
                    xs={12}
                >
                    <FormTitle title="Resting Place" complete={this.checkIfTouched() ? this.checkValid() : null} />
                    <form
                        className={classNames(GStyles.overflowHidden, classes.inviteHelperForm)}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container alignItems="flex-end" justifyContent="center" >
                            <Grid item className={GStyles.width100}>
                                <ConfigurableToggleButtons
                                    id={ConfigurableRestingPlaceFieldKey.burialMethod}
                                    label="Which option has been selected?"
                                    options={values(RestingPlaceOptionsEnum)}
                                    handleOpenSnackbar={handleOpenSnackbar}
                                    selected={localData.options || null}
                                    isEditMode={isEditMode}
                                    onSelect={this.handleOptionChange}
                                    error={optionValidator === false}
                                    rootClass={classNames(GStyles.textLeft, GStyles.marginTop40)}
                                    disabled={isDeathCertificateLocked}
                                    buttonContainerClass={classes.restingButton}
                                />
                            </Grid>
                        </Grid>
                        {localData.options === 'Other' ? this.otherSpecify() : ''}
                    </form>
                    {localData.options !== undefined && (
                        <>
                            {(localData.options === RestingPlaceOptionsEnum.Cremation ||
                                localData.options === RestingPlaceOptionsEnum['Cremation with Burial'] ||
                                localData.options === RestingPlaceOptionsEnum['Cremation with Entombment']) && (
                                    <Grid
                                        container
                                        alignItems="flex-end"
                                        justifyContent="center"
                                        className={classNames(classes.gmapSearch, GStyles.marginAuto)}
                                    >
                                        <Grid item className={classNames(GStyles.width100, GStyles.textCenter)}>
                                            <ConfigurableTextField
                                                id={ConfigurableRestingPlaceFieldKey.crematoryName}
                                                label="Crematory Name"
                                                value={localData.crematoryName || ''}
                                                onChange={this.handleCrematoryNameChange}
                                                onBlur={this.validateCrematoryName}
                                                disabled={isDeathCertificateLocked}
                                                error={crematoryNameValidator === false}
                                                handleOpenSnackbar={handleOpenSnackbar}
                                                isEditMode={isEditMode}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            <Grid
                                container
                                alignItems="flex-end"
                                justifyContent="center"
                                className={classNames(classes.gmapSearch, GStyles.marginAuto)}
                            >
                                <ConfigurableAddressSearchWithCheckbox
                                    id={ConfigurableRestingPlaceFieldKey.dispositionPlace}
                                    label={`Where will ${caseFname} be laid to rest?`}
                                    value={localData.dispositionPlace}
                                    searchType={GmapsSearchType.longAddress}
                                    onChange={this.handleDispositionPlace}
                                    disabled={isDeathCertificateLocked}
                                    handleOpenSnackbar={handleOpenSnackbar}
                                    zIndex={1320}
                                    controlClass={classes.inputTextWidth}
                                    rootClass={classNames(
                                        classes.inputTextWidth,
                                        GStyles.textCenter,
                                        GStyles.marginAuto,
                                        classes.paddingBottom0
                                    )}
                                    checkboxLabel="Unknown or Unnecessary"
                                    onCheckboxChange={this.handleDispositionPlaceUnknown}
                                    isChecked={!!localData.dispositionPlace.unknown}
                                    isEditMode={isEditMode}
                                    error={dispositionPlaceValidator === false}
                                />
                            </Grid>
                        </>
                    )}
                    <form
                        className={classNames(GStyles.overflowHidden, classes.inviteHelperForm, classes.maxWidth450)}
                        noValidate
                        autoComplete="off"
                    >
                        {localData.options !== undefined && activeCase.case_type !== CaseType.pre_need && (
                            <Grid container alignItems="flex-end" justifyContent="center" >
                                <Grid item className={classNames(GStyles.width100, GStyles.textCenter)}>
                                    <ConfigurableDateField
                                        id={ConfigurableRestingPlaceFieldKey.dispositionDate}
                                        label={`When will ${caseFname} be laid to rest?`}
                                        value={localData.dispositionDate}
                                        onChange={this.handleDispositionDateChange}
                                        disabled={isDeathCertificateLocked}
                                        onBlur={this.debouncedSave}
                                        error={laidToRestDateValidator === false}
                                        handleOpenSnackbar={handleOpenSnackbar}
                                        isEditMode={isEditMode}
                                    />
                                </Grid>
                            </Grid>
                        )}

                        {(localData.options === RestingPlaceOptionsEnum['Cremation with Burial'] ||
                            localData.options === RestingPlaceOptionsEnum['Cremation with Entombment']) && (
                                <Grid
                                    container
                                    alignItems="flex-end"
                                    justifyContent="center"
                                    className={classNames(classes.gmapSearch, GStyles.marginAuto)}
                                >
                                    <ConfigurableAddressSearchWithCheckbox
                                        id={ConfigurableRestingPlaceFieldKey.secondDispositionPlace}
                                        label={`Where will ${caseFname}'s ashes be laid to rest?`}
                                        value={localData.secondDispositionPlace || { ...NullLongAddress }}
                                        searchType={GmapsSearchType.longAddress}
                                        onChange={this.handleSecondDispositionLocation}
                                        disabled={isDeathCertificateLocked}
                                        handleOpenSnackbar={handleOpenSnackbar}
                                        zIndex={1320}
                                        controlClass={classes.inputTextWidth}
                                        rootClass={classNames(
                                            classes.inputTextWidth,
                                            GStyles.textCenter,
                                            GStyles.marginAuto,
                                            classes.paddingBottom0
                                        )}
                                        checkboxLabel="Unknown or Unnecessary"
                                        onCheckboxChange={this.handleSecondDispositionPlaceUnknown}
                                        isChecked={(localData.secondDispositionPlace &&
                                            localData.secondDispositionPlace.unknown) || false}
                                        isEditMode={isEditMode}
                                        error={secondDispositionPlaceValidator === false}
                                    />
                                </Grid>
                            )}

                        <Grid container alignItems="flex-end" justifyContent="center" >
                            <Grid item className={GStyles.width100}>
                                <ConfigurableToggleButtonsForYesNo
                                    id={ConfigurableRestingPlaceFieldKey.ownCemeteryPlot}
                                    error={ownsCemeteryPropertyValidator === false}
                                    rootClass={GStyles.marginTop40}
                                    labelClass={'width-100'}
                                    label={`Do you own any cemetery property for ${caseFname}?`}
                                    options={values(YesNoEnum)}
                                    selected={localData.ownsCemeteryProperty || null}
                                    disabled={isDeathCertificateLocked}
                                    isEditMode={isEditMode}
                                    handleOpenSnackbar={handleOpenSnackbar}
                                    onSelect={this.handleCemeteryPropertyChange}
                                />
                            </Grid>
                        </Grid>
                        {(localData.ownsCemeteryProperty !== undefined
                            && localData.ownsCemeteryProperty === 'Yes')
                            ? this.getContentIfOwnsCemeteryProperty() : null}
                    </form>
                </Grid>
            </div>
        );
    }

    handleDispositionPlaceUnknown = (isChecked: boolean) => {

        this.updateData({
            dispositionPlace: {
                ...NullLongAddress,
                unknown: isChecked,
            },
        });
    };
}

export default withStyles(styles)(RestingPlaceForm);
